import { useReact_useEffect_Z5ECA432F, useReact_useEffect_Z101E1A95, useReact_useRef_1505, useFeliz_React__React_useState_Static_1505, useReact_useEffect_Z5234A374, React_useStateWithUpdater_1505 } from "../client/src/fable_modules/Feliz.1.68.0/React.fs.js";
import { addSeconds } from "../client/src/fable_modules/fable-library.3.7.17/DateOffset.js";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "notistack";
import { awaitPromise, startImmediate } from "../client/src/fable_modules/fable-library.3.7.17/Async.js";
import { singleton } from "../client/src/fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../Notistack/Notistack.fs.js";

export function useCountdown(fromSeconds) {
    const patternInput = React_useStateWithUpdater_1505(fromSeconds);
    const setSeconds = patternInput[1];
    const seconds = patternInput[0] | 0;
    useReact_useEffect_Z5234A374(() => {
        setSeconds((_arg_1) => fromSeconds);
        const subscriptionId = window.setInterval((_arg) => {
            if (seconds > 0) {
                setSeconds((s) => (s - 1));
            }
        }, 1000);
        return {
            Dispose() {
                window.clearTimeout(subscriptionId);
            },
        };
    }, [fromSeconds]);
    return seconds | 0;
}

export function useDateOffset(date) {
    const patternInput = React_useStateWithUpdater_1505(date);
    const setDate = patternInput[1];
    const date_1 = patternInput[0];
    useReact_useEffect_Z5234A374(() => {
        setDate((_arg_1) => date_1);
        const subscriptionId = window.setInterval((_arg) => {
            setDate((d) => addSeconds(d, 1));
        }, 1000);
        return {
            Dispose() {
                window.clearTimeout(subscriptionId);
            },
        };
    }, [date_1]);
    return date_1;
}

export function useToggle(initial) {
    const patternInput = useFeliz_React__React_useState_Static_1505(initial);
    const setIsOn = patternInput[1];
    const isOn = patternInput[0];
    return {
        Off: () => {
            setIsOn(false);
        },
        On: () => {
            setIsOn(true);
        },
        State: isOn,
        Toggle: () => {
            setIsOn(!isOn);
        },
    };
}

export function usePolling(callback, seconds, dependencies) {
    const savedCallback = useReact_useRef_1505(void 0);
    useReact_useEffect_Z101E1A95(() => {
        savedCallback.current = callback;
    }, dependencies);
    useReact_useEffect_Z5ECA432F(() => {
        const delay = (seconds * 1000) | 0;
        const id = window.setInterval(() => {
            const matchValue = savedCallback.current;
            if (matchValue == null) {
            }
            else {
                matchValue();
            }
        }, delay);
        return {
            Dispose() {
                window.clearInterval(id);
            },
        };
    });
}

export function useDebouncedValue(milliseconds, value) {
    const patternInput = useFeliz_React__React_useState_Static_1505(value);
    useReact_useEffect_Z5234A374(() => {
        const handler = window.setTimeout(() => {
            patternInput[1](value);
        }, milliseconds);
        return {
            Dispose() {
                window.clearTimeout(handler);
            },
        };
    }, [value, milliseconds]);
    return patternInput[0];
}

export function useUsername() {
    const auth = useAuth0();
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => {
            const matchValue = auth.user;
            if (matchValue == null) {
                console.log("Could not get authenticated user name.");
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren. [Could not get username]", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                return singleton.Zero();
            }
            else {
                return singleton.Bind(awaitPromise(auth.getIdTokenClaims()), (_arg) => {
                    patternInput[1](_arg.name);
                    return singleton.Zero();
                });
            }
        }));
    }, [auth]);
    return patternInput[0];
}

