import { useSnackbar } from "notistack";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Modal from "@material-ui/core/Modal";
import { createElement } from "react";
import * as react from "react";
import { comparePrimitives, equals, createObj } from "../fable_modules/fable-library.3.7.17/Util.js";
import { printf, toConsole, join } from "../fable_modules/fable-library.3.7.17/String.js";
import { alignStart, fullWidth, TextColor, Gap, MarginRight, MarginTop, MarginLeft, MarginBottom, Padding, modal } from "../../../Styles/Utils.fs.js";
import { DateParsing_parse, Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { append as append_1, tryFind, toArray as toArray_2, sortBy, singleton as singleton_1, filter, map, empty, ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import Button from "@material-ui/core/Button";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { useForm, useFormValidator, useFormField } from "../../../CustomHooks/ComposedForm.fs.js";
import { NonEmptyString___UnsafeUnwrap_Z73AD07C, Quarter, PartnerId, Email_get_Create, SelectOptionData$1_Create_Z510A3DC0 } from "../../../shared/Domain.fs.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { useToggle } from "../../../CustomHooks/CustomHooks.fs.js";
import { map as map_2, defaultArg, toArray, some } from "../fable_modules/fable-library.3.7.17/Option.js";
import { PartnerOption, PartnerOption_get_empty, PartnerOption_get_none } from "../../../DesignSystems/partner/PartnerUtils.fs.js";
import { collect, toArray as toArray_1, append, empty as empty_1, singleton as singleton_2, delay, toList, iterate } from "../fable_modules/fable-library.3.7.17/Seq.js";
import Send from "@material-ui/icons/Send";
import Book from "@material-ui/icons/Book";
import { create, year as year_1, today } from "../fable_modules/fable-library.3.7.17/Date.js";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import { GeneralFormats_dateTime } from "../../../shared/Format.fs.js";
import { map as map_1 } from "../fable_modules/fable-library.3.7.17/Array.js";
import { partnerGrid } from "../../../Styles/ClientPageStyles.fs.js";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Browser_Types_Event__Event_get_Checked } from "../fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { TextField } from "../../../DesignSystems/DesignSystem.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { Page } from "../components/Page.fs.js";
import Paper from "@material-ui/core/Paper";

export function ConfirmEmailModal(confirmEmailModalInputProps) {
    let elems_1, elems;
    const buttonsDisabled = confirmEmailModalInputProps.buttonsDisabled;
    const onConfirm = confirmEmailModalInputProps.onConfirm;
    const setShowConfirmModal = confirmEmailModalInputProps.setShowConfirmModal;
    const showConfirmationModal = confirmEmailModalInputProps.showConfirmationModal;
    const snackbar = useSnackbar();
    return MuiHelpers_createElement(Modal, [["open", showConfirmationModal], ["onClose", (_arg_2, v) => {
        setShowConfirmModal(false);
    }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, Padding.L])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", [MarginBottom.M])], (elems = [createElement("h3", {
        children: ["Are you sure you want to bulk send emails?"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), MuiHelpers_createElement(Button, [["onClick", (_arg_3) => {
        startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
            onConfirm();
            return singleton.Zero();
        }), (_arg) => {
            const e = _arg;
            setShowConfirmModal(false);
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden tijdens het bevestigen van de afspraak. Gelieve support te contacteren. [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            console.log(e);
            return singleton.Zero();
        })));
    }], ["className", join(" ", [])], ["disabled", buttonsDisabled.State], ["variant", "contained"], ["color", "secondary"], ["children", "Yes"]]), MuiHelpers_createElement(Button, [["onClick", (_arg_4) => {
        setShowConfirmModal(false);
    }], ["disabled", buttonsDisabled.State], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "primary"], ["children", "No"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]]);
}

export function AdminTools_SendEmailsToPartnersForm(partnersList, onSendEmails, onLogRealData) {
    let f1, props, props_2, dt, dt_1;
    const quarter = useFormField(void 0, SelectOptionData$1_Create_Z510A3DC0);
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setShowConfirmSendEmailsModal = patternInput[1];
    const buttonsDisabled = useToggle(false);
    const optionalEmailAddress = useFormField("", (f1 = Email_get_Create(), (arg_2) => some(f1(arg_2))));
    const patternInput_1 = [void 0, void 0];
    const isDateError = useToggle(false);
    const dropDownOpen = useToggle(false);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(patternInput_1[0]);
    const setDate1 = patternInput_2[1];
    const date1 = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(patternInput_1[1]);
    const setDate2 = patternInput_3[1];
    const date2 = patternInput_3[0];
    const partnerOption = useFeliz_React__React_useState_Static_1505((partnersList == null) ? PartnerOption_get_none() : PartnerOption_get_empty())[0];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(empty());
    const setSelectedPartnersList = patternInput_5[1];
    const selectedPartnersList = patternInput_5[0];
    const form = useFormValidator(quarter.Validator, (r, x_1) => ({
        OptionalEmailAddress: r.OptionalEmailAddress,
        Quarter: x_1,
    }), useFormValidator(optionalEmailAddress.Validator, (unitVar, x) => ({
        OptionalEmailAddress: x,
    }), useForm()));
    const sendEmailsButton = MuiHelpers_createElement(Button, [["onClick", (_arg_4) => {
        isDateError.On();
        iterate((data) => {
            const matchValue = [date1, date2];
            let pattern_matching_result;
            if (matchValue[0] != null) {
                if (matchValue[1] != null) {
                    pattern_matching_result = 0;
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    isDateError.Off();
                    setShowConfirmSendEmailsModal(true);
                    break;
                }
                case 1: {
                    isDateError.On();
                    break;
                }
            }
        }, toArray(form.Validate()));
    }], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props = {}, react.createElement(Send, props))], ["disabled", buttonsDisabled.State], ["children", "Send bulk emails"]]);
    const logRealDataButton = MuiHelpers_createElement(Button, [["onClick", (_arg_5) => {
        iterate((data_2) => {
            const matchValue_2 = [date1, date2];
            let pattern_matching_result_1, date1_3, date2_3;
            if (matchValue_2[0] != null) {
                if (matchValue_2[1] != null) {
                    pattern_matching_result_1 = 0;
                    date1_3 = matchValue_2[0];
                    date2_3 = matchValue_2[1];
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    buttonsDisabled.On();
                    onLogRealData(data_2.Quarter.Value, date1_3, date2_3, (equals(partnerOption, PartnerOption_get_empty()) ? true : equals(partnerOption, PartnerOption_get_none())) ? (void 0) : (new PartnerId(partnerOption.Id)), () => {
                        buttonsDisabled.Off();
                    });
                    break;
                }
                case 1: {
                    break;
                }
            }
        }, toArray(form.Validate()));
    }], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props_2 = {}, react.createElement(Book, props_2))], ["disabled", buttonsDisabled.State], ["children", "Log real data for quarter"]]);
    useReact_useEffect_Z101E1A95(() => {
        const matchValue_3 = quarter.FieldValue;
        if (matchValue_3 == null) {
        }
        else {
            const selq = matchValue_3;
            let year;
            let copyOfStruct = today();
            year = year_1(copyOfStruct);
            switch (selq.tag) {
                case 1: {
                    setDate1(create(year, 4, 1));
                    setDate2(create(year, 6, 30));
                    break;
                }
                case 2: {
                    setDate1(create(year, 7, 1));
                    setDate2(create(year, 9, 30));
                    break;
                }
                case 3: {
                    setDate1(create(year, 10, 1));
                    setDate2(create(year, 12, 31));
                    break;
                }
                case 4: {
                    break;
                }
                default: {
                    setDate1(create(year, 1, 1));
                    setDate2(create(year, 3, 31));
                }
            }
        }
    }, [quarter.FieldValue]);
    let isDateInputDisabled;
    const matchValue_4 = quarter.FieldValue;
    isDateInputDisabled = ((matchValue_4 == null) ? true : (!equals(matchValue_4, new Quarter(4))));
    const xs_2 = [createElement(ConfirmEmailModal, {
        showConfirmationModal: patternInput[0],
        setShowConfirmModal: setShowConfirmSendEmailsModal,
        onConfirm: () => {
            iterate((data_1) => {
                const matchValue_1 = [date1, date2];
                let pattern_matching_result_2, date1_2, date2_2;
                if (matchValue_1[0] != null) {
                    if (matchValue_1[1] != null) {
                        pattern_matching_result_2 = 0;
                        date1_2 = matchValue_1[0];
                        date2_2 = matchValue_1[1];
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                }
                else {
                    pattern_matching_result_2 = 1;
                }
                switch (pattern_matching_result_2) {
                    case 0: {
                        isDateError.Off();
                        buttonsDisabled.On();
                        const partnerList = map((tupledArg_1) => (new PartnerId(tupledArg_1[0].Id)), filter((tupledArg) => tupledArg[1], selectedPartnersList));
                        const partnerId = (equals(partnerOption, PartnerOption_get_empty()) ? true : equals(partnerOption, PartnerOption_get_none())) ? (void 0) : (new PartnerId(partnerOption.Id));
                        onSendEmails(data_1.Quarter.Value, date1_2, date2_2, partnerList, data_1.OptionalEmailAddress, () => {
                            setShowConfirmSendEmailsModal(false);
                            buttonsDisabled.Off();
                        });
                        break;
                    }
                    case 1: {
                        isDateError.On();
                        break;
                    }
                }
            }, toArray(form.Validate()));
        },
        buttonsDisabled: buttonsDisabled,
    }), createElement(Col, {
        classes: ofArray([MarginTop.M, MarginLeft.M, MarginBottom.M, MarginRight.M]),
        children: ofArray([createElement(Col, {
            classes: empty(),
            children: ofArray([createElement("h2", {
                children: ["Send emails to all partners with dossiers"],
            }), createElement(Row, {
                classes: singleton_1(Gap.S),
                children: ofArray([MuiHelpers_createElement(Button, [["onClick", (_arg_6) => {
                    quarter.SetFieldValue(new Quarter(0));
                }], ["className", join(" ", [])], ["disabled", buttonsDisabled.State], ["variant", "contained"], ["children", "Q1"]]), MuiHelpers_createElement(Button, [["onClick", (_arg_7) => {
                    quarter.SetFieldValue(new Quarter(1));
                }], ["className", join(" ", [])], ["disabled", buttonsDisabled.State], ["variant", "contained"], ["children", "Q2"]]), MuiHelpers_createElement(Button, [["onClick", (_arg_8) => {
                    quarter.SetFieldValue(new Quarter(2));
                }], ["className", join(" ", [])], ["disabled", buttonsDisabled.State], ["variant", "contained"], ["children", "Q3"]]), MuiHelpers_createElement(Button, [["onClick", (_arg_9) => {
                    quarter.SetFieldValue(new Quarter(3));
                }], ["className", join(" ", [])], ["disabled", buttonsDisabled.State], ["variant", "contained"], ["children", "Q4"]]), MuiHelpers_createElement(Button, [["onClick", (_arg_10) => {
                    quarter.SetFieldValue(new Quarter(4));
                }], ["className", join(" ", [])], ["disabled", buttonsDisabled.State], ["variant", "contained"], ["children", "Custom"]])]),
            })]),
        }), createElement(Row, {
            classes: ofArray([MarginTop.M, Gap.M]),
            children: ofArray([createElement(Col, {
                classes: toList(delay(() => (isDateError.State ? singleton_2(TextColor.Danger) : empty_1()))),
                children: ofArray(["Date From", createElement("input", {
                    disabled: isDateInputDisabled,
                    type: "date",
                    onChange: (ev) => {
                        iterate((n) => {
                            setDate1(n);
                        }, toArray(DateParsing_parse(ev.target.value)));
                    },
                    className: join(" ", toList(delay(() => append(singleton_2(fullWidth), delay(() => (isDateError.State ? singleton_2(TextColor.Danger) : empty_1())))))),
                    value: (date1 == null) ? "" : ((dt = date1, (toConsole(printf("date1 %A"))(dt), GeneralFormats_dateTime(dt)))),
                })]),
            }), createElement(Col, {
                classes: toList(delay(() => (isDateError.State ? singleton_2(TextColor.Danger) : empty_1()))),
                children: ofArray(["Date To", createElement("input", {
                    disabled: isDateInputDisabled,
                    type: "date",
                    onChange: (ev_1) => {
                        iterate((n_1) => {
                            setDate2(n_1);
                        }, toArray(DateParsing_parse(ev_1.target.value)));
                    },
                    className: join(" ", toList(delay(() => append(singleton_2(fullWidth), delay(() => (isDateError.State ? singleton_2(TextColor.Danger) : empty_1())))))),
                    value: (date2 == null) ? "" : ((dt_1 = date2, (toConsole(printf("date2 %A"))(dt_1), GeneralFormats_dateTime(dt_1)))),
                })]),
            })]),
        }), createElement(Col, {
            classes: singleton_1(MarginTop.M),
            children: toList(delay(() => {
                if (partnersList == null) {
                    return singleton_2(null);
                }
                else {
                    const partners = partnersList;
                    return append(singleton_2(createElement("h2", {
                        children: ["Partner"],
                    })), delay(() => {
                        const partners_1 = sortBy((p_1) => p_1.CompanyName.Value, partners, {
                            Compare: comparePrimitives,
                        });
                        const options = toArray_1(delay(() => toArray_2(map((partner) => (new PartnerOption(NonEmptyString___UnsafeUnwrap_Z73AD07C(partner.CompanyName), partner.Id.Value, partner.IsCibPartner, partner.PartnerType, partner.CompensationAmount)), partners_1))));
                        return append(singleton_2(createElement(Row, {
                            classes: ofArray([alignStart, Gap.S, MarginBottom.M]),
                            children: ofArray([MuiHelpers_createElement(Button, [["onClick", (_arg_11) => {
                                setSelectedPartnersList(ofArray(map_1((p_3) => [p_3, true], options)));
                            }], ["variant", "contained"], ["color", "secondary"], ["disabled", buttonsDisabled.State], ["children", "Select All"]]), MuiHelpers_createElement(Button, [["onClick", (_arg_12) => {
                                setSelectedPartnersList(ofArray(map_1((p_4) => [p_4, false], options)));
                            }], ["variant", "contained"], ["color", "primary"], ["disabled", buttonsDisabled.State], ["children", "Select None"]])]),
                        })), delay(() => {
                            let elements_1;
                            return singleton_2(createElement(Col, {
                                classes: ofArray([Padding.M, partnerGrid]),
                                children: singleton_1(MuiHelpers_createElement(Grid, [["spacing", 0], ["container", true], (elements_1 = toList(delay(() => collect((partner_1) => {
                                    let elements;
                                    const checkeds = defaultArg(map_2((tuple) => tuple[1], tryFind((tupledArg_2) => equals(tupledArg_2[0], partner_1), selectedPartnersList)), false);
                                    return singleton_2(MuiHelpers_createElement(Grid, [["item", true], ["spacing", 0], ["xs", 6], ["lg", 3], ["md", 3], (elements = [MuiHelpers_createElement(FormControlLabel, [["label", `${partner_1.Label}`], ["control", MuiHelpers_createElement(Checkbox, [["checked", checkeds], ["onChange", (e) => {
                                        const v = Browser_Types_Event__Event_get_Checked(e);
                                        setSelectedPartnersList(append_1(filter((tupledArg_3) => (!equals(tupledArg_3[0], partner_1)), selectedPartnersList), singleton_1([partner_1, v])));
                                    }]])]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]));
                                }, options))), ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])])),
                            }));
                        }));
                    }));
                }
            })),
        }), createElement(Row, {
            classes: singleton_1(MarginTop.M),
            children: singleton_1(createElement(Col, {
                classes: singleton_1(Gap.M),
                children: ofArray([TextField(optionalEmailAddress, "Optional Email Adress", "", false), logRealDataButton]),
            })),
        }), createElement(Row, {
            classes: singleton_1(MarginTop.Xl),
            children: singleton_1(sendEmailsButton),
        })]),
    })];
    return react.createElement(react.Fragment, {}, ...xs_2);
}

export function AdminTools(adminToolsInputProps) {
    let children;
    const api = adminToolsInputProps.api;
    const currentPage = adminToolsInputProps.currentPage;
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(api.GetPartners(new PagingQuery(0, 999), void 0), (_arg_4) => {
            const partners_1 = _arg_4;
            if (partners_1.tag === 1) {
                const err = partners_1.fields[0];
                console.error(err);
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                return singleton.Zero();
            }
            else {
                patternInput[1](partners_1.fields[0].Data);
                return singleton.Zero();
            }
        })));
    }, []);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Administratieve Hulpmiddelen",
        actions: [],
        children: [(children = singleton_1(AdminTools_SendEmailsToPartnersForm(patternInput[0], (quarter, date1, date2, partnerIdList, emailOption, onCompleted) => {
            startImmediate(singleton.Delay(() => singleton.TryFinally(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(api.SendEmails_PartnersWithDossiers(emailOption, quarter, date1, date2, partnerIdList), (_arg) => {
                const result = _arg;
                if (result.tag === 0) {
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een nieuw dossier is aangemaakt", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                    return singleton.Zero();
                }
                else {
                    const e = result.fields[0];
                    console.error(e);
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    return singleton.Zero();
                }
            })), (_arg_1) => {
                const e_1 = _arg_1;
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e_1.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                console.error(e_1);
                return singleton.Zero();
            })), () => {
                onCompleted();
            })));
        }, (quarter_1, date1_1, date2_1, partnerId, onCompleted_1) => {
            startImmediate(singleton.Delay(() => singleton.TryFinally(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(api.LogRealData_PartnersWithDossiers(quarter_1, date1_1, date2_1, partnerId), (_arg_2) => {
                const result_1 = _arg_2;
                if (result_1.tag === 0) {
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een nieuw dossier is aangemaakt", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                    return singleton.Zero();
                }
                else {
                    const e_2 = result_1.fields[0];
                    console.error(e_2);
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e_2}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    return singleton.Zero();
                }
            })), (_arg_3) => {
                const e_3 = _arg_3;
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e_3.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                console.error(e_3);
                return singleton.Zero();
            })), () => {
                onCompleted_1();
            })));
        })), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))],
    });
}

