import { isNullOrWhiteSpace } from "../client/src/fable_modules/fable-library.3.7.17/String.js";

export function toOption(str) {
    if (isNullOrWhiteSpace(str)) {
        return void 0;
    }
    else {
        return str;
    }
}

