import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import ListItemText from "@material-ui/core/ListItemText";
import { ofArray, sortBy, chunkBySize, append, filter, map, toArray, empty, singleton } from "../fable_modules/fable-library.3.7.17/List.js";
import ListItem from "@material-ui/core/ListItem";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { DossierCode, AdiRoleModule_string, NonEmptyString___UnsafeUnwrap_Z73AD07C } from "../../../shared/Domain.fs.js";
import { CalendarResource$1 } from "../lib/bindings/BigCalendar.fs.js";
import { createElement } from "react";
import { compare, equals, createObj } from "../fable_modules/fable-library.3.7.17/Util.js";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import { singleton as singleton_1, collect, map as map_1, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Browser_Types_Event__Event_get_Checked } from "../fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { Gap, MarginTop } from "../../../Styles/Utils.fs.js";
import { AppointmentScheduler } from "../components/AppointmentScheduler.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { useSnackbar } from "notistack";
import { Page } from "../components/Page.fs.js";
import { Card } from "../../../DesignSystems/DesignSystem.fs.js";

export function Info(infoInputProps) {
    const primary = infoInputProps.primary;
    const children = singleton(MuiHelpers_createElement(ListItemText, [["secondary", infoInputProps.secondary], ["primary", primary]]));
    return MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function RenderAdisList(renderAdisListInputProps) {
    let elems;
    const setAppointment = renderAdisListInputProps.setAppointment;
    const dossierId = renderAdisListInputProps.dossierId;
    const adis = renderAdisListInputProps.adis;
    const api = renderAdisListInputProps.api;
    const patternInput = useFeliz_React__React_useState_Static_1505(empty());
    const selectedAdisList = patternInput[0];
    const resources = useReact_useMemo_CF4EA67(() => toArray(map((tupledArg_1) => {
        const adi = tupledArg_1[0];
        return new CalendarResource$1(adi.Id.Value, `${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.FirstName)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.LastName)}`, "");
    }, filter((tupledArg) => tupledArg[1], selectedAdisList))), [selectedAdisList]);
    return createElement("div", createObj(singleton((elems = [createElement("h2", {
        children: ["ADIs"],
    }), createElement(Col, {
        classes: empty(),
        children: ofArray([createElement(Row, {
            classes: empty(),
            children: toList(delay(() => map_1((adis_1) => createElement(Col, {
                classes: empty(),
                children: toList(delay(() => collect((adi_1) => {
                    let role_2;
                    const matchValue = adi_1.Role;
                    if (matchValue == null) {
                        role_2 = "";
                    }
                    else {
                        const role = matchValue;
                        role_2 = (` (${AdiRoleModule_string(role)})`);
                    }
                    return singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", `X ${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi_1.FirstName)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi_1.LastName)}${role_2}`], ["control", MuiHelpers_createElement(Checkbox, [["onChange", (e) => {
                        const v = Browser_Types_Event__Event_get_Checked(e);
                        patternInput[1](append(filter((tupledArg_2) => (!equals(tupledArg_2[0], adi_1)), selectedAdisList), singleton([adi_1, v])));
                    }]])]]));
                }, adis_1))),
            }), chunkBySize(5, sortBy((a_1) => a_1.FirstName, adis.Data, {
                Compare: compare,
            }))))),
        }), createElement(Row, {
            classes: singleton(MarginTop.L),
            children: singleton(createElement(Col, {
                classes: empty(),
                children: toList(delay(() => ((resources.length === 0) ? singleton_1(createElement("h4", {
                    children: ["Selecteer een ADI"],
                })) : singleton_1(createElement(AppointmentScheduler, {
                    api: api,
                    dossierCode: dossierId,
                    appointmentLengthInMinutes: 120,
                    resources: resources,
                    setAppointment: setAppointment,
                    readOnly: true,
                    enableOpenDossierLink: true,
                }))))),
            })),
        })]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))));
}

export function AdisList(adisListInputProps) {
    const setAppointment = adisListInputProps.setAppointment;
    const dossierCode = adisListInputProps.dossierCode;
    const api = adisListInputProps.api;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetAdis(new PagingQuery(0, 999), void 0), []);
    return createElement(DisplayDeferred, {
        data: data,
        view: (adis) => createElement(RenderAdisList, {
            api: api,
            adis: adis,
            dossierId: dossierCode,
            setAppointment: setAppointment,
        }),
    });
}

export function AdminAvailabilityForm(api) {
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const dossierCode = new DossierCode("");
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    return createElement(Col, {
        classes: singleton(Gap.M),
        children: singleton(createElement(AdisList, {
            api: api,
            dossierCode: dossierCode,
            setAppointment: patternInput_1[1],
        })),
    });
}

export function AdminAvailability(adminAvailabilityInputProps) {
    const api = adminAvailabilityInputProps.api;
    const currentPage = adminAvailabilityInputProps.currentPage;
    const snackbar = useSnackbar();
    return createElement(Page, {
        currentPage: currentPage,
        title: "Beschikbaarheden",
        actions: [],
        children: [Card([createElement(AdminAvailabilityForm, api)])],
    });
}

