import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import ListItemText from "@material-ui/core/ListItemText";
import { ofArray, append as append_1, tryFind, length, empty, contains, filter, map, toArray, singleton } from "../fable_modules/fable-library.3.7.17/List.js";
import ListItem from "@material-ui/core/ListItem";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { useReact_useEffect_Z101E1A95, useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { AppointmentId, CommentSource as CommentSource_1, CommentType, Appointment, Customer2__get_Code, AdiRoleModule_string, PreferredAdi__AdisWithMinimumRole_Z7F80284D, NonEmptyString___UnsafeUnwrap_Z73AD07C } from "../../../shared/Domain.fs.js";
import { CalendarResource$1 } from "../lib/bindings/BigCalendar.fs.js";
import { createObj, safeHash, equals } from "../fable_modules/fable-library.3.7.17/Util.js";
import { useUsername, useToggle } from "../../../CustomHooks/CustomHooks.fs.js";
import { createElement } from "react";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import { iterate, map as map_1, empty as empty_1, collect, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { Gap, fullWidth, MarginRight, MarginTop } from "../../../Styles/Utils.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Browser_Types_Event__Event_get_Checked } from "../fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { ErrorText, Card, Collapsible } from "../../../DesignSystems/DesignSystem.fs.js";
import { Dossier_contactPhoneNumber, Dossier_contact, Adi_nameAndRole } from "../../../shared/DomainHelpers.fs.js";
import { AppointmentScheduler } from "../components/AppointmentScheduler.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { toArray as toArray_1, defaultArg, map as map_2 } from "../fable_modules/fable-library.3.7.17/Option.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { appointmentDetail, dossiers, customerDetail, dossierDetail } from "../AppRoutes.fs.js";
import Divider from "@material-ui/core/Divider";
import { Customer2_name } from "../../../shared/Format.fs.js";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import { useSnackbar } from "notistack";
import { useForm } from "../../../CustomHooks/ComposedForm.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { CommentEditor, CommentList } from "../../../DesignSystems/CommentEditor.fs.js";
import Button from "@material-ui/core/Button";
import { CommentData, AppointmentDataEdit } from "../../../shared/Api.fs.js";
import { Page } from "../components/Page.fs.js";

export function Info(infoInputProps) {
    const primary = infoInputProps.primary;
    const children = singleton(MuiHelpers_createElement(ListItemText, [["secondary", infoInputProps.secondary], ["primary", primary]]));
    return MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function RenderAdiList(renderAdiListInputProps) {
    let role, role_1, elems;
    const partner = renderAdiListInputProps.partner;
    const setAppointment = renderAdiListInputProps.setAppointment;
    const adis = renderAdiListInputProps.adis;
    const appointmentAdi = renderAdiListInputProps.appointmentAdi;
    const dossierCode = renderAdiListInputProps.dossierCode;
    const api = renderAdiListInputProps.api;
    const patternInput = useFeliz_React__React_useState_Static_1505(singleton([appointmentAdi, true]));
    const setSelectedAdisList = patternInput[1];
    const selectedAdisList = patternInput[0];
    const resources = useReact_useMemo_CF4EA67(() => toArray(map((tupledArg_1) => {
        const adi = tupledArg_1[0];
        return new CalendarResource$1(adi.Id.Value, `${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.FirstName)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.LastName)}`, "");
    }, filter((tupledArg) => tupledArg[1], selectedAdisList))), [selectedAdisList]);
    const normalAdis = (partner != null) ? ((partner.MinimumRole != null) ? ((role = partner.MinimumRole, filter((adi_1) => (!contains(adi_1, PreferredAdi__AdisWithMinimumRole_Z7F80284D(adis, role), {
        Equals: equals,
        GetHashCode: safeHash,
    })), adis.NormalAdis))) : adis.NormalAdis) : adis.NormalAdis;
    const roleAdis = (partner != null) ? ((partner.MinimumRole != null) ? ((role_1 = partner.MinimumRole, PreferredAdi__AdisWithMinimumRole_Z7F80284D(adis, role_1))) : empty()) : empty();
    const collapsePartnerAdis = useToggle(false);
    const collapseRoleAdis = useToggle(length(adis.PreferredAdis) > 0);
    const collapseNormalAdis = useToggle((length(adis.PreferredAdis) > 0) ? true : (length(roleAdis) > 0));
    return createElement("div", createObj(singleton((elems = [createElement(Col, {
        classes: empty(),
        children: toList(delay(() => append((length(adis.PreferredAdis) > 0) ? singleton_1(createElement(Col, {
            classes: singleton(MarginTop.M),
            children: toList(delay(() => {
                const partnerAdis = createElement(Row, {
                    classes: empty(),
                    children: singleton(createElement(Row, {
                        classes: empty(),
                        children: toList(delay(() => collect((adi_2) => {
                            let isChecked;
                            const matchValue = tryFind((tupledArg_2) => {
                                if (equals(tupledArg_2[0], adi_2)) {
                                    return tupledArg_2[1] === true;
                                }
                                else {
                                    return false;
                                }
                            }, selectedAdisList);
                            isChecked = (!(matchValue == null));
                            return singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", `${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi_2.FirstName)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi_2.LastName)}`], ["control", MuiHelpers_createElement(Checkbox, [["checked", isChecked], ["onChange", (e) => {
                                const v = Browser_Types_Event__Event_get_Checked(e);
                                setSelectedAdisList(append_1(filter((tupledArg_3) => (!equals(tupledArg_3[0], adi_2)), selectedAdisList), singleton([adi_2, v])));
                            }]])]]));
                        }, adis.PreferredAdis))),
                    })),
                });
                return singleton_1(createElement(Collapsible, {
                    title: "ADIs (Partner)",
                    children: partnerAdis,
                    collapseToggle: collapsePartnerAdis,
                    disabled: length(adis.PreferredAdis) === 0,
                }));
            })),
        })) : empty_1(), delay(() => {
            let matchValue_1, role_2, role_3;
            return append((matchValue_1 = partner, (matchValue_1 != null) ? ((matchValue_1.MinimumRole != null) ? (((role_2 = matchValue_1.MinimumRole, length(roleAdis) > 0)) ? ((role_3 = matchValue_1.MinimumRole, singleton_1(createElement(Col, {
                classes: singleton(MarginTop.M),
                children: toList(delay(() => {
                    const roles = createElement(Row, {
                        classes: empty(),
                        children: singleton(createElement(Row, {
                            classes: empty(),
                            children: toList(delay(() => map_1((adi_3) => MuiHelpers_createElement(FormControlLabel, [["label", Adi_nameAndRole(adi_3)], ["control", MuiHelpers_createElement(Checkbox, [["onChange", (e_1) => {
                                const v_1 = Browser_Types_Event__Event_get_Checked(e_1);
                                setSelectedAdisList(append_1(filter((tupledArg_4) => (!equals(tupledArg_4[0], adi_3)), selectedAdisList), singleton([adi_3, v_1])));
                            }]])]]), roleAdis))),
                        })),
                    });
                    return singleton_1(createElement(Collapsible, {
                        title: `ADIs (Minimum Role: ${AdiRoleModule_string(role_3)})`,
                        children: roles,
                        collapseToggle: collapseRoleAdis,
                        disabled: length(roleAdis) === 0,
                    }));
                })),
            })))) : ((empty_1()))) : ((empty_1()))) : ((empty_1()))), delay(() => append(singleton_1(createElement(Col, {
                classes: singleton(MarginTop.M),
                children: toList(delay(() => {
                    const normal = createElement(Row, {
                        classes: empty(),
                        children: singleton(createElement(Row, {
                            classes: empty(),
                            children: toList(delay(() => collect((adi_5) => {
                                let isChecked_1;
                                const matchValue_2 = tryFind((tupledArg_5) => {
                                    if (equals(tupledArg_5[0], adi_5)) {
                                        return tupledArg_5[1] === true;
                                    }
                                    else {
                                        return false;
                                    }
                                }, selectedAdisList);
                                isChecked_1 = (!(matchValue_2 == null));
                                return singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", `${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi_5.FirstName)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi_5.LastName)}`], ["control", MuiHelpers_createElement(Checkbox, [["checked", isChecked_1], ["onChange", (e_2) => {
                                    const v_2 = Browser_Types_Event__Event_get_Checked(e_2);
                                    setSelectedAdisList(append_1(filter((tupledArg_6) => (!equals(tupledArg_6[0], adi_5)), selectedAdisList), singleton([adi_5, v_2])));
                                }]])]]));
                            }, normalAdis))),
                        })),
                    });
                    return singleton_1(createElement(Collapsible, {
                        title: "ADIs (Alle)",
                        children: normal,
                        collapseToggle: collapseNormalAdis,
                        disabled: false,
                    }));
                })),
            })), delay(() => singleton_1(createElement(Row, {
                classes: singleton(MarginTop.L),
                children: singleton(createElement(Col, {
                    classes: empty(),
                    children: toList(delay(() => ((resources.length === 0) ? singleton_1(createElement("h4", {
                        children: ["Selecteer een ADI"],
                    })) : singleton_1(createElement(AppointmentScheduler, {
                        api: api,
                        dossierCode: dossierCode,
                        appointmentLengthInMinutes: 120,
                        resources: resources,
                        setAppointment: setAppointment,
                        readOnly: false,
                        enableOpenDossierLink: false,
                    }))))),
                })),
            }))))));
        })))),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))));
}

export function AdisList(adisListInputProps) {
    const partner = adisListInputProps.partner;
    const setAppointment = adisListInputProps.setAppointment;
    const appointmentAdi = adisListInputProps.appointmentAdi;
    const dossierId = adisListInputProps.dossierId;
    const api = adisListInputProps.api;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetPreferredAdis(map_2((p) => p.Id, partner)), []);
    return createElement(DisplayDeferred, {
        data: data,
        view: (adis) => createElement(RenderAdiList, {
            api: api,
            dossierCode: dossierId,
            appointmentAdi: appointmentAdi,
            adis: adis,
            setAppointment: setAppointment,
            partner: partner,
        }),
    });
}

export function DossierAdiInfo(dossierAdiInfoInputProps) {
    const setAppointment = dossierAdiInfoInputProps.setAppointment;
    const adiId = dossierAdiInfoInputProps.adiId;
    const dossier = dossierAdiInfoInputProps.dossier;
    const api = dossierAdiInfoInputProps.api;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetAdi(adiId), []);
    return createElement(DisplayDeferred, {
        data: data,
        view: (adi_1) => {
            let children_3, children_2, children, details, elems_1, children_1, customerName, customerId, elems, children_5, children_4, matchValue, matchValue_1;
            if (adi_1 == null) {
                return `No ADI found with ID ${adiId}`;
            }
            else {
                const adi = adi_1;
                const details_1 = dossier;
                return createElement(Row, {
                    classes: empty(),
                    children: singleton(createElement(Col, {
                        classes: ofArray([MarginRight.Xl, fullWidth]),
                        children: ofArray([createElement("h2", {
                            children: ["Algemeen"],
                        }), (children_3 = singleton((children_2 = ofArray([(children = singleton(MuiHelpers_createElement(ListItemText, [["primary", (details = details_1, createElement("div", createObj(singleton((elems_1 = [createElement("a", {
                            href: "#",
                            target: "_blank",
                            onClick: (e_1) => {
                                e_1.preventDefault();
                                RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossierDetail, details.Dossier.Code.Value)), 1, 1);
                            },
                            children: `${details.Dossier.Code.Value}`,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])))))], ["secondary", "Dossier nummer"]])), MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), MuiHelpers_createElement(Divider, [["component", "li"]]), (children_1 = singleton(MuiHelpers_createElement(ListItemText, [["primary", (customerName = Customer2_name(details_1.Customer), (customerId = Customer2__get_Code(details_1.Customer).Value, createElement("div", createObj(singleton((elems = [createElement("a", {
                            href: "#",
                            onClick: (e) => {
                                e.preventDefault();
                                RouterModule_nav(ofArray(Page$2__toUrl_2B594(customerDetail, customerId)), 1, 1);
                            },
                            children: `${customerName}`,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))))], ["secondary", "Klant"]])), MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))]), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]])), createElement("h2", {
                            children: ["Inspectielocatie"],
                        }), (children_5 = singleton((children_4 = ofArray([createElement(Info, {
                            secondary: "Straat",
                            primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(details_1.Dossier.Address.Street),
                        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                            secondary: "Nummer",
                            primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(details_1.Dossier.Address.Number),
                        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                            secondary: "Bus",
                            primary: defaultArg(map_2(NonEmptyString___UnsafeUnwrap_Z73AD07C, details_1.Dossier.Address.Bus), "--"),
                        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                            secondary: "Postcode",
                            primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(details_1.Dossier.Address.PostalCode),
                        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                            secondary: "Gemeente",
                            primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(details_1.Dossier.Address.City),
                        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                            secondary: "Contact afspraak",
                            primary: Dossier_contact(details_1.Dossier, details_1.Partner),
                        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                            secondary: "Telefoon contactpersoon",
                            primary: Dossier_contactPhoneNumber(details_1.Dossier, details_1.Partner),
                        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                            secondary: "Gebouwdeel/Vleugel/Blok",
                            primary: (matchValue = details_1.Dossier.BuildingDetail, (matchValue == null) ? "" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue)),
                        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                            secondary: "Wooneenheden",
                            primary: (matchValue_1 = details_1.Dossier.HousingUnit, (matchValue_1 == null) ? "" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_1)),
                        })]), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]])), createElement(AdisList, {
                            api: api,
                            dossierId: details_1.Dossier.Code,
                            appointmentAdi: adi,
                            setAppointment: setAppointment,
                            partner: dossier.Partner,
                        })]),
                    })),
                });
            }
        },
    });
}

export function DossierCustomerInfo(dossierCustomerInfoInputProps) {
    const setAppointment = dossierCustomerInfoInputProps.setAppointment;
    const appointment = dossierCustomerInfoInputProps.appointment;
    const api = dossierCustomerInfoInputProps.api;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetDossierDetails(appointment.DossierCode), []);
    return createElement(DisplayDeferred, {
        data: data,
        view: (dossier) => {
            if (dossier == null) {
                return `No dossier found with ID ${appointment.DossierCode}`;
            }
            else {
                const dossier_1 = dossier;
                return createElement(DossierAdiInfo, {
                    api: api,
                    dossier: dossier_1,
                    adiId: appointment.AdiId,
                    setAppointment: setAppointment,
                });
            }
        },
    });
}

export function AppointmentDossierEditForm(appointmentDossierEditFormInputProps) {
    const onEditAppointment = appointmentDossierEditFormInputProps.onEditAppointment;
    const details = appointmentDossierEditFormInputProps.details;
    const api = appointmentDossierEditFormInputProps.api;
    const editingApt = new Appointment(details.Appointment.Id, details.Appointment.Status, details.Dossier.Code, details.Dossier.Id, details.AdiId, details.Appointment.StartTime, details.Appointment.EndTime, details.Appointment.CalendarServiceEventId);
    const notifyClient = useToggle(true);
    const notifyAdi = useToggle(true);
    const patternInput = useFeliz_React__React_useState_Static_1505(editingApt);
    const appointment = patternInput[0];
    const snackbar = useSnackbar();
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const comment = patternInput_1[0];
    const username = useUsername();
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(empty());
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(0);
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(25);
    const commentType = useFeliz_React__React_useState_Static_1505(new CommentType(0))[0];
    const form = useForm();
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(false);
    const setDisable = patternInput_6[1];
    const disable = patternInput_6[0];
    const patternInput_7 = useFeliz_React__React_useState_Static_1505(false);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_2.Delay(() => singleton_2.Bind(api.GetDossierComments(details.Dossier.Code, new PagingQuery(patternInput_3[0], patternInput_4[0]), commentType), (_arg) => {
            const comments_1 = _arg;
            if (comments_1.tag === 0) {
                patternInput_2[1](comments_1.fields[0].Data);
                return singleton_2.Zero();
            }
            else {
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                return singleton_2.Zero();
            }
        })));
    }, [commentType]);
    return createElement(Col, {
        classes: singleton(Gap.M),
        children: toList(delay(() => {
            let matchValue, appointment_2;
            return append((matchValue = appointment, (matchValue == null) ? singleton_1("Invalid apt") : ((appointment_2 = matchValue, append(singleton_1(createElement(DossierCustomerInfo, {
                api: api,
                appointment: appointment_2,
                setAppointment: patternInput[1],
            })), delay(() => append(singleton_1(createElement(CommentList, {
                comments: patternInput_2[0],
                onDeleteComment: void 0,
            })), delay(() => singleton_1(createElement(CommentEditor, {
                comment: comment,
                setComment: patternInput_1[1],
                commentType: commentType,
            }))))))))), delay(() => append(singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", "Email versturen naar klant"], ["control", MuiHelpers_createElement(Checkbox, [["checked", notifyClient.State], ["onChange", (e) => {
                const _arg_2 = Browser_Types_Event__Event_get_Checked(e);
                notifyClient.Toggle();
            }]])]])), delay(() => append(singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", "Email versturen naar ADI"], ["control", MuiHelpers_createElement(Checkbox, [["checked", notifyAdi.State], ["onChange", (e_1) => {
                const _arg_3 = Browser_Types_Event__Event_get_Checked(e_1);
                notifyClient.Toggle();
            }]])]])), delay(() => singleton_1(createElement(Row, {
                classes: ofArray([Gap.M, MarginTop.S]),
                children: ofArray([MuiHelpers_createElement(Button, [["disabled", disable], ["color", "default"], ["variant", "contained"], ["onClick", (_arg_4) => {
                    RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossiers)), 1, 1);
                }], ["children", Interop_reactApi.Children.toArray(["Annuleren"])]]), MuiHelpers_createElement(Button, [["disabled", disable], ["color", "primary"], ["variant", "contained"], ["onClick", (_arg_1) => {
                    if (appointment == null) {
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Selecteer een tijdstip", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    }
                    else {
                        const appointment_1 = appointment;
                        iterate(() => {
                            setDisable(true);
                            console.log(void 0);
                            onEditAppointment(() => {
                                setDisable(false);
                            })(details.Appointment.Id)(new AppointmentDataEdit(appointment_1.Status, appointment_1.DossierCode, appointment_1.AdiId, appointment_1.StartTime, appointment_1.EndTime, notifyClient.State, notifyAdi.State))((comment.length > 0) ? (new CommentData(comment, username, new CommentSource_1(1), commentType)) : (void 0));
                        }, toArray_1(form.Validate()));
                    }
                }], ["children", Interop_reactApi.Children.toArray(["Afspraak maken"])]])]),
            }))))))));
        })),
    });
}

export function AppointmentDossierEdit(appointmentDossierEditInputProps) {
    const appointmentId = appointmentDossierEditInputProps.appointmentId;
    const api = appointmentDossierEditInputProps.api;
    const currentPage = appointmentDossierEditInputProps.currentPage;
    const snackbar = useSnackbar();
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetAppointmentDetails(new AppointmentId(appointmentId)), []);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Afspraken Aanpassen",
        actions: [],
        children: [Card([createElement(DisplayDeferred, {
            data: data,
            view: (appointment_1) => {
                if (appointment_1 == null) {
                    return createElement(ErrorText, {
                        text: `Geen afspraak gevonden met ID ${appointmentId}`,
                    });
                }
                else {
                    const appointment_2 = appointment_1;
                    return createElement(AppointmentDossierEditForm, {
                        api: api,
                        details: appointment_2,
                        onEditAppointment: (onComplete) => ((id) => ((appointment) => ((comment) => {
                            startImmediate(singleton_2.Delay(() => singleton_2.TryFinally(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(api.EditAppointment(id, appointment, comment), (_arg) => {
                                const result = _arg;
                                if (result.tag === 0) {
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "De afspraak is aangepast", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                    RouterModule_nav(ofArray(Page$2__toUrl_2B594(appointmentDetail, appointmentId)), 1, 1);
                                    return singleton_2.Zero();
                                }
                                else {
                                    const err = result.fields[0];
                                    console.error(err);
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                    return singleton_2.Zero();
                                }
                            })), (_arg_1) => {
                                const e = _arg_1;
                                console.log(e);
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                return singleton_2.Zero();
                            })), () => {
                                onComplete();
                            })));
                        }))),
                    });
                }
            },
        })])],
    });
}

