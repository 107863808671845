import { useSnackbar } from "notistack";
import { MuiHelpers_createElement } from "../client/src/fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Modal from "@material-ui/core/Modal";
import { createElement } from "react";
import * as react from "react";
import { equals, createObj } from "../client/src/fable_modules/fable-library.3.7.17/Util.js";
import { join } from "../client/src/fable_modules/fable-library.3.7.17/String.js";
import { hEnd, cursorPointer, cancelDossierButton, hCenter, fullWidth, alignCenter, center, Padding, hideInPrint, Gap, MarginTop, MarginLeft, MarginBottom, PaddingRight, PaddingLeft, PaddingBottom, PaddingTop, modal } from "../Styles/Utils.fs.js";
import { collect, empty as empty_1, append, iterate, singleton, delay, toList } from "../client/src/fable_modules/fable-library.3.7.17/Seq.js";
import { Interop_reactApi } from "../client/src/fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { map, length, filter, cons, singleton as singleton_1, empty, ofArray } from "../client/src/fable_modules/fable-library.3.7.17/List.js";
import Button from "@material-ui/core/Button";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../Notistack/Notistack.fs.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../client/src/fable_modules/Feliz.1.68.0/React.fs.js";
import { useToggle } from "../CustomHooks/CustomHooks.fs.js";
import { useForm, useFormValidator, useFormField } from "../CustomHooks/ComposedForm.fs.js";
import { toArray, some } from "../client/src/fable_modules/fable-library.3.7.17/Option.js";
import { QuoteFileType, QuoteDocumentType, SelectOptionData$1_Create_Z510A3DC0 } from "../shared/Domain.fs.js";
import { Col, Row } from "../Components/UtilComponents.fs.js";
import RadioGroup from "@material-ui/core/RadioGroup";
import { InfoTitle, FileField, OptionField } from "./DesignSystem.fs.js";
import { parallel, startImmediate } from "../client/src/fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_2 } from "../client/src/fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { ByteArrayExtensions_SaveFileAs_72B26259, Browser_Types_File__File_ReadAsByteArray } from "../client/src/fable_modules/Fable.Remoting.Client.7.25.0/Extensions.fs.js";
import { choose } from "../client/src/fable_modules/fable-library.3.7.17/Array.js";
import Paper from "@material-ui/core/Paper";
import { Feliz_prop__prop_fss_Static_72C268A9 } from "../client/src/fable_modules/Fss-lib.Feliz.1.0.3/FssFeliz.fs.js";
import { FlexClasses_FlexShrinkGrow__value_5E38073B } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Flex.fs.js";
import { FlexGrow } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Flex.fs.js";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Close from "@material-ui/icons/Close";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

export function DeleteFileModal(deleteFileModalInputProps) {
    let elems_1, elems;
    const onDeleteFile = deleteFileModalInputProps.onDeleteFile;
    const setShowDeletionModal = deleteFileModalInputProps.setShowDeletionModal;
    const showDeleteModal = deleteFileModalInputProps.showDeleteModal;
    const fileName = deleteFileModalInputProps.fileName;
    const filePath = deleteFileModalInputProps.filePath;
    const snackbar = useSnackbar();
    return MuiHelpers_createElement(Modal, [["open", showDeleteModal], ["onClose", (_arg_1, v) => {
        setShowDeletionModal(false);
    }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.L, PaddingBottom.L, PaddingLeft.Xxl, PaddingRight.Xxl])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", [MarginBottom.M])], (elems = toList(delay(() => {
        if (fileName == null) {
            return singleton(null);
        }
        else {
            const fileName_1 = fileName;
            return singleton(`Verwijder bestand ${fileName_1}`);
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), MuiHelpers_createElement(Button, [["onClick", (_arg_2) => {
        if (filePath == null) {
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Geen bestand geselecteerd.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            console.error("Geen bestand geselecteerd");
            setShowDeletionModal(false);
        }
        else {
            onDeleteFile(filePath);
            setShowDeletionModal(false);
        }
    }], ["className", join(" ", [])], ["variant", "contained"], ["color", "secondary"], ["children", "Ja"]]), MuiHelpers_createElement(Button, [["onClick", (_arg_3) => {
        setShowDeletionModal(false);
    }], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "primary"], ["children", "Nee"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]]);
}

export function UploadDocumentModal(uploadDocumentModalInputProps) {
    let elems_2, elems, elements;
    const setFilesList = uploadDocumentModalInputProps.setFilesList;
    const filesList = uploadDocumentModalInputProps.filesList;
    const showUploadDocumentModal = uploadDocumentModalInputProps.showUploadDocumentModal;
    const api = uploadDocumentModalInputProps.api;
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const buttonsDisabled = useToggle(false);
    const file = useFormField(void 0, some);
    const documentType = useFormField(void 0, (arg_3) => some(SelectOptionData$1_Create_Z510A3DC0(arg_3)));
    const form = useFormValidator(documentType.Validator, (r, x_1) => ({
        DocumentType: x_1,
        File: r.File,
    }), useFormValidator(file.Validator, (unitVar_1, x) => ({
        File: x,
    }), useForm()));
    return MuiHelpers_createElement(Modal, [["open", showUploadDocumentModal.State], ["onClose", (_arg_5, v) => {
        showUploadDocumentModal.Off();
    }], ["onClick", (e_2) => {
        e_2.stopPropagation();
    }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.M, PaddingBottom.L, PaddingLeft.L, PaddingRight.L])], ["onClick", (e_3) => {
        e_3.stopPropagation();
    }], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", [MarginBottom.M])], (elems = [createElement("h3", {
        children: ["Document Uploaden"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement(Row, {
        classes: empty(),
        children: singleton_1(MuiHelpers_createElement(RadioGroup, [["row", true], (elements = [OptionField(documentType, new QuoteDocumentType(0), "Intern", "", true), OptionField(documentType, new QuoteDocumentType(1), "Extern", "", true)], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])),
    }), createElement(Row, {
        classes: singleton_1(MarginTop.M),
        children: singleton_1(FileField(file, "Bestand selecteren", "", true)),
    }), createElement(Row, {
        classes: singleton_1(MarginTop.L),
        children: ofArray([MuiHelpers_createElement(Button, [["onClick", (e_4) => {
            e_4.stopPropagation();
            iterate((data) => {
                const matchValue = data.DocumentType;
                if (matchValue == null) {
                }
                else {
                    const documentType_1 = matchValue.Value;
                    const file_1 = data.File;
                    startImmediate(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => {
                        let filename;
                        if (file_1 == null) {
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Selecteer een bestand", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                            return singleton_2.Zero();
                        }
                        else {
                            const file_2 = file_1;
                            startImmediate((filename = file_2.name, singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => {
                                buttonsDisabled.On();
                                return singleton_2.Bind(Browser_Types_File__File_ReadAsByteArray(file_2), (_arg) => singleton_2.Bind(api.UploadQuoteDocument(_arg, filename, documentType_1), (_arg_1) => {
                                    const output = _arg_1;
                                    buttonsDisabled.Off();
                                    if (output.tag === 1) {
                                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${output.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                        return singleton_2.Zero();
                                    }
                                    else {
                                        setFilesList(cons(output.fields[0], filesList));
                                        file.SetFieldValue(void 0);
                                        documentType.SetFieldValue(void 0);
                                        showUploadDocumentModal.Off();
                                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Bestand opgeladen", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                        return singleton_2.Zero();
                                    }
                                }));
                            }), (_arg_2) => {
                                const e = _arg_2;
                                buttonsDisabled.Off();
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `TEen onbekende fout is opgetreden. Gelieve support te contacteren. [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                console.log(e);
                                return singleton_2.Zero();
                            }))));
                            return singleton_2.Zero();
                        }
                    }), (_arg_3) => {
                        const e_1 = _arg_3;
                        showUploadDocumentModal.Off();
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden tijdens het bevestigen van de afspraak. Gelieve support te contacteren. [${e_1.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        console.log(e_1);
                        return singleton_2.Zero();
                    })));
                }
            }, toArray(form.Validate()));
        }], ["className", join(" ", [])], ["disabled", buttonsDisabled.State ? true : (file.FieldValue == null)], ["variant", "contained"], ["color", "secondary"], ["children", "Uploaden"]]), MuiHelpers_createElement(Button, [["onClick", (e_5) => {
            e_5.stopPropagation();
            showUploadDocumentModal.Off();
        }], ["disabled", buttonsDisabled.State], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "primary"], ["children", "Cancel"]])]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))]]);
}

export function ImageList(imageListInputProps) {
    const deleteFile = imageListInputProps.deleteFile;
    const getImage = imageListInputProps.getImage;
    const getFile = imageListInputProps.getFile;
    const quoteDocuments = imageListInputProps.quoteDocuments;
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setShowDeletionModal = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const isLoading = useToggle(false);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => {
            const images = filter((d) => equals(d.DocumentFileType, new QuoteFileType(1)), quoteDocuments);
            return singleton_2.Combine((length(images) > 0) ? ((isLoading.On(), singleton_2.Zero())) : singleton_2.Zero(), singleton_2.Delay(() => {
                const results = map((d_1) => singleton_2.Delay(() => singleton_2.Bind(getImage(getFile)(d_1.DocumentFilePath), (_arg) => singleton_2.Return([_arg, d_1]))), images);
                return singleton_2.Bind(parallel(results), (_arg_1) => {
                    const filesResult = choose((_arg_2) => {
                        const copyOfStruct = _arg_2[0];
                        if (copyOfStruct.tag === 1) {
                            console.error(copyOfStruct.fields[0]);
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                            return void 0;
                        }
                        else {
                            return [copyOfStruct.fields[0], _arg_2[1]];
                        }
                    }, _arg_1);
                    isLoading.Off();
                    patternInput[1](ofArray(filesResult));
                    return singleton_2.Zero();
                });
            }));
        }), (_arg_3) => {
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            console.error(_arg_3);
            return singleton_2.Zero();
        })));
    }, [quoteDocuments]);
    return createElement(Row, {
        classes: ofArray([Gap.L, MarginTop.M, hideInPrint]),
        children: toList(delay(() => {
            let matchValue, deleteFile_1;
            return append((matchValue = deleteFile, (matchValue == null) ? singleton(null) : ((deleteFile_1 = matchValue, singleton(createElement(DeleteFileModal, {
                filePath: patternInput_2[0],
                fileName: patternInput_3[0],
                showDeleteModal: patternInput_1[0],
                setShowDeletionModal: setShowDeletionModal,
                onDeleteFile: deleteFile_1,
            }))))), delay(() => {
                let elems;
                return singleton(MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton_1(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 4))), (elems = [createElement(Col, {
                    classes: singleton_1(Gap.M),
                    children: ofArray([createElement(InfoTitle, {
                        text: "Afbeeldingen",
                    }), MuiHelpers_createElement(Divider, []), createElement(Row, {
                        classes: ofArray([Gap.L, Padding.M]),
                        children: toList(delay(() => append(isLoading.State ? singleton(MuiHelpers_createElement(CircularProgress, [["className", join(" ", [center, alignCenter, fullWidth, hCenter])], ["variant", "indeterminate"]])) : empty_1(), delay(() => {
                            const matchValue_1 = patternInput[0];
                            if (matchValue_1 == null) {
                                return empty_1();
                            }
                            else {
                                return collect((preview) => {
                                    const file_2 = preview[1];
                                    return singleton(createElement(Col, {
                                        classes: singleton_1(Gap.Xs),
                                        children: toList(delay(() => append(singleton(createElement("img", {
                                            style: {
                                                objectFit: "contain",
                                                borderColor: "#efefef",
                                                borderWidth: 1,
                                                borderRadius: 8,
                                                borderStyle: "solid",
                                            },
                                            className: join(" ", [Padding.Xs]),
                                            width: 300,
                                            height: 300,
                                            src: preview[0],
                                        })), delay(() => append(singleton(MuiHelpers_createElement(Button, [["onClick", (_arg_6) => {
                                            let filePath;
                                            startImmediate((filePath = file_2.DocumentFilePath, singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(getFile(filePath), (_arg_4) => {
                                                const result_1 = _arg_4;
                                                if (result_1.tag === 1) {
                                                    console.error(result_1.fields[0]);
                                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                                    return singleton_2.Zero();
                                                }
                                                else {
                                                    ByteArrayExtensions_SaveFileAs_72B26259(result_1.fields[0], filePath.Value);
                                                    return singleton_2.Zero();
                                                }
                                            })), (_arg_5) => {
                                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                                console.error(_arg_5);
                                                return singleton_2.Zero();
                                            }))));
                                        }], ["className", join(" ", [])], ["variant", "contained"], ["color", "primary"], ["size", "small"], ["children", "Download"]])), delay(() => {
                                            let props_4;
                                            return (deleteFile == null) ? singleton(null) : singleton(MuiHelpers_createElement(Button, [["className", cancelDossierButton], ["children", "Verwijderen"], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props_4 = {}, react.createElement(Close, props_4))], ["onClick", (e_2) => {
                                                e_2.preventDefault();
                                                e_2.stopPropagation();
                                                patternInput_2[1](file_2.DocumentFilePath);
                                                patternInput_3[1](file_2.DocumentFileName);
                                                setShowDeletionModal(true);
                                            }]]));
                                        })))))),
                                    }));
                                }, matchValue_1);
                            }
                        })))),
                    })]),
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]));
            }));
        })),
    });
}

export function DocumentsList(documentsListInputProps) {
    const deleteFile = documentsListInputProps.deleteFile;
    const getFile = documentsListInputProps.getFile;
    const quoteDocuments = documentsListInputProps.quoteDocuments;
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setShowDeletionModal = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    return createElement(Row, {
        classes: ofArray([Gap.L, hideInPrint]),
        children: toList(delay(() => {
            let matchValue, deleteFile_1;
            return append((matchValue = deleteFile, (matchValue == null) ? singleton(null) : ((deleteFile_1 = matchValue, singleton(createElement(DeleteFileModal, {
                filePath: patternInput_1[0],
                fileName: patternInput_2[0],
                showDeleteModal: patternInput[0],
                setShowDeletionModal: setShowDeletionModal,
                onDeleteFile: deleteFile_1,
            }))))), delay(() => {
                let elems_6, elems_5, elems_4, children_3;
                return singleton(MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton_1(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 3))), (elems_6 = [createElement(Col, {
                    classes: empty(),
                    children: ofArray([createElement(InfoTitle, {
                        text: "Documenten",
                    }), MuiHelpers_createElement(Divider, []), MuiHelpers_createElement(TableContainer, [["component", "table"], ["className", join(" ", [MarginTop.M])], (elems_5 = [MuiHelpers_createElement(Table, [["size", "small"], (elems_4 = [(children_3 = toList(delay(() => collect((file_1) => {
                        let elems_2, children_2;
                        const filename = file_1.DocumentFileName;
                        return singleton(MuiHelpers_createElement(TableRow, [["className", join(" ", [cursorPointer])], ["hover", true], (elems_2 = [MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([filename])]]), (children_2 = singleton_1(createElement(Row, {
                            classes: ofArray([Gap.M, hEnd]),
                            children: toList(delay(() => append(singleton(MuiHelpers_createElement(Button, [["children", "Downloaden"], ["variant", "contained"], ["color", "default"], ["onClick", (e_1) => {
                                let filePath;
                                e_1.preventDefault();
                                e_1.stopPropagation();
                                startImmediate((filePath = file_1.DocumentFilePath, singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(getFile(filePath), (_arg) => {
                                    const result = _arg;
                                    if (result.tag === 1) {
                                        console.error(result.fields[0]);
                                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                        return singleton_2.Zero();
                                    }
                                    else {
                                        ByteArrayExtensions_SaveFileAs_72B26259(result.fields[0], filePath.Value);
                                        return singleton_2.Zero();
                                    }
                                })), (_arg_1) => {
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                    console.error(_arg_1);
                                    return singleton_2.Zero();
                                }))));
                            }]])), delay(() => {
                                let props_2;
                                const matchValue_1 = deleteFile;
                                if (matchValue_1 == null) {
                                    return singleton(null);
                                }
                                else {
                                    const deleteFile_2 = matchValue_1;
                                    return singleton(MuiHelpers_createElement(Button, [["className", cancelDossierButton], ["children", "Verwijderen"], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props_2 = {}, react.createElement(Close, props_2))], ["onClick", (e_2) => {
                                        e_2.preventDefault();
                                        e_2.stopPropagation();
                                        patternInput_1[1](file_1.DocumentFilePath);
                                        patternInput_2[1](filename);
                                        setShowDeletionModal(true);
                                    }]]));
                                }
                            })))),
                        })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]));
                    }, filter((d) => equals(d.DocumentFileType, new QuoteFileType(0)), quoteDocuments)))), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])]),
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]));
            }));
        })),
    });
}

