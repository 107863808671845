import { format } from "../../client/src/fable_modules/fable-library.3.7.17/String.js";
import { op_Addition, op_Multiply, round, op_Subtraction, fromParts } from "../../client/src/fable_modules/fable-library.3.7.17/Decimal.js";
import { Customer2__get_CustomerOrCompanyName, NonEmptyString___UnsafeUnwrap_Z73AD07C, Customer2__get_Address } from "../../shared/Domain.fs.js";
import { createElement } from "react";
import { Col, Row } from "../../Components/UtilComponents.fs.js";
import { ofArray, singleton, empty } from "../../client/src/fable_modules/fable-library.3.7.17/List.js";
import { MuiHelpers_createElement } from "../../client/src/fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Paper from "@material-ui/core/Paper";
import { Feliz_prop__prop_fss_Static_72C268A9 } from "../../client/src/fable_modules/Fss-lib.Feliz.1.0.3/FssFeliz.fs.js";
import { FlexClasses_FlexShrinkGrow__value_5E38073B } from "../../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Flex.fs.js";
import { FlexGrow } from "../../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Flex.fs.js";
import { DisplayDataWide, DisplayData, InfoTitle } from "../DesignSystem.fs.js";
import Divider from "@material-ui/core/Divider";
import { flexExpand } from "../../Styles/Utils.fs.js";
import { Interop_reactApi } from "../../client/src/fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../../client/src/fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { DisplayDeferred } from "../DisplayDeferred.fs.js";

export function QuoteInvoice(quoteDetails) {
    let elems;
    const toString = (amt) => ("€ " + format('{0:' + "C2" + '}', amt));
    const feeDue = fromParts(50, 0, 0, false, 0);
    const feeString = toString(feeDue);
    let actualPrice;
    const matchValue = quoteDetails.Quote.CustomPrice;
    actualPrice = ((matchValue == null) ? fromParts(0, 0, 0, false, 0) : matchValue.Value);
    const linePrice = op_Subtraction(actualPrice, feeDue);
    const vatAmount = round(op_Multiply(linePrice, fromParts(21, 0, 0, false, 2)), 2);
    const netAmount = op_Addition(actualPrice, vatAmount);
    const address = Customer2__get_Address(quoteDetails.Customer);
    let bus_1;
    const matchValue_1 = address.Bus;
    if (matchValue_1 == null) {
        bus_1 = "";
    }
    else {
        const bus = matchValue_1;
        bus_1 = (`, ${NonEmptyString___UnsafeUnwrap_Z73AD07C(bus)}`);
    }
    let vatNo;
    const matchValue_2 = quoteDetails.Customer;
    vatNo = ((matchValue_2.tag === 0) ? "" : matchValue_2.fields[0].VatNumber.Value);
    return createElement(Row, {
        classes: empty(),
        children: singleton(MuiHelpers_createElement(Paper, [Feliz_prop__prop_fss_Static_72C268A9(singleton(FlexClasses_FlexShrinkGrow__value_5E38073B(FlexGrow, 2))), (elems = [createElement(Col, {
            classes: empty(),
            children: ofArray([createElement(InfoTitle, {
                text: "Facturatiegegevens",
            }), MuiHelpers_createElement(Divider, []), createElement(Row, {
                classes: empty(),
                children: ofArray([createElement(Col, {
                    classes: singleton(flexExpand),
                    children: singleton(createElement(DisplayData, {
                        data: ofArray([["Offerte nummer", quoteDetails.Quote.Code.Value], ["Klant", Customer2__get_CustomerOrCompanyName(quoteDetails.Customer)], ["Klant Adres", `${NonEmptyString___UnsafeUnwrap_Z73AD07C(address.Street)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(address.Number)} ${bus_1} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(address.PostalCode)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(address.City)}`], ["BTW", vatNo]]),
                    })),
                }), createElement(Col, {
                    classes: singleton(flexExpand),
                    children: singleton(createElement(DisplayDataWide, {
                        data: ofArray([["Asbestattest Gemene Delen", toString(linePrice)], ["Retributie OVAM", feeString], ["Bijkomende stalen", "€ 0,00"], ["Opmaken plannen indien nog niet aanwezig", "€ 0,00"], ["Subtotaal", toString(actualPrice)], ["BTW 21%", toString(vatAmount)], ["Totaal incl. BTW", toString(netAmount)]]),
                    })),
                })]),
            })]),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])),
    });
}

export function QuoteInvoiceDetailsFromDossierCode(quoteInvoiceDetailsFromDossierCodeInputProps) {
    const dossierCode = quoteInvoiceDetailsFromDossierCodeInputProps.dossierCode;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(quoteInvoiceDetailsFromDossierCodeInputProps.api.GetQuoteDetailsByDossierCode(dossierCode), []);
    return createElement(DisplayDeferred, {
        data: data,
        view: (quoteDetails) => ((quoteDetails == null) ? null : QuoteInvoice(quoteDetails)),
    });
}

