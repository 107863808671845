import { keyValueList } from "../../fable_modules/fable-library.3.7.17/MapUtil.js";
import { Union, Record } from "../../fable_modules/fable-library.3.7.17/Types.js";
import { bool_type, union_type, lambda_type, unit_type, option_type, class_type, int32_type, record_type, string_type } from "../../fable_modules/fable-library.3.7.17/Reflection.js";
import * as react from "react";
import { Page as Page_1, Document as Document$_1 } from "react-pdf/dist/esm/entry.webpack5";

function toObj(p) {
    return keyValueList(p, 1);
}

export class PdfFile extends Record {
    constructor(url) {
        super();
        this.url = url;
    }
}

export function PdfFile$reflection() {
    return record_type("ReactPdf.PdfFile", [], PdfFile, () => [["url", string_type]]);
}

export class LoadProp extends Record {
    constructor(numPages) {
        super();
        this.numPages = (numPages | 0);
    }
}

export function LoadProp$reflection() {
    return record_type("ReactPdf.LoadProp", [], LoadProp, () => [["numPages", int32_type]]);
}

export class DocumentProp extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ClassName", "Error", "ExternalLinkTarget", "File", "InputRef", "Loading", "NoData", "OnItemClick", "OnLoadError", "OnLoadSuccess", "OnPassword", "OnSourceError", "OnSourceSuccess"];
    }
}

export function DocumentProp$reflection() {
    return union_type("ReactPdf.DocumentProp", [], DocumentProp, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", class_type("Fable.React.IRefValue`1", [option_type(class_type("Browser.Types.HTMLDivElement"))])]], [["Item", string_type]], [["Item", string_type]], [["Item", lambda_type(int32_type, unit_type)]], [["Item", lambda_type(class_type("ReactPdf.Error"), unit_type)]], [["Item", lambda_type(LoadProp$reflection(), unit_type)]], [["Item", lambda_type(unit_type, lambda_type(unit_type, unit_type))]], [["Item", lambda_type(class_type("ReactPdf.Error"), unit_type)]], [["Item", lambda_type(unit_type, unit_type)]]]);
}

export class PageProp extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ClassName", "PageNumber", "Error", "Key", "RenderTextLayer", "RenderAnnotationLayer", "Width", "Height"];
    }
}

export function PageProp$reflection() {
    return union_type("ReactPdf.PageProp", [], PageProp, () => [[["Item", string_type]], [["Item", int32_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", int32_type]], [["Item", int32_type]]]);
}

export function Document$(props) {
    const props_1 = toObj(props);
    return react.createElement(Document$_1, props_1);
}

export function Page(props) {
    const props_1 = toObj(props);
    return react.createElement(Page_1, props_1);
}

