import { render } from "react-dom";
import { createElement } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { Root } from "./Root.fs.js";

render(createElement(Auth0Provider, {
    domain: "dev-wlhor2dm6y8idnas.us.auth0.com",
    clientId: "qJQ3c7BkSelBy2TSkO19cG0JzSmzismq",
    redirectUri: window.location.origin,
    children: createElement(Root, null),
}), document.getElementById("feliz-app"));

