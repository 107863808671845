import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Button from "@material-ui/core/Button";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { singleton as singleton_2, ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { dossierDetail, partnersDetail, dossierCreate, customerEdit } from "../AppRoutes.fs.js";
import { createElement } from "react";
import * as react from "react";
import Edit from "@material-ui/icons/Edit";
import { join } from "../fable_modules/fable-library.3.7.17/String.js";
import { PaddingTop, MarginLeft } from "../../../Styles/Utils.fs.js";
import { useReact_useEffect_Z101E1A95, useReact_useMemo_CF4EA67, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { useSnackbar } from "notistack";
import { State, searchTerms, initialState, update } from "../../../DesignSystems/dossier/DossierFilter.fs.js";
import { useDebouncedValue } from "../../../CustomHooks/CustomHooks.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { CustomerCode as CustomerCode_2 } from "../../../shared/Domain.fs.js";
import { DossierSearchTerms } from "../../../shared/DomainHelpers.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { append, singleton as singleton_1, empty, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { Page } from "../components/Page.fs.js";
import { DisplayCustomerInfo } from "../../../DesignSystems/CustomerForms.fs.js";
import { Auth0UserPrivileges, Auth0UserClaims__HasPrivilege_6B2F319B } from "../../../shared/Auth0Constants.fs.js";
import { createObj } from "../fable_modules/fable-library.3.7.17/Util.js";
import { DossiersData } from "../../../DesignSystems/dossier/DossierDetails.fs.js";
import Paper from "@material-ui/core/Paper";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";

export function CustomerDetail(customerDetailInputProps) {
    let props;
    const auth0User = customerDetailInputProps.auth0User;
    const customerCode = customerDetailInputProps.customerCode;
    const api = customerDetailInputProps.api;
    const currentPage = customerDetailInputProps.currentPage;
    const editCustomerButton = MuiHelpers_createElement(Button, [["onClick", (_arg) => {
        RouterModule_nav(ofArray(Page$2__toUrl_2B594(customerEdit, customerCode)), 1, 1);
    }], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props = {}, react.createElement(Edit, props))], ["children", "Klant bewerken"]]);
    const startDossierButton = MuiHelpers_createElement(Button, [["onClick", (_arg_1) => {
        RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossierCreate)), 1, 1);
    }], ["className", join(" ", [MarginLeft.M])], ["variant", "contained"], ["color", "secondary"], ["children", "Dossier Starten"]]);
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setCustomer = patternInput[1];
    const customer = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const partner = patternInput_1[0];
    const snackbar = useSnackbar();
    const patternInput_2 = useReact_useReducer_2B9E6EA0(update, initialState);
    const state_1 = patternInput_2[0];
    const search = useDebouncedValue(500, useReact_useMemo_CF4EA67(() => searchTerms(state_1), [state_1]));
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetDossiersDetails(new PagingQuery(state_1.PageNumber, state_1.PageSize), new DossierSearchTerms(search.DossierCode, search.PartnerName, search.AdiName, search.CustomerName, search.Address, search.PhoneNumber, search.Status, search.StartDate, search.EndDate, search.FilterOutDossiersWithoutPartners, search.CibFilter, new CustomerCode_2(customerCode), search.PartnerPortal, search.Urgent, search.PartnerId, search.PartnerIdList)), [state_1.PageNumber, state_1.PageSize, search]);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(api.GetCustomer(new CustomerCode_2(customerCode)), (_arg_3) => {
            const customer_1 = _arg_3;
            if (customer_1.tag === 1) {
                const err_1 = customer_1.fields[0];
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_1}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                console.error(err_1);
                return singleton.Zero();
            }
            else if (customer_1.fields[0] == null) {
                setCustomer(void 0);
                return singleton.Zero();
            }
            else {
                const customer_2 = customer_1.fields[0];
                setCustomer(customer_2);
                const getPartner = (partnerId) => singleton.Delay(() => singleton.Bind(api.GetPartner(partnerId), (_arg_4) => {
                    const partner_1 = _arg_4;
                    if (partner_1.tag === 1) {
                        const err = partner_1.fields[0];
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        console.error(err);
                        return singleton.Zero();
                    }
                    else {
                        patternInput_1[1](partner_1.fields[0]);
                        return singleton.Zero();
                    }
                }));
                if (customer_2.tag === 1) {
                    const matchValue_1 = customer_2.fields[0].TenantPartnerId;
                    if (matchValue_1 == null) {
                        return singleton.Zero();
                    }
                    else {
                        const partnerId_2 = matchValue_1;
                        return singleton.Bind(getPartner(partnerId_2), () => singleton.Return());
                    }
                }
                else {
                    const matchValue = customer_2.fields[0].TenantPartnerId;
                    if (matchValue == null) {
                        return singleton.Zero();
                    }
                    else {
                        const partnerId_1 = matchValue;
                        return singleton.Bind(getPartner(partnerId_1), () => singleton.Return());
                    }
                }
            }
        })));
    }, [customerCode]);
    const buttons = toList(delay(() => {
        if (partner != null) {
            return empty();
        }
        else {
            return singleton_1(editCustomerButton);
        }
    }));
    return createElement(Page, {
        currentPage: currentPage,
        title: "Klantenfiche",
        actions: buttons,
        children: toList(delay(() => {
            if (customer == null) {
                return singleton_1(`No customer found with code ${customerCode}`);
            }
            else {
                const customer_5 = customer;
                return append(singleton_1(createElement(DisplayCustomerInfo, {
                    customer: customer_5,
                    partner: partner,
                    onPartnerClick: () => {
                        if (partner == null) {
                        }
                        else {
                            RouterModule_nav(ofArray(Page$2__toUrl_2B594(partnersDetail, partner.Id.Value)), 1, 1);
                        }
                    },
                })), delay(() => {
                    let elems_1, children_1;
                    return Auth0UserClaims__HasPrivilege_6B2F319B(auth0User, new Auth0UserPrivileges(0)) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", [PaddingTop.M])], (elems_1 = [createElement("h2", {
                        children: ["Gekoppelde dossiers"],
                    }), (children_1 = singleton_2(createElement(DossiersData, {
                        data: data,
                        state: new State(state_1.DossierCode, state_1.AdiName, state_1.PartnerName, state_1.CustomerName, new CustomerCode_2(customerCode), state_1.Address, state_1.PhoneNumber, state_1.DateRange, state_1.PartnerId, state_1.Status, state_1.PageNumber, state_1.PageSize, state_1.CibFilter, state_1.PartnerPortal, state_1.Urgent),
                        dispatch: patternInput_2[1],
                        url: (dossierId) => Page$2__toUrl_2B594(dossierDetail, dossierId.Value),
                        showPartnerName: false,
                        optionalExportCsvFunction: void 0,
                        showCibFilter: false,
                        isBackOffice: true,
                        klantNameHeader: void 0,
                    })), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))) : empty();
                }));
            }
        })),
    });
}

