import { date as date_1, dayOfWeek as dayOfWeek_1, addDays, day, month, year, create } from "../fable_modules/fable-library.3.7.17/Date.js";

export function getDays(dayOfWeek) {
    switch (dayOfWeek) {
        case 0: {
            return 6;
        }
        case 1: {
            return 0;
        }
        case 2: {
            return 1;
        }
        case 3: {
            return 2;
        }
        case 4: {
            return 3;
        }
        case 5: {
            return 4;
        }
        case 6: {
            return 5;
        }
        default: {
            return 0;
        }
    }
}

export function getDayStart(date) {
    return create(year(date), month(date), day(date), 0, 0, 0);
}

export function getDayEnd(date) {
    return create(year(date), month(date), day(date), 23, 59, 59);
}

export function weekStartDate(dt) {
    return getDayStart(addDays(dt, 0 - getDays(dayOfWeek_1(dt))));
}

export function weekEndDate(dt) {
    let copyOfStruct_1, copyOfStruct;
    return getDayEnd((copyOfStruct_1 = ((copyOfStruct = weekStartDate(dt), date_1(copyOfStruct))), addDays(copyOfStruct_1, 6)));
}

