import { printf, toText, split, isNullOrWhiteSpace, join } from "./fable_modules/fable-library.3.7.17/String.js";
import { ofArray, collect, filter } from "./fable_modules/fable-library.3.7.17/List.js";
import { IServerApi$reflection, routerPaths } from "../../shared/Api.fs.js";
import { Remoting_buildProxy_64DC51C } from "./fable_modules/Fable.Remoting.Client.7.25.0/./Remoting.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder } from "./fable_modules/Fable.Remoting.Client.7.25.0/Remoting.fs.js";
import { Record } from "./fable_modules/fable-library.3.7.17/Types.js";
import { record_type, lambda_type, class_type, unit_type } from "./fable_modules/fable-library.3.7.17/Reflection.js";

export const virtualPath = eval("window.location.pathname");

export function combine(paths) {
    const arg_2 = join("/", filter((arg_1) => (!isNullOrWhiteSpace(arg_1)), filter((segment) => (!(segment.indexOf(".") >= 0)), collect((path) => ofArray(split(path, ["/"], null, 0)), paths))));
    return toText(printf("/%s"))(arg_2);
}

export function normalize(path) {
    return combine(ofArray([virtualPath, path]));
}

export function normalizeRoutes(typeName, methodName) {
    return normalize(routerPaths(typeName, methodName));
}

export const Api = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(normalizeRoutes, RemotingModule_createApi()), IServerApi$reflection());

export class ApiHandler$1 extends Record {
    constructor(userClaims, call) {
        super();
        this.userClaims = userClaims;
        this.call = call;
    }
}

export function ApiHandler$1$reflection(gen0) {
    return record_type("Server.ApiHandler`1", [gen0], ApiHandler$1, () => [["userClaims", lambda_type(unit_type, class_type("Fable.Core.JS.Promise`1", [class_type("AuthZero.AuthZeroTokenClaims")]))], ["call", lambda_type(IServerApi$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [gen0]))]]);
}

