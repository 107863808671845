import { useToggle } from "../../../CustomHooks/CustomHooks.fs.js";
import { useForm, useFormValidator, useFormField } from "../../../CustomHooks/ComposedForm.fs.js";
import { DossierStatus_isAppointmentAllowed_Z7B6F6316, CommentType, AdiId, AdiOption, NonEmptyString___UnsafeUnwrap_Z73AD07C, NonEmptyString_get_Create, AsbestosConclusion, DossierCode, SelectOptionData$1_Create_Z510A3DC0 } from "../../../shared/Domain.fs.js";
import { useReact_useEffect_Z101E1A95, useReact_useElementRef, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { map as map_1, defaultArg, toArray, some } from "../fable_modules/fable-library.3.7.17/Option.js";
import { useSnackbar } from "notistack";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { getImage, openPdfInNewTab, downloadFile } from "./DownloadFile.fs.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { Browser_Types_File__File_ReadAsByteArray } from "../fable_modules/Fable.Remoting.Client.7.25.0/Extensions.fs.js";
import { createElement } from "react";
import * as react from "react";
import { partialApply, curry, createObj } from "../fable_modules/fable-library.3.7.17/Util.js";
import { printf, toConsole, join } from "../fable_modules/fable-library.3.7.17/String.js";
import { cancelDossierButton as cancelDossierButton_1, MarginLeft, fullWidth, MarginTop, PaddingRight, PaddingLeft, PaddingBottom, PaddingTop, modal, center, MarginRight, hideInPrint, Gap, MarginBottom, Boldness, TextSize, TextColor } from "../../../Styles/Utils.fs.js";
import { DateParsing_parse, Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { map, toArray as toArray_1, singleton as singleton_1, empty, ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { Auth0UserPrivileges, Auth0UserClaims__HasPrivilege_6B2F319B } from "../../../shared/Auth0Constants.fs.js";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Button from "@material-ui/core/Button";
import { append, iterate, singleton as singleton_2, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { ErrorText, TextField, FileField, OptionField, DownloadLink } from "../../../DesignSystems/DesignSystem.fs.js";
import Modal from "@material-ui/core/Modal";
import { fromDate } from "../fable_modules/fable-library.3.7.17/DateOffset.js";
import { GeneralFormats_dateTimeOffset } from "../../../shared/Format.fs.js";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Browser_Types_Event__Event_get_Checked } from "../fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { UploadMiscFiles } from "../../../DesignSystems/FileUpload.fs.js";
import { RouterModule_encodeParts, RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { appointmentDetail, partnersDetail, customerDetail, digitalSignaturePage, dossierAppointmentCreate, dossierEdit, dossiers } from "../AppRoutes.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField_1 from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Print from "@material-ui/icons/Print";
import { Page } from "../components/Page.fs.js";
import { DisplayDossierInfo } from "../../../DesignSystems/dossier/DossierDetails.fs.js";
import { ImageList, DocumentsList } from "../../../DesignSystems/QuoteFiles.fs.js";
import { DossierEventHistory } from "../components/DossierEventHistory.fs.js";

export function UploadFiles(uploadFilesInputProps) {
    let elems_8, elems_3, elems_2, elems_7, elems_13, elems_10, elems_9, elems_12, elems_19, elems_15, elems_14, elems_18;
    const dossierDetail = uploadFilesInputProps.dossierDetail;
    const auth0User = uploadFilesInputProps.auth0User;
    const dossierCode = uploadFilesInputProps.dossierCode;
    const api = uploadFilesInputProps.api;
    const sendEmailToClient = useToggle(true);
    const sendEmailToPartner = useToggle(true);
    const conclusion = useFormField(dossierDetail.Dossier.Conclusion, SelectOptionData$1_Create_Z510A3DC0);
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setShowUploadCertificate = patternInput[1];
    const disableAssignmentConfirmationUpload = useToggle(false);
    const disableCertificateUpload = useToggle(false);
    const disableLabUpload = useToggle(false);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setShowUploadAssignmentConfirmation = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setShowUploadLabCertificate = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setShowOverwriteWarningCertificate = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const setShowOverwriteWarningAssignmentConfirmation = patternInput_4[1];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(false);
    const setShowOverwriteWarningLab = patternInput_5[1];
    const file = useFormField(void 0, some);
    const assignmentDocfile = useFormField(void 0, some);
    const labfile = useFormField(void 0, some);
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(void 0);
    const certDate = patternInput_6[0];
    const form = useFormValidator(conclusion.Validator, (r, x_1) => ({
        Conclusion: x_1,
        File: r.File,
    }), useFormValidator(file.Validator, (unitVar, x) => ({
        File: x,
    }), useForm()));
    const assignmentDocUploadform = useFormValidator(assignmentDocfile.Validator, (unitVar_1, x_2) => ({
        File: x_2,
    }), useForm());
    const labUploadform = useFormValidator(labfile.Validator, (unitVar_2, x_3) => ({
        File: x_3,
    }), useForm());
    const snackbar = useSnackbar();
    const download = (filePath) => {
        startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
            startImmediate(downloadFile(api, filePath));
            return singleton.Zero();
        }), (_arg) => {
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${_arg.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            return singleton.Zero();
        })));
    };
    const uploadFile = (file_1, endpoint, onConfirm) => singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Browser_Types_File__File_ReadAsByteArray(file_1), (_arg_1) => singleton.Bind(endpoint(new DossierCode(dossierCode))(_arg_1)(file_1.name), (_arg_2) => {
        const output = _arg_2;
        onConfirm();
        if (output.tag === 1) {
            const err = output.fields[0];
            console.error(err);
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            return singleton.Zero();
        }
        else {
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Bestand opgeladen", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
            return singleton.Return();
        }
    }))), (_arg_3) => {
        const e_1 = _arg_3;
        console.error(e_1);
        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e_1.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
        return singleton.Zero();
    }));
    const uploadAttestFile = (file_2, conclusion_1, certificateDate, sendEmailToClient_1, sendEmailToPartner_1, endpoint_1, onConfirm_1, onError) => {
        startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Browser_Types_File__File_ReadAsByteArray(file_2), (_arg_4) => singleton.Bind(endpoint_1(new DossierCode(dossierCode))(conclusion_1)(certificateDate)(sendEmailToClient_1)(sendEmailToPartner_1)(_arg_4)(file_2.name), (_arg_5) => {
            const output_1 = _arg_5;
            if (output_1.tag === 1) {
                const err_1 = output_1.fields[0];
                onError();
                console.error(err_1);
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_1}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                return singleton.Zero();
            }
            else {
                onConfirm_1();
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Bestand opgeladen", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                return singleton.Return();
            }
        }))), (_arg_6) => {
            const e_2 = _arg_6;
            console.error(e_2);
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e_2.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            return singleton.Zero();
        })));
    };
    const uploadFileAssignmentConfirmation = (file_3, appointmentConfirmationId, filename, endpoint_2, onCompletion) => {
        startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Browser_Types_File__File_ReadAsByteArray(file_3), (_arg_7) => singleton.Bind(endpoint_2(new DossierCode(dossierCode))(_arg_7)(filename), (_arg_8) => {
            const output_2 = _arg_8;
            onCompletion();
            if (output_2.tag === 1) {
                const err_2 = output_2.fields[0];
                console.error(err_2);
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_2}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                return singleton.Zero();
            }
            else {
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Bestand opgeladen", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                return singleton.Zero();
            }
        }))), (_arg_9) => {
            const e_3 = _arg_9;
            console.error(e_3);
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e_3.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            return singleton.Zero();
        })));
    };
    const warningText = (text) => {
        let elems_1;
        return createElement("div", createObj(ofArray([["className", join(" ", [TextColor.Danger, TextSize.M, Boldness.b600, MarginBottom.S])], (elems_1 = [createElement("div", {
            className: join(" ", []),
            children: Interop_reactApi.Children.toArray([text]),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
    };
    if (Auth0UserClaims__HasPrivilege_6B2F319B(auth0User, new Auth0UserPrivileges(0))) {
        return createElement(Col, {
            classes: ofArray([Gap.M, hideInPrint]),
            children: ofArray([createElement("div", createObj(singleton_1((elems_8 = [createElement("h2", {
                children: ["Asbestattest opladen"],
            }), createElement(Row, {
                classes: empty(),
                children: ofArray([createElement(Col, {
                    classes: singleton_1(MarginRight.M),
                    children: singleton_1(MuiHelpers_createElement(Button, [["variant", "contained"], ["children", "Uploaden"], ["onClick", (_arg_10) => {
                        setShowUploadCertificate(true);
                    }]])),
                }), createElement(Col, {
                    classes: singleton_1(center),
                    children: toList(delay(() => {
                        const matchValue = dossierDetail.Dossier.Certificate;
                        if (matchValue == null) {
                            return singleton_2("");
                        }
                        else {
                            const file_4 = matchValue;
                            return singleton_2(createElement(DownloadLink, {
                                text: "Download",
                                onDownload: () => {
                                    download(file_4);
                                },
                            }));
                        }
                    })),
                })]),
            }), MuiHelpers_createElement(Modal, [["open", patternInput_3[0]], ["onClose", (_arg_12, v) => {
                setShowOverwriteWarningCertificate(false);
            }], ["onClick", (e_4) => {
                e_4.stopPropagation();
            }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.L, PaddingBottom.L, PaddingLeft.L, PaddingRight.L])], (elems_3 = [createElement(Col, {
                classes: empty(),
                children: ofArray([createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_2 = [warningText("Opgepast: Er is reeds een asbest attest aanwezig!"), warningText("Ben je zeker dat je het bestaande asbest attest wil overschrijven? Deze actie kan niet teruggedraaid worden.")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement(Row, {
                    classes: ofArray([Gap.M, MarginTop.M]),
                    children: ofArray([createElement(Col, {
                        classes: empty(),
                        children: singleton_1(MuiHelpers_createElement(Button, [["color", "secondary"], ["variant", "contained"], ["disabled", disableCertificateUpload.State], ["onClick", (_arg_13) => {
                            iterate((data) => {
                                const matchValue_1 = [data.File, certDate];
                                let pattern_matching_result_4, certDate_1, file_5;
                                if (matchValue_1[0] != null) {
                                    if (matchValue_1[1] != null) {
                                        pattern_matching_result_4 = 0;
                                        certDate_1 = matchValue_1[1];
                                        file_5 = matchValue_1[0];
                                    }
                                    else {
                                        pattern_matching_result_4 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_4 = 1;
                                }
                                switch (pattern_matching_result_4) {
                                    case 0: {
                                        disableCertificateUpload.On();
                                        uploadAttestFile(file_5, data.Conclusion.Value, certDate_1, sendEmailToClient.State, sendEmailToPartner.State, curry(7, api.UploadCertificate), () => {
                                            setShowOverwriteWarningCertificate(false);
                                            setShowUploadCertificate(false);
                                            disableCertificateUpload.Off();
                                        }, () => {
                                            disableCertificateUpload.Off();
                                        });
                                        break;
                                    }
                                    case 1: {
                                        break;
                                    }
                                }
                            }, toArray(form.Validate()));
                        }], ["children", "Bevestigen"]])),
                    }), createElement(Col, {
                        classes: empty(),
                        children: singleton_1(MuiHelpers_createElement(Button, [["disabled", disableCertificateUpload.State], ["color", "primary"], ["variant", "contained"], ["onClick", (_arg_14) => {
                            setShowOverwriteWarningCertificate(false);
                        }], ["children", "Annuleren"]])),
                    })]),
                })]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))]]), MuiHelpers_createElement(Modal, [["open", patternInput[0]], ["onClose", (_arg_16, v_1) => {
                setShowUploadCertificate(false);
            }], ["onClick", (e_5) => {
                e_5.stopPropagation();
            }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.L, PaddingBottom.L, PaddingLeft.Xxl, PaddingRight.Xxl])], (elems_7 = [createElement(Col, {
                classes: empty(),
                children: toList(delay(() => {
                    let elems_4;
                    return append(singleton_2(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_4 = [createElement("h2", {
                        children: ["Upload Attest"],
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                        let elems_5;
                        return append((dossierDetail.Dossier.Certificate == null) ? singleton_2(null) : singleton_2(createElement("div", createObj(ofArray([["className", join(" ", [MarginTop.S])], (elems_5 = [warningText("Opgepast: Er is reeds een asbestattest aanwezig!")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => {
                            let dt;
                            return append(singleton_2(createElement(Col, {
                                classes: ofArray([MarginTop.M, Gap.S]),
                                children: ofArray([createElement("b", {
                                    children: ["Certificaat datum"],
                                }), createElement("input", {
                                    type: "date",
                                    onChange: (ev) => {
                                        iterate((n) => {
                                            patternInput_6[1](fromDate(n));
                                        }, toArray(DateParsing_parse(ev.target.value)));
                                    },
                                    className: join(" ", [fullWidth]),
                                    value: (certDate == null) ? "" : ((dt = certDate, (toConsole(printf("date1 %A"))(dt), GeneralFormats_dateTimeOffset(dt)))),
                                })]),
                            })), delay(() => {
                                let elements;
                                return append(singleton_2(createElement(Col, {
                                    classes: singleton_1(MarginTop.M),
                                    children: ofArray([createElement("b", {
                                        children: ["Conclusie asbest"],
                                    }), MuiHelpers_createElement(RadioGroup, [["row", true], (elements = [OptionField(conclusion, new AsbestosConclusion(0), "Veilig", "", true), OptionField(conclusion, new AsbestosConclusion(1), "Onveilig", "", true)], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])]),
                                })), delay(() => append(singleton_2(createElement(Row, {
                                    classes: singleton_1(MarginTop.M),
                                    children: singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", "Stuur een e-mail naar de klant"], ["control", MuiHelpers_createElement(Checkbox, [["checked", sendEmailToClient.State]])], ["onChange", (e_6) => {
                                        if (Browser_Types_Event__Event_get_Checked(e_6)) {
                                            sendEmailToClient.On();
                                        }
                                        else {
                                            sendEmailToClient.Off();
                                        }
                                    }]])),
                                })), delay(() => {
                                    let matchValue_3, p;
                                    return append((matchValue_3 = dossierDetail.Partner, (matchValue_3 == null) ? singleton_2(null) : ((p = matchValue_3, singleton_2(createElement(Row, {
                                        classes: empty(),
                                        children: singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", "Stuur een e-mail naar de partner"], ["control", MuiHelpers_createElement(Checkbox, [["checked", sendEmailToPartner.State]])], ["onChange", (e_7) => {
                                            if (Browser_Types_Event__Event_get_Checked(e_7)) {
                                                sendEmailToPartner.On();
                                            }
                                            else {
                                                sendEmailToPartner.Off();
                                            }
                                        }]])),
                                    }))))), delay(() => append(singleton_2(createElement(Row, {
                                        classes: singleton_1(MarginTop.M),
                                        children: singleton_1(FileField(file, "Bestand selecteren", "", true)),
                                    })), delay(() => singleton_2(createElement(Row, {
                                        classes: singleton_1(MarginTop.L),
                                        children: singleton_1(MuiHelpers_createElement(Button, [["disabled", disableCertificateUpload.State], ["color", "secondary"], ["variant", "contained"], ["onClick", (_arg_17) => {
                                            iterate((data_1) => {
                                                const matchValue_4 = [data_1.File, certDate];
                                                let pattern_matching_result_5, certDate_2, file_6;
                                                if (matchValue_4[0] != null) {
                                                    if (matchValue_4[1] != null) {
                                                        pattern_matching_result_5 = 0;
                                                        certDate_2 = matchValue_4[1];
                                                        file_6 = matchValue_4[0];
                                                    }
                                                    else {
                                                        pattern_matching_result_5 = 1;
                                                    }
                                                }
                                                else {
                                                    pattern_matching_result_5 = 1;
                                                }
                                                switch (pattern_matching_result_5) {
                                                    case 0: {
                                                        const matchValue_5 = dossierDetail.Dossier.Certificate;
                                                        if (matchValue_5 == null) {
                                                            disableCertificateUpload.On();
                                                            uploadAttestFile(file_6, data_1.Conclusion.Value, certDate_2, sendEmailToClient.State, sendEmailToPartner.State, curry(7, api.UploadCertificate), () => {
                                                                setShowOverwriteWarningCertificate(false);
                                                                setShowUploadCertificate(false);
                                                                disableCertificateUpload.Off();
                                                            }, () => {
                                                                disableCertificateUpload.Off();
                                                            });
                                                        }
                                                        else {
                                                            const file_7 = matchValue_5;
                                                            setShowOverwriteWarningCertificate(true);
                                                        }
                                                        break;
                                                    }
                                                    case 1: {
                                                        break;
                                                    }
                                                }
                                            }, toArray(form.Validate()));
                                        }], ["children", "Asbestattest opladen"]])),
                                    }))))));
                                }))));
                            }));
                        }));
                    }));
                })),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])))]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])))), createElement("div", createObj(singleton_1((elems_13 = [createElement("h2", {
                children: ["Opdrachtformulier opladen"],
            }), createElement(Row, {
                classes: empty(),
                children: ofArray([createElement(Col, {
                    classes: singleton_1(MarginRight.M),
                    children: singleton_1(MuiHelpers_createElement(Button, [["variant", "contained"], ["children", "Uploaden"], ["onClick", (_arg_18) => {
                        setShowUploadAssignmentConfirmation(true);
                    }]])),
                }), createElement(Col, {
                    classes: singleton_1(center),
                    children: toList(delay(() => {
                        const matchValue_6 = dossierDetail.Dossier.AssignmentConfirmationPhysicallySigned;
                        if (matchValue_6 == null) {
                            const matchValue_7 = dossierDetail.Dossier.AssigmentConfirmation;
                            if (matchValue_7 == null) {
                                return singleton_2(null);
                            }
                            else {
                                const assignmentConfirmation = matchValue_7;
                                return singleton_2(createElement(DownloadLink, {
                                    text: "Verzonden (niet getekend)",
                                    onDownload: () => {
                                        download(assignmentConfirmation);
                                    },
                                }));
                            }
                        }
                        else {
                            const assignmentDocumentSigned = matchValue_6;
                            return singleton_2(createElement(DownloadLink, {
                                text: "Verzonden (digitaal getekend)",
                                onDownload: () => {
                                    download(assignmentDocumentSigned);
                                },
                            }));
                        }
                    })),
                })]),
            }), MuiHelpers_createElement(Modal, [["open", patternInput_4[0]], ["onClose", (_arg_20, v_2) => {
                setShowOverwriteWarningAssignmentConfirmation(false);
            }], ["onClick", (e_8) => {
                e_8.stopPropagation();
            }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.L, PaddingBottom.L, PaddingLeft.L, PaddingRight.L])], (elems_10 = [createElement(Col, {
                classes: empty(),
                children: ofArray([createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_9 = [warningText("Opgepast: Het opdracht formulier is reeds getekend!"), warningText("Sure to overwrite?")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]))), createElement(Row, {
                    classes: ofArray([Gap.M, MarginTop.M]),
                    children: ofArray([createElement(Col, {
                        classes: empty(),
                        children: singleton_1(MuiHelpers_createElement(Button, [["color", "secondary"], ["variant", "contained"], ["disabled", disableAssignmentConfirmationUpload.State], ["onClick", (_arg_21) => {
                            iterate((data_2) => {
                                const matchValue_8 = data_2.File;
                                if (matchValue_8 != null) {
                                    const file_8 = matchValue_8;
                                    disableAssignmentConfirmationUpload.On();
                                    uploadFileAssignmentConfirmation(file_8, void 0, file_8.name, curry(3, api.UploadPhysicallySigned_AssigmentConfirmation), () => {
                                        setShowOverwriteWarningAssignmentConfirmation(false);
                                        setShowUploadAssignmentConfirmation(false);
                                        disableAssignmentConfirmationUpload.Off();
                                    });
                                }
                                else {
                                    toConsole(printf("no file selected"));
                                }
                            }, toArray(assignmentDocUploadform.Validate()));
                        }], ["children", "Bevestigen"]])),
                    }), createElement(Col, {
                        classes: empty(),
                        children: singleton_1(MuiHelpers_createElement(Button, [["disabled", disableAssignmentConfirmationUpload.State], ["color", "primary"], ["variant", "contained"], ["onClick", (_arg_22) => {
                            setShowOverwriteWarningAssignmentConfirmation(false);
                        }], ["children", "Annuleren"]])),
                    })]),
                })]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))]]), MuiHelpers_createElement(Modal, [["open", patternInput_1[0]], ["onClose", (_arg_24, v_3) => {
                setShowUploadAssignmentConfirmation(false);
            }], ["onClick", (e_9) => {
                e_9.stopPropagation();
            }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.M, PaddingBottom.L, PaddingLeft.L, PaddingRight.L])], (elems_12 = [createElement(Col, {
                classes: empty(),
                children: toList(delay(() => {
                    let elems_11;
                    return append(singleton_2(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_11 = [createElement("h2", {
                        children: ["Opdrachtformulier"],
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])))), delay(() => {
                        let matchValue_9, signed;
                        return append((matchValue_9 = dossierDetail.Dossier.AssignmentConfirmationPhysicallySigned, (matchValue_9 == null) ? singleton_2(null) : ((signed = matchValue_9, singleton_2(warningText("Opgepast: Het opdracht formulier is reeds getekend!"))))), delay(() => append(singleton_2(createElement(Row, {
                            classes: singleton_1(MarginTop.M),
                            children: singleton_1(FileField(assignmentDocfile, "Bestand selecteren", "", true)),
                        })), delay(() => singleton_2(createElement(Row, {
                            classes: singleton_1(MarginTop.L),
                            children: singleton_1(MuiHelpers_createElement(Button, [["disabled", disableAssignmentConfirmationUpload.State], ["color", "secondary"], ["variant", "contained"], ["onClick", (_arg_25) => {
                                iterate((data_3) => {
                                    const matchValue_10 = data_3.File;
                                    if (matchValue_10 != null) {
                                        const file_9 = matchValue_10;
                                        const matchValue_11 = dossierDetail.Dossier.AssignmentConfirmationPhysicallySigned;
                                        if (matchValue_11 == null) {
                                            disableAssignmentConfirmationUpload.On();
                                            uploadFileAssignmentConfirmation(file_9, void 0, file_9.name, curry(3, api.UploadPhysicallySigned_AssigmentConfirmation), () => {
                                                setShowUploadAssignmentConfirmation(false);
                                                disableAssignmentConfirmationUpload.Off();
                                            });
                                        }
                                        else {
                                            const signed_1 = matchValue_11;
                                            setShowOverwriteWarningAssignmentConfirmation(true);
                                        }
                                    }
                                    else {
                                        toConsole(printf("no file selected"));
                                    }
                                }, toArray(assignmentDocUploadform.Validate()));
                            }], ["children", "Opdrachtformulier opladen"]])),
                        }))))));
                    }));
                })),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])])))]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])))), createElement("div", createObj(singleton_1((elems_19 = [createElement("h2", {
                children: ["Labo resultaten opladen"],
            }), createElement(Row, {
                classes: empty(),
                children: ofArray([createElement(Col, {
                    classes: singleton_1(MarginRight.M),
                    children: singleton_1(MuiHelpers_createElement(Button, [["variant", "contained"], ["children", "Uploaden"], ["onClick", (_arg_26) => {
                        setShowUploadLabCertificate(true);
                    }]])),
                }), createElement(Col, {
                    classes: singleton_1(center),
                    children: toList(delay(() => {
                        const matchValue_12 = dossierDetail.Dossier.Lab;
                        if (matchValue_12 == null) {
                            return singleton_2("-");
                        }
                        else {
                            const file_10 = matchValue_12;
                            return singleton_2(createElement(DownloadLink, {
                                text: "Download",
                                onDownload: () => {
                                    download(file_10);
                                },
                            }));
                        }
                    })),
                }), MuiHelpers_createElement(Modal, [["open", patternInput_5[0]], ["onClose", (_arg_28, v_4) => {
                    setShowOverwriteWarningLab(false);
                }], ["onClick", (e_10) => {
                    e_10.stopPropagation();
                }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.L, PaddingBottom.L, PaddingLeft.L, PaddingRight.L])], (elems_15 = [createElement(Col, {
                    classes: empty(),
                    children: ofArray([createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_14 = [warningText("Opgepast: Er is reeds een labo resultaat aanwezig!"), warningText("Sure to overwrite?")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))), createElement(Row, {
                        classes: ofArray([Gap.M, MarginTop.M]),
                        children: ofArray([createElement(Col, {
                            classes: empty(),
                            children: singleton_1(MuiHelpers_createElement(Button, [["disabled", disableLabUpload.State], ["color", "secondary"], ["variant", "contained"], ["onClick", (_arg_29) => {
                                iterate((data_4) => {
                                    const matchValue_13 = data_4.File;
                                    if (matchValue_13 != null) {
                                        const file_11 = matchValue_13;
                                        disableLabUpload.On();
                                        startImmediate(uploadFile(file_11, curry(3, api.UploadLab), () => {
                                            setShowOverwriteWarningLab(false);
                                            setShowUploadLabCertificate(false);
                                            disableLabUpload.Off();
                                        }));
                                    }
                                    else {
                                        toConsole(printf("no file selected"));
                                    }
                                }, toArray(labUploadform.Validate()));
                            }], ["children", "Bevestigen"]])),
                        }), createElement(Col, {
                            classes: empty(),
                            children: singleton_1(MuiHelpers_createElement(Button, [["disabled", disableLabUpload.State], ["color", "primary"], ["variant", "contained"], ["onClick", (_arg_30) => {
                                toConsole(printf("cancel"));
                                setShowOverwriteWarningLab(false);
                            }], ["children", "Annuleren"]])),
                        })]),
                    })]),
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])])))]]), MuiHelpers_createElement(Modal, [["open", patternInput_2[0]], ["onClose", (_arg_32, v_5) => {
                    setShowUploadLabCertificate(false);
                }], ["onClick", (e_11) => {
                    e_11.stopPropagation();
                }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.M, PaddingBottom.L, PaddingLeft.L, PaddingRight.L])], (elems_18 = [createElement(Col, {
                    classes: empty(),
                    children: toList(delay(() => {
                        let elems_16;
                        return append(singleton_2(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_16 = [createElement("h2", {
                            children: ["Labo resultaten opladen"],
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))), delay(() => {
                            let elems_17;
                            return append((dossierDetail.Dossier.Lab == null) ? singleton_2(null) : singleton_2(createElement("div", createObj(ofArray([["className", join(" ", [TextColor.Danger, TextSize.M, Boldness.b600, MarginBottom.S])], (elems_17 = [warningText("Opgepast: Er is reeds een labo resultaat aanwezig!")], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])])))), delay(() => append(singleton_2(createElement(Row, {
                                classes: empty(),
                                children: singleton_1(FileField(labfile, "Bestand selecteren", "", true)),
                            })), delay(() => singleton_2(createElement(Row, {
                                classes: singleton_1(MarginTop.L),
                                children: singleton_1(MuiHelpers_createElement(Button, [["disabled", disableLabUpload.State], ["color", "secondary"], ["variant", "contained"], ["onClick", (_arg_33) => {
                                    iterate((data_5) => {
                                        const matchValue_15 = data_5.File;
                                        if (matchValue_15 != null) {
                                            const file_12 = matchValue_15;
                                            const matchValue_16 = dossierDetail.Dossier.Lab;
                                            if (matchValue_16 == null) {
                                                disableLabUpload.On();
                                                startImmediate(uploadFile(file_12, curry(3, api.UploadLab), () => {
                                                    setShowOverwriteWarningLab(false);
                                                    setShowUploadLabCertificate(false);
                                                    disableLabUpload.Off();
                                                }));
                                            }
                                            else {
                                                const signed_2 = matchValue_16;
                                                setShowOverwriteWarningLab(true);
                                            }
                                        }
                                        else {
                                            toConsole(printf("no file selected"));
                                        }
                                    }, toArray(labUploadform.Validate()));
                                }], ["children", "Labo resultaten opladen"]])),
                            }))))));
                        }));
                    })),
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])))]])]),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])))), createElement(UploadMiscFiles, {
                prefix: `dossier/${dossierCode}/`,
                getFiles: api.GetDossierFiles(new DossierCode(dossierCode)),
                getFile: api.GetDocument,
                uploadFile: partialApply(2, api.UploadDossierFile, [new DossierCode(dossierCode)]),
                removeFile: api.RemoveDocument,
            })]),
        });
    }
    else {
        return null;
    }
}

export function GenerateAssignmentDocumentModal(generateAssignmentDocumentModalInputProps) {
    let elems_1;
    const setShowModal = generateAssignmentDocumentModalInputProps.setShowModal;
    const showModal = generateAssignmentDocumentModalInputProps.showModal;
    const dossierDetails = generateAssignmentDocumentModalInputProps.dossierDetails;
    const api = generateAssignmentDocumentModalInputProps.api;
    const snackbar = useSnackbar();
    const buttonsDisabled = useToggle(false);
    return MuiHelpers_createElement(Modal, [["open", showModal], ["onClose", (_arg_3, v) => {
        setShowModal(false);
    }], ["onClick", (e_1) => {
        e_1.stopPropagation();
    }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.M, PaddingBottom.L, PaddingLeft.L, PaddingRight.L])], ["onClick", (e_2) => {
        e_2.stopPropagation();
    }], (elems_1 = toList(delay(() => {
        let elems;
        return append(singleton_2(createElement("div", createObj(ofArray([["className", join(" ", [MarginBottom.M])], (elems = [createElement("h3", {
            children: ["Ben je zeker dat je het opdrachtformulier opnieuw wil versturen?"],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let matchValue, signed;
            return append((matchValue = dossierDetails.Dossier.AssignmentConfirmationPhysicallySigned, (matchValue == null) ? singleton_2(null) : ((signed = matchValue, singleton_2(createElement(Col, {
                classes: singleton_1(TextColor.Danger),
                children: singleton_1(createElement("h3", {
                    children: ["Opgepast: Het opdracht formulier is reeds getekend!"],
                })),
            }))))), delay(() => singleton_2(createElement(Row, {
                classes: singleton_1(MarginTop.L),
                children: ofArray([MuiHelpers_createElement(Button, [["onClick", (e_3) => {
                    startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
                        buttonsDisabled.On();
                        return singleton.Bind(api.GenerateAssignmentConfirmation(dossierDetails.Dossier.Code), (_arg) => {
                            const result = _arg;
                            if (result.tag === 0) {
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Assignment confirmation generated.", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossiers)), 1, 1);
                                return singleton.Zero();
                            }
                            else {
                                const err = result.fields[0];
                                buttonsDisabled.Off();
                                console.error(err);
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                return singleton.Zero();
                            }
                        });
                    }), (_arg_1) => {
                        const e = _arg_1;
                        console.error(e);
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        return singleton.Zero();
                    })));
                }], ["className", join(" ", [])], ["disabled", buttonsDisabled.State], ["variant", "contained"], ["color", "secondary"], ["children", "Ja, ik ben zeker"]]), MuiHelpers_createElement(Button, [["onClick", (e_4) => {
                    setShowModal(false);
                }], ["disabled", buttonsDisabled.State], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "primary"], ["children", "Annuleren"]])]),
            }))));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]]);
}

export function ChangeDossierStatusModal(changeDossierStatusModalInputProps) {
    let elems_2, elems;
    const setShowModal = changeDossierStatusModalInputProps.setShowModal;
    const showModal = changeDossierStatusModalInputProps.showModal;
    const dossierDetails = changeDossierStatusModalInputProps.dossierDetails;
    const api = changeDossierStatusModalInputProps.api;
    const snackbar = useSnackbar();
    const buttonsDisabled = useToggle(false);
    const userName = useFormField("", NonEmptyString_get_Create());
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const adiId = patternInput[0];
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetAdis(new PagingQuery(0, 999), void 0), []);
    return MuiHelpers_createElement(Modal, [["open", showModal], ["onClose", (_arg_2, v) => {
        setShowModal(false);
    }], ["onClick", (e_1) => {
        e_1.stopPropagation();
    }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.M, PaddingBottom.L, PaddingLeft.L, PaddingRight.L])], ["onClick", (e_2) => {
        e_2.stopPropagation();
    }], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", [MarginBottom.M])], (elems = [createElement("h3", {
        children: ["Bent u zeker dat u dit dossier in behandeling wil nemen?"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), TextField(userName, "User Naam", "", true), createElement(Row, {
        classes: singleton_1(MarginTop.M),
        children: singleton_1(createElement(Col, {
            classes: singleton_1(fullWidth),
            children: ofArray([createElement("h3", {
                children: ["Adi"],
            }), createElement(DisplayDeferred, {
                data: data,
                view: (adis) => {
                    let elems_1;
                    const options = toArray_1(map((a) => (new AdiOption((NonEmptyString___UnsafeUnwrap_Z73AD07C(a.FirstName) + " ") + NonEmptyString___UnsafeUnwrap_Z73AD07C(a.LastName), a.Id.Value)), adis.Data));
                    return MuiHelpers_createElement(FormControl, [(elems_1 = [MuiHelpers_createElement(Autocomplete, [["id", "adi-combo-box-label"], ["options", Array.from(options)], ["value", adiId], ["getOptionSelected", (delegateArg, delegateArg_1) => (delegateArg.Id === delegateArg_1.Id)], ["onChange", (_arg_3, v_1, _arg_1_1) => {
                        const adi = v_1;
                        if (!(adi == null)) {
                            patternInput[1](new AdiId(adi.Id));
                        }
                    }], ["renderInput", (params$0027) => MuiHelpers_createElement(TextField_1, toList(delay(() => append(singleton_2(["variant", "outlined"]), delay(() => append(singleton_2(["label", "Adi"]), delay(() => (Object.entries(params$0027)))))))))], ["renderOption", (delegateArg_2, delegateArg_1_1) => {
                        const xs_2 = [createElement("span", {
                            children: [delegateArg_2.Label],
                        })];
                        return react.createElement(react.Fragment, {}, ...xs_2);
                    }], ["getOptionLabel", (adi_1) => adi_1.Label]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]);
                },
            })]),
        })),
    }), createElement(Row, {
        classes: singleton_1(MarginTop.L),
        children: ofArray([MuiHelpers_createElement(Button, [["onClick", (e_3) => {
            try {
                iterate((data_1) => {
                    startImmediate(singleton.Delay(() => {
                        buttonsDisabled.On();
                        return singleton.Bind(api.ChangeDossierStatus(dossierDetails.Dossier.Code, adiId, data_1.AdiName), (_arg) => {
                            const result = _arg;
                            if (result.tag === 0) {
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Dossier status changed.", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossiers)), 1, 1);
                                return singleton.Zero();
                            }
                            else {
                                const err = result.fields[0];
                                buttonsDisabled.Off();
                                console.error(err);
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                return singleton.Zero();
                            }
                        });
                    }));
                }, toArray(useFormValidator(userName.Validator, (r, x) => ({
                    AdiName: x,
                }), useForm()).Validate()));
            }
            catch (e) {
                console.error(e);
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            }
        }], ["className", join(" ", [])], ["disabled", buttonsDisabled.State], ["variant", "contained"], ["color", "secondary"], ["children", "Ja"]]), MuiHelpers_createElement(Button, [["onClick", (e_4) => {
            setShowModal(false);
        }], ["disabled", buttonsDisabled.State], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "primary"], ["children", "Annuleren"]])]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))]]);
}

export function CancellationModal(cancellationModalInputProps) {
    let elems_1;
    const onCancelDossier = cancellationModalInputProps.onCancelDossier;
    const setShowCancellationModal = cancellationModalInputProps.setShowCancellationModal;
    const showCancellationModal = cancellationModalInputProps.showCancellationModal;
    const dossierId = cancellationModalInputProps.dossierId;
    return MuiHelpers_createElement(Modal, [["open", showCancellationModal], ["onClose", (_arg_1, v) => {
        setShowCancellationModal(false);
    }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.L, PaddingBottom.L, PaddingLeft.Xxl, PaddingRight.Xxl])], (elems_1 = [createElement("div", {
        className: join(" ", [MarginBottom.M]),
        children: Interop_reactApi.Children.toArray(["Dit dossier annuleren?"]),
    }), MuiHelpers_createElement(Button, [["onClick", (_arg_2) => {
        onCancelDossier(dossierId);
    }], ["className", join(" ", [])], ["variant", "contained"], ["color", "secondary"], ["children", "Ja"]]), MuiHelpers_createElement(Button, [["onClick", (_arg_3) => {
        setShowCancellationModal(false);
    }], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "primary"], ["children", "Nee"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]]);
}

export function RenderDossierDetail(renderDossierDetailInputProps) {
    let elems, props, elems_1, props_6, props_8, props_10, props_12;
    const auth0User = renderDossierDetailInputProps.auth0User;
    const dossierDetail = renderDossierDetailInputProps.dossierDetail;
    const dossierCode = renderDossierDetailInputProps.dossierCode;
    const api = renderDossierDetailInputProps.api;
    const currentPage = renderDossierDetailInputProps.currentPage;
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setShowCancellationModal = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const busyGeneratingAssignmentConfirmation = useToggle(false);
    const menuState = useToggle(false);
    const anchorEl = useReact_useElementRef();
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setShowModal = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setShowChangeStatusModal = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(empty());
    const files = patternInput_4[0];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(0);
    const patternInput_7 = useFeliz_React__React_useState_Static_1505(25);
    const patternInput_8 = useFeliz_React__React_useState_Static_1505(empty());
    const patternInput_9 = useFeliz_React__React_useState_Static_1505(new CommentType(0));
    const commentType = patternInput_9[0];
    const patternInput_10 = useFeliz_React__React_useState_Static_1505(false);
    const setIsPrintDisabled = patternInput_10[1];
    const getComments = () => singleton.Delay(() => singleton.Bind(api.GetDossierComments(dossierDetail.Dossier.Code, new PagingQuery(patternInput_6[0], patternInput_7[0]), commentType), (_arg_4) => {
        const comments_1 = _arg_4;
        if (comments_1.tag === 0) {
            patternInput_8[1](comments_1.fields[0].Data);
            return singleton.Zero();
        }
        else {
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            return singleton.Zero();
        }
    }));
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(api.GetAppointmentDetailsByDossierId(dossierDetail.Dossier.Code), (_arg_2) => {
            const result_1 = _arg_2;
            if (result_1.tag === 1) {
                const err_1 = result_1.fields[0];
                console.error(err_1);
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_1}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                return singleton.Zero();
            }
            else if (result_1.fields[0] == null) {
                return singleton.Zero();
            }
            else {
                patternInput_1[1](defaultArg(map_1((ac) => ac.AreSampelsUploaded, result_1.fields[0].AppointmentConfirmation), false));
                return singleton.Zero();
            }
        })), (_arg_3) => {
            const ex = _arg_3;
            console.error(ex);
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${ex.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            return singleton.Zero();
        })));
    }, []);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(getComments());
    }, [commentType]);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(api.GetQuoteDocumentsByDossierCode(new DossierCode(dossierCode)), (_arg_5) => {
            const quoteDocsList = _arg_5;
            if (quoteDocsList.tag === 0) {
                patternInput_4[1](quoteDocsList.fields[0]);
                return singleton.Zero();
            }
            else {
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden (getQuoteFiles). Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                return singleton.Zero();
            }
        })));
    }, []);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(api.GetQuoteDetailsByDossierCode(new DossierCode(dossierCode)), (_arg_6) => {
            const quteDetails = _arg_6;
            if (quteDetails.tag === 0) {
                patternInput_5[1](quteDetails.fields[0]);
                return singleton.Zero();
            }
            else {
                return singleton.Zero();
            }
        })));
    }, []);
    const generateAssignmentConfirmationButton = createElement(Col, {
        classes: empty(),
        children: ofArray([MuiHelpers_createElement(IconButton, [["onClick", (e_3) => {
            e_3.stopPropagation();
            menuState.On();
        }], ["ref", anchorEl], (elems = [(props = {}, react.createElement(MoreVert, props))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]), ["size", "small"]]), MuiHelpers_createElement(Menu, [["open", menuState.State], ["onBackdropClick", (e_4) => {
            menuState.Off();
        }], ["onClose", (_arg_10, v) => {
            menuState.Off();
        }], ["anchorEl", () => anchorEl.current], ["keepMounted", false], ["children", createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = toList(delay(() => append((dossierDetail.Dossier.TypeOfDossier.tag === 1) ? singleton_2(null) : singleton_2(MuiHelpers_createElement(MenuItem, [["children", "Opdrachtformulier opnieuw versturen"], ["value", "Resend"], ["disabled", busyGeneratingAssignmentConfirmation.State], ["onClick", (_arg_11) => {
            menuState.Off();
            setShowModal(true);
        }]])), delay(() => ((dossierDetail.Dossier.Status.tag === 0) ? singleton_2(MuiHelpers_createElement(MenuItem, [["children", "In behandeling nemen"], ["value", "ChangeStatus"], ["disabled", busyGeneratingAssignmentConfirmation.State], ["onClick", (_arg_12) => {
            menuState.Off();
            setShowChangeStatusModal(true);
        }]])) : singleton_2(null)))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]]), createElement(GenerateAssignmentDocumentModal, {
            api: api,
            dossierDetails: dossierDetail,
            showModal: patternInput_2[0],
            setShowModal: setShowModal,
        }), createElement(ChangeDossierStatusModal, {
            api: api,
            dossierDetails: dossierDetail,
            showModal: patternInput_3[0],
            setShowModal: setShowChangeStatusModal,
        })]),
    });
    let cancelDossierButton;
    let isCancelDisabled;
    const matchValue_2 = dossierDetail.Dossier.Status;
    switch (matchValue_2.tag) {
        case 1:
        case 5:
        case 6:
        case 7: {
            isCancelDisabled = true;
            break;
        }
        default: {
            isCancelDisabled = false;
        }
    }
    cancelDossierButton = MuiHelpers_createElement(Button, [["onClick", (_arg_13) => {
        setShowCancellationModal(true);
    }], ["className", join(" ", [MarginLeft.Xs, cancelDossierButton_1])], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props_6 = {}, react.createElement(Close, props_6))], ["children", "Dossier Annuleren"], ["disabled", isCancelDisabled]]);
    const editDossierButton = MuiHelpers_createElement(Button, [["onClick", (_arg_14) => {
        RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossierEdit, dossierCode)), 1, 1);
    }], ["className", join(" ", [MarginRight.S])], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props_8 = {}, react.createElement(Edit, props_8))], ["children", "Dossier bewerken"]]);
    const planAppointmentButton = MuiHelpers_createElement(Button, [["onClick", (_arg_15) => {
        RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossierAppointmentCreate, dossierDetail.Dossier.Code.Value)), 1, 1);
    }], ["className", join(" ", [MarginRight.S])], ["disabled", !DossierStatus_isAppointmentAllowed_Z7B6F6316(dossierDetail.Dossier.Status)], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props_10 = {}, react.createElement(CalendarToday, props_10))], ["children", "Afspraak inplannen"]]);
    const printDossierButton = MuiHelpers_createElement(Button, [["onClick", (_arg_18) => {
        const dossierCode_1 = new DossierCode(dossierCode);
        startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
            setIsPrintDisabled(true);
            return singleton.Bind(api.GenerateDossierPdf(dossierCode_1), (_arg_16) => {
                const result_3 = _arg_16;
                if (result_3.tag === 0) {
                    const ba = result_3.fields[0];
                    setIsPrintDisabled(false);
                    if (ba == null) {
                        return singleton.Zero();
                    }
                    else {
                        openPdfInNewTab(ba, `Dossier_${dossierCode_1.Value}.pdf`);
                        return singleton.Zero();
                    }
                }
                else {
                    const err_3 = result_3.fields[0];
                    setIsPrintDisabled(false);
                    console.error(err_3);
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${err_3}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    return singleton.Zero();
                }
            });
        }), (_arg_17) => {
            const e_6 = _arg_17;
            setIsPrintDisabled(false);
            console.error(e_6);
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${e_6.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            return singleton.Zero();
        })));
    }], ["className", join(" ", [MarginRight.S])], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props_12 = {}, react.createElement(Print, props_12))], ["children", "Print"], ["disabled", patternInput_10[0]]]);
    let signatureLink;
    if (Auth0UserClaims__HasPrivilege_6B2F319B(auth0User, new Auth0UserPrivileges(0))) {
        const matchValue_3 = dossierDetail.Dossier.AssignmentConfirmationPhysicallySigned;
        if (matchValue_3 == null) {
            const matchValue_4 = dossierDetail.Dossier.AssignmentConfirmationDigitalSignature;
            if (matchValue_4 == null) {
                signatureLink = null;
            }
            else {
                const url_1 = RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(digitalSignaturePage, matchValue_4.SignatureCode.Value)), 1);
                signatureLink = createElement("a", {
                    target: "_blank",
                    href: url_1,
                    children: `${"https://backoffice.asbestconsulting.be/"}${url_1}`,
                });
            }
        }
        else {
            const filePath_1 = matchValue_3;
            signatureLink = null;
        }
    }
    else {
        signatureLink = null;
    }
    const buttons = Auth0UserClaims__HasPrivilege_6B2F319B(auth0User, new Auth0UserPrivileges(0)) ? createElement(Row, {
        classes: singleton_1(Gap.Xs),
        children: toList(delay(() => append(singleton_2(planAppointmentButton), delay(() => append((dossierDetail.Dossier.Status.tag === 1) ? singleton_2(null) : singleton_2(editDossierButton), delay(() => append(singleton_2(printDossierButton), delay(() => append(singleton_2(cancelDossierButton), delay(() => singleton_2(generateAssignmentConfirmationButton))))))))))),
    }) : null;
    return createElement(Page, {
        currentPage: currentPage,
        title: "",
        actions: [buttons],
        children: [createElement(DisplayDossierInfo, {
            dossierDetails: dossierDetail,
            auth0User: auth0User,
            sampledUploaded: patternInput_1[0],
            downloadFile: (filePath) => {
                startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
                    startImmediate(downloadFile(api, filePath));
                    return singleton.Zero();
                }), (_arg_7) => {
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${_arg_7.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    return singleton.Zero();
                })));
            },
            comments: patternInput_8[0],
            commentType: commentType,
            setCommentType: patternInput_9[1],
            customerUrl: (customerId) => Page$2__toUrl_2B594(customerDetail, customerId.Value),
            partnerUrl: (partnerId) => Page$2__toUrl_2B594(partnersDetail, partnerId.Value),
            appartmentUrl: (appointmentId) => Page$2__toUrl_2B594(appointmentDetail, appointmentId.Value),
            isPartner: false,
            onSaveComment: (comment) => ((onSaveComment) => {
                startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(api.CreateDossierComment(dossierDetail.Dossier.Code, comment), (_arg_8) => {
                    const result_2 = _arg_8;
                    if (result_2.tag === 0) {
                        onSaveComment();
                        startImmediate(getComments());
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Commet saved!", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                        return singleton.Zero();
                    }
                    else {
                        onSaveComment();
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${result_2.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        return singleton.Zero();
                    }
                })), (_arg_9) => {
                    const e_2 = _arg_9;
                    console.error(e_2);
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e_2.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    return singleton.Zero();
                })));
            }),
            signatureink: signatureLink,
            quoteDetails: patternInput_5[0],
        }), createElement(UploadFiles, {
            api: api,
            dossierCode: dossierCode,
            auth0User: auth0User,
            dossierDetail: dossierDetail,
        }), createElement(Col, {
            classes: singleton_1(MarginTop.L),
            children: ofArray([createElement(DocumentsList, {
                quoteDocuments: files,
                getFile: api.GetDocument,
                deleteFile: void 0,
            }), createElement(ImageList, {
                quoteDocuments: files,
                getFile: api.GetDocument,
                getImage: (getDoc) => ((filePath_2) => getImage(getDoc, filePath_2)),
                deleteFile: void 0,
            })]),
        }), createElement(DossierEventHistory, {
            api: api,
            dossierCode: new DossierCode(dossierCode),
        }), createElement(CancellationModal, {
            dossierId: dossierDetail.Dossier.Id,
            showCancellationModal: patternInput[0],
            setShowCancellationModal: setShowCancellationModal,
            onCancelDossier: (dossierId) => {
                startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(api.CancelDossier(dossierId), (_arg) => {
                    const result = _arg;
                    if (result.tag === 0) {
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Dossier geannuleerd", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                        RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossiers)), 1, 1);
                        return singleton.Zero();
                    }
                    else {
                        const err = result.fields[0];
                        console.error(err);
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        return singleton.Zero();
                    }
                })), (_arg_1) => {
                    const e = _arg_1;
                    console.error(e);
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    return singleton.Zero();
                })));
            },
        })],
    });
}

export function DossierDetail(dossierDetailInputProps) {
    const auth0User = dossierDetailInputProps.auth0User;
    const dossierCode = dossierDetailInputProps.dossierCode;
    const api = dossierDetailInputProps.api;
    const currentPage = dossierDetailInputProps.currentPage;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetDossierDetails(new DossierCode(dossierCode)), []);
    return createElement(DisplayDeferred, {
        data: data,
        view: (dossierDetails) => {
            if (dossierDetails == null) {
                return createElement(Page, {
                    currentPage: currentPage,
                    title: "",
                    actions: [],
                    children: [createElement(ErrorText, {
                        text: `Geen Dossiers gevonden met code ${dossierCode}`,
                    })],
                });
            }
            else {
                const dossierDetail = dossierDetails;
                return createElement(RenderDossierDetail, {
                    currentPage: currentPage,
                    api: api,
                    dossierCode: dossierCode,
                    dossierDetail: dossierDetail,
                    auth0User: auth0User,
                });
            }
        },
    });
}

