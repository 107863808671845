import { Record } from "../client/src/fable_modules/fable-library.3.7.17/Types.js";
import { list_type, record_type, int32_type } from "../client/src/fable_modules/fable-library.3.7.17/Reflection.js";

export class PagingQuery extends Record {
    constructor(PageNumber, PageSize) {
        super();
        this.PageNumber = (PageNumber | 0);
        this.PageSize = (PageSize | 0);
    }
}

export function PagingQuery$reflection() {
    return record_type("Paging.PagingQuery", [], PagingQuery, () => [["PageNumber", int32_type], ["PageSize", int32_type]]);
}

export class PagingResult$1 extends Record {
    constructor(Data, TotalPages) {
        super();
        this.Data = Data;
        this.TotalPages = (TotalPages | 0);
    }
}

export function PagingResult$1$reflection(gen0) {
    return record_type("Paging.PagingResult`1", [gen0], PagingResult$1, () => [["Data", list_type(gen0)], ["TotalPages", int32_type]]);
}

export function map(mapper, result) {
    return new PagingResult$1(mapper(result.Data), result.TotalPages);
}

