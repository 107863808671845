import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { createElement } from "react";
import * as react from "react";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { ofArray, empty, map, singleton, isEmpty } from "../fable_modules/fable-library.3.7.17/List.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import { Dossier_Events_event, GeneralFormats_dateTimeStamp } from "../../../shared/Format.fs.js";
import { fromDateTimeOffset } from "../fable_modules/fable-library.3.7.17/Date.js";
import { rgba } from "../fable_modules/Feliz.1.68.0/Colors.fs.js";
import { intersperse } from "../fable_modules/FSharpPlus.1.4.0/Extensions/List.fs.js";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

export function DossierEventHistory(dossierEventHistoryInputProps) {
    let children_4, children_3;
    const dossierCode = dossierEventHistoryInputProps.dossierCode;
    const events = useFeliz_React__React_useDeferred_Static_2344FC52(dossierEventHistoryInputProps.api.GetDossierEventHistory(dossierCode, new PagingQuery(useFeliz_React__React_useState_Static_1505(0)[0], useFeliz_React__React_useState_Static_1505(25)[0])), []);
    const children_5 = ofArray([createElement("h2", {
        children: ["Evenementen"],
    }), (children_4 = singleton((children_3 = singleton(createElement(DisplayDeferred, {
        data: events,
        view: (events_1) => {
            let source;
            if (isEmpty(events_1.Data)) {
                const children = singleton(MuiHelpers_createElement(ListItemText, [["secondary", "Geen evenementen"], ["primary", ""]]));
                return MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
            }
            else {
                const xs_3 = (source = map((event) => {
                    let value_6, elements_1;
                    const eventDetails = createElement(Col, {
                        classes: empty(),
                        children: singleton((value_6 = (`${GeneralFormats_dateTimeStamp(fromDateTimeOffset(event.CreatedOn, 0))} ${event.Name.Value}`), createElement("div", {
                            children: [value_6],
                        }))),
                    });
                    const xs_2 = [MuiHelpers_createElement(ListItem, [(elements_1 = [createElement(Row, {
                        classes: empty(),
                        children: singleton(createElement(Col, {
                            classes: empty(),
                            children: ofArray([createElement("div", {
                                children: Dossier_Events_event(event.Event),
                            }), createElement("div", {
                                style: {
                                    color: rgba(0, 0, 0, 0.54),
                                },
                                children: Interop_reactApi.Children.toArray([eventDetails]),
                            })]),
                        })),
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])])];
                    return react.createElement(react.Fragment, {}, ...xs_2);
                }, events_1.Data), intersperse(MuiHelpers_createElement(Divider, [["component", "li"]]), source));
                return react.createElement(react.Fragment, {}, ...xs_3);
            }
        },
    })), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_5)),
    });
}

