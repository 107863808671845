import { join, printf, toText } from "../client/src/fable_modules/fable-library.3.7.17/String.js";
import { VatNumber___UnsafeUnwrap_18C04ACB, PhoneNumber___UnsafeUnwrap_23E45174, Email___UnsafeUnwrap_Z27D11339, NonEmptyString___UnsafeUnwrap_Z73AD07C, ContactOwnerModule_string, ContactOwner, CustomerCompanyData, CustomerPersonData, DossierOwnerPerson, DossierOwnerCompany, VatNumber_get_Create, PhoneNumber_get_Create, Email_get_Create, NonEmptyString_get_Create, CustomerCode, Email as Email_1, AssignmentDocumentModule_string, AssignmentDocument, HomeVisitContact, HomeVisitContactModule_string } from "../shared/Domain.fs.js";
import { empty as empty_1, singleton, append, delay, toList } from "../client/src/fable_modules/fable-library.3.7.17/Seq.js";
import { createElement } from "react";
import * as react from "react";
import { MuiHelpers_createElement } from "../client/src/fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import RadioGroup from "@material-ui/core/RadioGroup";
import { EmailFieldDisabled, MultiEmailField, OptionField, TextFieldDisabled, TextField, OptionFieldDisabled } from "./DesignSystem.fs.js";
import { Interop_reactApi } from "../client/src/fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { Row, Col } from "../Components/UtilComponents.fs.js";
import { MarginBottom, Padding, modal, Boldness, TextSize, MarginTop, error, PaddingLeft, vCenter, MarginLeft, alignCenter, Gap } from "../Styles/Utils.fs.js";
import { empty, ofArray, singleton as singleton_1 } from "../client/src/fable_modules/fable-library.3.7.17/List.js";
import Badge from "@material-ui/core/Badge";
import { Record, Union } from "../client/src/fable_modules/fable-library.3.7.17/Types.js";
import { record_type, string_type, union_type } from "../client/src/fable_modules/fable-library.3.7.17/Reflection.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { Browser_Types_Event__Event_get_Checked } from "../client/src/fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../client/src/fable_modules/Feliz.1.68.0/React.fs.js";
import { createObj, equals } from "../client/src/fable_modules/fable-library.3.7.17/Util.js";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField_1 from "@material-ui/core/TextField";
import Add from "@material-ui/icons/Add";
import { CreateAddressFormSmallHeader, useAddressForm, addressFields } from "./AddressHook.fs.js";
import { useForm, useFormValidator, FormValidator$1_map, useFormField } from "../CustomHooks/ComposedForm.fs.js";
import { map, defaultArg, some } from "../client/src/fable_modules/fable-library.3.7.17/Option.js";
import { useToggle } from "../CustomHooks/CustomHooks.fs.js";
import { DossierCreatePageSource } from "./DossierUtils.fs.js";
import Checkbox from "@material-ui/core/Checkbox";

export function ChooseContact(chooseContactInputProps) {
    const homeVisitContactPhoneNo = chooseContactInputProps.homeVisitContactPhoneNo;
    const homeVisitContactName = chooseContactInputProps.homeVisitContactName;
    const isPartnerSelected = chooseContactInputProps.isPartnerSelected;
    const partnerName = chooseContactInputProps.partnerName;
    const contactOption = chooseContactInputProps.contactOption;
    const partner = toText(printf("%s / %s"))(partnerName)("Afspraak maken voor locatie");
    let pickupKey;
    const arg_3 = HomeVisitContactModule_string(new HomeVisitContact(4));
    pickupKey = toText(printf("%s / %s"))(partnerName)(arg_3);
    const xs = toList(delay(() => append(singleton(createElement("h2", {
        children: ["Wie kunnen we contacteren om een huisbezoek in te plannen?"],
    })), delay(() => {
        let elements;
        return append(singleton(MuiHelpers_createElement(RadioGroup, [(elements = [OptionFieldDisabled(contactOption, new HomeVisitContact(1), partner, "", true, !isPartnerSelected), OptionFieldDisabled(contactOption, new HomeVisitContact(4), pickupKey, "", true, !isPartnerSelected), OptionFieldDisabled(contactOption, new HomeVisitContact(0), HomeVisitContactModule_string(new HomeVisitContact(0)), "", true, false), OptionFieldDisabled(contactOption, new HomeVisitContact(2), HomeVisitContactModule_string(new HomeVisitContact(2)), "", true, false), OptionFieldDisabled(contactOption, new HomeVisitContact(3), HomeVisitContactModule_string(new HomeVisitContact(3)), "", true, false)], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])), delay(() => {
            const matchValue = contactOption.FieldValue;
            let pattern_matching_result;
            if (matchValue != null) {
                if (matchValue.tag === 3) {
                    pattern_matching_result = 0;
                }
                else if (matchValue.tag === 2) {
                    pattern_matching_result = 0;
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton(createElement(Col, {
                        classes: singleton_1(Gap.S),
                        children: ofArray([TextField(homeVisitContactName, "Naam", "", true), TextField(homeVisitContactPhoneNo, "Telefoon", "", true)]),
                    }));
                }
                case 1: {
                    return singleton(null);
                }
            }
        }));
    }))));
    return react.createElement(react.Fragment, {}, ...xs);
}

export function ChooseContactQuote(chooseContactQuoteInputProps) {
    const isOptional = chooseContactQuoteInputProps.isOptional;
    const homeVisitContactPhoneNo = chooseContactQuoteInputProps.homeVisitContactPhoneNo;
    const homeVisitContactName = chooseContactQuoteInputProps.homeVisitContactName;
    const isPartnerSelected = chooseContactQuoteInputProps.isPartnerSelected;
    const partnerName = chooseContactQuoteInputProps.partnerName;
    const contactOption = chooseContactQuoteInputProps.contactOption;
    const partner = toText(printf("%s / %s"))(partnerName)("Afspraak maken voor locatie");
    let pickupKey;
    const arg_3 = HomeVisitContactModule_string(new HomeVisitContact(4));
    pickupKey = toText(printf("%s / %s"))(partnerName)(arg_3);
    const xs = toList(delay(() => append(isOptional ? singleton(createElement(Row, {
        classes: empty(),
        children: ofArray([createElement(Col, {
            classes: empty(),
            children: singleton_1(createElement("h3", {
                children: ["Wie kunnen we contacteren om een huisbezoek in te plannen?"],
            })),
        }), createElement(Col, {
            classes: ofArray([alignCenter, MarginLeft.L]),
            children: singleton_1(MuiHelpers_createElement(Badge, [["className", join(" ", [MarginLeft.S, vCenter])], ["badgeContent", "Optioneel"], ["color", "secondary"], ["variant", "standard"]])),
        })]),
    })) : singleton(createElement(Row, {
        classes: empty(),
        children: singleton_1(createElement(Col, {
            classes: empty(),
            children: singleton_1(createElement("h3", {
                children: ["Wie kunnen we contacteren om een afspraak te maken?"],
            })),
        })),
    })), delay(() => {
        let elements;
        return append(singleton(MuiHelpers_createElement(RadioGroup, [(elements = [OptionFieldDisabled(contactOption, new HomeVisitContact(1), partner, "", true, !isPartnerSelected), OptionFieldDisabled(contactOption, new HomeVisitContact(4), pickupKey, "", true, !isPartnerSelected), OptionFieldDisabled(contactOption, new HomeVisitContact(0), HomeVisitContactModule_string(new HomeVisitContact(0)), "", true, false), OptionFieldDisabled(contactOption, new HomeVisitContact(2), HomeVisitContactModule_string(new HomeVisitContact(2)), "", true, false), OptionFieldDisabled(contactOption, new HomeVisitContact(3), HomeVisitContactModule_string(new HomeVisitContact(3)), "", true, false)], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])), delay(() => {
            const matchValue = contactOption.FieldValue;
            let pattern_matching_result;
            if (matchValue != null) {
                if (matchValue.tag === 3) {
                    pattern_matching_result = 0;
                }
                else if (matchValue.tag === 2) {
                    pattern_matching_result = 0;
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return singleton(createElement(Col, {
                        classes: singleton_1(Gap.S),
                        children: ofArray([TextField(homeVisitContactName, "Naam", "", true), TextField(homeVisitContactPhoneNo, "Telefoon", "", true)]),
                    }));
                }
                case 1: {
                    return singleton(null);
                }
            }
        }));
    }))));
    return react.createElement(react.Fragment, {}, ...xs);
}

export class Invoiced extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Partner", "Person", "Company"];
    }
}

export function Invoiced$reflection() {
    return union_type("Contacts.Invoiced", [], Invoiced, () => [[], [], []]);
}

export function InvoicedModule_string(invoiced) {
    switch (invoiced.tag) {
        case 1: {
            return "Natuurlijk persoon";
        }
        case 2: {
            return "Vennootschap";
        }
        default: {
            return "Partner";
        }
    }
}

export function ChooseAssignmentDocumentContact(chooseAssignmentDocumentContactInputProps) {
    let elements;
    const isPartnerSelected = chooseAssignmentDocumentContactInputProps.isPartnerSelected;
    const other = chooseAssignmentDocumentContactInputProps.other;
    const syndicEmail = chooseAssignmentDocumentContactInputProps.syndicEmail;
    const legalRepEmail = chooseAssignmentDocumentContactInputProps.legalRepEmail;
    const partnerName = chooseAssignmentDocumentContactInputProps.partnerName;
    const assignmentDocumentOption = chooseAssignmentDocumentContactInputProps.assignmentDocumentOption;
    let showEmail;
    const matchValue = assignmentDocumentOption.FieldValue;
    let pattern_matching_result;
    if (matchValue != null) {
        if (matchValue.tag === 2) {
            pattern_matching_result = 0;
        }
        else if (matchValue.tag === 3) {
            pattern_matching_result = 1;
        }
        else if (matchValue.tag === 4) {
            pattern_matching_result = 2;
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else {
        pattern_matching_result = 3;
    }
    switch (pattern_matching_result) {
        case 0: {
            showEmail = createElement(Col, {
                classes: singleton_1(PaddingLeft.L),
                children: singleton_1(TextFieldDisabled(legalRepEmail, "", "", true, false)),
            });
            break;
        }
        case 1: {
            showEmail = createElement(Col, {
                classes: singleton_1(PaddingLeft.L),
                children: singleton_1(TextFieldDisabled(syndicEmail, "", "", true, false)),
            });
            break;
        }
        case 2: {
            showEmail = createElement(Col, {
                classes: singleton_1(PaddingLeft.L),
                children: singleton_1(TextFieldDisabled(other, "", "", true, false)),
            });
            break;
        }
        case 3: {
            showEmail = null;
            break;
        }
    }
    const xs = [createElement("h2", {
        children: ["Wie zal het opdrachtformulier ondertekenen?"],
    }), MuiHelpers_createElement(RadioGroup, [(elements = toList(delay(() => append(singleton(OptionFieldDisabled(assignmentDocumentOption, new AssignmentDocument(1), partnerName, "", true, !isPartnerSelected)), delay(() => append(singleton(OptionField(assignmentDocumentOption, new AssignmentDocument(0), AssignmentDocumentModule_string(new AssignmentDocument(0)), "", true)), delay(() => append(singleton(OptionField(assignmentDocumentOption, new AssignmentDocument(2, new Email_1(legalRepEmail.FieldValue)), AssignmentDocumentModule_string(new AssignmentDocument(2, new Email_1(""))), "", true)), delay(() => {
        let matchValue_1;
        return append((matchValue_1 = assignmentDocumentOption.FieldValue, (matchValue_1 != null) ? ((matchValue_1.tag === 2) ? singleton(createElement(Col, {
            classes: singleton_1(PaddingLeft.L),
            children: singleton_1(TextFieldDisabled(legalRepEmail, "Email", "", true, false)),
        })) : singleton(null)) : singleton(null)), delay(() => {
            let matchValue_2;
            return append((matchValue_2 = assignmentDocumentOption.FieldValue, (matchValue_2 != null) ? ((matchValue_2.tag === 3) ? singleton(createElement(Col, {
                classes: singleton_1(PaddingLeft.L),
                children: singleton_1(TextFieldDisabled(syndicEmail, "Email", "", true, false)),
            })) : singleton(null)) : singleton(null)), delay(() => append(singleton(OptionField(assignmentDocumentOption, new AssignmentDocument(4, new Email_1(other.FieldValue)), "Andere", "", true)), delay(() => {
                const matchValue_3 = assignmentDocumentOption.FieldValue;
                let pattern_matching_result_1;
                if (matchValue_3 != null) {
                    if (matchValue_3.tag === 4) {
                        pattern_matching_result_1 = 0;
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
                switch (pattern_matching_result_1) {
                    case 0: {
                        return singleton(createElement(Col, {
                            classes: singleton_1(PaddingLeft.L),
                            children: singleton_1(TextFieldDisabled(other, "Email", "", true, false)),
                        }));
                    }
                    case 1: {
                        return singleton(null);
                    }
                }
            }))));
        }));
    })))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])];
    return react.createElement(react.Fragment, {}, ...xs);
}

export function ChooseCertificateContact(chooseCertificateContactInputProps) {
    const isPartnerSelected = chooseCertificateContactInputProps.isPartnerSelected;
    const emailsError = chooseCertificateContactInputProps.emailsError;
    const emails = chooseCertificateContactInputProps.emails;
    const partnerName = chooseCertificateContactInputProps.partnerName;
    const emailToggle = chooseCertificateContactInputProps.emailToggle;
    const customerToggle = chooseCertificateContactInputProps.customerToggle;
    const partnerToggle = chooseCertificateContactInputProps.partnerToggle;
    const xs = toList(delay(() => append(singleton(createElement(Col, {
        classes: toList(delay(() => (emailsError ? singleton(error) : empty_1()))),
        children: singleton_1(createElement("h2", {
            children: ["Naar wie mogen we het asbest attest versturen?"],
        })),
    })), delay(() => {
        const partnerSelVal = isPartnerSelected && partnerToggle.State;
        return singleton(createElement(Col, {
            classes: empty(),
            children: toList(delay(() => append(singleton(MuiHelpers_createElement(FormControlLabel, [["disabled", !isPartnerSelected], ["label", partnerName], ["control", MuiHelpers_createElement(Switch, [["checked", partnerSelVal]])], ["onChange", (e) => {
                const _arg = Browser_Types_Event__Event_get_Checked(e);
                partnerToggle.Toggle();
            }]])), delay(() => append(singleton(MuiHelpers_createElement(FormControlLabel, [["label", "Klant"], ["control", MuiHelpers_createElement(Switch, [["checked", customerToggle.State]])], ["onChange", (e_1) => {
                const _arg_1 = Browser_Types_Event__Event_get_Checked(e_1);
                customerToggle.Toggle();
            }]])), delay(() => append(singleton(MuiHelpers_createElement(FormControlLabel, [["label", "Emails"], ["control", MuiHelpers_createElement(Switch, [["checked", emailToggle.State]])], ["onChange", (e_2) => {
                const _arg_2 = Browser_Types_Event__Event_get_Checked(e_2);
                emailToggle.Toggle();
            }]])), delay(() => (emailToggle.State ? singleton(createElement(Col, {
                classes: singleton_1(PaddingLeft.L),
                children: singleton_1(MultiEmailField(emails, "Email", "", true)),
            })) : empty_1()))))))))),
        }));
    }))));
    return react.createElement(react.Fragment, {}, ...xs);
}

export class CustomerOption extends Record {
    constructor(Label, Code) {
        super();
        this.Label = Label;
        this.Code = Code;
    }
}

export function CustomerOption$reflection() {
    return record_type("Contacts.CustomerOption", [], CustomerOption, () => [["Label", string_type], ["Code", string_type]]);
}

export function CustomerOption_get_none() {
    return new CustomerOption("Geen", "0");
}

export function CustomerOption_get_create() {
    return new CustomerOption("Klant aanmaken", "-1");
}

export function FindCustomer(findCustomerInputProps) {
    let elems_5, elems_4;
    const setFoundCustomer = findCustomerInputProps.setFoundCustomer;
    const reset = findCustomerInputProps.reset;
    const setShowCustomerForm = findCustomerInputProps.setShowCustomerForm;
    const getCustomer = findCustomerInputProps.getCustomer;
    const setCustomerOption = findCustomerInputProps.setCustomerOption;
    const customerOption = findCustomerInputProps.customerOption;
    const options = findCustomerInputProps.options;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setShowModal = patternInput[1];
    const xs_4 = [createElement(Row, {
        classes: singleton_1(Gap.S),
        children: toList(delay(() => {
            let elems;
            return append((!equals(customerOption, CustomerOption_get_none())) ? singleton(createElement("div", createObj(ofArray([["className", join(" ", [MarginTop.Xs])], (elems = [createElement("span", {
                className: join(" ", [TextSize.S, Boldness.b600]),
                children: customerOption.Label,
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))) : empty_1(), delay(() => {
                let elems_1;
                return append(equals(customerOption, CustomerOption_get_none()) ? singleton(MuiHelpers_createElement(Button, [["size", "small"], ["color", "secondary"], ["variant", "contained"], ["onClick", (_arg) => {
                    setShowModal(true);
                }], (elems_1 = [equals(customerOption, CustomerOption_get_none()) ? "Selecteer bestaande klant" : customerOption.Label], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])) : singleton(MuiHelpers_createElement(Button, [["size", "small"], ["color", "secondary"], ["variant", "contained"], ["onClick", (_arg_1) => {
                    setShowModal(true);
                }], ["children", Interop_reactApi.Children.toArray(["Selecteer een andere klant"])]])), delay(() => ((!equals(customerOption, CustomerOption_get_none())) ? singleton(MuiHelpers_createElement(Button, [["size", "small"], ["color", "secondary"], ["variant", "contained"], ["onClick", (_arg_2) => {
                    reset();
                    setCustomerOption(CustomerOption_get_none());
                    setFoundCustomer(false);
                }], ["children", Interop_reactApi.Children.toArray(["Wissen"])]])) : empty_1())));
            }));
        })),
    }), MuiHelpers_createElement(Modal, [["open", patternInput[0]], ["onClose", (_arg_4, v) => {
        setShowModal(false);
    }], ["children", createElement("div", createObj(ofArray([["style", {
        width: 100 + "%",
        height: 100 + "%",
    }], ["className", join(" ", [modal, Padding.Xl])], (elems_5 = [MuiHelpers_createElement(FormControl, [["fullWidth", true], (elems_4 = [MuiHelpers_createElement(Autocomplete, [["id", "customer-combo-box-label"], ["options", Array.from(options)], ["value", customerOption], ["getOptionSelected", (delegateArg, delegateArg_1) => (delegateArg.Code === delegateArg_1.Code)], ["onChange", (_arg_5, v_1, _arg_1_1) => {
        const customer = v_1;
        if (!(customer == null)) {
            setCustomerOption(customer);
            getCustomer(new CustomerCode(customer.Code));
            setShowModal(false);
            setFoundCustomer(true);
            if (equals(customer, CustomerOption_get_none())) {
                reset();
                setFoundCustomer(false);
            }
            if (equals(customer, CustomerOption_get_create())) {
                setShowCustomerForm(true);
            }
        }
    }], ["renderInput", (params$0027) => MuiHelpers_createElement(TextField_1, toList(delay(() => append(singleton(["variant", "outlined"]), delay(() => append(singleton(["label", "Klant"]), delay(() => (Object.entries(params$0027)))))))))], ["renderOption", (delegateArg_2, delegateArg_1_1) => {
        const customerOption_1 = delegateArg_2;
        const xs_2 = toList(delay(() => {
            let props_7;
            return equals(customerOption_1, CustomerOption_get_none()) ? singleton(createElement("em", {
                children: Interop_reactApi.Children.toArray([customerOption_1.Label]),
            })) : (equals(customerOption_1, CustomerOption_get_create()) ? singleton(MuiHelpers_createElement(Button, [["fullWidth", true], ["size", "large"], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props_7 = {}, react.createElement(Add, props_7))], ["children", customerOption_1.Label]])) : singleton(createElement("span", {
                children: [customerOption_1.Label],
            })));
        }));
        return react.createElement(react.Fragment, {}, ...xs_2);
    }], ["getOptionLabel", (customer_1) => customer_1.Label]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))]])];
    return react.createElement(react.Fragment, {}, ...xs_4);
}

export function VmeSocialToggles(vmeSocialTogglesInputProps) {
    const companyTypeField = vmeSocialTogglesInputProps.companyTypeField;
    const disabled = vmeSocialTogglesInputProps.disabled;
    const social = vmeSocialTogglesInputProps.social;
    const vme = vmeSocialTogglesInputProps.vme;
    const xs = [MuiHelpers_createElement(FormControlLabel, [["label", "VME"], ["control", MuiHelpers_createElement(Switch, [["checked", vme.State], ["disabled", disabled]])], ["onChange", (e) => {
        if (Browser_Types_Event__Event_get_Checked(e)) {
            vme.On();
            companyTypeField.SetFieldValue("VME");
            social.Off();
        }
        else {
            companyTypeField.SetFieldValue("");
            vme.Off();
        }
    }]]), MuiHelpers_createElement(FormControlLabel, [["label", "Sociale huisvestigingsmaatschappij"], ["control", MuiHelpers_createElement(Switch, [["checked", social.State], ["disabled", disabled]])], ["onChange", (e_1) => {
        if (Browser_Types_Event__Event_get_Checked(e_1)) {
            social.On();
            vme.Off();
            companyTypeField.SetFieldValue("");
        }
        else {
            social.Off();
        }
    }]])];
    return react.createElement(react.Fragment, {}, ...xs);
}

export function CreateDossierOwnerCompanyForm(createDossierOwnerCompanyFormInputProps) {
    const disabled = createDossierOwnerCompanyFormInputProps.disabled;
    const form = createDossierOwnerCompanyFormInputProps.form;
    const xs = toList(delay(() => append(singleton(createElement(VmeSocialToggles, {
        vme: form.Vme,
        social: form.Social,
        disabled: disabled,
        companyTypeField: form.CompanyTypeField,
    })), delay(() => append(singleton(TextField(form.EmailField, "Emailadres", "", true)), delay(() => append(singleton(TextField(form.VatNumberField, "BTW", "", true)), delay(() => append(singleton(TextField(form.CompanyNameField, "Bedrijfsnaam", "", true)), delay(() => append(form.Vme.State ? singleton(TextFieldDisabled(form.CompanyTypeField, "Bedrijfstype", "", false, true)) : singleton(TextFieldDisabled(form.CompanyTypeField, "Bedrijfstype", "", false, false)), delay(() => append(singleton(TextField(form.TelephoneField, "Telefoon", "", false)), delay(() => append(singleton(TextField(form.FirstName, "Voornaam", "", false)), delay(() => append(singleton(TextField(form.LastName, "Naam", "", false)), delay(() => singleton(addressFields(form.AddressField, disabled))))))))))))))))))));
    return react.createElement(react.Fragment, {}, ...xs);
}

export function useCreateDossierOwnerCompanyForm(companyName, companyType, email, phoneNumber, vatNumber, addressStreet, addressNumber, addressBus, addressPostalCode, addressCity, vmeToggle, socialToggle, firstName, lastName) {
    let f1, f1_1, f1_2, f1_3;
    const companyName_1 = useFormField(companyName, NonEmptyString_get_Create());
    const email_1 = useFormField(email, Email_get_Create());
    const phoneNumber_1 = useFormField(phoneNumber, (f1 = PhoneNumber_get_Create(), (arg_1) => some(f1(arg_1))));
    const vatNumber_1 = useFormField(vatNumber, VatNumber_get_Create());
    const address = useAddressForm(addressStreet, addressNumber, addressBus, addressPostalCode, addressCity);
    const vmeToggle_1 = useToggle(vmeToggle);
    const socialToggle_1 = useToggle(socialToggle);
    const companyType_1 = useFormField(companyType, (f1_1 = NonEmptyString_get_Create(), (arg_3) => some(f1_1(arg_3))));
    const firstName_1 = useFormField(firstName, (f1_2 = NonEmptyString_get_Create(), (arg_5) => some(f1_2(arg_5))));
    const lastName_1 = useFormField(lastName, (f1_3 = NonEmptyString_get_Create(), (arg_7) => some(f1_3(arg_7))));
    return {
        AddressField: address,
        CompanyNameField: companyName_1,
        CompanyTypeField: companyType_1,
        EmailField: email_1,
        FirstName: firstName_1,
        LastName: lastName_1,
        Social: socialToggle_1,
        TelephoneField: phoneNumber_1,
        Validator: FormValidator$1_map((x_7) => (new DossierOwnerCompany(x_7.CompanyName, x_7.CompanyType, x_7.Address, x_7.PhoneNumber, x_7.VatNumber, x_7.Email, vmeToggle_1.State, socialToggle_1.State, x_7.CompanyFirstName, x_7.CompanyLastName)), useFormValidator(lastName_1.Validator, (r_6, x_6) => ({
            Address: r_6.Address,
            CompanyFirstName: r_6.CompanyFirstName,
            CompanyLastName: x_6,
            CompanyName: r_6.CompanyName,
            CompanyType: r_6.CompanyType,
            Email: r_6.Email,
            PhoneNumber: r_6.PhoneNumber,
            VatNumber: r_6.VatNumber,
        }), useFormValidator(firstName_1.Validator, (r_5, x_5) => ({
            Address: r_5.Address,
            CompanyFirstName: x_5,
            CompanyName: r_5.CompanyName,
            CompanyType: r_5.CompanyType,
            Email: r_5.Email,
            PhoneNumber: r_5.PhoneNumber,
            VatNumber: r_5.VatNumber,
        }), useFormValidator(vatNumber_1.Validator, (r_4, x_4) => ({
            Address: r_4.Address,
            CompanyName: r_4.CompanyName,
            CompanyType: r_4.CompanyType,
            Email: r_4.Email,
            PhoneNumber: r_4.PhoneNumber,
            VatNumber: x_4,
        }), useFormValidator(address.Validator, (r_3, x_3) => ({
            Address: x_3,
            CompanyName: r_3.CompanyName,
            CompanyType: r_3.CompanyType,
            Email: r_3.Email,
            PhoneNumber: r_3.PhoneNumber,
        }), useFormValidator(phoneNumber_1.Validator, (r_2, x_2) => ({
            CompanyName: r_2.CompanyName,
            CompanyType: r_2.CompanyType,
            Email: r_2.Email,
            PhoneNumber: x_2,
        }), useFormValidator(email_1.Validator, (r_1, x_1) => ({
            CompanyName: r_1.CompanyName,
            CompanyType: r_1.CompanyType,
            Email: x_1,
        }), useFormValidator(companyType_1.Validator, (r, x) => ({
            CompanyName: r.CompanyName,
            CompanyType: x,
        }), useFormValidator(companyName_1.Validator, (unitVar, y) => ({
            CompanyName: y,
        }), useForm()))))))))),
        VatNumberField: vatNumber_1,
        Vme: vmeToggle_1,
    };
}

export function CreateDossierOwnerPersonForm(createDossierOwnerPersonFormInputProps) {
    const disabled = createDossierOwnerPersonFormInputProps.disabled;
    const form = createDossierOwnerPersonFormInputProps.form;
    const xs = [TextField(form.FirstNameField, "Voornaam", "", true), TextField(form.LastNameField, "Naam", "", true), TextField(form.EmailField, "Emailadres", "", true), TextField(form.TelephoneField, "Telefoon", "", false), addressFields(form.AddressField, disabled)];
    return react.createElement(react.Fragment, {}, ...xs);
}

export function useCreateDossierOwnerPersonForm(firstName, lastName, email, phoneNumber, addressStreet, addressNumber, addressBus, addressPostalCode, addressCity) {
    let f1;
    const firstName_1 = useFormField(firstName, NonEmptyString_get_Create());
    const lastName_1 = useFormField(lastName, NonEmptyString_get_Create());
    const email_1 = useFormField(email, Email_get_Create());
    const phoneNumber_1 = useFormField(phoneNumber, (f1 = PhoneNumber_get_Create(), (arg_1) => some(f1(arg_1))));
    const address = useAddressForm(addressStreet, addressNumber, addressBus, addressPostalCode, addressCity);
    return {
        AddressField: address,
        EmailField: email_1,
        FirstNameField: firstName_1,
        LastNameField: lastName_1,
        TelephoneField: phoneNumber_1,
        Validator: FormValidator$1_map((x_5) => (new DossierOwnerPerson(x_5.FirstName, x_5.LastName, x_5.Address, x_5.PhoneNumber, x_5.Email)), useFormValidator(address.Validator, (r_3, x_4) => ({
            Address: x_4,
            Email: r_3.Email,
            FirstName: r_3.FirstName,
            LastName: r_3.LastName,
            PhoneNumber: r_3.PhoneNumber,
        }), useFormValidator(phoneNumber_1.Validator, (r_2, x_3) => ({
            Email: r_2.Email,
            FirstName: r_2.FirstName,
            LastName: r_2.LastName,
            PhoneNumber: x_3,
        }), useFormValidator(email_1.Validator, (r_1, x_2) => ({
            Email: x_2,
            FirstName: r_1.FirstName,
            LastName: r_1.LastName,
        }), useFormValidator(lastName_1.Validator, (r, x_1) => ({
            FirstName: r.FirstName,
            LastName: x_1,
        }), useFormValidator(firstName_1.Validator, (unitVar, x) => ({
            FirstName: x,
        }), useForm())))))),
    };
}

export function CreateCustomerPersonForm(createCustomerPersonFormInputProps) {
    const disabled = createCustomerPersonFormInputProps.disabled;
    const form = createCustomerPersonFormInputProps.form;
    const xs = [createElement(Row, {
        classes: singleton_1(MarginTop.S),
        children: singleton_1(createElement(Col, {
            classes: empty(),
            children: ofArray([TextFieldDisabled(form.FirstNameField, "Voornaam", "", true, disabled), TextFieldDisabled(form.LastNameField, "Naam", "", true, disabled), EmailFieldDisabled(form.EmailField, "Email adres", "", true, disabled), TextFieldDisabled(form.TelephoneField, "Telefoon", "", false, disabled), createElement(CreateAddressFormSmallHeader, {
                addressForm: form.AddressField,
                heading: "Adres",
                disabled: disabled,
            })]),
        })),
    })];
    return react.createElement(react.Fragment, {}, ...xs);
}

export function useCreateCustomerPersonForm(firstName, lastName, email, phoneNumber, addressStreet, addressNumber, addressBus, addressPostalCode, addressCity) {
    let f1;
    const firstName_1 = useFormField(firstName, NonEmptyString_get_Create());
    const lastName_1 = useFormField(lastName, NonEmptyString_get_Create());
    const email_1 = useFormField(email, Email_get_Create());
    const phoneNumber_1 = useFormField(phoneNumber, (f1 = PhoneNumber_get_Create(), (arg_1) => some(f1(arg_1))));
    const address = useAddressForm(addressStreet, addressNumber, addressBus, addressPostalCode, addressCity);
    const isCibCustomerField = useToggle(false);
    return {
        AddressField: address,
        EmailField: email_1,
        FirstNameField: firstName_1,
        LastNameField: lastName_1,
        TelephoneField: phoneNumber_1,
        Validator: FormValidator$1_map((x_5) => (new CustomerPersonData(x_5.LastName, x_5.FirstName, x_5.Address, x_5.Email, x_5.PhoneNumber, isCibCustomerField.State)), useFormValidator(address.Validator, (r_3, x_4) => ({
            Address: x_4,
            Email: r_3.Email,
            FirstName: r_3.FirstName,
            LastName: r_3.LastName,
            PhoneNumber: r_3.PhoneNumber,
        }), useFormValidator(phoneNumber_1.Validator, (r_2, x_3) => ({
            Email: r_2.Email,
            FirstName: r_2.FirstName,
            LastName: r_2.LastName,
            PhoneNumber: x_3,
        }), useFormValidator(email_1.Validator, (r_1, x_2) => ({
            Email: x_2,
            FirstName: r_1.FirstName,
            LastName: r_1.LastName,
        }), useFormValidator(lastName_1.Validator, (r, x_1) => ({
            FirstName: r.FirstName,
            LastName: x_1,
        }), useFormValidator(firstName_1.Validator, (unitVar, x) => ({
            FirstName: x,
        }), useForm())))))),
    };
}

export function CreateCustomerCompanyForm(createCustomerCompanyFormInputProps) {
    const disabled = createCustomerCompanyFormInputProps.disabled;
    const form = createCustomerCompanyFormInputProps.form;
    const xs = [createElement(Col, {
        classes: empty(),
        children: ofArray([createElement(VmeSocialToggles, {
            vme: form.IsVMEField,
            social: form.IsSocialHousingCompanyField,
            disabled: disabled,
            companyTypeField: form.CompanyTypeField,
        }), TextFieldDisabled(form.CompanyNameField, "Bedrijfsnaam", "", true, disabled), TextFieldDisabled(form.CompanyTypeField, "Bedrijfstype", "", false, disabled), EmailFieldDisabled(form.EmailField, "Email adres", "", true, disabled), TextFieldDisabled(form.TelephoneField, "Telefoon", "", false, disabled), TextFieldDisabled(form.VatNumberField, "BTW", "", true, disabled), TextFieldDisabled(form.FirstName, "Voornaam", "", false, disabled), TextFieldDisabled(form.LastName, "Naam", "", false, disabled)]),
    }), createElement(CreateAddressFormSmallHeader, {
        addressForm: form.AddressField,
        heading: "Adres",
        disabled: disabled,
    })];
    return react.createElement(react.Fragment, {}, ...xs);
}

export function useCreateCustomerCompanyForm(companyName, companyType, email, phoneNumber, vatNumber, addressStreet, addressNumber, addressBus, addressPostalCode, addressCity, isVme, isSocialHousingCompany, firstName, lastName) {
    let f1, f1_1, f1_2, f1_3;
    const companyName_1 = useFormField(companyName, NonEmptyString_get_Create());
    const companyType_1 = useFormField(companyType, (f1 = NonEmptyString_get_Create(), (arg_1) => some(f1(arg_1))));
    const email_1 = useFormField(email, Email_get_Create());
    const phoneNumber_1 = useFormField(phoneNumber, (f1_1 = PhoneNumber_get_Create(), (arg_3) => some(f1_1(arg_3))));
    const vatNumber_1 = useFormField(vatNumber, VatNumber_get_Create());
    const address = useAddressForm(addressStreet, addressNumber, addressBus, addressPostalCode, addressCity);
    const isVme_1 = useToggle(isVme);
    const isSocialHousingCompanyField = useToggle(isSocialHousingCompany);
    const isCibCustomerField = useToggle(false);
    const firstName_1 = useFormField(firstName, (f1_2 = NonEmptyString_get_Create(), (arg_5) => some(f1_2(arg_5))));
    const lastName_1 = useFormField(lastName, (f1_3 = NonEmptyString_get_Create(), (arg_7) => some(f1_3(arg_7))));
    return {
        AddressField: address,
        CompanyNameField: companyName_1,
        CompanyTypeField: companyType_1,
        EmailField: email_1,
        FirstName: firstName_1,
        IsCibCustomerField: isCibCustomerField,
        IsSocialHousingCompanyField: isSocialHousingCompanyField,
        IsVMEField: isVme_1,
        LastName: lastName_1,
        TelephoneField: phoneNumber_1,
        Validator: FormValidator$1_map((x_8) => (new CustomerCompanyData(x_8.CompanyName, x_8.CompanyType, x_8.Address, x_8.Email, x_8.PhoneNumber, x_8.VatNumber, isCibCustomerField.State, isVme_1.State, isCibCustomerField.State, x_8.CompanyLastName, x_8.CompanyFirstName)), useFormValidator(lastName_1.Validator, (r_6, x_7) => ({
            Address: r_6.Address,
            CompanyFirstName: r_6.CompanyFirstName,
            CompanyLastName: x_7,
            CompanyName: r_6.CompanyName,
            CompanyType: r_6.CompanyType,
            Email: r_6.Email,
            PhoneNumber: r_6.PhoneNumber,
            VatNumber: r_6.VatNumber,
        }), useFormValidator(firstName_1.Validator, (r_5, x_6) => ({
            Address: r_5.Address,
            CompanyFirstName: x_6,
            CompanyName: r_5.CompanyName,
            CompanyType: r_5.CompanyType,
            Email: r_5.Email,
            PhoneNumber: r_5.PhoneNumber,
            VatNumber: r_5.VatNumber,
        }), useFormValidator(address.Validator, (r_4, x_5) => ({
            Address: x_5,
            CompanyName: r_4.CompanyName,
            CompanyType: r_4.CompanyType,
            Email: r_4.Email,
            PhoneNumber: r_4.PhoneNumber,
            VatNumber: r_4.VatNumber,
        }), useFormValidator(phoneNumber_1.Validator, (r_3, x_4) => ({
            CompanyName: r_3.CompanyName,
            CompanyType: r_3.CompanyType,
            Email: r_3.Email,
            PhoneNumber: x_4,
            VatNumber: r_3.VatNumber,
        }), useFormValidator(vatNumber_1.Validator, (r_2, x_3) => ({
            CompanyName: r_2.CompanyName,
            CompanyType: r_2.CompanyType,
            Email: r_2.Email,
            VatNumber: x_3,
        }), useFormValidator(email_1.Validator, (r_1, x_2) => ({
            CompanyName: r_1.CompanyName,
            CompanyType: r_1.CompanyType,
            Email: x_2,
        }), useFormValidator(companyType_1.Validator, (r, x_1) => ({
            CompanyName: r.CompanyName,
            CompanyType: x_1,
        }), useFormValidator(companyName_1.Validator, (unitVar, x) => ({
            CompanyName: x,
        }), useForm()))))))))),
        VatNumberField: vatNumber_1,
    };
}

export function ChooseOwner(chooseOwnerInputProps) {
    let elements;
    const ownerPersonForm = chooseOwnerInputProps.ownerPersonForm;
    const ownerCompanyForm = chooseOwnerInputProps.ownerCompanyForm;
    const ownerOption = chooseOwnerInputProps.ownerOption;
    const xs = [createElement("h2", {
        children: ["Eigenaar"],
    }), MuiHelpers_createElement(RadioGroup, [["row", true], (elements = [OptionField(ownerOption, new ContactOwner(0), ContactOwnerModule_string(new ContactOwner(0)), "", true), OptionField(ownerOption, new ContactOwner(1), ContactOwnerModule_string(new ContactOwner(1)), "", true)], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]), createElement(Col, {
        classes: singleton_1(MarginTop.S),
        children: toList(delay(() => {
            const matchValue = ownerOption.FieldValue;
            return (matchValue != null) ? ((matchValue.tag === 1) ? singleton(createElement(CreateDossierOwnerCompanyForm, {
                form: ownerCompanyForm,
                disabled: false,
            })) : singleton(createElement(CreateDossierOwnerPersonForm, {
                form: ownerPersonForm,
                disabled: false,
            }))) : singleton(null);
        })),
    })];
    return react.createElement(react.Fragment, {}, ...xs);
}

export function ChoosePricing(invoicePricing) {
    return createElement(Col, {
        classes: singleton_1(MarginBottom.M),
        children: singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", "Particuliere prijzen hanteren"], ["control", MuiHelpers_createElement(Switch, [["checked", invoicePricing.State]])], ["onChange", (e) => {
            const _arg = Browser_Types_Event__Event_get_Checked(e);
            invoicePricing.Toggle();
        }]])),
    });
}

export function ChooseInvoicing(chooseInvoicingInputProps) {
    const invoicePricing = chooseInvoicingInputProps.invoicePricing;
    const dossierCreatePageSource = chooseInvoicingInputProps.dossierCreatePageSource;
    const foundCustomer = chooseInvoicingInputProps.foundCustomer;
    const isPartnerInvoicingAvailable = chooseInvoicingInputProps.isPartnerInvoicingAvailable;
    const customerCompany = chooseInvoicingInputProps.customerCompany;
    const customerPerson = chooseInvoicingInputProps.customerPerson;
    const contactOwner = chooseInvoicingInputProps.contactOwner;
    const setInvoicedValue = chooseInvoicingInputProps.setInvoicedValue;
    const invoicingPartySameAsOwner = chooseInvoicingInputProps.invoicingPartySameAsOwner;
    const customerControl = chooseInvoicingInputProps.customerControl;
    const partnerName = chooseInvoicingInputProps.partnerName;
    const invoiceOption = chooseInvoicingInputProps.invoiceOption;
    const xs = toList(delay(() => append(singleton(createElement(Col, {
        classes: singleton_1(MarginTop.S),
        children: singleton_1(createElement("h2", {
            children: ["Prijzen"],
        })),
    })), delay(() => {
        let matchValue;
        return append(equals(dossierCreatePageSource, new DossierCreatePageSource(0)) ? ((matchValue = invoicePricing, (matchValue == null) ? singleton(null) : singleton(createElement(ChoosePricing, matchValue)))) : empty_1(), delay(() => append(singleton(createElement(Col, {
            classes: singleton_1(MarginTop.S),
            children: singleton_1(createElement("h2", {
                children: ["Facturatiegegevens"],
            })),
        })), delay(() => {
            let matchValue_1, customerControl_1;
            return append((matchValue_1 = customerControl, (matchValue_1 == null) ? singleton(null) : ((customerControl_1 = matchValue_1, (invoicingPartySameAsOwner.State !== true) ? singleton(createElement(Col, {
                classes: singleton_1(MarginBottom.M),
                children: singleton_1(customerControl_1),
            })) : singleton(null)))), delay(() => append(singleton(MuiHelpers_createElement(FormControlLabel, [["label", "Zelfde als Eigenaar"], ["control", MuiHelpers_createElement(Checkbox, [["disabled", foundCustomer], ["checked", invoicingPartySameAsOwner.State], ["onChange", (_arg) => {
                invoicingPartySameAsOwner.Toggle();
                if (contactOwner.tag === 1) {
                    const company = contactOwner.fields[0];
                    setInvoicedValue(new Invoiced(2));
                    customerCompany.CompanyNameField.SetFieldValue(NonEmptyString___UnsafeUnwrap_Z73AD07C(company.CompanyName));
                    customerCompany.CompanyTypeField.SetFieldValue(defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, company.CompanyType), ""));
                    customerCompany.FirstName.SetFieldValue(defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, company.FirstName), ""));
                    customerCompany.LastName.SetFieldValue(defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, company.LastName), ""));
                    customerCompany.EmailField.SetFieldValue(Email___UnsafeUnwrap_Z27D11339(company.Email));
                    customerCompany.TelephoneField.SetFieldValue(defaultArg(map(PhoneNumber___UnsafeUnwrap_23E45174, company.PhoneNumber), ""));
                    customerCompany.VatNumberField.SetFieldValue(VatNumber___UnsafeUnwrap_18C04ACB(company.VatNumber));
                    customerCompany.AddressField.StreetField.SetFieldValue(company.Address.Street.Value);
                    customerCompany.AddressField.NumberField.SetFieldValue(company.Address.Number.Value);
                    customerCompany.AddressField.BusField.SetFieldValue(defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, company.Address.Bus), ""));
                    customerCompany.AddressField.PostalCodeField.SetFieldValue(company.Address.PostalCode.Value);
                    customerCompany.AddressField.City.SetFieldValue(company.Address.City.Value);
                    customerPerson.FirstNameField.SetFieldValue(defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, company.FirstName), ""));
                    customerPerson.LastNameField.SetFieldValue(defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, company.LastName), ""));
                    if (company.IsVME) {
                        customerCompany.IsVMEField.On();
                    }
                    if (company.IsSocialHousingCompany) {
                        customerCompany.IsSocialHousingCompanyField.On();
                    }
                }
                else {
                    const person = contactOwner.fields[0];
                    setInvoicedValue(new Invoiced(1));
                    customerPerson.FirstNameField.SetFieldValue(NonEmptyString___UnsafeUnwrap_Z73AD07C(person.FirstName));
                    customerPerson.LastNameField.SetFieldValue(NonEmptyString___UnsafeUnwrap_Z73AD07C(person.LastName));
                    customerPerson.EmailField.SetFieldValue(Email___UnsafeUnwrap_Z27D11339(person.Email));
                    customerPerson.TelephoneField.SetFieldValue(defaultArg(map(PhoneNumber___UnsafeUnwrap_23E45174, person.PhoneNumber), ""));
                    customerPerson.AddressField.StreetField.SetFieldValue(person.Address.Street.Value);
                    customerPerson.AddressField.NumberField.SetFieldValue(person.Address.Number.Value);
                    customerPerson.AddressField.BusField.SetFieldValue(defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, person.Address.Bus), ""));
                    customerPerson.AddressField.PostalCodeField.SetFieldValue(person.Address.PostalCode.Value);
                    customerPerson.AddressField.City.SetFieldValue(person.Address.City.Value);
                }
            }]])]])), delay(() => {
                let elements;
                return append(singleton(MuiHelpers_createElement(RadioGroup, [(elements = [OptionFieldDisabled(invoiceOption, new Invoiced(0), partnerName, "", true, (invoicingPartySameAsOwner.State ? true : (!isPartnerInvoicingAvailable)) ? true : foundCustomer), OptionFieldDisabled(invoiceOption, new Invoiced(1), InvoicedModule_string(new Invoiced(1)), "", true, invoicingPartySameAsOwner.State ? true : foundCustomer), OptionFieldDisabled(invoiceOption, new Invoiced(2), InvoicedModule_string(new Invoiced(2)), "", true, invoicingPartySameAsOwner.State ? true : foundCustomer)], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])), delay(() => {
                    if (invoicingPartySameAsOwner.State) {
                        const matchValue_2 = invoiceOption.FieldValue;
                        return (matchValue_2 == null) ? singleton(null) : ((matchValue_2.tag === 1) ? singleton(createElement(Col, {
                            classes: empty(),
                            children: singleton_1(createElement(CreateCustomerPersonForm, {
                                form: customerPerson,
                                disabled: true,
                            })),
                        })) : ((matchValue_2.tag === 2) ? singleton(createElement(Col, {
                            classes: empty(),
                            children: singleton_1(createElement(CreateCustomerCompanyForm, {
                                form: customerCompany,
                                disabled: true,
                            })),
                        })) : singleton(null)));
                    }
                    else {
                        const matchValue_3 = invoiceOption.FieldValue;
                        let pattern_matching_result;
                        if (matchValue_3 != null) {
                            if (matchValue_3.tag === 1) {
                                if (foundCustomer !== true) {
                                    pattern_matching_result = 1;
                                }
                                else {
                                    pattern_matching_result = 3;
                                }
                            }
                            else if (matchValue_3.tag === 2) {
                                if (foundCustomer !== true) {
                                    pattern_matching_result = 2;
                                }
                                else {
                                    pattern_matching_result = 3;
                                }
                            }
                            else {
                                pattern_matching_result = 0;
                            }
                        }
                        else {
                            pattern_matching_result = 3;
                        }
                        switch (pattern_matching_result) {
                            case 0: {
                                return singleton(null);
                            }
                            case 1: {
                                return singleton(createElement(Col, {
                                    classes: empty(),
                                    children: singleton_1(createElement(CreateCustomerPersonForm, {
                                        form: customerPerson,
                                        disabled: false,
                                    })),
                                }));
                            }
                            case 2: {
                                return singleton(createElement(Col, {
                                    classes: empty(),
                                    children: singleton_1(createElement(CreateCustomerCompanyForm, {
                                        form: customerCompany,
                                        disabled: false,
                                    })),
                                }));
                            }
                            case 3: {
                                return singleton(null);
                            }
                        }
                    }
                }));
            }))));
        }))));
    }))));
    return react.createElement(react.Fragment, {}, ...xs);
}

