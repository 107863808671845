import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505, useReact_useRef_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { join, toText, printf, toConsole } from "../fable_modules/fable-library.3.7.17/String.js";
import { toArray, flatten, map as map_1, defaultArg, some, value as value_103 } from "../fable_modules/fable-library.3.7.17/Option.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { getFile } from "./DownloadFile.fs.js";
import { createElement } from "react";
import * as react from "react";
import { defaultErrorMessage, DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { PageProp, Page, DocumentProp, Document$ } from "../lib/bindings/ReactPdf.fs.js";
import { SignaturePageMessage, SignBoxCloseButton, SignatureSlider, SigningBoxOpen, PdfPageWrapper, SignatureBox, PdfPageContainer, SignaturePageToolbar, SignaturePage, SignatureDocument } from "../../../Styles/SignaturePageStyles.fs.js";
import { mapIndexed } from "../fable_modules/fable-library.3.7.17/Array.js";
import { iterate, empty, append, map, singleton, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { useToggle } from "../../../CustomHooks/CustomHooks.fs.js";
import { useSnackbar } from "notistack";
import { useForm, useFormValidator, useFormField } from "../../../CustomHooks/ComposedForm.fs.js";
import { SignatureCode, SelectOptionData$1_Create_Z510A3DC0, KboNumber_get_Create, PhoneNumber_get_Create, Email_get_Create, NonEmptyString_get_Create } from "../../../shared/Domain.fs.js";
import { CreateAddressFormSmallHeader, useAddressForm } from "../../../DesignSystems/AddressHook.fs.js";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import { ofArray, singleton as singleton_1, empty as empty_1 } from "../fable_modules/fable-library.3.7.17/List.js";
import { createObj } from "../fable_modules/fable-library.3.7.17/Util.js";
import { MarginLeft, TextColor, MarginTop, PaddingBottom, PaddingTop, Gap, MarginBottom, TextSize, Boldness, fullWidth, Padding, alignCenter, loginLogoSmall } from "../../../Styles/Utils.fs.js";
import { logo } from "../Img.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Button from "@material-ui/core/Button";
import { SignaturePadOptions, SignatureCanvas, CanvasProps } from "../lib/bindings/SignatureCanvas.fs.js";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { OptionField, TextField } from "../../../DesignSystems/DesignSystem.fs.js";
import RadioGroup from "@material-ui/core/RadioGroup";
import { SignatureCommonData, SignatureVmeData, LegalRepresentativeData } from "../../../shared/Api.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { toString, fromDateTimeOffset } from "../fable_modules/fable-library.3.7.17/Date.js";
import CircularProgress from "@material-ui/core/CircularProgress";

export function DigitalSignature(api) {
    const inputRef2 = useReact_useRef_1505(void 0);
    const matchValue = inputRef2.current;
    if (matchValue == null) {
        toConsole(printf("None"));
    }
    else {
        const signatureCanvas_1 = value_103(matchValue);
        console.log(signatureCanvas_1.isEmpty());
        console.log(signatureCanvas_1.getTrimmedCanvas().toDataURL(""));
    }
}

export function RPdfFile(rPdfFileInputProps) {
    const isPdfLoading = rPdfFileInputProps.isPdfLoading;
    const path = rPdfFileInputProps.path;
    const api = rPdfFileInputProps.api;
    const filePath = useFeliz_React__React_useState_Static_1505(path)[0];
    const fileData = useFeliz_React__React_useDeferred_Static_2344FC52(getFile(api, filePath), [filePath]);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(1);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    return createElement(DisplayDeferred, {
        data: fileData,
        view: (digitalSign) => {
            let elems;
            return Document$([new DocumentProp(0, SignatureDocument), new DocumentProp(3, digitalSign), new DocumentProp(8, (e) => {
                toConsole(printf("loadError %A"))(e);
                isPdfLoading.Off();
            }), new DocumentProp(11, (e_1) => {
                toConsole(printf("OnSourceError %A"))(e_1);
                isPdfLoading.Off();
            }), new DocumentProp(9, (e_2) => {
                patternInput_2[1](mapIndexed((i, a) => (i + 1), new Int32Array(e_2.numPages), Int32Array));
                isPdfLoading.Off();
            }), (elems = toList(delay(() => {
                const windowWidth = window.innerWidth;
                const matchValue = patternInput_2[0];
                return (matchValue == null) ? singleton(null) : map((page) => Page(toList(delay(() => append(singleton(new PageProp(0, SignaturePage)), delay(() => append(singleton(new PageProp(1, page)), delay(() => append(singleton(new PageProp(3, toText(`page-${page}`))), delay(() => append(singleton(new PageProp(4, false)), delay(() => append(singleton(new PageProp(5, false)), delay(() => ((windowWidth > 1000) ? singleton(new PageProp(6, 800)) : empty())))))))))))))), matchValue);
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]);
        },
    });
}

export function DisplayDocumentAndSignature(displayDocumentAndSignatureInputProps) {
    let f1, f1_1, elems, elems_1;
    const signautreVmeData = displayDocumentAndSignatureInputProps.signautreVmeData;
    const setAlreadySigned = displayDocumentAndSignatureInputProps.setAlreadySigned;
    const alreadySigned = displayDocumentAndSignatureInputProps.alreadySigned;
    const partnerInfo = displayDocumentAndSignatureInputProps.partnerInfo;
    const allowSigning = displayDocumentAndSignatureInputProps.allowSigning;
    const signature = displayDocumentAndSignatureInputProps.signature;
    const filePath = displayDocumentAndSignatureInputProps.filePath;
    const code = displayDocumentAndSignatureInputProps.code;
    const signatureApi = displayDocumentAndSignatureInputProps.signatureApi;
    const isVme = useToggle(!(signautreVmeData == null));
    const isPdfLoading = useToggle(true);
    const signatureValid = useToggle(true);
    const containerRef = useReact_useRef_1505(void 0);
    const snackbar = useSnackbar();
    const signatureCanvasRef = useReact_useRef_1505(void 0);
    const showSigningBox = useToggle(false);
    const disableInput = useToggle(false);
    let patternInput;
    if (partnerInfo == null) {
        patternInput = [false, "", "", "", "", "", "", "", "", "", void 0, void 0];
    }
    else {
        const partnerInfo_1 = partnerInfo;
        patternInput = [true, partnerInfo_1.FirstName, partnerInfo_1.LastName, partnerInfo_1.Email, partnerInfo_1.PhoneNumber, partnerInfo_1.Address.Street, partnerInfo_1.Address.Number, partnerInfo_1.Address.Bus, partnerInfo_1.Address.City, partnerInfo_1.Address.PostalCode, partnerInfo_1.HiddenAsbestos, partnerInfo_1.FloorplanAvailable];
    }
    const isLegalRepresentative = useToggle(patternInput[0]);
    const firstName = useFormField(patternInput[1], NonEmptyString_get_Create());
    const lastName = useFormField(patternInput[2], NonEmptyString_get_Create());
    const email = useFormField(patternInput[3], Email_get_Create());
    const phoneNumber = useFormField(patternInput[4], (f1 = PhoneNumber_get_Create(), (arg_1) => some(f1(arg_1))));
    const address = useAddressForm(patternInput[5], patternInput[6], patternInput[7], patternInput[8], patternInput[9]);
    const vmeOrg = defaultArg(map_1((o) => o.OrganisationName.Value, signautreVmeData), "");
    const vmeKbo = defaultArg(map_1((o_1) => o_1.KboNumber.Value, signautreVmeData), "");
    const vmeemail = defaultArg(flatten(map_1((o_2) => map_1((e) => e.Value, o_2.Email), signautreVmeData)), "");
    const vmePh = defaultArg(flatten(map_1((o_3) => map_1((e_1) => e_1.Value, o_3.PhoneNumber), signautreVmeData)), "");
    const patternInput_1 = defaultArg(map_1((o_4) => [o_4.Address.Street.Value, o_4.Address.Number.Value, defaultArg(map_1((e_2) => e_2.Value, o_4.Address.Bus), ""), o_4.Address.PostalCode.Value, o_4.Address.City.Value], signautreVmeData), ["", "", "", "", ""]);
    const vmeFirstName = useFormField(defaultArg(map_1((o_5) => o_5.FirstName.Value, signautreVmeData), ""), NonEmptyString_get_Create());
    const vmeLastName = useFormField(defaultArg(map_1((o_6) => o_6.LastName.Value, signautreVmeData), ""), NonEmptyString_get_Create());
    const vmeEmail = useFormField(vmeemail, Email_get_Create());
    const vmePhoneNumber = useFormField(vmePh, (f1_1 = PhoneNumber_get_Create(), (arg_3) => some(f1_1(arg_3))));
    const vmeAddress = useAddressForm(patternInput_1[0], patternInput_1[1], patternInput_1[2], patternInput_1[3], patternInput_1[4]);
    const organisationName = useFormField(vmeOrg, NonEmptyString_get_Create());
    const kboNumber = useFormField(vmeKbo, KboNumber_get_Create());
    const hiddenAsbestos = useFormField(patternInput[10], SelectOptionData$1_Create_Z510A3DC0);
    const floorPlanAvailable = useFormField(patternInput[11], SelectOptionData$1_Create_Z510A3DC0);
    useReact_useEffect_Z101E1A95(() => {
        if (isLegalRepresentative.State) {
            isVme.Off();
        }
    }, [isLegalRepresentative.State]);
    useReact_useEffect_Z101E1A95(() => {
        if (isVme.State) {
            isLegalRepresentative.Off();
        }
    }, [isVme.State]);
    const formCommon = useFormValidator(floorPlanAvailable.Validator, (r, x_1) => ({
        FloorPlanAvailable: x_1,
        HiddenAsbestos: r.HiddenAsbestos,
    }), useFormValidator(hiddenAsbestos.Validator, (unitVar_3, x) => ({
        HiddenAsbestos: x,
    }), useForm()));
    const formLegalPerson = useFormValidator(address.Validator, (r_4, x_6) => ({
        Address: x_6,
        Email: r_4.Email,
        FirstName: r_4.FirstName,
        LastName: r_4.LastName,
        PhoneNumber: r_4.PhoneNumber,
    }), useFormValidator(phoneNumber.Validator, (r_3, x_5) => ({
        Email: r_3.Email,
        FirstName: r_3.FirstName,
        LastName: r_3.LastName,
        PhoneNumber: x_5,
    }), useFormValidator(email.Validator, (r_2, x_4) => ({
        Email: x_4,
        FirstName: r_2.FirstName,
        LastName: r_2.LastName,
    }), useFormValidator(lastName.Validator, (r_1, x_3) => ({
        FirstName: r_1.FirstName,
        LastName: x_3,
    }), useFormValidator(firstName.Validator, (unitVar_4, x_2) => ({
        FirstName: x_2,
    }), useForm())))));
    const formVme = useFormValidator(vmeAddress.Validator, (r_10, x_13) => ({
        Address: x_13,
        Email: r_10.Email,
        FirstName: r_10.FirstName,
        KboNumber: r_10.KboNumber,
        LastName: r_10.LastName,
        OrganisationName: r_10.OrganisationName,
        PhoneNumber: r_10.PhoneNumber,
    }), useFormValidator(vmePhoneNumber.Validator, (r_9, x_12) => ({
        Email: r_9.Email,
        FirstName: r_9.FirstName,
        KboNumber: r_9.KboNumber,
        LastName: r_9.LastName,
        OrganisationName: r_9.OrganisationName,
        PhoneNumber: x_12,
    }), useFormValidator(vmeEmail.Validator, (r_8, x_11) => ({
        Email: x_11,
        FirstName: r_8.FirstName,
        KboNumber: r_8.KboNumber,
        LastName: r_8.LastName,
        OrganisationName: r_8.OrganisationName,
    }), useFormValidator(vmeLastName.Validator, (r_7, x_10) => ({
        FirstName: r_7.FirstName,
        KboNumber: r_7.KboNumber,
        LastName: x_10,
        OrganisationName: r_7.OrganisationName,
    }), useFormValidator(vmeFirstName.Validator, (r_6, x_9) => ({
        FirstName: x_9,
        KboNumber: r_6.KboNumber,
        OrganisationName: r_6.OrganisationName,
    }), useFormValidator(kboNumber.Validator, (r_5, x_8) => ({
        KboNumber: x_8,
        OrganisationName: r_5.OrganisationName,
    }), useFormValidator(organisationName.Validator, (unitVar_5, x_7) => ({
        OrganisationName: x_7,
    }), useForm())))))));
    return createElement(Col, {
        classes: empty_1(),
        children: ofArray([createElement(Row, {
            classes: singleton_1(SignaturePageToolbar),
            children: ofArray([createElement(Col, {
                classes: empty_1(),
                children: singleton_1(createElement("div", createObj(singleton_1((elems = [createElement("img", {
                    className: loginLogoSmall,
                    alt: "Asbest Consulting Logo",
                    src: logo,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))),
            }), createElement(Col, {
                classes: ofArray([alignCenter, Padding.M]),
                children: singleton_1(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = toList(delay(() => (((!showSigningBox.State) && (!alreadySigned)) ? singleton(MuiHelpers_createElement(Button, [["variant", "contained"], ["color", "secondary"], ["size", "medium"], ["disabled", isPdfLoading.State], ["children", "Opdrachtbevestiging Tekenen"], ["onClick", (_arg_1) => {
                    window.scrollTo(0, 0);
                    showSigningBox.On();
                }]])) : empty()))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))),
            })]),
        }), createElement(Col, {
            classes: ofArray([fullWidth, PdfPageContainer]),
            children: toList(delay(() => {
                let elems_17, elems_2, elems_16;
                const canvasProps = new CanvasProps(600, 600, SignatureBox(signatureValid.State));
                return singleton(createElement("div", createObj(ofArray([["className", join(" ", [PdfPageWrapper])], ["ref", containerRef], (elems_17 = [createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => (showSigningBox.State ? singleton(SigningBoxOpen) : empty()))))], (elems_2 = [createElement(RPdfFile, {
                    api: signatureApi,
                    path: filePath,
                    isPdfLoading: isPdfLoading,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), MuiHelpers_createElement(Slide, [["direction", "left"], ["in", showSigningBox.State], ["children", createElement("div", createObj(ofArray([["className", join(" ", [Padding.M, SignatureSlider])], (elems_16 = toList(delay(() => {
                    let elems_3, props_5;
                    return append(singleton(MuiHelpers_createElement(IconButton, [["size", "small"], ["className", join(" ", [SignBoxCloseButton])], ["onClick", (_arg_2) => {
                        showSigningBox.Off();
                    }], (elems_3 = [(props_5 = {}, react.createElement(Close, props_5))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])), delay(() => append(singleton(createElement("div", {
                        className: join(" ", [Boldness.b600, TextSize.M, MarginBottom.M]),
                        children: Interop_reactApi.Children.toArray(["Opdrachtformulier ondertekenen"]),
                    })), delay(() => {
                        let elems_15;
                        return allowSigning ? append(singleton(createElement(Col, {
                            classes: empty_1(),
                            children: toList(delay(() => append(singleton(createElement(Col, {
                                classes: singleton_1(Gap.M),
                                children: singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", "Ik ben een gevolmachtigd persoon"], ["control", MuiHelpers_createElement(Switch, [["checked", isLegalRepresentative.State], ["onChange", (v) => {
                                    isLegalRepresentative.Toggle();
                                }]])]])),
                            })), delay(() => (isLegalRepresentative.State ? singleton(createElement(Col, {
                                classes: ofArray([Gap.M, MarginBottom.L]),
                                children: ofArray([TextField(firstName, "Voornaam", "", true), TextField(lastName, "Naam", "", true), TextField(email, "Emailadres", "", true), TextField(phoneNumber, "Telefoon", "", false), createElement(CreateAddressFormSmallHeader, {
                                    addressForm: address,
                                    heading: void 0,
                                    disabled: false,
                                })]),
                            })) : empty()))))),
                        })), delay(() => append(singleton(createElement(Col, {
                            classes: empty_1(),
                            children: toList(delay(() => append(singleton(createElement(Col, {
                                classes: singleton_1(Gap.M),
                                children: singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", "Ik teken in naam van een Vereninging van mede-eigendom (VME) / Ik ben een rechtspersoon"], ["control", MuiHelpers_createElement(Switch, [["checked", isVme.State], ["onChange", (v_1) => {
                                    isVme.Toggle();
                                }]])]])),
                            })), delay(() => (isVme.State ? singleton(createElement(Col, {
                                classes: singleton_1(Gap.M),
                                children: ofArray([TextField(organisationName, "Naam Organisatie", "", true), TextField(kboNumber, "KBO nummer", "", true), TextField(vmeFirstName, "Voornaam", "", true), TextField(vmeLastName, "Naam", "", true), TextField(vmeEmail, "Emailadres", "", true), TextField(vmePhoneNumber, "Telefoon", "", false), createElement(CreateAddressFormSmallHeader, {
                                    addressForm: vmeAddress,
                                    heading: void 0,
                                    disabled: false,
                                })]),
                            })) : empty()))))),
                        })), delay(() => {
                            let elems_5;
                            return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", [PaddingTop.M, PaddingBottom.M])], (elems_5 = [createElement("hr", {})], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => {
                                let elems_10, elements, elements_1;
                                return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_10 = [createElement("div", {
                                    className: join(" ", [TextSize.S, Boldness.b500]),
                                    children: Interop_reactApi.Children.toArray(["Bent u op de hoogte van de aanwezigheid van ingesloten of bedekte asbesthoudende materialen in uw eigendom?"]),
                                }), MuiHelpers_createElement(RadioGroup, [["row", true], (elements = [OptionField(hiddenAsbestos, true, "Ja", "", false), OptionField(hiddenAsbestos, false, "Nee", "", false)], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]), createElement("div", {
                                    className: join(" ", [MarginTop.M, TextSize.S, Boldness.b500]),
                                    children: Interop_reactApi.Children.toArray(["Is er een grondplan aanwezig?"]),
                                }), MuiHelpers_createElement(RadioGroup, [["row", true], (elements_1 = [OptionField(floorPlanAvailable, true, "Ja", "", false), OptionField(floorPlanAvailable, false, "Nee", "", false)], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])))), delay(() => {
                                    let elems_13;
                                    return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", [MarginTop.M])], (elems_13 = toList(delay(() => append(singleton(createElement("div", {
                                        className: join(" ", [Boldness.b500]),
                                        children: Interop_reactApi.Children.toArray(["Handtekening"]),
                                    })), delay(() => append((!signatureValid.State) ? singleton(createElement("div", {
                                        className: join(" ", [TextColor.Danger, Boldness.b500]),
                                        children: Interop_reactApi.Children.toArray(["Please place your signature"]),
                                    })) : empty(), delay(() => singleton(SignatureCanvas([["ref", signatureCanvasRef], new SignaturePadOptions(0, canvasProps)])))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_13))])])))), delay(() => {
                                        let elems_14;
                                        return singleton(createElement("div", createObj(ofArray([["className", join(" ", [MarginTop.S, PaddingBottom.M])], (elems_14 = [MuiHelpers_createElement(Button, [["variant", "contained"], ["color", "secondary"], ["size", "medium"], ["children", "Tekenen"], ["disabled", (disableInput.State ? true : alreadySigned) ? true : (!allowSigning)], ["onClick", (_arg_3) => {
                                            const matchValue_2 = signatureCanvasRef.current;
                                            if (matchValue_2 == null) {
                                                toConsole(printf("None"));
                                            }
                                            else {
                                                const signatureCanvas = matchValue_2;
                                                let patternInput_2;
                                                if (isLegalRepresentative.State) {
                                                    const matchValue = formLegalPerson.Validate();
                                                    if (matchValue == null) {
                                                        patternInput_2 = [void 0, false];
                                                    }
                                                    else {
                                                        const data = matchValue;
                                                        patternInput_2 = [new LegalRepresentativeData(data.LastName, data.FirstName, data.Address, data.Email, data.PhoneNumber), true];
                                                    }
                                                }
                                                else {
                                                    patternInput_2 = [void 0, true];
                                                }
                                                let patternInput_3;
                                                if (isVme.State) {
                                                    const matchValue_1 = formVme.Validate();
                                                    if (matchValue_1 == null) {
                                                        patternInput_3 = [void 0, false];
                                                    }
                                                    else {
                                                        const data_1 = matchValue_1;
                                                        patternInput_3 = [new SignatureVmeData(data_1.KboNumber, data_1.OrganisationName, data_1.LastName, data_1.FirstName, data_1.Address, data_1.Email, data_1.PhoneNumber), true];
                                                    }
                                                }
                                                else {
                                                    patternInput_3 = [void 0, true];
                                                }
                                                iterate((data_2) => {
                                                    const commonData_1 = new SignatureCommonData(data_2.HiddenAsbestos.Value, data_2.FloorPlanAvailable.Value);
                                                    if (patternInput_2[1] && patternInput_3[1]) {
                                                        if (signatureCanvas.isEmpty()) {
                                                            signatureValid.Off();
                                                        }
                                                        else {
                                                            signatureValid.On();
                                                            const signatureBase64 = signatureCanvas.getCanvas().toDataURL("image/png");
                                                            const code_1 = new SignatureCode(code);
                                                            startImmediate(singleton_2.Delay(() => {
                                                                disableInput.On();
                                                                return singleton_2.Bind(signatureApi.SaveSignature(code_1, commonData_1, patternInput_2[0], patternInput_3[0], signatureBase64), (_arg) => {
                                                                    const res = _arg;
                                                                    if (res.tag === 1) {
                                                                        disableInput.Off();
                                                                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, res.fields[0], SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                                                        return singleton_2.Zero();
                                                                    }
                                                                    else {
                                                                        disableInput.Off();
                                                                        setAlreadySigned(true);
                                                                        return singleton_2.Zero();
                                                                    }
                                                                });
                                                            }));
                                                        }
                                                    }
                                                }, toArray(formCommon.Validate()));
                                            }
                                        }]]), MuiHelpers_createElement(Button, [["variant", "contained"], ["color", "primary"], ["size", "medium"], ["children", "Wissen"], ["disabled", (disableInput.State ? true : alreadySigned) ? true : (!allowSigning)], ["className", join(" ", [MarginLeft.M])], ["onClick", (_arg_4) => {
                                            const matchValue_3 = signatureCanvasRef.current;
                                            if (matchValue_3 == null) {
                                                toConsole(printf("None"));
                                            }
                                            else {
                                                const signatureCanvas_3 = matchValue_3;
                                                signatureCanvas_3.clear();
                                            }
                                        }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_14))])]))));
                                    }));
                                }));
                            }));
                        })))) : singleton(createElement("div", createObj(ofArray([["className", join(" ", [Boldness.b600, TextSize.M])], (elems_15 = toList(delay(() => {
                            let signedDate;
                            const matchValue_4 = signature.SignedAt;
                            if (matchValue_4 == null) {
                                signedDate = "";
                            }
                            else {
                                let copyOfStruct = fromDateTimeOffset(matchValue_4, 0);
                                signedDate = toString(copyOfStruct, "dd/MM/yyyy HH:mm");
                            }
                            return singleton(`Document signed on ${signedDate}.`);
                        })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_15))])]))));
                    }))));
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])])))]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_17))])]))));
            })),
        })]),
    });
}

export function ClientDigitalSignature(clientDigitalSignatureInputProps) {
    const code = clientDigitalSignatureInputProps.code;
    const signatureApi = clientDigitalSignatureInputProps.signatureApi;
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setAlreadySigned = patternInput[1];
    const alreadySigned = patternInput[0];
    const DisplayMessage = (children) => createElement(Col, {
        classes: singleton_1(SignaturePageMessage),
        children: children,
    });
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(signatureApi.GetDigitalSignature(new SignatureCode(code)), [alreadySigned]);
    if (data.tag === 1) {
        return DisplayMessage(singleton_1(MuiHelpers_createElement(CircularProgress, [["color", "secondary"]])));
    }
    else if (data.tag === 2) {
        if (data.fields[0].tag === 1) {
            console.log(data.fields[0].fields[0]);
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, defaultErrorMessage, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            return null;
        }
        else {
            const signautreVmeData = data.fields[0].fields[0][1];
            const digitalSign = data.fields[0].fields[0][0];
            switch (digitalSign.tag) {
                case 1: {
                    return createElement(DisplayDocumentAndSignature, {
                        signatureApi: signatureApi,
                        code: code,
                        filePath: digitalSign.fields[0][0],
                        signature: digitalSign.fields[0][1],
                        allowSigning: true,
                        partnerInfo: digitalSign.fields[0][2],
                        alreadySigned: alreadySigned,
                        setAlreadySigned: setAlreadySigned,
                        signautreVmeData: signautreVmeData,
                    });
                }
                case 2: {
                    return DisplayMessage(singleton_1(createElement("h2", {
                        children: ["Signature code expired!"],
                    })));
                }
                case 3: {
                    return DisplayMessage(singleton_1(createElement("h2", {
                        children: ["Invalid signature code!"],
                    })));
                }
                default: {
                    return createElement(DisplayDocumentAndSignature, {
                        signatureApi: signatureApi,
                        code: code,
                        filePath: digitalSign.fields[0][0],
                        signature: digitalSign.fields[0][1],
                        allowSigning: false,
                        partnerInfo: void 0,
                        alreadySigned: alreadySigned,
                        setAlreadySigned: setAlreadySigned,
                        signautreVmeData: signautreVmeData,
                    });
                }
            }
        }
    }
    else if (data.tag === 3) {
        console.log(data.fields[0]);
        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, defaultErrorMessage, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
        return null;
    }
    else {
        return null;
    }
}

