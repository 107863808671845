import { Record } from "../fable_modules/fable-library.3.7.17/Types.js";
import { AdiId$reflection } from "../../../shared/Domain.fs.js";
import { record_type } from "../fable_modules/fable-library.3.7.17/Reflection.js";
import { useSnackbar } from "notistack";
import { useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { singleton, ofArray, map, toArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { CalendarProp$2_Selectable_Z1FBCCD16, CalendarProp$2_DayLayoutAlgorithm_Z3A793B44, CalendarProp$2, CalendarResource$1, CalendarEvent$1 } from "../lib/bindings/BigCalendar.fs.js";
import { List_groupBy } from "../fable_modules/fable-library.3.7.17/Seq2.js";
import { createObj, safeHash, equals } from "../fable_modules/fable-library.3.7.17/Util.js";
import { createElement } from "react";
import { Feliz_prop__prop_fss_Static_72C268A9 } from "../fable_modules/Fss-lib.Feliz.1.0.3/FssFeliz.fs.js";
import { unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B } from "../fable_modules/Fss-lib.Core.1.0.4/Types/Units.fs.js";
import { px } from "../fable_modules/Fss-lib.Core.1.0.4/Functions.fs.js";
import { Width, Height } from "../fable_modules/Fss-lib.Core.1.0.4/css/ContentSize.fs.js";
import { Scheduler } from "../components/Scheduler.fs.js";
import { setHours, setMinutes } from "date-fns";
import { now } from "../fable_modules/fable-library.3.7.17/Date.js";
import { append } from "../fable_modules/fable-library.3.7.17/Array.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { Page } from "../components/Page.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Paper from "@material-ui/core/Paper";

export class Appointment extends Record {
    constructor(AdiId) {
        super();
        this.AdiId = AdiId;
    }
}

export function Appointment$reflection() {
    return record_type("AppointmentCreate.Appointment", [], Appointment, () => [["AdiId", AdiId$reflection()]]);
}

export function AppointmentCalendar(appointmentCalendarInputProps) {
    let elems;
    const appointmentLengthInMinutes = appointmentCalendarInputProps.appointmentLengthInMinutes;
    const eventsData = appointmentCalendarInputProps.eventsData;
    const snackbar = useSnackbar();
    const events = useFeliz_React__React_useState_Static_1505(eventsData)[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505([]);
    const appointments = patternInput_1[0];
    const calenderEvents = useReact_useMemo_CF4EA67(() => toArray(map((e) => (new CalendarEvent$1(false, "", e.StartTime, e.EndTime, e.AdiId.Value, e.AdiId.Value)), events)), [events]);
    const resources = useReact_useMemo_CF4EA67(() => toArray(map((tupledArg) => {
        const adiId = tupledArg[0];
        return new CalendarResource$1(adiId.Value, `Adi ${adiId.Value}`, "");
    }, List_groupBy((x) => x.AdiId, events, {
        Equals: equals,
        GetHashCode: safeHash,
    }))), [events]);
    return createElement("div", createObj(ofArray([Feliz_prop__prop_fss_Static_72C268A9(ofArray([unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Height, px(750)), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Width, px(1500))])), (elems = [Scheduler([new CalendarProp$2(3, appointments), new CalendarProp$2(4, calenderEvents), new CalendarProp$2(28, true), new CalendarProp$2(24, 30), new CalendarProp$2(25, 2), new CalendarProp$2(29, setMinutes(setHours(now(), 7), 0)), new CalendarProp$2(30, setMinutes(setHours(now(), 22), 0)), CalendarProp$2_DayLayoutAlgorithm_Z3A793B44("no-overlap"), new CalendarProp$2(40, resources), new CalendarProp$2(41, (r) => r.resourceId), new CalendarProp$2(42, (r_1) => r_1.resourceTitle), new CalendarProp$2(9, (info) => {
        patternInput_1[1](append([new CalendarEvent$1(false, "Afspraak", info.start, info.end, info.resourceId, info.resourceId)], appointments));
    }), CalendarProp$2_Selectable_Z1FBCCD16(true), new CalendarProp$2(43, "week")])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function AppointmentCreate(appointmentCreateInputProps) {
    let children;
    const api = appointmentCreateInputProps.api;
    const currentPage = appointmentCreateInputProps.currentPage;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetAllAvailabilityCalendarEvents(), []);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Afspraak aanmaken",
        actions: [],
        children: [(children = singleton(createElement(DisplayDeferred, {
            data: data,
            view: (events) => createElement(AppointmentCalendar, {
                eventsData: events,
                appointmentLengthInMinutes: 120,
            }),
        })), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))],
    });
}

