import { useSnackbar } from "notistack";
import { useReact_useEffect_Z101E1A95, useReact_useButtonRef, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { createElement } from "react";
import { Page } from "../components/Page.fs.js";
import { CreateDossierStepperPage } from "../../../DesignSystems/dossier/DossierCreate.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { dossierDetail, customerDetail } from "../AppRoutes.fs.js";
import { printf, toConsole } from "../fable_modules/fable-library.3.7.17/String.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { DossierCreatePageSource } from "../../../DesignSystems/DossierUtils.fs.js";
import { Compensation_get_DefaultCompensation } from "../../../shared/Domain.fs.js";

export function DossierCreate(dossierCreateInputProps) {
    const api = dossierCreateInputProps.api;
    const currentPage = dossierCreateInputProps.currentPage;
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setCustomer = patternInput[1];
    const pageNumber = useFeliz_React__React_useState_Static_1505(0)[0] | 0;
    const pageSize = useFeliz_React__React_useState_Static_1505(2147483647)[0] | 0;
    const buttonRef = useReact_useButtonRef();
    const customers = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetCustomers(new PagingQuery(0, 2147483647), void 0), []);
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const partnerId = patternInput_4[0];
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(api.GetPartnersAndSyndics(new PagingQuery(pageNumber, pageSize), void 0), (_arg_1) => {
            const partners_1 = _arg_1;
            if (partners_1.tag === 1) {
                const err_1 = partners_1.fields[0];
                console.error(err_1);
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_1}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                return singleton.Zero();
            }
            else {
                patternInput_3[1](partners_1.fields[0].Data);
                return singleton.Zero();
            }
        })));
    }, [pageNumber, pageSize]);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton.Delay(() => {
            if (partnerId == null) {
                return singleton.Zero();
            }
            else {
                const partnerId_1 = partnerId;
                return singleton.Bind(api.GetCustomerOfPartner(partnerId_1), (_arg_2) => {
                    const customer_3 = _arg_2;
                    if (customer_3.tag === 1) {
                        const err_2 = customer_3.fields[0];
                        console.error(err_2);
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_2}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        return singleton.Zero();
                    }
                    else {
                        setCustomer(customer_3.fields[0]);
                        return singleton.Zero();
                    }
                });
            }
        }));
    }, [partnerId]);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Nieuw dossier aanmaken",
        actions: [],
        children: [createElement(CreateDossierStepperPage, {
            customerUrl: (arg_7) => Page$2__toUrl_2B594(customerDetail, arg_7),
            getCustomer: (customerCode) => {
                startImmediate(singleton.Delay(() => singleton.Bind(api.GetCustomer(customerCode), (_arg) => {
                    const customer_1 = _arg;
                    if (customer_1.tag === 1) {
                        const err = customer_1.fields[0];
                        console.error(err);
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        return singleton.Zero();
                    }
                    else {
                        setCustomer(customer_1.fields[0]);
                        return singleton.Zero();
                    }
                })));
            },
            customers: customers,
            onCreateDossier: (dossierData) => ((comment) => ((floorplan) => ((cadastralRecords) => ((propertyOther) => ((sendMailToPartner) => ((onCompleted) => {
                startImmediate(singleton.Delay(() => singleton.TryFinally(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
                    toConsole(printf("%A"))(dossierData);
                    return singleton.Bind(api.CreateDossier(dossierData, comment, sendMailToPartner), (_arg_3) => {
                        const result = _arg_3;
                        if (result.tag === 0) {
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een nieuw dossier is aangemaakt", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                            RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossierDetail, result.fields[0].Value)), 1, 1);
                            return singleton.Zero();
                        }
                        else {
                            const err_3 = result.fields[0];
                            console.error(err_3);
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_3}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                            return singleton.Zero();
                        }
                    });
                }), (_arg_4) => {
                    const e = _arg_4;
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    console.error(e);
                    return singleton.Zero();
                })), () => {
                    onCompleted();
                })));
            })))))),
            onCreateCustomer: (customerData) => ((onCompleted_1) => {
                startImmediate(singleton.Delay(() => singleton.TryFinally(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(api.CreateCustomer(customerData), (_arg_5) => {
                    const result_1 = _arg_5;
                    if (result_1.tag === 0) {
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een nieuwe klant is aangemaakt", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                        return singleton.Zero();
                    }
                    else {
                        const err_4 = result_1.fields[0];
                        console.error(err_4);
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_4}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        return singleton.Zero();
                    }
                })), (_arg_6) => {
                    const e_1 = _arg_6;
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e_1.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    console.error(e_1);
                    return singleton.Zero();
                })), () => {
                    onCompleted_1();
                })));
            }),
            customer: patternInput[0],
            setCustomer: setCustomer,
            partnersList: patternInput_3[0],
            setPartnerId: patternInput_4[1],
            dossierCreatePageSource: new DossierCreatePageSource(0),
            compensation: Compensation_get_DefaultCompensation(),
        })],
    });
}

