import { Auth0UserPrivileges, Auth0UserClaims__HasPrivilege_6B2F319B } from "../../../shared/Auth0Constants.fs.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { singleton, ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { quoteDetail, quoteCreate, dashboard } from "../AppRoutes.fs.js";
import { useReact_useMemo_CF4EA67, useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { searchTerms, initialState, update } from "../../../DesignSystems/quote/QuoteFilter.fs.js";
import { useDebouncedValue } from "../../../CustomHooks/CustomHooks.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Button from "@material-ui/core/Button";
import { join } from "../fable_modules/fable-library.3.7.17/String.js";
import { MarginLeft } from "../../../Styles/Utils.fs.js";
import { createElement } from "react";
import { Page } from "../components/Page.fs.js";
import { QuotesData } from "../../../DesignSystems/quote/QuoteDetails.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { downloadFile } from "./DownloadFile.fs.js";
import Paper from "@material-ui/core/Paper";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";

export function Quotes(quotesInputProps) {
    let children;
    const auth0User = quotesInputProps.auth0User;
    const api = quotesInputProps.api;
    const currentPage = quotesInputProps.currentPage;
    if (!Auth0UserClaims__HasPrivilege_6B2F319B(auth0User, new Auth0UserPrivileges(0))) {
        RouterModule_nav(ofArray(Page$2__toUrl_2B594(dashboard)), 1, 1);
        return null;
    }
    else {
        const patternInput = useReact_useReducer_2B9E6EA0(update, initialState);
        const state_1 = patternInput[0];
        const search = useDebouncedValue(500, useReact_useMemo_CF4EA67(() => searchTerms(state_1), [state_1]));
        const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetQuotesDetails(new PagingQuery(state_1.PageNumber, state_1.PageSize), search), [state_1.PageNumber, state_1.PageSize, search]);
        const startDossierButton = MuiHelpers_createElement(Button, [["onClick", (_arg_1) => {
            RouterModule_nav(ofArray(Page$2__toUrl_2B594(quoteCreate)), 1, 1);
        }], ["className", join(" ", [MarginLeft.M])], ["variant", "contained"], ["color", "secondary"], ["children", "Offerte VME Starten"]]);
        return createElement(Page, {
            currentPage: currentPage,
            title: "Offertes VME",
            actions: [startDossierButton],
            children: [(children = singleton(createElement(QuotesData, {
                data: data,
                state: state_1,
                dispatch: patternInput[1],
                url: (code) => Page$2__toUrl_2B594(quoteDetail, code.Value),
                showPartnerName: true,
                optionalExportCsvFunction: void 0,
                showCibFilter: true,
                showPartnerPortal: true,
                downloadLink: (filePath) => {
                    if (filePath == null) {
                        return null;
                    }
                    else {
                        const filePath_1 = filePath;
                        return createElement("a", {
                            target: "_blank",
                            href: "#",
                            onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                startImmediate(downloadFile(api, filePath_1));
                            },
                            children: "Offerte PDF",
                        });
                    }
                },
            })), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))],
        });
    }
}

