import { useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { useDebouncedValue } from "../../../CustomHooks/CustomHooks.fs.js";
import { defaultArg, map } from "../fable_modules/fable-library.3.7.17/Option.js";
import { PhoneNumber___UnsafeUnwrap_23E45174, NonEmptyString___UnsafeUnwrap_Z73AD07C, PartnerCode___UnsafeUnwrap_786B17C8, Compensation_get_DefaultCompensation, PartnerCode___UnsafeCreate_Z721C83C5 } from "../../../shared/Domain.fs.js";
import { PartnerSearchTerms } from "../../../shared/DomainHelpers.fs.js";
import { join, isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.17/String.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { createElement } from "react";
import * as react from "react";
import { Col } from "../../../Components/UtilComponents.fs.js";
import { alignEnd, cursorPointer, TableHeaderCell } from "../../../Styles/Utils.fs.js";
import { empty, ofArray, singleton } from "../fable_modules/fable-library.3.7.17/List.js";
import { CheckIcon, TableHeader, FilterField } from "../../../DesignSystems/DesignSystem.fs.js";
import TableCell from "@material-ui/core/TableCell";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { ToggleFilter } from "../../../DesignSystems/FilterUtils.fs.js";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { singleton as singleton_1, collect, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { partnersCreate, partnersDetail } from "../AppRoutes.fs.js";
import { Compensation_compensation, Email_email, Address_address } from "../../../shared/Format.fs.js";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";
import { Page } from "../components/Page.fs.js";
import Paper from "@material-ui/core/Paper";

export function PartnersData(client) {
    let elems_19, elems_18, children_8, children_7, children, children_1, children_2, children_3, children_4, children_5, children_6, children_17;
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const pageNumber = patternInput[0] | 0;
    const pageSize = useFeliz_React__React_useState_Static_1505(25)[0] | 0;
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermPartnerCode = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermCompanyName = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermAddress = patternInput_4[0];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermPhone = patternInput_5[0];
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermEmail = patternInput_6[0];
    const patternInput_7 = useFeliz_React__React_useState_Static_1505(void 0);
    const isCibPartner = patternInput_7[0];
    const search = useDebouncedValue(500, useReact_useMemo_CF4EA67(() => (new PartnerSearchTerms(map(PartnerCode___UnsafeCreate_Z721C83C5, searchTermPartnerCode), searchTermCompanyName, searchTermAddress, searchTermPhone, searchTermEmail, isCibPartner)), [searchTermPartnerCode, searchTermCompanyName, searchTermAddress, searchTermPhone, [searchTermEmail, isCibPartner]]));
    const updateTerm = (text, setTerm) => {
        setTerm(isNullOrWhiteSpace(text) ? (void 0) : text);
    };
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(client.GetPartners(new PagingQuery(pageNumber, pageSize), search), [pageNumber, pageSize, search]);
    const xs_2 = [MuiHelpers_createElement(TableContainer, [["component", "table"], (elems_19 = [MuiHelpers_createElement(Table, [["size", "small"], (elems_18 = [(children_8 = singleton((children_7 = ofArray([(children = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s_1) => {
            updateTerm(s_1, patternInput_2[1]);
        }, defaultArg(searchTermPartnerCode, "")), createElement(TableHeader, {
            text: "Code",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s_2) => {
            updateTerm(s_2, patternInput_3[1]);
        }, defaultArg(searchTermCompanyName, "")), createElement(TableHeader, {
            text: "Naam",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), (children_2 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([createElement(ToggleFilter, {
            label: "",
            value: isCibPartner,
            onChange: (b) => {
                patternInput_7[1](b);
            },
            labelLeft: "Ja",
            valueLeft: true,
            labelRight: "Nee",
            valueRight: false,
        }), createElement(TableHeader, {
            text: "CIB",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]])), (children_3 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s_3) => {
            updateTerm(s_3, patternInput_4[1]);
        }, defaultArg(searchTermAddress, "")), createElement(TableHeader, {
            text: "Adres",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]])), (children_4 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s_4) => {
            updateTerm(s_4, patternInput_5[1]);
        }, defaultArg(searchTermPhone, "")), createElement(TableHeader, {
            text: "Tel",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]])), (children_5 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s_5) => {
            updateTerm(s_5, patternInput_6[1]);
        }, defaultArg(searchTermEmail, "")), createElement(TableHeader, {
            text: "Email",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]])), (children_6 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: singleton(createElement(TableHeader, {
            text: "Vergoedingen",
        })),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_6))]]))]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_7))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_8))]])), (children_17 = singleton(createElement(DisplayDeferred, {
        data: data,
        view: (partners) => {
            const xs_1 = toList(delay(() => collect((partner) => {
                let elems_16, children_9, children_10, children_11, children_12, children_13, children_14, children_15;
                let compensation;
                const matchValue = partner.CompensationAmount;
                compensation = ((matchValue == null) ? Compensation_get_DefaultCompensation() : matchValue);
                return singleton_1(MuiHelpers_createElement(TableRow, [["className", join(" ", [cursorPointer])], ["hover", true], ["onClick", (_arg) => {
                    RouterModule_nav(ofArray(Page$2__toUrl_2B594(partnersDetail, partner.Id.Value)), 1, 1);
                }], ["key", partner.Id.Value], (elems_16 = [(children_9 = singleton(PartnerCode___UnsafeUnwrap_786B17C8(partner.Code)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_9))]])), (children_10 = singleton(NonEmptyString___UnsafeUnwrap_Z73AD07C(partner.CompanyName)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_10))]])), (children_11 = singleton(createElement(CheckIcon, {
                    props: [],
                    value: partner.IsCibPartner,
                })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_11))]])), (children_12 = singleton(Address_address(partner.Address)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_12))]])), (children_13 = singleton(PhoneNumber___UnsafeUnwrap_23E45174(partner.PhoneNumber)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_13))]])), (children_14 = singleton(Email_email(partner.Email)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_14))]])), (children_15 = singleton(Compensation_compensation(compensation)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_15))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])]));
            }, partners.Data)));
            return react.createElement(react.Fragment, {}, ...xs_1);
        },
    })), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_17))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_18))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_19))])]), createElement(Col, {
        classes: empty(),
        children: singleton(createElement(DisplayDeferred, {
            data: data,
            view: (partners_1) => {
                let values;
                return MuiHelpers_createElement(TablePagination, [["className", join(" ", [alignEnd])], ["rowsPerPage", pageSize], ["page", pageNumber], ["count", partners_1.TotalPages], ["onChangePage", (_arg_1, v) => {
                    patternInput[1](v);
                }], (values = (new Int32Array([])), ["rowsPerPageOptions", Array.from(values)]), ["onChangeRowsPerPage", (value_50) => {
                }]]);
            },
        })),
    })];
    return react.createElement(react.Fragment, {}, ...xs_2);
}

export function Partners(partnersInputProps) {
    let props, children_1;
    const api = partnersInputProps.api;
    const currentPage = partnersInputProps.currentPage;
    const addNewCustomerButton = MuiHelpers_createElement(Button, [["onClick", (_arg) => {
        RouterModule_nav(ofArray(Page$2__toUrl_2B594(partnersCreate)), 1, 1);
    }], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props = {}, react.createElement(Add, props))], ["children", "Partner aanmaken"]]);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Partner",
        actions: [addNewCustomerButton],
        children: [(children_1 = singleton(createElement(PartnersData, api)), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))],
    });
}

