import { createElement } from "react";
import * as react from "react";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { downloadFile } from "./DownloadFile.fs.js";
import { useSnackbar } from "notistack";
import { useDebouncedValue, useToggle } from "../../../CustomHooks/CustomHooks.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Modal from "@material-ui/core/Modal";
import { equals, createObj } from "../fable_modules/fable-library.3.7.17/Util.js";
import { printf, toConsole, isNullOrWhiteSpace, join } from "../fable_modules/fable-library.3.7.17/String.js";
import { allAppointmentsMinHeight, TableHeaderCell, cursorPointer, MarginTop, MarginLeft, MarginBottom, PaddingRight, PaddingLeft, PaddingBottom, PaddingTop, modal } from "../../../Styles/Utils.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import Button from "@material-ui/core/Button";
import { singleton } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { nonSeeded } from "../fable_modules/fable-library.3.7.17/Random.js";
import { length, map as map_1, toArray, isEmpty, singleton as singleton_1, empty, ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { useReact_useMemo_CF4EA67, useReact_useElementRef, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { Col, Row } from "../../../Components/UtilComponents.fs.js";
import { Browser_Types_File__File_ReadAsByteArray } from "../fable_modules/Fable.Remoting.Client.7.25.0/Extensions.fs.js";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TableCell from "@material-ui/core/TableCell";
import { RouterModule_nav, RouterModule_encodeParts } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { appointmentConfirm, appointmentDetail, digitalSignaturePage } from "../AppRoutes.fs.js";
import { MultiSelect, FilterField, TableHeader, AppointmentStatusChip, CheckIcon } from "../../../DesignSystems/DesignSystem.fs.js";
import { DossierCode, AppointmentStatus, AppointmentStatus_get_list, AppointmentListType, PhoneNumber___UnsafeUnwrap_23E45174, Customer2__get_PhoneNumber, NonEmptyString___UnsafeUnwrap_Z73AD07C } from "../../../shared/Domain.fs.js";
import TableRow from "@material-ui/core/TableRow";
import { map as map_3, empty as empty_1, singleton as singleton_2, append, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { Appointment_parseStatus, Appointment_status, Address_address, Customer2_name, GeneralFormats_dateTimeStamp } from "../../../shared/Format.fs.js";
import { day, month, year, hour, now, equals as equals_1, dayOfWeek, addDays, date as date_3, utcNow, fromDateTimeOffset } from "../fable_modules/fable-library.3.7.17/Date.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.17/Option.js";
import TextField from "@material-ui/core/TextField";
import { ToggleFilter, FilterFieldDateRange } from "../../../DesignSystems/FilterUtils.fs.js";
import TableHead from "@material-ui/core/TableHead";
import { getDayStart, getDayEnd } from "../../../DesignSystems/Utils.fs.js";
import { create, addDays as addDays_1, utcNow as utcNow_1, date as date_4 } from "../fable_modules/fable-library.3.7.17/DateOffset.js";
import { fromTicks } from "../fable_modules/fable-library.3.7.17/TimeSpan.js";
import { fromInteger } from "../fable_modules/fable-library.3.7.17/Long.js";
import { map as map_2, choose } from "../fable_modules/fable-library.3.7.17/Array.js";
import { AppointmentSearchTerms } from "../../../shared/DomainHelpers.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import { Page } from "../components/Page.fs.js";
import { Auth0UserPrivileges, Auth0UserClaims__HasPrivilege_6B2F319B } from "../../../shared/Auth0Constants.fs.js";

export function DownloadLink(api, filePath) {
    if (filePath == null) {
        return null;
    }
    else {
        const filePath_1 = filePath;
        return createElement("a", {
            target: "_blank",
            href: "#",
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                startImmediate(downloadFile(api, filePath_1));
            },
            children: "Download",
        });
    }
}

export function ConfirmMailedSamplesModal(confirmMailedSamplesModalInputProps) {
    let elems_1;
    const setPageRefresh = confirmMailedSamplesModalInputProps.setPageRefresh;
    const appointmentConfirmationId = confirmMailedSamplesModalInputProps.appointmentConfirmationId;
    const setShowConfirmModal = confirmMailedSamplesModalInputProps.setShowConfirmModal;
    const showConfirmationModal = confirmMailedSamplesModalInputProps.showConfirmationModal;
    const api = confirmMailedSamplesModalInputProps.api;
    const snackbar = useSnackbar();
    const buttonsDisabled = useToggle(false);
    return MuiHelpers_createElement(Modal, [["open", showConfirmationModal], ["onClose", (_arg_3, v) => {
        setShowConfirmModal(false);
    }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.L, PaddingBottom.L, PaddingLeft.Xxl, PaddingRight.Xxl])], (elems_1 = [createElement("div", {
        className: join(" ", [MarginBottom.M]),
        children: Interop_reactApi.Children.toArray(["Bevestig monsters?"]),
    }), MuiHelpers_createElement(Button, [["onClick", (_arg_4) => {
        startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
            if (appointmentConfirmationId == null) {
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Geen geldige afspraak bevestiging gevonden", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                return singleton.Zero();
            }
            else {
                const appointmentConfirmationId_1 = appointmentConfirmationId;
                buttonsDisabled.Toggle();
                return singleton.Bind(api.ConfirmMailedSamples(appointmentConfirmationId_1), (_arg) => {
                    const result = _arg;
                    if (result.tag === 0) {
                        buttonsDisabled.Off();
                        setShowConfirmModal(false);
                        setPageRefresh(nonSeeded().Next0());
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Monsters bevestigd", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                        return singleton.Zero();
                    }
                    else {
                        const err = result.fields[0];
                        buttonsDisabled.Off();
                        setShowConfirmModal(false);
                        console.error(err);
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        return singleton.Zero();
                    }
                });
            }
        }), (_arg_1) => {
            const e = _arg_1;
            setShowConfirmModal(false);
            console.error(e);
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            return singleton.Zero();
        })));
    }], ["className", join(" ", [])], ["variant", "contained"], ["color", "secondary"], ["disabled", buttonsDisabled.State], ["children", "Ja"]]), MuiHelpers_createElement(Button, [["onClick", (_arg_5) => {
        setShowConfirmModal(false);
    }], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "primary"], ["disabled", buttonsDisabled.State], ["children", "Nee"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]]);
}

export function UploadDocumentModal(uploadDocumentModalInputProps) {
    let elems_1, elems;
    const setPageRefresh = uploadDocumentModalInputProps.setPageRefresh;
    const buttonsDisabled = uploadDocumentModalInputProps.buttonsDisabled;
    const setShowUploadDocumentModal = uploadDocumentModalInputProps.setShowUploadDocumentModal;
    const showUploadDocumentModal = uploadDocumentModalInputProps.showUploadDocumentModal;
    const appointmentDetails = uploadDocumentModalInputProps.appointmentDetails;
    const api = uploadDocumentModalInputProps.api;
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const file = patternInput[0];
    return MuiHelpers_createElement(Modal, [["open", showUploadDocumentModal], ["onClose", (_arg_5, v) => {
        setShowUploadDocumentModal(false);
    }], ["onClick", (e_2) => {
        e_2.stopPropagation();
    }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.M, PaddingBottom.L, PaddingLeft.L, PaddingRight.L])], ["onClick", (e_3) => {
        e_3.stopPropagation();
    }], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", [MarginBottom.M])], (elems = [createElement("h3", {
        children: ["Upload Assignment Confirmation"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement(Row, {
        classes: empty(),
        children: singleton_1(createElement("input", {
            type: "file",
            onClick: (e_4) => {
                e_4.stopPropagation();
            },
            onChange: (ev) => {
                const files = ev.target.files;
                if ((!(files == null)) && (files.length > 0)) {
                    patternInput[1](files.item(0));
                }
            },
        })),
    }), createElement(Row, {
        classes: singleton_1(MarginTop.L),
        children: ofArray([MuiHelpers_createElement(Button, [["onClick", (e_5) => {
            e_5.stopPropagation();
            startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
                let buttonsDisabled_1, filename;
                if (file == null) {
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Please select a file!", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    return singleton.Zero();
                }
                else {
                    const file_2 = file;
                    startImmediate((buttonsDisabled_1 = buttonsDisabled, (filename = file_2.name, singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
                        buttonsDisabled_1.On();
                        return singleton.Bind(Browser_Types_File__File_ReadAsByteArray(file_2), (_arg) => singleton.Bind(api.UploadPhysicallySigned_AssigmentConfirmation(appointmentDetails.Dossier.Code, _arg, filename), (_arg_1) => {
                            const output = _arg_1;
                            if (output.tag === 1) {
                                buttonsDisabled_1.Off();
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${output.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                return singleton.Zero();
                            }
                            else {
                                buttonsDisabled_1.Off();
                                setShowUploadDocumentModal(false);
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Bestand opgeladen", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                setPageRefresh(nonSeeded().Next0());
                                return singleton.Zero();
                            }
                        }));
                    }), (_arg_2) => {
                        const e = _arg_2;
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        console.log(e);
                        return singleton.Zero();
                    })))));
                    return singleton.Zero();
                }
            }), (_arg_3) => {
                const e_1 = _arg_3;
                setShowUploadDocumentModal(false);
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden tijdens het bevestigen van de afspraak. Gelieve support te contacteren. [${e_1.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                console.log(e_1);
                return singleton.Zero();
            })));
        }], ["className", join(" ", [])], ["disabled", buttonsDisabled.State ? true : (file == null)], ["variant", "contained"], ["color", "secondary"], ["children", "Upload"]]), MuiHelpers_createElement(Button, [["onClick", (e_6) => {
            e_6.stopPropagation();
            setShowUploadDocumentModal(false);
        }], ["disabled", buttonsDisabled.State], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "primary"], ["children", "Cancel"]])]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]]);
}

export function PopupMenu(popupMenuInputProps) {
    let elems, props_1, elems_1;
    const setPageRefresh = popupMenuInputProps.setPageRefresh;
    const details = popupMenuInputProps.details;
    const api = popupMenuInputProps.api;
    const menuState = useToggle(false);
    const buttonsDisabled = useToggle(false);
    const anchorEl = useReact_useElementRef();
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setShowUploadDocumentModal = patternInput[1];
    const matchValue = details.Dossier.AssignmentConfirmationPhysicallySigned;
    if (matchValue == null) {
        const children_1 = ofArray([MuiHelpers_createElement(IconButton, [["onClick", (e) => {
            e.stopPropagation();
            menuState.On();
        }], ["ref", anchorEl], (elems = [(props_1 = {}, react.createElement(MoreVert, props_1))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), MuiHelpers_createElement(Menu, [["open", menuState.State], ["onClose", (_arg, v) => {
        }], ["anchorEl", () => anchorEl.current], ["keepMounted", true], ["children", createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [MuiHelpers_createElement(MenuItem, [["children", "Upload Assignment Confirmation Manually"], ["value", "Upload"], ["onClick", (e_2) => {
            e_2.preventDefault();
            e_2.stopPropagation();
            menuState.Off();
            setShowUploadDocumentModal(true);
        }]]), createElement(UploadDocumentModal, {
            api: api,
            appointmentDetails: details,
            showUploadDocumentModal: patternInput[0],
            setShowUploadDocumentModal: setShowUploadDocumentModal,
            buttonsDisabled: buttonsDisabled,
            setPageRefresh: setPageRefresh,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]])]);
        return MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]);
    }
    else {
        const filePath = matchValue;
        return MuiHelpers_createElement(TableCell, []);
    }
}

export function AppointmentRow(details, api, listType, setPageRefresh, setSelectedAppointmentConfirmationId, setShowConfirmMailedSamplesModal) {
    let elems_16;
    const snackbar = useSnackbar();
    const signatureButton = (apt) => {
        const matchValue = apt.Dossier.AssignmentConfirmationPhysicallySigned;
        if (matchValue == null) {
            const matchValue_1 = apt.Dossier.AssignmentConfirmationDigitalSignature;
            if (matchValue_1 == null) {
                return MuiHelpers_createElement(TableCell, []);
            }
            else {
                const digitalSignature = matchValue_1;
                const url_1 = RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(digitalSignaturePage, digitalSignature.SignatureCode.Value)), 1);
                const children_1 = singleton_1(MuiHelpers_createElement(Button, [["onClick", (e_1) => {
                    e_1.stopPropagation();
                    startImmediate(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(api.ValidateSignatureCode(digitalSignature.SignatureCode), (_arg) => {
                        const validateResult = _arg;
                        if (validateResult.tag === 1) {
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${validateResult.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                            return singleton.Zero();
                        }
                        else {
                            window.open(url_1, "_blank");
                            return singleton.Zero();
                        }
                    })), (_arg_1) => {
                        const e = _arg_1;
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        console.log(e);
                        return singleton.Zero();
                    })));
                }], ["variant", "contained"], ["color", "default"], ["children", "Tekenen"]]));
                return MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]);
            }
        }
        else {
            const filePath = matchValue;
            const children = singleton_1(createElement(CheckIcon, {
                props: [],
                value: true,
            }));
            return MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
        }
    };
    let adiName;
    const firstName = NonEmptyString___UnsafeUnwrap_Z73AD07C(details.AdiFirstName);
    const lastName = NonEmptyString___UnsafeUnwrap_Z73AD07C(details.AdiLastName);
    adiName = (`${firstName} ${lastName}`);
    return MuiHelpers_createElement(TableRow, [["className", join(" ", [cursorPointer])], ["hover", true], ["onClick", (_arg_2) => {
        RouterModule_nav(ofArray(Page$2__toUrl_2B594(appointmentDetail, details.Appointment.Id.Value)), 1, 1);
    }], ["key", details.Appointment.Id.Value], (elems_16 = toList(delay(() => {
        let children_2;
        return append(singleton_2((children_2 = singleton_1(GeneralFormats_dateTimeStamp(fromDateTimeOffset(details.Appointment.StartTime, 0))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))), delay(() => append(singleton_2(MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([details.Dossier.Code.Value])]])), delay(() => {
            let children_4;
            return append(singleton_2((children_4 = singleton_1(createElement(AppointmentStatusChip, {
                status: details.Appointment.Status,
            })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))), delay(() => {
                let children_5, value_20;
                return append(singleton_2((children_5 = singleton_1((value_20 = (!isEmpty(details.Comments)), createElement(CheckIcon, {
                    props: [],
                    value: value_20,
                }))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]]))), delay(() => {
                    let children_6, value_23;
                    return append((listType.tag === 3) ? append(singleton_2((children_6 = singleton_1((value_23 = defaultArg(map((ac) => ac.AreSampelsUploaded, details.AppointmentConfirmation), false), createElement(CheckIcon, {
                        props: [],
                        value: value_23,
                    }))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_6))]]))), delay(() => {
                        let children_7, value_26;
                        return singleton_2((children_7 = singleton_1((value_26 = defaultArg(map((_arg_3) => true, details.Dossier.AssignmentConfirmationPhysicallySigned), false), createElement(CheckIcon, {
                            props: [],
                            value: value_26,
                        }))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_7))]])));
                    })) : ((empty_1())), delay(() => append((listType.tag === 3) ? singleton_2(MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([adiName])]])) : ((empty_1())), delay(() => {
                        let children_9;
                        return append(singleton_2((children_9 = singleton_1(Customer2_name(details.Customer)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_9))]]))), delay(() => {
                            let children_10;
                            return append(singleton_2((children_10 = singleton_1(Address_address(details.Dossier.Address)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_10))]]))), delay(() => {
                                let children_11;
                                return append(singleton_2((children_11 = toList(delay(() => {
                                    const matchValue_4 = Customer2__get_PhoneNumber(details.Customer);
                                    return (matchValue_4 == null) ? singleton_2("-") : singleton_2(PhoneNumber___UnsafeUnwrap_23E45174(matchValue_4));
                                })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_11))]]))), delay(() => {
                                    let matchValue_5, children_12, matchValue_6, ac_2, children_13, children_14;
                                    return append((matchValue_5 = listType, (matchValue_5.tag === 0) ? singleton_2((children_12 = singleton_1(MuiHelpers_createElement(Button, [["onClick", (e_2) => {
                                        e_2.preventDefault();
                                        e_2.stopPropagation();
                                        RouterModule_nav(ofArray(Page$2__toUrl_2B594(appointmentConfirm, details.Appointment.Id.Value)), 1, 1);
                                    }], ["variant", "contained"], ["color", "default"], ["children", "Bevestig plaatsbezoek"]])), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_12))]]))) : ((matchValue_5.tag === 2) ? ((matchValue_6 = details.AppointmentConfirmation, (matchValue_6 != null) ? ((matchValue_6.AreSampelsUploaded === true) ? ((ac_2 = matchValue_6, singleton_2((children_13 = singleton_1(createElement(CheckIcon, {
                                        props: [],
                                        value: true,
                                    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_13))]]))))) : singleton_2((children_14 = singleton_1(MuiHelpers_createElement(Button, [["onClick", (e_3) => {
                                        e_3.preventDefault();
                                        e_3.stopPropagation();
                                        setSelectedAppointmentConfirmationId(map((ac_3) => ac_3.Id, details.AppointmentConfirmation));
                                        setShowConfirmMailedSamplesModal(true);
                                    }], ["variant", "contained"], ["color", "default"], ["children", "Bevestig monsters"]])), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_14))]])))) : singleton_2((children_14 = singleton_1(MuiHelpers_createElement(Button, [["onClick", (e_3) => {
                                        e_3.preventDefault();
                                        e_3.stopPropagation();
                                        setSelectedAppointmentConfirmationId(map((ac_3) => ac_3.Id, details.AppointmentConfirmation));
                                        setShowConfirmMailedSamplesModal(true);
                                    }], ["variant", "contained"], ["color", "default"], ["children", "Bevestig monsters"]])), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_14))]]))))) : ((empty_1())))), delay(() => {
                                        let children_15, matchValue_8;
                                        const matchValue_7 = listType;
                                        switch (matchValue_7.tag) {
                                            case 1:
                                            case 0: {
                                                return append(singleton_2((children_15 = singleton_1(DownloadLink(api, (matchValue_8 = details.Dossier.AssignmentConfirmationPhysicallySigned, (matchValue_8 == null) ? details.Dossier.AssigmentConfirmation : matchValue_8))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_15))]]))), delay(() => (equals(listType, new AppointmentListType(0)) ? singleton_2(signatureButton(details)) : empty_1())));
                                            }
                                            case 2: {
                                                return append(singleton_2(signatureButton(details)), delay(() => singleton_2(createElement(PopupMenu, {
                                                    api: api,
                                                    details: details,
                                                    setPageRefresh: setPageRefresh,
                                                }))));
                                            }
                                            default: {
                                                return empty_1();
                                            }
                                        }
                                    }));
                                }));
                            }));
                        }));
                    }))));
                }));
            }));
        }))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_16))])]);
}

export function updateTerm(text, setTerm) {
    setTerm(isNullOrWhiteSpace(text) ? (void 0) : text);
}

export function TableHeaders(tableHeadersInputProps) {
    let children_16;
    const setFilterAssignmentConfirmationUploaded = tableHeadersInputProps.setFilterAssignmentConfirmationUploaded;
    const filterAssignmentConfirmationUploaded = tableHeadersInputProps.filterAssignmentConfirmationUploaded;
    const setFilterSamplesUploaded = tableHeadersInputProps.setFilterSamplesUploaded;
    const filterSamplesUploaded = tableHeadersInputProps.filterSamplesUploaded;
    const setStatuses = tableHeadersInputProps.setStatuses;
    const statuses = tableHeadersInputProps.statuses;
    const setSearchTermTel = tableHeadersInputProps.setSearchTermTel;
    const searchTermTel = tableHeadersInputProps.searchTermTel;
    const setSearchTermAdres = tableHeadersInputProps.setSearchTermAdres;
    const searchTermAdres = tableHeadersInputProps.searchTermAdres;
    const setSearchTermCustomerNaam = tableHeadersInputProps.setSearchTermCustomerNaam;
    const searchTermCustomerNaam = tableHeadersInputProps.searchTermCustomerNaam;
    const setSearchTermAdiNaam = tableHeadersInputProps.setSearchTermAdiNaam;
    const searchTermAdiNaam = tableHeadersInputProps.searchTermAdiNaam;
    const saveDossierId = tableHeadersInputProps.saveDossierId;
    const searchTermDossierNummer = tableHeadersInputProps.searchTermDossierNummer;
    const setDateRange = tableHeadersInputProps.setDateRange;
    const dateRange = tableHeadersInputProps.dateRange;
    const listType = tableHeadersInputProps.listType;
    const showFilters = (listType.tag === 1) ? true : (listType.tag === 3);
    const children_17 = singleton_1((children_16 = toList(delay(() => {
        let children;
        return append(singleton_2((children = toList(delay(() => ((listType.tag === 3) ? singleton_2(createElement(Col, {
            classes: singleton_1(TableHeaderCell),
            children: ofArray([MuiHelpers_createElement(TextField, [["style", {
                visibility: "hidden",
            }], ["label", "Datum en tijd"]]), createElement(TableHeader, {
                text: "Datum en tijd",
            })]),
        })) : ((listType.tag === 1) ? singleton_2(createElement(Col, {
            classes: singleton_1(TableHeaderCell),
            children: ofArray([FilterFieldDateRange("Filter", (s) => {
                toConsole(printf("daterange set %A"))(s);
                setDateRange(s);
            }, dateRange), createElement(TableHeader, {
                text: "Datum en tijd",
            })]),
        })) : singleton_2(createElement(TableHeader, {
            text: "Datum en tijd",
        })))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))), delay(() => {
            let children_1;
            return append(singleton_2((children_1 = toList(delay(() => (showFilters ? singleton_2(createElement(Col, {
                classes: singleton_1(TableHeaderCell),
                children: ofArray([FilterField("Filter", (s_1) => {
                    saveDossierId(s_1);
                }, defaultArg(map((d) => d.Value, searchTermDossierNummer), "")), createElement(TableHeader, {
                    text: "Dossier nummer",
                })]),
            })) : singleton_2(createElement(TableHeader, {
                text: "Dossier nummer",
            }))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))), delay(() => {
                let children_2;
                return append(singleton_2((children_2 = toList(delay(() => (showFilters ? singleton_2(createElement(Col, {
                    classes: singleton_1(TableHeaderCell),
                    children: ofArray([createElement(MultiSelect, {
                        label: "Filter",
                        items: toArray(map_1(Appointment_status, AppointmentStatus_get_list())),
                        v: statuses,
                        onChange: setStatuses,
                    }), createElement(TableHeader, {
                        text: "Status",
                    })]),
                })) : singleton_2(createElement(TableHeader, {
                    text: "Status",
                }))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))), delay(() => {
                    let children_3;
                    return append(singleton_2((children_3 = toList(delay(() => (showFilters ? singleton_2(createElement(Col, {
                        classes: singleton_1(TableHeaderCell),
                        children: ofArray([createElement("div", {}), createElement(TableHeader, {
                            text: "Opmerkingen?",
                        })]),
                    })) : singleton_2(createElement(TableHeader, {
                        text: "Opmerkingen?",
                    }))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))), delay(() => {
                        let children_4;
                        return append((listType.tag === 3) ? append(singleton_2((children_4 = toList(delay(() => (showFilters ? singleton_2(createElement(Col, {
                            classes: singleton_1(TableHeaderCell),
                            children: ofArray([createElement(Row, {
                                classes: empty(),
                                children: singleton_1(createElement(ToggleFilter, {
                                    label: "",
                                    value: filterSamplesUploaded,
                                    onChange: setFilterSamplesUploaded,
                                    labelLeft: "Ja",
                                    valueLeft: true,
                                    labelRight: "Nee",
                                    valueRight: false,
                                })),
                            }), createElement(TableHeader, {
                                text: "Monsters opgeladen",
                            })]),
                        })) : singleton_2(createElement(TableHeader, {
                            text: "Monsters opgeladen",
                        }))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))), delay(() => {
                            let children_5;
                            return singleton_2((children_5 = toList(delay(() => (showFilters ? singleton_2(createElement(Col, {
                                classes: singleton_1(TableHeaderCell),
                                children: ofArray([createElement(Row, {
                                    classes: empty(),
                                    children: singleton_1(createElement(ToggleFilter, {
                                        label: "",
                                        value: filterAssignmentConfirmationUploaded,
                                        onChange: setFilterAssignmentConfirmationUploaded,
                                        labelLeft: "Ja",
                                        valueLeft: true,
                                        labelRight: "Nee",
                                        valueRight: false,
                                    })),
                                }), createElement(TableHeader, {
                                    text: "Opdrachtformulier opgeladen",
                                })]),
                            })) : singleton_2(createElement(TableHeader, {
                                text: "Opdrachtformulier opgeladen",
                            }))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]])));
                        })) : ((empty_1())), delay(() => {
                            let children_6;
                            return append(showFilters ? ((listType.tag === 3) ? singleton_2((children_6 = singleton_1(createElement(Col, {
                                classes: singleton_1(TableHeaderCell),
                                children: ofArray([FilterField("Filter", (s_2) => {
                                    updateTerm(s_2, setSearchTermAdiNaam);
                                }, defaultArg(searchTermAdiNaam, "")), createElement(TableHeader, {
                                    text: "ADI",
                                })]),
                            })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_6))]]))) : ((empty_1()))) : ((empty_1())), delay(() => {
                                let children_7;
                                return append(singleton_2((children_7 = toList(delay(() => (showFilters ? singleton_2(createElement(Col, {
                                    classes: singleton_1(TableHeaderCell),
                                    children: ofArray([FilterField("Filter", (s_3) => {
                                        updateTerm(s_3, setSearchTermCustomerNaam);
                                    }, defaultArg(searchTermCustomerNaam, "")), createElement(TableHeader, {
                                        text: "Naam",
                                    })]),
                                })) : singleton_2(createElement(TableHeader, {
                                    text: "Naam",
                                }))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_7))]]))), delay(() => {
                                    let children_8;
                                    return append(singleton_2((children_8 = toList(delay(() => (showFilters ? singleton_2(createElement(Col, {
                                        classes: singleton_1(TableHeaderCell),
                                        children: ofArray([FilterField("Filter", (s_4) => {
                                            updateTerm(s_4, setSearchTermAdres);
                                        }, defaultArg(searchTermAdres, "")), createElement(TableHeader, {
                                            text: "Adres",
                                        })]),
                                    })) : singleton_2(createElement(TableHeader, {
                                        text: "Adres",
                                    }))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_8))]]))), delay(() => {
                                        let children_9;
                                        return append(singleton_2((children_9 = toList(delay(() => (showFilters ? singleton_2(createElement(Col, {
                                            classes: singleton_1(TableHeaderCell),
                                            children: ofArray([FilterField("Filter", (s_5) => {
                                                updateTerm(s_5, setSearchTermTel);
                                            }, defaultArg(searchTermTel, "")), createElement(TableHeader, {
                                                text: "Tel",
                                            })]),
                                        })) : singleton_2(createElement(TableHeader, {
                                            text: "Tel",
                                        }))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_9))]]))), delay(() => {
                                            let matchValue_2, children_10, children_11;
                                            return append((matchValue_2 = listType, (matchValue_2.tag === 0) ? singleton_2((children_10 = toList(delay(() => (showFilters ? singleton_2(createElement(Col, {
                                                classes: singleton_1(TableHeaderCell),
                                                children: ofArray([createElement("div", {}), createElement(TableHeader, {
                                                    text: "Bevestig Plaatsbezoek",
                                                })]),
                                            })) : singleton_2(createElement(TableHeader, {
                                                text: "Bevestig Plaatsbezoek",
                                            }))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_10))]]))) : ((matchValue_2.tag === 2) ? singleton_2((children_11 = toList(delay(() => (showFilters ? singleton_2(createElement(Col, {
                                                classes: singleton_1(TableHeaderCell),
                                                children: ofArray([createElement("div", {}), createElement(TableHeader, {
                                                    text: "Bevestig Monsters",
                                                })]),
                                            })) : singleton_2(createElement(TableHeader, {
                                                text: "Bevestig Monsters",
                                            }))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_11))]]))) : ((empty_1())))), delay(() => {
                                                let matchValue_3, children_12, children_13;
                                                return append((matchValue_3 = listType, (matchValue_3.tag === 1) ? singleton_2((children_12 = toList(delay(() => (showFilters ? singleton_2(createElement(Col, {
                                                    classes: singleton_1(TableHeaderCell),
                                                    children: ofArray([createElement("div", {}), createElement(TableHeader, {
                                                        text: "Opdrachtformulier",
                                                    })]),
                                                })) : singleton_2(createElement(TableHeader, {
                                                    text: "Opdrachtformulier",
                                                }))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_12))]]))) : ((matchValue_3.tag === 0) ? singleton_2((children_12 = toList(delay(() => (showFilters ? singleton_2(createElement(Col, {
                                                    classes: singleton_1(TableHeaderCell),
                                                    children: ofArray([createElement("div", {}), createElement(TableHeader, {
                                                        text: "Opdrachtformulier",
                                                    })]),
                                                })) : singleton_2(createElement(TableHeader, {
                                                    text: "Opdrachtformulier",
                                                }))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_12))]]))) : ((matchValue_3.tag === 2) ? singleton_2((children_13 = toList(delay(() => (showFilters ? singleton_2(createElement(Col, {
                                                    classes: singleton_1(TableHeaderCell),
                                                    children: ofArray([createElement("div", {}), createElement(TableHeader, {
                                                        text: "Opdrachtformulier",
                                                    })]),
                                                })) : singleton_2(createElement(TableHeader, {
                                                    text: "Opdrachtformulier",
                                                }))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_13))]]))) : ((empty_1()))))), delay(() => {
                                                    let children_14, children_15;
                                                    const matchValue_4 = listType;
                                                    switch (matchValue_4.tag) {
                                                        case 0: {
                                                            return singleton_2((children_14 = toList(delay(() => (showFilters ? singleton_2(createElement(Col, {
                                                                classes: singleton_1(TableHeaderCell),
                                                                children: ofArray([createElement("div", {}), createElement(TableHeader, {
                                                                    text: "Digitaal Tekenen",
                                                                })]),
                                                            })) : singleton_2(createElement(TableHeader, {
                                                                text: "Digitaal Tekenen",
                                                            }))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_14))]])));
                                                        }
                                                        case 2: {
                                                            return singleton_2((children_15 = toList(delay(() => (showFilters ? singleton_2(createElement(Col, {
                                                                classes: singleton_1(TableHeaderCell),
                                                                children: ofArray([createElement("div", {}), createElement(TableHeader, {
                                                                    text: "",
                                                                })]),
                                                            })) : singleton_2(createElement(TableHeader, {
                                                                text: "",
                                                            }))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_15))]])));
                                                        }
                                                        default: {
                                                            return empty_1();
                                                        }
                                                    }
                                                }));
                                            }));
                                        }));
                                    }));
                                }));
                            }));
                        }));
                    }));
                }));
            }));
        }));
    })), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_16))]])));
    return MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_17))]]);
}

export function AppointmentsTable(appointmentsTableInputProps) {
    let copyOfStruct_5, copyOfStruct_3, copyOfStruct_2, matchValue, copyOfStruct_4, copyOfStruct_7, copyOfStruct_8, copyOfStruct_10, copyOfStruct_9, copyOfStruct_11, copyOfStruct_12, copyOfStruct_6, date_1, copyOfStruct_1, date_2, children_2, elems_2, elems_1, children_1;
    const listType = appointmentsTableInputProps.listType;
    const api = appointmentsTableInputProps.api;
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const setPageRefresh = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    const pageNumber = patternInput_1[0] | 0;
    const pageSize = useFeliz_React__React_useState_Static_1505(25)[0] | 0;
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setShowConfirmMailedSamplesModal = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(void 0);
    const setSearchTermDossierNummer = patternInput_5[1];
    const searchTermDossierNummer = patternInput_5[0];
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermAdiNaam = patternInput_6[0];
    const patternInput_7 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermCustomerNaam = patternInput_7[0];
    const patternInput_8 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermAdres = patternInput_8[0];
    const patternInput_9 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermTel = patternInput_9[0];
    const patternInput_10 = useFeliz_React__React_useState_Static_1505([]);
    const statuses = patternInput_10[0];
    const patternInput_11 = useFeliz_React__React_useState_Static_1505(void 0);
    const filterSamplesUploaded = patternInput_11[0];
    const patternInput_12 = useFeliz_React__React_useState_Static_1505(void 0);
    const filterAssignmentConfirmationUploaded = patternInput_12[0];
    const patternInput_13 = useFeliz_React__React_useState_Static_1505(void 0);
    let today;
    let copyOfStruct = utcNow();
    today = date_3(copyOfStruct);
    const weekEndDate = getDayEnd((copyOfStruct_5 = date_4(getDayStart((copyOfStruct_3 = ((copyOfStruct_2 = utcNow(), date_3(copyOfStruct_2))), addDays(copyOfStruct_3, 0 - ((matchValue = (dayOfWeek((copyOfStruct_4 = utcNow(), date_3(copyOfStruct_4))) | 0), (matchValue === 0) ? 0 : ((matchValue === 1) ? 1 : ((matchValue === 2) ? 2 : ((matchValue === 3) ? 3 : ((matchValue === 4) ? 4 : ((matchValue === 5) ? 5 : ((matchValue === 6) ? 6 : 0)))))))))))), addDays(copyOfStruct_5, 6)));
    const patternInput_14 = (listType.tag === 2) ? ["Afspraken te vervolledigen", (copyOfStruct_7 = utcNow_1(), addDays_1(copyOfStruct_7, -999)), (copyOfStruct_8 = utcNow_1(), addDays_1(copyOfStruct_8, 7)), new AppointmentStatus(1)] : ((listType.tag === 1) ? ["Mijn afspraken", getDayStart((copyOfStruct_10 = ((copyOfStruct_9 = utcNow(), date_3(copyOfStruct_9))), addDays(copyOfStruct_10, 1))), weekEndDate, void 0] : ((listType.tag === 3) ? ["Alle afspraken", (copyOfStruct_11 = utcNow_1(), addDays_1(copyOfStruct_11, -7)), (copyOfStruct_12 = utcNow_1(), addDays_1(copyOfStruct_12, 50)), void 0] : ["Vandaag", (copyOfStruct_6 = utcNow_1(), addDays_1(copyOfStruct_6, -999)), (date_1 = today, (equals_1(date_3(date_1), date_3(today)) && (((copyOfStruct_1 = now(), hour(copyOfStruct_1))) >= 17)) ? ((date_2 = addDays(date_1, 1), create(year(date_2), month(date_2), day(date_2), 23, 59, 59, fromTicks(fromInteger(0, false, 2))))) : create(year(date_1), month(date_1), day(date_1), 23, 59, 59, fromTicks(fromInteger(0, false, 2)))), new AppointmentStatus(0)]));
    const patternInput_15 = useFeliz_React__React_useState_Static_1505(void 0);
    const dateRange = patternInput_15[0];
    const searchTerms_1 = useReact_useMemo_CF4EA67(() => (new AppointmentSearchTerms(ofArray(choose((x) => x, map_2(Appointment_parseStatus, statuses))), filterAssignmentConfirmationUploaded, filterSamplesUploaded, searchTermDossierNummer, searchTermAdiNaam, searchTermCustomerNaam, searchTermAdres, searchTermTel)), [searchTermDossierNummer, searchTermAdiNaam, searchTermCustomerNaam, searchTermAdres, searchTermTel, filterSamplesUploaded, filterAssignmentConfirmationUploaded, statuses]);
    const patternInput_16 = useReact_useMemo_CF4EA67(() => {
        if (dateRange == null) {
            return (listType.tag === 1) ? [void 0, void 0] : [patternInput_14[1], patternInput_14[2]];
        }
        else {
            const range = dateRange;
            return [getDayStart(range.Date1), getDayEnd(range.Date2)];
        }
    }, [dateRange]);
    const date2 = patternInput_16[1];
    const date1 = patternInput_16[0];
    const searchTermsDebounced = useDebouncedValue(500, searchTerms_1);
    const heightClass = (listType.tag === 3) ? allAppointmentsMinHeight : "";
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetAppointmentsDetailsInRange(date1, date2, patternInput_14[3], listType, new PagingQuery(pageNumber, pageSize), searchTermsDebounced), [pageNumber, pageSize, patternInput[0], searchTermsDebounced, date1, date2]);
    const headers = createElement(TableHeaders, {
        listType: listType,
        dateRange: dateRange,
        setDateRange: patternInput_15[1],
        searchTermDossierNummer: searchTermDossierNummer,
        saveDossierId: (s_1) => {
            if (isNullOrWhiteSpace(s_1)) {
                setSearchTermDossierNummer(void 0);
            }
            else {
                setSearchTermDossierNummer(new DossierCode(s_1));
            }
        },
        searchTermAdiNaam: searchTermAdiNaam,
        setSearchTermAdiNaam: patternInput_6[1],
        searchTermCustomerNaam: searchTermCustomerNaam,
        setSearchTermCustomerNaam: patternInput_7[1],
        searchTermAdres: searchTermAdres,
        setSearchTermAdres: patternInput_8[1],
        searchTermTel: searchTermTel,
        setSearchTermTel: patternInput_9[1],
        statuses: statuses,
        setStatuses: patternInput_10[1],
        filterSamplesUploaded: filterSamplesUploaded,
        setFilterSamplesUploaded: patternInput_11[1],
        filterAssignmentConfirmationUploaded: filterAssignmentConfirmationUploaded,
        setFilterAssignmentConfirmationUploaded: patternInput_12[1],
    });
    return createElement(Col, {
        classes: ofArray([MarginBottom.L, heightClass]),
        children: ofArray([createElement(ConfirmMailedSamplesModal, {
            api: api,
            showConfirmationModal: patternInput_3[0],
            setShowConfirmModal: setShowConfirmMailedSamplesModal,
            appointmentConfirmationId: patternInput_4[0],
            setPageRefresh: setPageRefresh,
        }), (children_2 = ofArray([createElement(Col, {
            classes: singleton_1(PaddingLeft.M),
            children: singleton_1(createElement("h1", {
                children: [patternInput_14[0]],
            })),
        }), createElement(Col, {
            classes: singleton_1(MarginBottom.L),
            children: ofArray([MuiHelpers_createElement(TableContainer, [["component", "table"], (elems_2 = [MuiHelpers_createElement(Table, [["size", "small"], (elems_1 = [headers, (children_1 = singleton_1(createElement(DisplayDeferred, {
                data: data,
                view: (appointments) => {
                    const xs = toList(delay(() => ((length(appointments.Data) === 0) ? singleton_2(null) : map_3((apt) => AppointmentRow(apt, api, listType, setPageRefresh, patternInput_4[1], setShowConfirmMailedSamplesModal), appointments.Data))));
                    return react.createElement(react.Fragment, {}, ...xs);
                },
            })), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]), createElement(Col, {
                classes: empty(),
                children: singleton_1(createElement(DisplayDeferred, {
                    data: data,
                    view: (appointments_1) => {
                        let values;
                        return MuiHelpers_createElement(TablePagination, [["rowsPerPage", pageSize], ["page", pageNumber], ["count", appointments_1.TotalPages], ["onChangePage", (_arg, v) => {
                            patternInput_1[1](v);
                        }], (values = (new Int32Array([])), ["rowsPerPageOptions", Array.from(values)]), ["onChangeRowsPerPage", (value_15) => {
                        }]]);
                    },
                })),
            })]),
        })]), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))]),
    });
}

export function Appointments(appointmentsInputProps) {
    const auth0User = appointmentsInputProps.auth0User;
    const api = appointmentsInputProps.api;
    const currentPage = appointmentsInputProps.currentPage;
    return createElement(Page, {
        currentPage: currentPage,
        title: "",
        actions: [],
        children: [createElement(Col, {
            classes: empty(),
            children: toList(delay(() => append(Auth0UserClaims__HasPrivilege_6B2F319B(auth0User, new Auth0UserPrivileges(1)) ? append(singleton_2(createElement(AppointmentsTable, {
                api: api,
                listType: new AppointmentListType(0),
            })), delay(() => append(singleton_2(createElement(AppointmentsTable, {
                api: api,
                listType: new AppointmentListType(2),
            })), delay(() => singleton_2(createElement(AppointmentsTable, {
                api: api,
                listType: new AppointmentListType(1),
            })))))) : empty_1(), delay(() => (Auth0UserClaims__HasPrivilege_6B2F319B(auth0User, new Auth0UserPrivileges(0)) ? singleton_2(createElement(AppointmentsTable, {
                api: api,
                listType: new AppointmentListType(3),
            })) : empty_1()))))),
        })],
    });
}

