import { useForm, useFormValidator, useFormField } from "../../../CustomHooks/ComposedForm.fs.js";
import { PartnerId, SelectOptionData$1_Create_Z510A3DC0, VatNumber_get_Create, VatNumber___UnsafeUnwrap_18C04ACB, Partner__get_LastName, Partner__get_Firstname, PhoneNumber_get_Create, PhoneNumber___UnsafeUnwrap_23E45174, Email_get_Create, Email___UnsafeUnwrap_Z27D11339, NonEmptyString_get_Create, NonEmptyString___UnsafeUnwrap_Z73AD07C } from "../../../shared/Domain.fs.js";
import { toArray, map, defaultArg, some } from "../fable_modules/fable-library.3.7.17/Option.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { useToggle } from "../../../CustomHooks/CustomHooks.fs.js";
import { ofList } from "../fable_modules/fable-library.3.7.17/Set.js";
import { compare } from "../fable_modules/fable-library.3.7.17/Util.js";
import { CreateAddressFormSmallHeader, useAddressForm } from "../../../DesignSystems/AddressHook.fs.js";
import { createElement } from "react";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import { MarginTop, Gap } from "../../../Styles/Utils.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.3.7.17/List.js";
import { VmeSocialToggles } from "../../../DesignSystems/Contacts.fs.js";
import { ErrorText, Card, TextField } from "../../../DesignSystems/DesignSystem.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Button from "@material-ui/core/Button";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { syndicsDetail } from "../AppRoutes.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { iterate } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { PartnerData } from "../../../shared/Api.fs.js";
import { useSnackbar } from "notistack";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { Page } from "../components/Page.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_1 } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";

export function EditSyndicForm(editSyndicFormInputProps) {
    let f1, f1_1, f1_2, matchValue, cname, f1_3;
    const data = editSyndicFormInputProps.data;
    const onEditPartner = editSyndicFormInputProps.onEditPartner;
    const partnerWithAdis = editSyndicFormInputProps.partnerWithAdis;
    const partner = partnerWithAdis.Partner;
    const name = useFormField(NonEmptyString___UnsafeUnwrap_Z73AD07C(partner.CompanyName), NonEmptyString_get_Create());
    const companyType = useFormField("", (f1 = NonEmptyString_get_Create(), (arg_2) => some(f1(arg_2))));
    const email = useFormField(Email___UnsafeUnwrap_Z27D11339(partner.Email), Email_get_Create());
    const phoneNumber = useFormField(PhoneNumber___UnsafeUnwrap_23E45174(partner.PhoneNumber), PhoneNumber_get_Create());
    const contactFirstName = useFormField(Partner__get_Firstname(partner), (f1_1 = NonEmptyString_get_Create(), (arg_6) => some(f1_1(arg_6))));
    const contactLastName = useFormField(Partner__get_LastName(partner), (f1_2 = NonEmptyString_get_Create(), (arg_8) => some(f1_2(arg_8))));
    const vatNumber = useFormField(defaultArg(map(VatNumber___UnsafeUnwrap_18C04ACB, partner.VatNumber), ""), VatNumber_get_Create());
    const patternInput = useFeliz_React__React_useState_Static_1505(partner.IsCibPartner);
    const isVme = useToggle(partner.IsVME);
    const isSocialHousingCompany = useToggle(partner.IsSocialHousingCompany);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(ofList(partnerWithAdis.PartnerAdis, {
        Compare: compare,
    }));
    const minimumRole = useFormField(partner.MinimumRole, SelectOptionData$1_Create_Z510A3DC0);
    const commercialName = useFormField((matchValue = partner.CommercialName, (matchValue != null) ? ((cname = matchValue, `${cname.Value}`)) : ""), (f1_3 = NonEmptyString_get_Create(), (arg_12) => some(f1_3(arg_12))));
    let bus_1;
    const matchValue_1 = partner.Address.Bus;
    bus_1 = ((matchValue_1 == null) ? "" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_1));
    const address = useAddressForm(NonEmptyString___UnsafeUnwrap_Z73AD07C(partner.Address.Street), NonEmptyString___UnsafeUnwrap_Z73AD07C(partner.Address.Number), bus_1, NonEmptyString___UnsafeUnwrap_Z73AD07C(partner.Address.PostalCode), NonEmptyString___UnsafeUnwrap_Z73AD07C(partner.Address.City));
    const form = useFormValidator(commercialName.Validator, (r_7, x_9) => ({
        Address: r_7.Address,
        CommercialName: x_9,
        CompanyType: r_7.CompanyType,
        ContactFirstName: r_7.ContactFirstName,
        ContactLastName: r_7.ContactLastName,
        Email: r_7.Email,
        Name: r_7.Name,
        PhoneNumber: r_7.PhoneNumber,
        VatNumber: r_7.VatNumber,
    }), useFormValidator(vatNumber.Validator, (r_6, x_8) => ({
        Address: r_6.Address,
        CompanyType: r_6.CompanyType,
        ContactFirstName: r_6.ContactFirstName,
        ContactLastName: r_6.ContactLastName,
        Email: r_6.Email,
        Name: r_6.Name,
        PhoneNumber: r_6.PhoneNumber,
        VatNumber: x_8,
    }), useFormValidator(phoneNumber.Validator, (r_5, x_7) => ({
        Address: r_5.Address,
        CompanyType: r_5.CompanyType,
        ContactFirstName: r_5.ContactFirstName,
        ContactLastName: r_5.ContactLastName,
        Email: r_5.Email,
        Name: r_5.Name,
        PhoneNumber: x_7,
    }), useFormValidator(contactLastName.Validator, (r_4, x_6) => ({
        Address: r_4.Address,
        CompanyType: r_4.CompanyType,
        ContactFirstName: r_4.ContactFirstName,
        ContactLastName: x_6,
        Email: r_4.Email,
        Name: r_4.Name,
    }), useFormValidator(contactFirstName.Validator, (r_3, x_5) => ({
        Address: r_3.Address,
        CompanyType: r_3.CompanyType,
        ContactFirstName: x_5,
        Email: r_3.Email,
        Name: r_3.Name,
    }), useFormValidator(email.Validator, (r_2, x_4) => ({
        Address: r_2.Address,
        CompanyType: r_2.CompanyType,
        Email: x_4,
        Name: r_2.Name,
    }), useFormValidator(address.Validator, (r_1, x_3) => ({
        Address: x_3,
        CompanyType: r_1.CompanyType,
        Name: r_1.Name,
    }), useFormValidator(companyType.Validator, (r, x_2) => ({
        CompanyType: x_2,
        Name: r.Name,
    }), useFormValidator(name.Validator, (unitVar, x_1) => ({
        Name: x_1,
    }), useForm())))))))));
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setDisable = patternInput_2[1];
    const disable = patternInput_2[0];
    return createElement(Col, {
        classes: singleton(Gap.M),
        children: ofArray([createElement(VmeSocialToggles, {
            vme: isVme,
            social: isSocialHousingCompany,
            disabled: false,
            companyTypeField: companyType,
        }), TextField(vatNumber, "BTW", "", false), TextField(commercialName, "Commercial Name", "", true), TextField(name, "Bedrijfsnaam", "", true), TextField(companyType, "Bedrijfstype", "", false), TextField(contactFirstName, "Contact Voornaam", "", false), TextField(contactLastName, "Contact Naam", "", false), TextField(email, "Email adres", "", true), TextField(phoneNumber, "Telefoon", "", true), createElement(CreateAddressFormSmallHeader, {
            addressForm: address,
            heading: void 0,
            disabled: false,
        }), createElement(Row, {
            classes: ofArray([Gap.M, MarginTop.L]),
            children: ofArray([MuiHelpers_createElement(Button, [["disabled", disable], ["color", "default"], ["variant", "contained"], ["onClick", (_arg_1) => {
                RouterModule_nav(ofArray(Page$2__toUrl_2B594(syndicsDetail, partner.Id.Value)), 1, 1);
            }], ["children", Interop_reactApi.Children.toArray(["Annuleren"])]]), MuiHelpers_createElement(Button, [["disabled", disable], ["color", "primary"], ["variant", "contained"], ["onClick", (_arg) => {
                iterate((data_1) => {
                    setDisable(true);
                    console.log(data_1);
                    onEditPartner(() => {
                        setDisable(false);
                    })(partner.Id)(new PartnerData(data_1.Name, data_1.CompanyType, data_1.ContactFirstName, data_1.ContactLastName, data_1.Address, data_1.Email, data_1.PhoneNumber, data_1.VatNumber, patternInput[0], isVme.State, isSocialHousingCompany.State, patternInput_1[0], void 0, partner.PartnerType, data_1.CommercialName));
                }, toArray(form.Validate()));
            }], ["children", Interop_reactApi.Children.toArray(["Bewaren"])]])]),
        })]),
    });
}

export function SyndicEdit(syndicEditInputProps) {
    const partnerId = syndicEditInputProps.partnerId;
    const api = syndicEditInputProps.api;
    const currentPage = syndicEditInputProps.currentPage;
    const snackbar = useSnackbar();
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetPartnerWithAdis(new PartnerId(partnerId)), []);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Syndicgegevens",
        actions: [],
        children: [Card([createElement(DisplayDeferred, {
            data: data,
            view: (partner) => {
                if (partner == null) {
                    return createElement(ErrorText, {
                        text: `Geen partner gevonden met ID ${partnerId}`,
                    });
                }
                else {
                    const partner_1 = partner;
                    return createElement(EditSyndicForm, {
                        partnerWithAdis: partner_1,
                        onEditPartner: (onCompleted) => ((id) => ((partnerData) => {
                            startImmediate(singleton_1.Delay(() => singleton_1.TryFinally(singleton_1.Delay(() => singleton_1.TryWith(singleton_1.Delay(() => singleton_1.Bind(api.EditPartner(id, partnerData), (_arg) => {
                                const result = _arg;
                                if (result.tag === 0) {
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "De partner is aangepast", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                    RouterModule_nav(ofArray(Page$2__toUrl_2B594(syndicsDetail, partnerId)), 1, 1);
                                    return singleton_1.Zero();
                                }
                                else {
                                    const err = result.fields[0];
                                    console.error(err);
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                    return singleton_1.Zero();
                                }
                            })), (_arg_1) => {
                                const e = _arg_1;
                                console.log(e);
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                return singleton_1.Zero();
                            })), () => {
                                onCompleted();
                            })));
                        })),
                        data: void 0,
                    });
                }
            },
        })])],
    });
}

