import { useReact_useMemo_CF4EA67, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { ofList } from "../fable_modules/fable-library.3.7.17/Set.js";
import { createObj, int32ToString, compare } from "../fable_modules/fable-library.3.7.17/Util.js";
import { createElement } from "react";
import * as react from "react";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import { singleton, ofArray, empty } from "../fable_modules/fable-library.3.7.17/List.js";
import { ErrorText, InfoCheckbox, Info } from "../../../DesignSystems/DesignSystem.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Divider from "@material-ui/core/Divider";
import { PartnerId as PartnerId_2, VatNumber___UnsafeUnwrap_18C04ACB, PhoneNumber___UnsafeUnwrap_23E45174, Email___UnsafeUnwrap_Z27D11339, Partner__get_LastName, Partner__get_Firstname, NonEmptyString___UnsafeUnwrap_Z73AD07C, PartnerCode___UnsafeUnwrap_786B17C8 } from "../../../shared/Domain.fs.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.17/Option.js";
import { join, isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.17/String.js";
import { Compensation_compensation } from "../../../shared/Format.fs.js";
import List from "@material-ui/core/List";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import Paper from "@material-ui/core/Paper";
import { useSnackbar } from "notistack";
import Modal from "@material-ui/core/Modal";
import { Gap, MarginLeft, Padding, MarginBottom, PaddingLeft, PaddingBottom, PaddingTop, modal } from "../../../Styles/Utils.fs.js";
import Button from "@material-ui/core/Button";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_1 } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { State, searchTerms as searchTerms_1, initialState, update } from "../../../DesignSystems/dossier/DossierFilter.fs.js";
import { useDebouncedValue } from "../../../CustomHooks/CustomHooks.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { DossierSearchTerms } from "../../../shared/DomainHelpers.fs.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { dossierDetail, syndicsEdit } from "../AppRoutes.fs.js";
import Edit from "@material-ui/icons/Edit";
import { Auth0UserPrivileges, Auth0UserClaims__HasPrivilege_6B2F319B } from "../../../shared/Auth0Constants.fs.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { Page } from "../components/Page.fs.js";
import { DossiersData } from "../../../DesignSystems/dossier/DossierDetails.fs.js";
import { downloadFile_CSV } from "./DownloadFile.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";

export function DisplayPartnerInfo(partnerWithAdis) {
    let children_1, children;
    const partner = partnerWithAdis.Partner;
    const patternInput = useFeliz_React__React_useState_Static_1505(ofList(partnerWithAdis.PartnerAdis, {
        Compare: compare,
    }));
    return createElement(Col, {
        classes: empty(),
        children: ofArray([createElement("h2", {
            children: ["Syndicgegevens"],
        }), (children_1 = singleton((children = ofArray([createElement(Info, {
            secondary: "Syndic nummer",
            primary: int32ToString(partner.Id.Value),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Code",
            primary: PartnerCode___UnsafeUnwrap_786B17C8(partner.Code),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Bedrijfsnaam",
            primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(partner.CompanyName),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Commercial Name",
            primary: defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, partner.CommercialName), "--"),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Bedrijfstype",
            primary: defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, partner.CompanyType), "--"),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Contact Voornaam",
            primary: isNullOrWhiteSpace(Partner__get_Firstname(partner)) ? "--" : Partner__get_Firstname(partner),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Contact Naam",
            primary: isNullOrWhiteSpace(Partner__get_LastName(partner)) ? "--" : Partner__get_LastName(partner),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Email",
            primary: Email___UnsafeUnwrap_Z27D11339(partner.Email),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Telefoon",
            primary: PhoneNumber___UnsafeUnwrap_23E45174(partner.PhoneNumber),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "BTW",
            primary: defaultArg(map(VatNumber___UnsafeUnwrap_18C04ACB, partner.VatNumber), "--"),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(InfoCheckbox, {
            secondary: "VME",
            state: partner.IsVME,
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(InfoCheckbox, {
            secondary: "Sociale huisvestigingsmaatschappij",
            state: partner.IsSocialHousingCompany,
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Vergoedingen",
            primary: defaultArg(map(Compensation_compensation, partner.CompensationAmount), "--"),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Straat",
            primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(partner.Address.Street),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Nummer",
            primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(partner.Address.Number),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Bus",
            primary: defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, partner.Address.Bus), "--"),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Postcode",
            primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(partner.Address.PostalCode),
        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
            secondary: "Gemeente",
            primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(partner.Address.City),
        }), MuiHelpers_createElement(Divider, [["component", "li"]])]), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))]),
    });
}

export function ConfirmCreateAccountModal(confirmCreateAccountModalInputProps) {
    let elems_1, elems;
    const onConfirm = confirmCreateAccountModalInputProps.onConfirm;
    const setShowConfirmModal = confirmCreateAccountModalInputProps.setShowConfirmModal;
    const showConfirmationModal = confirmCreateAccountModalInputProps.showConfirmationModal;
    const snackbar = useSnackbar();
    return MuiHelpers_createElement(Modal, [["open", showConfirmationModal], ["onClose", (_arg_2, v) => {
        setShowConfirmModal(false);
    }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.L, PaddingBottom.L, PaddingLeft.L])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", [MarginBottom.M, Padding.S])], (elems = [createElement("h3", {
        children: ["Partnerportalaccount maken voor deze partner?"],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), MuiHelpers_createElement(Button, [["onClick", (_arg_3) => {
        startImmediate(singleton_1.Delay(() => singleton_1.TryFinally(singleton_1.Delay(() => singleton_1.TryWith(singleton_1.Delay(() => {
            onConfirm();
            return singleton_1.Zero();
        }), (_arg) => {
            const e = _arg;
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden tijdens het bevestigen van de afspraak. Gelieve support te contacteren. [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            console.log(e);
            return singleton_1.Zero();
        })), () => {
            setShowConfirmModal(false);
        })));
    }], ["className", join(" ", [])], ["variant", "contained"], ["color", "secondary"], ["children", "Ja"]]), MuiHelpers_createElement(Button, [["onClick", (_arg_4) => {
        setShowConfirmModal(false);
    }], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "primary"], ["children", "Nee"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]]);
}

export function RenderPartnerDetail(renderPartnerDetailInputProps) {
    let props, children_1;
    const auth0User = renderPartnerDetailInputProps.auth0User;
    const partnerWithAdis = renderPartnerDetailInputProps.partnerWithAdis;
    const api = renderPartnerDetailInputProps.api;
    const currentPage = renderPartnerDetailInputProps.currentPage;
    const syndicDetails = partnerWithAdis.Partner;
    const snackbar = useSnackbar();
    const patternInput = useReact_useReducer_2B9E6EA0(update, initialState);
    const state_1 = patternInput[0];
    const search = useDebouncedValue(500, useReact_useMemo_CF4EA67(() => searchTerms_1(state_1), [state_1]));
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetDossiersDetails(new PagingQuery(state_1.PageNumber, state_1.PageSize), new DossierSearchTerms(search.DossierCode, search.PartnerName, search.AdiName, search.CustomerName, search.Address, search.PhoneNumber, search.Status, search.StartDate, search.EndDate, search.FilterOutDossiersWithoutPartners, search.CibFilter, search.CustomerCode, search.PartnerPortal, search.Urgent, syndicDetails.Id, search.PartnerIdList)), [state_1.PageNumber, state_1.PageSize, search]);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setCreateAccountModal = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setDisableCreateAccount = patternInput_2[1];
    const editPartnerButton = MuiHelpers_createElement(Button, [["onClick", (_arg_1) => {
        RouterModule_nav(ofArray(Page$2__toUrl_2B594(syndicsEdit, syndicDetails.Id.Value)), 1, 1);
    }], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props = {}, react.createElement(Edit, props))], ["children", "Syndic bewerken"]]);
    const createAccountButton = MuiHelpers_createElement(Button, [["disabled", patternInput_2[0]], ["onClick", (_arg_5) => {
        setCreateAccountModal(true);
    }], ["variant", "contained"], ["color", "secondary"], ["children", "Partner portaal activeren"]]);
    const showCreateAccountButton = (syndicDetails.TenantPartnerCustomerId == null) && (Auth0UserClaims__HasPrivilege_6B2F319B(auth0User, new Auth0UserPrivileges(0)));
    const buttons = createElement(Row, {
        classes: singleton(Gap.Xs),
        children: toList(delay(() => append(singleton_2(editPartnerButton), delay(() => (showCreateAccountButton ? singleton_2(createAccountButton) : empty_1()))))),
    });
    return createElement(Page, {
        currentPage: currentPage,
        title: "",
        actions: [buttons],
        children: [createElement(Col, {
            classes: singleton(Gap.S),
            children: ofArray([createElement(DisplayPartnerInfo, partnerWithAdis), createElement(ConfirmCreateAccountModal, {
                showConfirmationModal: patternInput_1[0],
                setShowConfirmModal: setCreateAccountModal,
                onConfirm: () => {
                    startImmediate(singleton_1.Delay(() => singleton_1.TryWith(singleton_1.Delay(() => {
                        setDisableCreateAccount(true);
                        return singleton_1.Bind(api.BootstrapPartner(syndicDetails.Id), (_arg_3) => {
                            const result = _arg_3;
                            if (result.tag === 1) {
                                const e_1 = result.fields[0];
                                console.log(e_1);
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${e_1}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                return singleton_1.Zero();
                            }
                            else {
                                (() => {
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Geactiveerd partner portaal", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                })();
                                return singleton_1.Zero();
                            }
                        });
                    }), (_arg_4) => {
                        const e_2 = _arg_4;
                        setDisableCreateAccount(false);
                        console.log(e_2);
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${e_2.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        return singleton_1.Zero();
                    })));
                },
            }), createElement("h2", {
                children: ["Gekoppelde dossiers"],
            }), (children_1 = singleton(createElement(DossiersData, {
                data: data,
                state: new State(state_1.DossierCode, state_1.AdiName, state_1.PartnerName, state_1.CustomerName, state_1.CustomerCode, state_1.Address, state_1.PhoneNumber, state_1.DateRange, syndicDetails.Id, state_1.Status, state_1.PageNumber, state_1.PageSize, state_1.CibFilter, state_1.PartnerPortal, state_1.Urgent),
                dispatch: patternInput[1],
                url: (dossierId) => Page$2__toUrl_2B594(dossierDetail, dossierId.Value),
                showPartnerName: false,
                optionalExportCsvFunction: (query) => ((searchTerms) => {
                    startImmediate(singleton_1.Delay(() => singleton_1.TryWith(singleton_1.Delay(() => {
                        startImmediate(downloadFile_CSV(api, query, searchTerms));
                        return singleton_1.Zero();
                    }), (_arg_2) => {
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${_arg_2.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        return singleton_1.Zero();
                    })));
                }),
                showCibFilter: false,
                isBackOffice: true,
                klantNameHeader: void 0,
            })), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))]),
        })],
    });
}

export function SyndicDetail(syndicDetailInputProps) {
    const auth0User = syndicDetailInputProps.auth0User;
    const partnerId = syndicDetailInputProps.partnerId;
    const api = syndicDetailInputProps.api;
    const currentPage = syndicDetailInputProps.currentPage;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetPartnerWithAdis(new PartnerId_2(partnerId)), []);
    return createElement(DisplayDeferred, {
        data: data,
        view: (partnerDetails) => {
            if (partnerDetails == null) {
                return createElement(Page, {
                    currentPage: currentPage,
                    title: "",
                    actions: [],
                    children: [createElement(ErrorText, {
                        text: `Geen syndic gevonden met ID ${partnerId}`,
                    })],
                });
            }
            else {
                const partnerDetails_1 = partnerDetails;
                return createElement(RenderPartnerDetail, {
                    currentPage: currentPage,
                    api: api,
                    partnerWithAdis: partnerDetails_1,
                    auth0User: auth0User,
                });
            }
        },
    });
}

