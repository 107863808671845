import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.68.0/React.fs.js";
import { RouterModule_nav, RouterModule_router, RouterModule_urlSegments } from "./fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { createObj } from "./fable_modules/fable-library.3.7.17/Util.js";
import { singleton, delay, toList } from "./fable_modules/fable-library.3.7.17/Seq.js";
import { mainRouter, login, appRouter } from "./AppRoutes.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Dashboard } from "./pages/Dashboard.fs.js";
import { Customers } from "./pages/Customers.fs.js";
import { CustomerDetail } from "./pages/CustomerDetail.fs.js";
import { CustomerEdit } from "./pages/CustomerEdit.fs.js";
import { CustomerCreate } from "./pages/CustomerCreate.fs.js";
import { Partners } from "./pages/Partners.fs.js";
import { PartnerDetail } from "./pages/PartnerDetail.fs.js";
import { AdiId as AdiId_1, PartnerType } from "../../shared/Domain.fs.js";
import { PartnerEdit } from "./pages/PartnerEdit.fs.js";
import { PartnerCreate } from "./pages/PartnerCreate.fs.js";
import { Adis } from "./pages/Adis.fs.js";
import { AdiDetail } from "./pages/AdiDetail.fs.js";
import { AdiEdit } from "./pages/AdiEdit.fs.js";
import { AdiCreate } from "./pages/AdiCreate.fs.js";
import { Dossiers } from "./pages/Dossiers.fs.js";
import { MyDossiers } from "./pages/MyDossiers.fs.js";
import { DossierDetail } from "./pages/DossierDetail.fs.js";
import { DossierCreate } from "./pages/DossierCreate.fs.js";
import { DossierEdit } from "./pages/DossierEdit.fs.js";
import { Availability } from "./pages/Availability.fs.js";
import { AppointmentCreate } from "./pages/AppointmentCreate.fs.js";
import { AppointmentDossierCreate } from "./pages/AppointmentDossierCreate.fs.js";
import { AppointmentDossierEdit } from "./pages/AppointmentDossierEdit.fs.js";
import { AppointmentDetail } from "./pages/AppointmentDetail.fs.js";
import { AppointmentConfirm } from "./pages/AppointmentConfirm.fs.js";
import { Appointments } from "./pages/AppointmentsList.fs.js";
import { AttestenFinaliseren } from "./pages/AttestenFinaliseren.fs.js";
import { AdminAvailability } from "./pages/AdminAvailability.fs.js";
import { AdminTools } from "./pages/AdminTools.fs.js";
import { Syndics } from "./pages/Syndics.fs.js";
import { SyndicCreate } from "./pages/SyndicCreate.fs.js";
import { SyndicDetail } from "./pages/SyndicDetail.fs.js";
import { SyndicEdit } from "./pages/SyndicEdit.fs.js";
import { QuoteCreate } from "./pages/QuoteCreate.fs.js";
import { Quotes } from "./pages/Quotes.fs.js";
import { QuoteDetail } from "./pages/QuoteDetail.fs.js";
import { QuoteEdit } from "./pages/QuoteEdit.fs.js";
import { singleton as singleton_2, empty, ofArray } from "./fable_modules/fable-library.3.7.17/List.js";
import { Page$2__toUrl_2B594 } from "../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { useAuth0 } from "@auth0/auth0-react";
import { Remoting_buildProxy_64DC51C } from "./fable_modules/Fable.Remoting.Client.7.25.0/./Remoting.fs.js";
import { RemotingModule_withAuthorizationHeader, RemotingModule_createApi, RemotingModule_withRouteBuilder } from "./fable_modules/Fable.Remoting.Client.7.25.0/Remoting.fs.js";
import { normalizeRoutes } from "./Server.fs.js";
import { toConsole, split, printf, toText } from "./fable_modules/fable-library.3.7.17/String.js";
import { ISignatureApi$reflection, IServerApi$reflection } from "../../shared/Api.fs.js";
import { Auth0UserPrivileges, Auth0UserClaims } from "../../shared/Auth0Constants.fs.js";
import { awaitPromise, startImmediate } from "./fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_1 } from "./fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { map } from "./fable_modules/fable-library.3.7.17/Array.js";
import { Progress } from "../../DesignSystems/DesignSystem.fs.js";
import { AppBar } from "./components/AppBar.fs.js";
import { loginLogo } from "../../Styles/Utils.fs.js";
import { logo } from "./Img.fs.js";
import { MuiHelpers_createElement } from "./fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Button from "@material-ui/core/Button";
import { Login } from "./pages/Login.fs.js";
import { ClientDigitalSignature } from "./pages/ClientDigitalSignature.fs.js";

export function AppRouter(appRouterInputProps) {
    let elements;
    const auth0User = appRouterInputProps.auth0User;
    const api = appRouterInputProps.api;
    const patternInput = useFeliz_React__React_useState_Static_1505(RouterModule_urlSegments(window.location.hash, 1));
    return RouterModule_router(createObj(ofArray([["onUrlChanged", patternInput[1]], (elements = toList(delay(() => {
        let children;
        const matchValue = appRouter(patternInput[0]);
        let pattern_matching_result, page, page_1, id, page_2, id_1, page_3, page_4, page_5, id_2, page_6, id_3, page_7, page_8, page_9, id_4, page_10, id_5, page_11, page_12, page_13, page_14, code, page_15, page_16, id_6, page_17, page_18, page_19, dossierCode, page_20, id_7, page_21, id_8, page_22, id_9, page_23, page_24, page_25, page_26, page_27, page_28, page_29, id_10, page_30, id_11, page_31, page_32, page_33, code_1, page_34, id_12, page_35;
        if (matchValue != null) {
            if (matchValue.tag === 1) {
                if (matchValue != null) {
                    pattern_matching_result = 0;
                    page = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 2) {
                if (matchValue != null) {
                    pattern_matching_result = 1;
                    page_1 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 3) {
                if (matchValue != null) {
                    pattern_matching_result = 2;
                    id = matchValue.fields[0];
                    page_2 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 4) {
                if (matchValue != null) {
                    pattern_matching_result = 3;
                    id_1 = matchValue.fields[0];
                    page_3 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 5) {
                if (matchValue != null) {
                    pattern_matching_result = 4;
                    page_4 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 6) {
                if (matchValue != null) {
                    pattern_matching_result = 5;
                    page_5 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 7) {
                if (matchValue != null) {
                    pattern_matching_result = 6;
                    id_2 = matchValue.fields[0];
                    page_6 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 8) {
                if (matchValue != null) {
                    pattern_matching_result = 7;
                    id_3 = matchValue.fields[0];
                    page_7 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 9) {
                if (matchValue != null) {
                    pattern_matching_result = 8;
                    page_8 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 10) {
                if (matchValue != null) {
                    pattern_matching_result = 9;
                    page_9 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 11) {
                if (matchValue != null) {
                    pattern_matching_result = 10;
                    id_4 = matchValue.fields[0];
                    page_10 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 12) {
                if (matchValue != null) {
                    pattern_matching_result = 11;
                    id_5 = matchValue.fields[0];
                    page_11 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 13) {
                if (matchValue != null) {
                    pattern_matching_result = 12;
                    page_12 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 14) {
                if (matchValue != null) {
                    pattern_matching_result = 13;
                    page_13 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 15) {
                if (matchValue != null) {
                    pattern_matching_result = 14;
                    page_14 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 16) {
                if (matchValue != null) {
                    pattern_matching_result = 15;
                    code = matchValue.fields[0];
                    page_15 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 18) {
                if (matchValue != null) {
                    pattern_matching_result = 16;
                    page_16 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 17) {
                if (matchValue != null) {
                    pattern_matching_result = 17;
                    id_6 = matchValue.fields[0];
                    page_17 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 19) {
                if (matchValue != null) {
                    pattern_matching_result = 18;
                    page_18 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 20) {
                if (matchValue != null) {
                    pattern_matching_result = 19;
                    page_19 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 21) {
                if (matchValue != null) {
                    pattern_matching_result = 20;
                    dossierCode = matchValue.fields[0];
                    page_20 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 22) {
                if (matchValue != null) {
                    pattern_matching_result = 21;
                    id_7 = matchValue.fields[0];
                    page_21 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 24) {
                if (matchValue != null) {
                    pattern_matching_result = 22;
                    id_8 = matchValue.fields[0];
                    page_22 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 25) {
                if (matchValue != null) {
                    pattern_matching_result = 23;
                    id_9 = matchValue.fields[0];
                    page_23 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 23) {
                if (matchValue != null) {
                    pattern_matching_result = 24;
                    page_24 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 26) {
                if (matchValue != null) {
                    pattern_matching_result = 25;
                    page_25 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 27) {
                if (matchValue != null) {
                    pattern_matching_result = 26;
                    page_26 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 29) {
                if (matchValue != null) {
                    pattern_matching_result = 27;
                    page_27 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 30) {
                if (matchValue != null) {
                    pattern_matching_result = 28;
                    page_28 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 33) {
                if (matchValue != null) {
                    pattern_matching_result = 29;
                    page_29 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 31) {
                if (matchValue != null) {
                    pattern_matching_result = 30;
                    id_10 = matchValue.fields[0];
                    page_30 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 32) {
                if (matchValue != null) {
                    pattern_matching_result = 31;
                    id_11 = matchValue.fields[0];
                    page_31 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 34) {
                if (matchValue != null) {
                    pattern_matching_result = 32;
                    page_32 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 35) {
                if (matchValue != null) {
                    pattern_matching_result = 33;
                    page_33 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 36) {
                if (matchValue != null) {
                    pattern_matching_result = 34;
                    code_1 = matchValue.fields[0];
                    page_34 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else if (matchValue.tag === 37) {
                if (matchValue != null) {
                    pattern_matching_result = 35;
                    id_12 = matchValue.fields[0];
                    page_35 = matchValue;
                }
                else {
                    pattern_matching_result = 36;
                }
            }
            else {
                pattern_matching_result = 36;
            }
        }
        else {
            pattern_matching_result = 36;
        }
        switch (pattern_matching_result) {
            case 0: {
                return singleton(createElement(Dashboard, {
                    currentPage: page,
                    api: api,
                }));
            }
            case 1: {
                return singleton(createElement(Customers, {
                    currentPage: page_1,
                    api: api,
                }));
            }
            case 2: {
                return singleton(createElement(CustomerDetail, {
                    currentPage: page_2,
                    api: api,
                    customerCode: id,
                    auth0User: auth0User,
                }));
            }
            case 3: {
                return singleton(createElement(CustomerEdit, {
                    currentPage: page_3,
                    api: api,
                    customerCode: id_1,
                }));
            }
            case 4: {
                return singleton(createElement(CustomerCreate, {
                    currentPage: page_4,
                    api: api,
                }));
            }
            case 5: {
                return singleton(createElement(Partners, {
                    currentPage: page_5,
                    api: api,
                }));
            }
            case 6: {
                return singleton(createElement(PartnerDetail, {
                    currentPage: page_6,
                    api: api,
                    partnerId: id_2,
                    auth0User: auth0User,
                    partnerType: new PartnerType(0),
                }));
            }
            case 7: {
                return singleton(createElement(PartnerEdit, {
                    currentPage: page_7,
                    api: api,
                    partnerId: id_3,
                }));
            }
            case 8: {
                return singleton(createElement(PartnerCreate, {
                    currentPage: page_8,
                    api: api,
                    partnerType: new PartnerType(0),
                }));
            }
            case 9: {
                return singleton(createElement(Adis, {
                    currentPage: page_9,
                    api: api,
                }));
            }
            case 10: {
                return singleton(createElement(AdiDetail, {
                    currentPage: page_10,
                    api: api,
                    adiId: id_4,
                }));
            }
            case 11: {
                return singleton(createElement(AdiEdit, {
                    currentPage: page_11,
                    api: api,
                    adiId: id_5,
                }));
            }
            case 12: {
                return singleton(createElement(AdiCreate, {
                    currentPage: page_12,
                    api: api,
                }));
            }
            case 13: {
                return singleton(createElement(Dossiers, {
                    currentPage: page_13,
                    api: api,
                    auth0User: auth0User,
                }));
            }
            case 14: {
                return singleton(createElement(MyDossiers, {
                    currentPage: page_14,
                    api: api,
                }));
            }
            case 15: {
                return singleton(createElement(DossierDetail, {
                    currentPage: page_15,
                    api: api,
                    dossierCode: code,
                    auth0User: auth0User,
                }));
            }
            case 16: {
                return singleton(createElement(DossierCreate, {
                    currentPage: page_16,
                    api: api,
                }));
            }
            case 17: {
                return singleton(createElement(DossierEdit, {
                    currentPage: page_17,
                    api: api,
                    dossierCode: id_6,
                }));
            }
            case 18: {
                return singleton(createElement(Availability, {
                    currentPage: page_18,
                    api: api,
                }));
            }
            case 19: {
                return singleton(createElement(AppointmentCreate, {
                    currentPage: page_19,
                    api: api,
                }));
            }
            case 20: {
                return singleton(createElement(AppointmentDossierCreate, {
                    currentPage: page_20,
                    api: api,
                    dossierCode: dossierCode,
                }));
            }
            case 21: {
                return singleton(createElement(AppointmentDossierEdit, {
                    currentPage: page_21,
                    api: api,
                    appointmentId: id_7,
                }));
            }
            case 22: {
                return singleton(createElement(AppointmentDetail, {
                    currentPage: page_22,
                    api: api,
                    auth0User: auth0User,
                    id: id_8,
                }));
            }
            case 23: {
                return singleton(createElement(AppointmentConfirm, {
                    currentPage: page_23,
                    api: api,
                    id: id_9,
                }));
            }
            case 24: {
                return singleton(createElement(Appointments, {
                    currentPage: page_24,
                    api: api,
                    auth0User: auth0User,
                }));
            }
            case 25: {
                return singleton(createElement(AttestenFinaliseren, {
                    currentPage: page_25,
                    api: api,
                }));
            }
            case 26: {
                return singleton(createElement(AdminAvailability, {
                    currentPage: page_26,
                    api: api,
                }));
            }
            case 27: {
                return singleton(createElement(AdminTools, {
                    currentPage: page_27,
                    api: api,
                }));
            }
            case 28: {
                return singleton(createElement(Syndics, {
                    currentPage: page_28,
                    api: api,
                }));
            }
            case 29: {
                return singleton(createElement(SyndicCreate, {
                    currentPage: page_29,
                    api: api,
                }));
            }
            case 30: {
                return singleton(createElement(SyndicDetail, {
                    currentPage: page_30,
                    api: api,
                    partnerId: id_10,
                    auth0User: auth0User,
                }));
            }
            case 31: {
                return singleton(createElement(SyndicEdit, {
                    currentPage: page_31,
                    api: api,
                    partnerId: id_11,
                }));
            }
            case 32: {
                return singleton(createElement(QuoteCreate, {
                    currentPage: page_32,
                    api: api,
                }));
            }
            case 33: {
                return singleton(createElement(Quotes, {
                    currentPage: page_33,
                    api: api,
                    auth0User: auth0User,
                }));
            }
            case 34: {
                return singleton(createElement(QuoteDetail, {
                    currentPage: page_34,
                    api: api,
                    quoteCode: code_1,
                    auth0User: auth0User,
                }));
            }
            case 35: {
                return singleton(createElement(QuoteEdit, {
                    currentPage: page_35,
                    api: api,
                    quoteCode: id_12,
                }));
            }
            case 36: {
                return singleton((children = ofArray([createElement("h1", {
                    children: ["Not found"],
                }), createElement("a", {
                    onClick: (_arg) => {
                        RouterModule_nav(ofArray(Page$2__toUrl_2B594(login)), 1, 1);
                    },
                    children: "Go home",
                })]), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                })));
            }
        }
    })), ["application", react.createElement(react.Fragment, {}, ...elements)])])));
}

export function App() {
    let options_1, user, elems_2, elems;
    const auth = useAuth0();
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const token = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(Remoting_buildProxy_64DC51C((options_1 = RemotingModule_withRouteBuilder(normalizeRoutes, RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(toText(printf("Bearer %s"))(token), options_1)), IServerApi$reflection()));
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(new Auth0UserClaims([], new AdiId_1(0), ""));
    const auth0User = patternInput_2[0];
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_1.Delay(() => {
            const matchValue = auth.user;
            if (matchValue == null) {
                return singleton_1.Zero();
            }
            else {
                const u = matchValue;
                return singleton_1.Bind(awaitPromise(auth.getIdTokenClaims()), (_arg_1) => {
                    let options_4;
                    const userClaims = _arg_1;
                    const tkn = userClaims.__raw;
                    patternInput_1[1](Remoting_buildProxy_64DC51C((options_4 = RemotingModule_withRouteBuilder(normalizeRoutes, RemotingModule_createApi()), RemotingModule_withAuthorizationHeader(toText(printf("Bearer %s"))(tkn), options_4)), IServerApi$reflection()));
                    patternInput[1](tkn);
                    patternInput_2[1](new Auth0UserClaims(map((p) => {
                        switch (p) {
                            case "Adi": {
                                return new Auth0UserPrivileges(1);
                            }
                            case "Admin": {
                                return new Auth0UserPrivileges(0);
                            }
                            default: {
                                return new Auth0UserPrivileges(2);
                            }
                        }
                    }, split(userClaims.privilege, [","], null, 0)), new AdiId_1(userClaims.adi_id), userClaims.name));
                    return singleton_1.Zero();
                });
            }
        }));
    }, [auth.isLoading]);
    const matchValue_1 = auth.user;
    if (matchValue_1 != null) {
        if ((user = matchValue_1, token === "")) {
            const user_1 = matchValue_1;
            return createElement(Progress, {
                classes: empty(),
            });
        }
        else {
            const user_2 = matchValue_1;
            return createElement(AppBar, {
                auth0User: auth0User,
                children: singleton_2(createElement(AppRouter, {
                    api: patternInput_1[0],
                    auth0User: auth0User,
                })),
            });
        }
    }
    else if (auth.isLoading) {
        return createElement(Progress, {
            classes: empty(),
        });
    }
    else {
        return createElement("div", createObj(ofArray([["style", {
            marginTop: 20,
            textAlign: "center",
        }], (elems_2 = [createElement("div", createObj(singleton_2((elems = [createElement("img", {
            className: loginLogo,
            alt: "Asbest Consulting Logo",
            src: logo,
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])))), MuiHelpers_createElement(Button, [["color", "default"], ["variant", "contained"], ["onClick", (_arg_2) => {
            auth.loginWithPopup();
        }], ["children", Interop_reactApi.Children.toArray(["Login"])]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
    }
}

export function MainRouter() {
    let elements;
    const auth = useAuth0();
    const patternInput = useFeliz_React__React_useState_Static_1505(RouterModule_urlSegments(window.location.hash, 1));
    const currentUrl = patternInput[0];
    const signatureApi = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(normalizeRoutes, RemotingModule_createApi()), ISignatureApi$reflection());
    return RouterModule_router(createObj(ofArray([["onUrlChanged", patternInput[1]], (elements = toList(delay(() => {
        toConsole(printf("current url %A"))(currentUrl);
        const matchValue = mainRouter(currentUrl);
        let pattern_matching_result, code;
        if (matchValue != null) {
            if (matchValue.tag === 0) {
                pattern_matching_result = 0;
            }
            else if (matchValue.tag === 28) {
                pattern_matching_result = 1;
                code = matchValue.fields[0];
            }
            else {
                pattern_matching_result = 2;
            }
        }
        else {
            pattern_matching_result = 2;
        }
        switch (pattern_matching_result) {
            case 0: {
                return singleton(createElement(Login, null));
            }
            case 1: {
                return singleton(createElement(ClientDigitalSignature, {
                    signatureApi: signatureApi,
                    code: code,
                }));
            }
            case 2: {
                return singleton(createElement(App, null));
            }
        }
    })), ["application", react.createElement(react.Fragment, {}, ...elements)])])));
}

