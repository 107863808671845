import { createElement } from "react";
import { join } from "../fable_modules/fable-library.3.7.17/String.js";
import { map, append, empty, singleton, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { TextSize, Gap, Padding, dashboardFooter, cursorPointer, Boldness } from "../../../Styles/Utils.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import TableRow from "@material-ui/core/TableRow";
import { empty as empty_1, choose, sumBy, ofArray, singleton as singleton_1 } from "../fable_modules/fable-library.3.7.17/List.js";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { adiDetail } from "../AppRoutes.fs.js";
import { tryFind } from "../fable_modules/fable-library.3.7.17/Array.js";
import { equals } from "../fable_modules/fable-library.3.7.17/Util.js";
import { Col, Row } from "../../../Components/UtilComponents.fs.js";
import { DashboardStatType } from "../../../shared/DomainHelpers.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { useReact_useRef_1505, useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { empty as empty_2 } from "../fable_modules/fable-library.3.7.17/Map.js";
import { useSnackbar } from "notistack";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { Page } from "../components/Page.fs.js";

export function cellText(text, isbold) {
    return createElement("div", {
        className: join(" ", toList(delay(() => (isbold ? singleton(Boldness.b500) : empty())))),
        children: Interop_reactApi.Children.toArray([text]),
    });
}

export function Info(infoInputProps) {
    let elems_2, children, children_1;
    const isFooter = infoInputProps.isFooter;
    const primary = infoInputProps.primary;
    const secondary = infoInputProps.secondary;
    return MuiHelpers_createElement(TableRow, [["className", join(" ", toList(delay(() => append(singleton(cursorPointer), delay(() => (isFooter ? singleton(dashboardFooter) : empty()))))))], ["hover", true], ["key", primary], (elems_2 = [(children = singleton_1(cellText(secondary, isFooter)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = singleton_1(cellText(primary, isFooter)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]);
}

export function dashboardTable(body) {
    let elems_2, elems_1;
    const children_1 = singleton_1(MuiHelpers_createElement(TableContainer, [["component", "table"], ["className", join(" ", [Padding.Xs])], (elems_2 = [MuiHelpers_createElement(Table, [["size", "small"], (elems_1 = [MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(body))]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]));
    return MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]);
}

export function AdiList(adiStats) {
    let elems_5, elems_4, children_2;
    const children_3 = singleton_1(MuiHelpers_createElement(TableContainer, [["component", "table"], ["className", join(" ", [Padding.Xs])], (elems_5 = [MuiHelpers_createElement(Table, [["size", "small"], (elems_4 = [(children_2 = map((kv) => {
        let elems_2, children, children_1;
        const adiInfo = kv[0];
        return MuiHelpers_createElement(TableRow, [["className", join(" ", [cursorPointer])], ["hover", true], ["key", `${adiInfo.Id}`], ["onClick", (_arg) => {
            window.open(RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(adiDetail, adiInfo.Id.Value)), 1), "_blank");
        }], (elems_2 = [(children = singleton_1(cellText(`${adiInfo.Email.Value} (${adiInfo.Name.Value})`, false)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = singleton_1(cellText(`${kv[1]}`, false)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]);
    }, adiStats), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]));
    return MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]);
}

export function DashboardInfo(dashboardInfoInputProps) {
    const adiStats = dashboardInfoInputProps.adiStats;
    const dashboard = dashboardInfoInputProps.dashboard;
    const getResult = (dashboard_1, statsType) => {
        const stats = tryFind((a) => equals(a.DashboardStatType, statsType), dashboard_1);
        if (stats == null) {
            return "Couldn\u0027t find result";
        }
        else {
            const matchValue = stats.DashboardStat;
            if (matchValue.tag === 1) {
                return `An error occured! ${matchValue.fields[0]}`;
            }
            else {
                return `${matchValue.fields[0]}`;
            }
        }
    };
    const getResultSum = (dashboard_2, stats_2) => sumBy((a_2) => a_2, choose((a_1) => {
        const stats_3 = tryFind((s) => equals(s.DashboardStatType, a_1), dashboard_2);
        if (stats_3 == null) {
            return void 0;
        }
        else {
            const matchValue_1 = stats_3.DashboardStat;
            if (matchValue_1.tag === 1) {
                return void 0;
            }
            else {
                return matchValue_1.fields[0];
            }
        }
    }, stats_2), {
        GetZero: () => 0,
        Add: (x, y) => (x + y),
    });
    if (dashboard == null) {
        return "In opbouw";
    }
    else {
        const dashboard_3 = dashboard;
        return createElement(Row, {
            classes: singleton_1(Gap.L),
            children: ofArray([createElement(Col, {
                classes: empty_1(),
                children: singleton_1(createElement(Row, {
                    classes: singleton_1(Gap.L),
                    children: ofArray([createElement(Col, {
                        classes: empty_1(),
                        children: singleton_1(dashboardTable(ofArray([createElement(Info, {
                            secondary: "Dossiers opgestart niet digitaal ondertekend",
                            primary: `${getResult(dashboard_3, new DashboardStatType(0))}`,
                            isFooter: false,
                        }), createElement(Info, {
                            secondary: "Dossiers digitaal ondertekend zonder afspraak",
                            primary: `${getResult(dashboard_3, new DashboardStatType(1))}`,
                            isFooter: false,
                        }), createElement(Info, {
                            secondary: "Dossiers met afspraak te maken (totaal)",
                            primary: `${getResultSum(dashboard_3, ofArray([new DashboardStatType(0), new DashboardStatType(1)]))}`,
                            isFooter: true,
                        })]))),
                    }), createElement(Col, {
                        classes: empty_1(),
                        children: singleton_1(dashboardTable(ofArray([createElement(Info, {
                            secondary: "Geplande afspraken",
                            primary: `${getResult(dashboard_3, new DashboardStatType(2))}`,
                            isFooter: false,
                        }), createElement(Info, {
                            secondary: "Afspraken te vervolledigen (zonder opdrachtformulier of monsters)",
                            primary: `${getResult(dashboard_3, new DashboardStatType(3))}`,
                            isFooter: false,
                        }), createElement(Info, {
                            secondary: "Afspraken te vervolledigen (zonder opdrachtformulier)",
                            primary: `${getResult(dashboard_3, new DashboardStatType(4))}`,
                            isFooter: false,
                        }), createElement(Info, {
                            secondary: "Afspraken te vervolledigen (zonder monsters)",
                            primary: `${getResult(dashboard_3, new DashboardStatType(5))}`,
                            isFooter: false,
                        }), createElement(Info, {
                            secondary: "Afspraken te vervolledigen (totaal)",
                            primary: `${getResultSum(dashboard_3, ofArray([new DashboardStatType(3), new DashboardStatType(4), new DashboardStatType(5)]))}`,
                            isFooter: true,
                        })]))),
                    })]),
                })),
            }), createElement(Col, {
                classes: empty_1(),
                children: singleton_1(dashboardTable(ofArray([createElement(Info, {
                    secondary: "Dossiers betaald",
                    primary: `${getResult(dashboard_3, new DashboardStatType(6))}`,
                    isFooter: false,
                }), createElement(Info, {
                    secondary: "Dossiers afgewerkt",
                    primary: `${getResult(dashboard_3, new DashboardStatType(7))}`,
                    isFooter: false,
                }), createElement(Info, {
                    secondary: "Dossiers in orde (totaal)",
                    primary: `${getResultSum(dashboard_3, ofArray([new DashboardStatType(6), new DashboardStatType(7)]))}`,
                    isFooter: true,
                })]))),
            }), createElement(Col, {
                classes: singleton_1(Gap.S),
                children: ofArray([createElement("b", {
                    className: join(" ", [TextSize.M]),
                    children: "Dossiers afgewerkt door adi",
                }), AdiList(adiStats)]),
            })]),
        });
    }
}

export function DashboardData(api) {
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetDashboard(), []);
    const patternInput = useFeliz_React__React_useState_Static_1505(empty_2());
    const snackbar = useSnackbar();
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_2.Delay(() => singleton_2.Bind(api.GetFinalizedDossiersFromAdis(), (_arg) => {
            const adiList_1 = _arg;
            if (adiList_1.tag === 1) {
                console.error(adiList_1.fields[0]);
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${adiList_1.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                return singleton_2.Zero();
            }
            else if (adiList_1.fields[0] == null) {
                console.error("Permission denied.");
                return singleton_2.Zero();
            }
            else if (adiList_1.fields[0].tag === 1) {
                const err = adiList_1.fields[0].fields[0];
                console.error(err);
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                return singleton_2.Zero();
            }
            else {
                const adiList_2 = adiList_1.fields[0].fields[0];
                patternInput[1](adiList_2);
                return singleton_2.Zero();
            }
        })));
    }, []);
    return createElement(DisplayDeferred, {
        data: data,
        view: (dashboard) => createElement(DashboardInfo, {
            dashboard: dashboard,
            adiStats: patternInput[0],
        }),
    });
}

export function Dashboard(dashboardInputProps) {
    const api = dashboardInputProps.api;
    const currentPage = dashboardInputProps.currentPage;
    const inputRef2 = useReact_useRef_1505(void 0);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Dashboard",
        actions: [],
        children: [createElement(DashboardData, api)],
    });
}

