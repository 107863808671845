import { useAuth0 } from "@auth0/auth0-react";
import { createElement } from "react";
import { ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";

export function Login() {
    const auth = useAuth0();
    const children = ofArray([createElement("h1", {
        children: ["Login"],
    }), createElement("button", {
        children: "Login",
        onClick: (_arg) => {
            auth.loginWithPopup();
        },
    }), createElement("button", {
        children: "Logout",
        onClick: (_arg_1) => {
            auth.logout();
        },
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

