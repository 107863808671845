import { useSnackbar } from "notistack";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { createElement } from "react";
import { Page } from "../components/Page.fs.js";
import { Card } from "../../../DesignSystems/DesignSystem.fs.js";
import { CreateSyndicForm } from "../../../DesignSystems/partner/PartnerForms.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { printf, toConsole } from "../fable_modules/fable-library.3.7.17/String.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { iterate } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { toArray } from "../fable_modules/fable-library.3.7.17/Option.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { partners, syndicsDetail } from "../AppRoutes.fs.js";

export function SyndicCreate(syndicCreateInputProps) {
    const api = syndicCreateInputProps.api;
    const currentPage = syndicCreateInputProps.currentPage;
    const snackbar = useSnackbar();
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetAdis(new PagingQuery(0, 999), void 0), []);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Syndic aanmaken",
        actions: [],
        children: [Card([createElement(CreateSyndicForm, {
            onCreatePartner: (partnerData) => ((onCompleted) => {
                startImmediate(singleton.Delay(() => singleton.TryFinally(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
                    toConsole(printf("partnerdata %A"))(partnerData);
                    return singleton.Bind(api.VerifyEmail(partnerData.Email), (_arg) => {
                        const verifyEmail = _arg;
                        if (verifyEmail.tag === 0) {
                            const verResponse = verifyEmail.fields[0];
                            if (verResponse.tag === 1) {
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `E-mailadres: ${verResponse.fields[0]}`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                return singleton.Zero();
                            }
                            else {
                                iterate((additionalInfo) => {
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `E-mailadres: ${additionalInfo}`, SnackbarProp_Variant_Z609E1E86("warning"), new EnqueueSnackbarOption(1, false));
                                }, toArray(verResponse.fields[0]));
                                return singleton.Bind(api.CreatePartner(partnerData), (_arg_1) => {
                                    const result = _arg_1;
                                    if (result.tag === 0) {
                                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een nieuw partner is aangemaakt", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                        RouterModule_nav(ofArray(Page$2__toUrl_2B594(syndicsDetail, result.fields[0].Value)), 1, 1);
                                        return singleton.Zero();
                                    }
                                    else {
                                        const err_1 = result.fields[0];
                                        console.error(err_1);
                                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${err_1}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                        return singleton.Zero();
                                    }
                                });
                            }
                        }
                        else {
                            const err = verifyEmail.fields[0];
                            console.error(err);
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                            return singleton.Zero();
                        }
                    });
                }), (_arg_2) => {
                    const e = _arg_2;
                    console.error(e);
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    return singleton.Zero();
                })), () => {
                    onCompleted();
                })));
            }),
            showActionButtons: true,
            saveButtonText: "Bewaren",
            isCibPartner: false,
            showCibPartner: true,
            onCompleted: () => {
                RouterModule_nav(ofArray(Page$2__toUrl_2B594(partners)), 1, 1);
            },
            data: data,
        })])],
    });
}

