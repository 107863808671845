import { join, isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.17/String.js";
import { createElement } from "react";
import * as react from "react";
import { Col } from "../../../Components/UtilComponents.fs.js";
import { alignEnd, cursorPointer, TableHeaderCell } from "../../../Styles/Utils.fs.js";
import { empty, ofArray, singleton } from "../fable_modules/fable-library.3.7.17/List.js";
import { TableHeader, FilterField } from "../../../DesignSystems/DesignSystem.fs.js";
import { defaultArg } from "../fable_modules/fable-library.3.7.17/Option.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import TableCell from "@material-ui/core/TableCell";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { AdiListSearchTerms } from "../../../shared/DomainHelpers.fs.js";
import { useDebouncedValue } from "../../../CustomHooks/CustomHooks.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { map, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { adiCreate, adiDetail } from "../AppRoutes.fs.js";
import { Email_email, Address_address, Adi_name } from "../../../shared/Format.fs.js";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";
import { Page } from "../components/Page.fs.js";
import Paper from "@material-ui/core/Paper";

export function updateTerm(text, setTerm) {
    setTerm(isNullOrWhiteSpace(text) ? (void 0) : text);
}

export function TableHeaders(tableHeadersInputProps) {
    let children_4, children, children_1, children_2, children_3;
    const setSearchTermEmail = tableHeadersInputProps.setSearchTermEmail;
    const searchTermEmail = tableHeadersInputProps.searchTermEmail;
    const setSearchTermTelephone = tableHeadersInputProps.setSearchTermTelephone;
    const searchTermTelephone = tableHeadersInputProps.searchTermTelephone;
    const setSearchTermAddress = tableHeadersInputProps.setSearchTermAddress;
    const searchTermAddress = tableHeadersInputProps.searchTermAddress;
    const setSearchTermNaam = tableHeadersInputProps.setSearchTermNaam;
    const searchTermNaam = tableHeadersInputProps.searchTermNaam;
    const children_5 = singleton((children_4 = ofArray([(children = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s) => {
            updateTerm(s, setSearchTermNaam);
        }, defaultArg(searchTermNaam, "")), createElement(TableHeader, {
            text: "Naam",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s_1) => {
            updateTerm(s_1, setSearchTermAddress);
        }, defaultArg(searchTermAddress, "")), createElement(TableHeader, {
            text: "Adres",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), (children_2 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s_2) => {
            updateTerm(s_2, setSearchTermTelephone);
        }, defaultArg(searchTermTelephone, "")), createElement(TableHeader, {
            text: "Telehone",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]])), (children_3 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s_3) => {
            updateTerm(s_3, setSearchTermEmail);
        }, defaultArg(searchTermEmail, "")), createElement(TableHeader, {
            text: "Email",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]])));
    return MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]]);
}

export function AdisTable(api) {
    let elems_7, elems_6, children_5;
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const pageNumber = patternInput[0] | 0;
    const pageSize = useFeliz_React__React_useState_Static_1505(25)[0] | 0;
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermNaam = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermAddress = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermTelephone = patternInput_4[0];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermEmail = patternInput_5[0];
    const headers = createElement(TableHeaders, {
        searchTermNaam: searchTermNaam,
        setSearchTermNaam: patternInput_2[1],
        searchTermAddress: searchTermAddress,
        setSearchTermAddress: patternInput_3[1],
        searchTermTelephone: searchTermTelephone,
        setSearchTermTelephone: patternInput_4[1],
        searchTermEmail: searchTermEmail,
        setSearchTermEmail: patternInput_5[1],
    });
    const searchTerms = useReact_useMemo_CF4EA67(() => (new AdiListSearchTerms(searchTermNaam, searchTermAddress, searchTermTelephone, searchTermEmail)), [searchTermNaam, searchTermAddress, searchTermTelephone, searchTermEmail]);
    const searchTermsDebounced = useDebouncedValue(500, searchTerms);
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetAdis(new PagingQuery(pageNumber, pageSize), searchTerms), [pageNumber, pageSize, searchTermsDebounced]);
    const xs_2 = [MuiHelpers_createElement(TableContainer, [["component", "table"], (elems_7 = [MuiHelpers_createElement(Table, [["size", "small"], (elems_6 = [headers, (children_5 = singleton(createElement(DisplayDeferred, {
        data: data,
        view: (adis) => {
            const xs_1 = toList(delay(() => map((adi) => {
                let elems_4, children, children_1, children_3;
                return MuiHelpers_createElement(TableRow, [["className", join(" ", [cursorPointer])], ["hover", true], ["onClick", (_arg) => {
                    RouterModule_nav(ofArray(Page$2__toUrl_2B594(adiDetail, adi.Id.Value)), 1, 1);
                }], ["key", adi.Id.Value], (elems_4 = [(children = singleton(Adi_name(adi)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = singleton(Address_address(adi.Address)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([adi.PhoneNumber.Value])]]), (children_3 = singleton(Email_email(adi.Email)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]);
            }, adis.Data)));
            return react.createElement(react.Fragment, {}, ...xs_1);
        },
    })), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]), createElement(Col, {
        classes: empty(),
        children: singleton(createElement(DisplayDeferred, {
            data: data,
            view: (adis_1) => {
                let values;
                return MuiHelpers_createElement(TablePagination, [["className", join(" ", [alignEnd])], ["rowsPerPage", pageSize], ["page", pageNumber], ["count", adis_1.TotalPages], ["onChangePage", (_arg_1, v) => {
                    patternInput[1](v);
                }], (values = (new Int32Array([])), ["rowsPerPageOptions", Array.from(values)]), ["onChangeRowsPerPage", (value_30) => {
                }]]);
            },
        })),
    })];
    return react.createElement(react.Fragment, {}, ...xs_2);
}

export function Adis(adisInputProps) {
    let props, children_1;
    const api = adisInputProps.api;
    const currentPage = adisInputProps.currentPage;
    const addNewAdiButton = MuiHelpers_createElement(Button, [["onClick", (_arg) => {
        RouterModule_nav(ofArray(Page$2__toUrl_2B594(adiCreate)), 1, 1);
    }], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props = {}, react.createElement(Add, props))], ["children", "Adi aanmaken"]]);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Adi",
        actions: [addNewAdiButton],
        children: [(children_1 = singleton(createElement(AdisTable, api)), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))],
    });
}

