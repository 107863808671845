import { Union, Record } from "../../client/src/fable_modules/fable-library.3.7.17/Types.js";
import { union_type, record_type, option_type, bool_type, int32_type, string_type } from "../../client/src/fable_modules/fable-library.3.7.17/Reflection.js";
import { PartnerId$reflection, Partner$reflection, PartnerType as PartnerType_1, Compensation$reflection, PartnerType$reflection as PartnerType$reflection_1 } from "../../shared/Domain.fs.js";
import { PagingResult$1$reflection } from "../../shared/Paging.fs.js";
import { FSharpResult$2 } from "../../client/src/fable_modules/fable-library.3.7.17/Choice.js";
import { Deferred$1$reflection } from "../../client/src/fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { tryFind } from "../../client/src/fable_modules/fable-library.3.7.17/List.js";
import { equals } from "../../client/src/fable_modules/fable-library.3.7.17/Util.js";
import { MuiHelpers_createElement } from "../../client/src/fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Checkbox from "@material-ui/core/Checkbox";
import { join } from "../../client/src/fable_modules/fable-library.3.7.17/String.js";
import { empty, singleton, delay, toList } from "../../client/src/fable_modules/fable-library.3.7.17/Seq.js";
import { cursorDefault } from "../../Styles/Utils.fs.js";
import { remove, add, contains } from "../../client/src/fable_modules/fable-library.3.7.17/Set.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Browser_Types_Event__Event_get_Checked } from "../../client/src/fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";

export class PartnerOption extends Record {
    constructor(Label, Id, IsCibPartner, PartnerType, CompensationAmount) {
        super();
        this.Label = Label;
        this.Id = (Id | 0);
        this.IsCibPartner = IsCibPartner;
        this.PartnerType = PartnerType;
        this.CompensationAmount = CompensationAmount;
    }
}

export function PartnerOption$reflection() {
    return record_type("PartnerUtils.PartnerOption", [], PartnerOption, () => [["Label", string_type], ["Id", int32_type], ["IsCibPartner", bool_type], ["PartnerType", PartnerType$reflection_1()], ["CompensationAmount", option_type(Compensation$reflection())]]);
}

export function PartnerOption_get_empty() {
    return new PartnerOption("Leeg", -1, false, new PartnerType_1(0), void 0);
}

export function PartnerOption_get_none() {
    return new PartnerOption("Geen", 0, false, new PartnerType_1(0), void 0);
}

export class PartnerType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Partners", "PartnerId"];
    }
}

export function PartnerType$reflection() {
    return union_type("PartnerUtils.PartnerType", [], PartnerType, () => [[["Item", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [PagingResult$1$reflection(Partner$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", PagingResult$1$reflection(Partner$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", PartnerId$reflection()]]]);
}

export function filterPartnerOptions(opts, searchTerm) {
    return opts.filter((a) => {
        const label = a.IsCibPartner ? (`CIB ${a.Label}`) : a.Label;
        if (label.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0) {
            return true;
        }
        else {
            return false;
        }
    });
}

export function getPartnerType(partnerId, partnersList) {
    if (partnersList == null) {
        return "";
    }
    else {
        const partner = tryFind((p) => equals(p.Id, partnerId), partnersList);
        if (partner == null) {
            return "";
        }
        else {
            const partner_1 = partner;
            if (partner_1.PartnerType.tag === 1) {
                return " (Syndicus) ";
            }
            else {
                return " (Makelaar) ";
            }
        }
    }
}

export function AdiCheckbox(adiCheckboxInputProps) {
    const disabled = adiCheckboxInputProps.disabled;
    const setAdis = adiCheckboxInputProps.setAdis;
    const values = adiCheckboxInputProps.values;
    const adiValue = adiCheckboxInputProps.adiValue;
    const label = adiCheckboxInputProps.label;
    const radi = MuiHelpers_createElement(Checkbox, [["className", join(" ", toList(delay(() => (disabled ? singleton(cursorDefault) : empty()))))], ["checked", (adiValue == null) ? false : (contains(adiValue, values))]]);
    return MuiHelpers_createElement(FormControlLabel, [["className", join(" ", toList(delay(() => (disabled ? singleton(cursorDefault) : empty()))))], ["label", label], ["control", radi], ["onChange", (e) => {
        const s = Browser_Types_Event__Event_get_Checked(e);
        if (!disabled) {
            if (adiValue == null) {
            }
            else {
                const r = adiValue;
                if (s) {
                    setAdis(add(r, values));
                }
                else {
                    setAdis(remove(r, values));
                }
            }
        }
    }]]);
}

