import { createElement } from "react";
import * as react from "react";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { downloadFile } from "./DownloadFile.fs.js";
import { useSnackbar } from "notistack";
import { useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { useDebouncedValue, useToggle } from "../../../CustomHooks/CustomHooks.fs.js";
import { useFormField } from "../../../CustomHooks/ComposedForm.fs.js";
import { DossierCode, PhoneNumber___UnsafeUnwrap_23E45174, AsbestosConclusion, SelectOptionData$1_Create_Z510A3DC0 } from "../../../shared/Domain.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Modal from "@material-ui/core/Modal";
import { comparePrimitives, equals, createObj } from "../fable_modules/fable-library.3.7.17/Util.js";
import { isNullOrWhiteSpace, printf, toConsole, join } from "../fable_modules/fable-library.3.7.17/String.js";
import { alignEnd, TableHeaderCell, cursorPointer, MarginLeft, fullWidth, Gap, MarginTop, Boldness, flexExpand, TextSize, MarginBottom, PaddingRight, PaddingLeft, PaddingBottom, PaddingTop, modal } from "../../../Styles/Utils.fs.js";
import { map as map_1, iterate, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { DateParsing_parse, Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { sortByDescending, singleton as singleton_1, empty, ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import { GeneralFormats_dateTimeStampShort, GeneralFormats_dateTimeOffset, Address_address } from "../../../shared/Format.fs.js";
import { fromDate } from "../fable_modules/fable-library.3.7.17/DateOffset.js";
import { map, defaultArg, toArray } from "../fable_modules/fable-library.3.7.17/Option.js";
import RadioGroup from "@material-ui/core/RadioGroup";
import { TableHeader, FilterField, CheckIcon, OptionField } from "../../../DesignSystems/DesignSystem.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Browser_Types_Event__Event_get_Checked } from "../fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import Button from "@material-ui/core/Button";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { Browser_Types_File__File_ReadAsByteArray } from "../fable_modules/Fable.Remoting.Client.7.25.0/Extensions.fs.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import TableRow from "@material-ui/core/TableRow";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { dossierDetail } from "../AppRoutes.fs.js";
import TableCell from "@material-ui/core/TableCell";
import { fromDateTimeOffset } from "../fable_modules/fable-library.3.7.17/Date.js";
import CloudUpload from "@material-ui/icons/CloudUpload";
import { AttestenFinaliserenSortFields, AttestenFinaliserenSearchTerms } from "../../../shared/DomainHelpers.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { SortField$1, SortOrder } from "../../../shared/Sorting.fs.js";
import TableHead from "@material-ui/core/TableHead";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { Page } from "../components/Page.fs.js";
import Paper from "@material-ui/core/Paper";

export function DownloadLink(api, filePath) {
    if (filePath == null) {
        return null;
    }
    else {
        const filePath_1 = filePath;
        return createElement("a", {
            target: "_blank",
            href: "#",
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                startImmediate(downloadFile(api, filePath_1));
            },
            children: "Download",
        });
    }
}

export function UploadDocumentModal(uploadDocumentModalInputProps) {
    let elems_4;
    const dossier = uploadDocumentModalInputProps.dossier;
    const setCertificateUploaded = uploadDocumentModalInputProps.setCertificateUploaded;
    const setShowUploadDocumentModal = uploadDocumentModalInputProps.setShowUploadDocumentModal;
    const showUploadDocumentModal = uploadDocumentModalInputProps.showUploadDocumentModal;
    const dossierCode = uploadDocumentModalInputProps.dossierCode;
    const api = uploadDocumentModalInputProps.api;
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const file = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const buttonsDisabled = useToggle(false);
    const sendEmailToClient = useToggle(true);
    const sendEmailToPartner = useToggle(true);
    const conclusion = useFormField(void 0, SelectOptionData$1_Create_Z510A3DC0);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const certDate = patternInput_2[0];
    return MuiHelpers_createElement(Modal, [["open", showUploadDocumentModal], ["onClose", (_arg_5, v) => {
        setShowUploadDocumentModal(false);
    }], ["onClick", (e_2) => {
        e_2.stopPropagation();
    }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.M, PaddingBottom.L, PaddingLeft.L, PaddingRight.L])], ["onClick", (e_3) => {
        e_3.stopPropagation();
    }], (elems_4 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["className", join(" ", [MarginBottom.M])], (elems = [createElement("h3", {
            children: ["Upload Attest"],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_2;
            return append(singleton(createElement(Col, {
                classes: empty(),
                children: singleton_1(createElement(Row, {
                    classes: ofArray([TextSize.L, MarginBottom.M]),
                    children: singleton_1(createElement(Col, {
                        classes: singleton_1(flexExpand),
                        children: ofArray([createElement("div", {
                            className: join(" ", [Boldness.b700, MarginBottom.Xs]),
                            children: Interop_reactApi.Children.toArray(["Inspectielocatie"]),
                        }), createElement("div", createObj(ofArray([["className", join(" ", [Boldness.b500, MarginBottom.Xs])], (elems_2 = [Address_address(dossier.AppointmentAddress)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))]),
                    })),
                })),
            })), delay(() => {
                let dt;
                return append(singleton(createElement(Col, {
                    classes: ofArray([MarginTop.M, Gap.S]),
                    children: ofArray([createElement("b", {
                        children: ["Certificaat datum"],
                    }), createElement("input", {
                        type: "date",
                        onChange: (ev) => {
                            iterate((n) => {
                                patternInput_2[1](fromDate(n));
                            }, toArray(DateParsing_parse(ev.target.value)));
                        },
                        className: join(" ", [fullWidth]),
                        value: (certDate == null) ? "" : ((dt = certDate, (toConsole(printf("date1 %A"))(dt), GeneralFormats_dateTimeOffset(dt)))),
                    })]),
                })), delay(() => {
                    let elements;
                    return append(singleton(createElement(Col, {
                        classes: singleton_1(MarginTop.M),
                        children: ofArray([createElement("b", {
                            children: ["Conclusie asbest"],
                        }), MuiHelpers_createElement(RadioGroup, [["row", true], (elements = [OptionField(conclusion, new AsbestosConclusion(0), "Veilig", "", true), OptionField(conclusion, new AsbestosConclusion(1), "Onveilig", "", true)], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])]),
                    })), delay(() => append(singleton(createElement(Row, {
                        classes: empty(),
                        children: singleton_1(createElement("input", {
                            type: "file",
                            onClick: (e_4) => {
                                e_4.stopPropagation();
                            },
                            onChange: (ev_1) => {
                                const files = ev_1.target.files;
                                if ((!(files == null)) && (files.length > 0)) {
                                    patternInput[1](files.item(0));
                                }
                            },
                        })),
                    })), delay(() => append(singleton(createElement(Row, {
                        classes: singleton_1(MarginTop.M),
                        children: singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", "Stuur een e-mail naar de klant"], ["control", MuiHelpers_createElement(Checkbox, [["checked", sendEmailToClient.State]])], ["onChange", (e_5) => {
                            if (Browser_Types_Event__Event_get_Checked(e_5)) {
                                sendEmailToClient.On();
                            }
                            else {
                                sendEmailToClient.Off();
                            }
                        }]])),
                    })), delay(() => {
                        let matchValue_1, p;
                        return append((matchValue_1 = dossier.PartnerId, (matchValue_1 == null) ? singleton(null) : ((p = matchValue_1, singleton(createElement(Row, {
                            classes: empty(),
                            children: singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", "Stuur een e-mail naar de partner"], ["control", MuiHelpers_createElement(Checkbox, [["checked", sendEmailToPartner.State]])], ["onChange", (e_6) => {
                                if (Browser_Types_Event__Event_get_Checked(e_6)) {
                                    sendEmailToPartner.On();
                                }
                                else {
                                    sendEmailToPartner.Off();
                                }
                            }]])),
                        }))))), delay(() => singleton(createElement(Row, {
                            classes: singleton_1(MarginTop.L),
                            children: ofArray([MuiHelpers_createElement(Button, [["onClick", (e_7) => {
                                e_7.stopPropagation();
                                startImmediate(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => {
                                    let file_1;
                                    const matchValue = [file, conclusion.FieldValue, certDate];
                                    let pattern_matching_result_1, certDate_1, conclusion_2, file_2;
                                    if (matchValue[0] == null) {
                                        pattern_matching_result_1 = 1;
                                    }
                                    else if (matchValue[1] != null) {
                                        if (matchValue[2] != null) {
                                            pattern_matching_result_1 = 0;
                                            certDate_1 = matchValue[2];
                                            conclusion_2 = matchValue[1];
                                            file_2 = matchValue[0];
                                        }
                                        else {
                                            pattern_matching_result_1 = 2;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_1 = 2;
                                    }
                                    switch (pattern_matching_result_1) {
                                        case 0: {
                                            startImmediate((file_1 = file_2, singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => {
                                                buttonsDisabled.On();
                                                return singleton_2.Bind(Browser_Types_File__File_ReadAsByteArray(file_1), (_arg) => singleton_2.Bind(api.UploadCertificate(dossierCode, conclusion_2, certDate_1, sendEmailToClient.State, sendEmailToPartner.State, _arg, file_1.name), (_arg_1) => {
                                                    const output = _arg_1;
                                                    buttonsDisabled.Off();
                                                    if (output.tag === 1) {
                                                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${output.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                                        return singleton_2.Zero();
                                                    }
                                                    else {
                                                        setCertificateUploaded(true);
                                                        setShowUploadDocumentModal(false);
                                                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Bestand opgeladen", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                                        return singleton_2.Zero();
                                                    }
                                                }));
                                            }), (_arg_2) => {
                                                const e = _arg_2;
                                                buttonsDisabled.Off();
                                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `TEen onbekende fout is opgetreden. Gelieve support te contacteren. [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                                console.log(e);
                                                return singleton_2.Zero();
                                            }))));
                                            return singleton_2.Zero();
                                        }
                                        case 1: {
                                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Selecteer een bestand", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                            return singleton_2.Zero();
                                        }
                                        case 2: {
                                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Zorg ervoor dat de certificaatdatum is ingevoerd en dat de asbestconclusie is geselecteerd", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                            return singleton_2.Zero();
                                        }
                                    }
                                }), (_arg_3) => {
                                    const e_1 = _arg_3;
                                    setShowUploadDocumentModal(false);
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden tijdens het bevestigen van de afspraak. Gelieve support te contacteren. [${e_1.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                    console.log(e_1);
                                    return singleton_2.Zero();
                                })));
                            }], ["className", join(" ", [])], ["disabled", buttonsDisabled.State ? true : (file == null)], ["variant", "contained"], ["color", "secondary"], ["children", "Upload"]]), MuiHelpers_createElement(Button, [["onClick", (e_8) => {
                                e_8.stopPropagation();
                                setShowUploadDocumentModal(false);
                            }], ["disabled", buttonsDisabled.State], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "primary"], ["children", "Cancel"]])]),
                        }))));
                    }))))));
                }));
            }));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))]]);
}

export function DossierRow(dossierRowInputProps) {
    let elems_8;
    const dossier = dossierRowInputProps.dossier;
    const api = dossierRowInputProps.api;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setShowUploadDocumentModal = patternInput_1[1];
    if (patternInput[0]) {
        return null;
    }
    else {
        const xs_1 = [createElement(UploadDocumentModal, {
            api: api,
            dossierCode: dossier.DossierCode,
            showUploadDocumentModal: patternInput_1[0],
            setShowUploadDocumentModal: setShowUploadDocumentModal,
            setCertificateUploaded: patternInput[1],
            dossier: dossier,
        }), MuiHelpers_createElement(TableRow, [["className", join(" ", [cursorPointer])], ["hover", true], ["onClick", (_arg) => {
            RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossierDetail, dossier.DossierCode.Value)), 1, 1);
        }], ["key", dossier.DossierId.Value], (elems_8 = toList(delay(() => append(singleton(MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([dossier.DossierCode.Value])]])), delay(() => append(singleton(MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([dossier.CustomerName.Value])]])), delay(() => {
            let children_2;
            return append(singleton((children_2 = singleton_1(Address_address(dossier.AppointmentAddress)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))), delay(() => {
                let children_3;
                return append(singleton((children_3 = toList(delay(() => {
                    const matchValue = dossier.CustomerPhoneNumber;
                    return (matchValue == null) ? singleton("-") : singleton(PhoneNumber___UnsafeUnwrap_23E45174(matchValue));
                })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))), delay(() => {
                    let children_4;
                    return append(singleton((children_4 = toList(delay(() => {
                        const matchValue_1 = dossier.PaymentReceivedDate;
                        return (matchValue_1 == null) ? singleton("-") : singleton(GeneralFormats_dateTimeStampShort(fromDateTimeOffset(matchValue_1, 0)));
                    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))), delay(() => {
                        let children_5;
                        return append(singleton((children_5 = toList(delay(() => ((dossier.AssignmentConfirmationPhysicallySigned == null) ? singleton(createElement(CheckIcon, {
                            props: [],
                            value: false,
                        })) : singleton(createElement(CheckIcon, {
                            props: [],
                            value: true,
                        }))))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]]))), delay(() => {
                            let children_7, props_2;
                            return append(singleton((children_7 = singleton_1(MuiHelpers_createElement(Button, [["onClick", (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setShowUploadDocumentModal(true);
                            }], ["variant", "contained"], ["color", "default"], ["size", "small"], ["startIcon", (props_2 = {}, react.createElement(CloudUpload, props_2))], ["children", "Upload"]])), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_7))]]))), delay(() => {
                                let children_8, matchValue_3;
                                return singleton((children_8 = singleton_1(DownloadLink(api, (matchValue_3 = dossier.AssignmentConfirmationPhysicallySigned, (matchValue_3 == null) ? (void 0) : matchValue_3))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_8))]])));
                            }));
                        }));
                    }));
                }));
            }));
        })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])])];
        return react.createElement(react.Fragment, {}, ...xs_1);
    }
}

export function AttestenFinaliserenData(api) {
    let elems_12, elems_11, children_9, children_8, children, children_1, children_2, children_3, children_4, children_5, children_6, children_7, children_11;
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const pageNumber = patternInput[0] | 0;
    const pageSize = useFeliz_React__React_useState_Static_1505(25)[0] | 0;
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const setSearchTermDossierNummer = patternInput_2[1];
    const searchTermDossierNummer = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermName = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermAddress = patternInput_4[0];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermPhone = patternInput_5[0];
    const searchTermEmail = useFeliz_React__React_useState_Static_1505(void 0)[0];
    const patternInput_7 = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_8 = useFeliz_React__React_useState_Static_1505(void 0);
    const sortField = patternInput_8[0];
    const search = useDebouncedValue(500, useReact_useMemo_CF4EA67(() => (new AttestenFinaliserenSearchTerms(searchTermDossierNummer, searchTermName, searchTermAddress, searchTermPhone, searchTermEmail)), [searchTermDossierNummer, searchTermName, searchTermAddress, searchTermPhone, searchTermEmail]));
    const updateTerm = (text, setTerm) => {
        setTerm(isNullOrWhiteSpace(text) ? (void 0) : text);
    };
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetFinalCertificateDossiers(new PagingQuery(pageNumber, pageSize), search, sortField), [pageNumber, pageSize, search, sortField]);
    const xs_6 = [MuiHelpers_createElement(TableContainer, [["component", "table"], (elems_12 = [MuiHelpers_createElement(Table, [["size", "small"], (elems_11 = [(children_9 = singleton_1((children_8 = ofArray([(children = singleton_1(createElement(Col, {
        classes: singleton_1(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s_2) => {
            const s = s_2;
            if (isNullOrWhiteSpace(s)) {
                setSearchTermDossierNummer(void 0);
            }
            else {
                setSearchTermDossierNummer(new DossierCode(s));
            }
        }, defaultArg(map((d) => d.Value, searchTermDossierNummer), "")), createElement(TableHeader, {
            text: "Dossier nummer",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = singleton_1(createElement(Col, {
        classes: singleton_1(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s_3) => {
            updateTerm(s_3, patternInput_3[1]);
        }, defaultArg(searchTermName, "")), createElement(TableHeader, {
            text: "Naam",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), (children_2 = singleton_1(createElement(Col, {
        classes: singleton_1(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s_4) => {
            updateTerm(s_4, patternInput_4[1]);
        }, defaultArg(searchTermAddress, "")), createElement(TableHeader, {
            text: "Adres",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]])), (children_3 = singleton_1(createElement(Col, {
        classes: singleton_1(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s_5) => {
            updateTerm(s_5, patternInput_5[1]);
        }, defaultArg(searchTermPhone, "")), createElement(TableHeader, {
            text: "Tel.",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]])), (children_4 = singleton_1(createElement(Col, {
        classes: singleton_1(TableHeaderCell),
        children: ofArray([createElement("div", {}), createElement("div", {
            className: join(" ", [Boldness.b600]),
            children: "Betaaldatum Factuur",
            onClick: (c) => {
                let s_8, s_9;
                patternInput_8[1](new SortField$1(new AttestenFinaliserenSortFields(0), (sortField != null) ? (equals(sortField.SortOrder, new SortOrder(0)) ? ((s_8 = sortField, new SortOrder(1))) : (equals(sortField.SortOrder, new SortOrder(1)) ? ((s_9 = sortField, new SortOrder(0))) : (new SortOrder(0)))) : (new SortOrder(0))));
            },
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]])), (children_5 = singleton_1(createElement(Col, {
        classes: singleton_1(TableHeaderCell),
        children: ofArray([createElement("div", {}), createElement(TableHeader, {
            text: "Opdrachtformulier",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]])), (children_6 = singleton_1(createElement(Col, {
        classes: singleton_1(TableHeaderCell),
        children: ofArray([createElement("div", {}), createElement(TableHeader, {
            text: "Upload Attest",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_6))]])), (children_7 = singleton_1(createElement(Col, {
        classes: singleton_1(TableHeaderCell),
        children: ofArray([createElement("div", {}), createElement(TableHeader, {
            text: "Opdrachtformulier",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_7))]]))]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_8))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_9))]])), (children_11 = singleton_1(createElement(DisplayDeferred, {
        data: data,
        view: (dossiers) => {
            const xs_5 = toList(delay(() => map_1((dossier) => createElement(DossierRow, {
                api: api,
                dossier: dossier,
            }), sortByDescending((s_10) => s_10.DossierId.Value, dossiers.Data, {
                Compare: comparePrimitives,
            }))));
            return react.createElement(react.Fragment, {}, ...xs_5);
        },
    })), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_11))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_11))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_12))])]), createElement(Col, {
        classes: empty(),
        children: singleton_1(createElement(DisplayDeferred, {
            data: data,
            view: (dossiers_1) => {
                let values;
                return MuiHelpers_createElement(TablePagination, [["className", join(" ", [alignEnd])], ["rowsPerPage", pageSize], ["page", pageNumber], ["count", dossiers_1.TotalPages], ["onChangePage", (_arg, v) => {
                    patternInput[1](v);
                }], (values = (new Int32Array([])), ["rowsPerPageOptions", Array.from(values)]), ["onChangeRowsPerPage", (value_33) => {
                }]]);
            },
        })),
    })];
    return react.createElement(react.Fragment, {}, ...xs_6);
}

export function AttestenFinaliseren(attestenFinaliserenInputProps) {
    let children;
    const api = attestenFinaliserenInputProps.api;
    const currentPage = attestenFinaliserenInputProps.currentPage;
    return createElement(Page, {
        currentPage: currentPage,
        title: "Attesten Finaliseren",
        actions: [],
        children: [(children = singleton_1(createElement(AttestenFinaliserenData, api)), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))],
    });
}

