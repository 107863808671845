import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import ListItemText from "@material-ui/core/ListItemText";
import { ofArray, empty, singleton } from "../fable_modules/fable-library.3.7.17/List.js";
import ListItem from "@material-ui/core/ListItem";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Row } from "../../../Components/UtilComponents.fs.js";
import { equals, int32ToString, createObj } from "../fable_modules/fable-library.3.7.17/Util.js";
import { RouterModule_nav, RouterModule_encodeParts } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import Modal from "@material-ui/core/Modal";
import { printf, toConsole, join } from "../fable_modules/fable-library.3.7.17/String.js";
import { MarginLeft, MarginBottom, PaddingRight, PaddingLeft, PaddingBottom, PaddingTop, modal } from "../../../Styles/Utils.fs.js";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { AppointmentId, AppointmentStatus, HomeVisitContactModule_string, DossierId__toFormattedString, NonEmptyString___UnsafeUnwrap_Z73AD07C, CommentType } from "../../../shared/Domain.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_1 } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { ErrorText, AppointmentStatusChip } from "../../../DesignSystems/DesignSystem.fs.js";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import { CommentList } from "../../../DesignSystems/CommentEditor.fs.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { Customer2_name } from "../../../shared/Format.fs.js";
import Divider from "@material-ui/core/Divider";
import { Auth0UserPrivileges, Auth0UserClaims__HasPrivilege_6B2F319B } from "../../../shared/Auth0Constants.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { appointments, dossierAppointmentEdit, dossierDetail } from "../AppRoutes.fs.js";
import { map, defaultArg } from "../fable_modules/fable-library.3.7.17/Option.js";
import { toString } from "../fable_modules/fable-library.3.7.17/Date.js";
import { Dossier_contactPhoneNumber, Dossier_contact } from "../../../shared/DomainHelpers.fs.js";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import { Page } from "../components/Page.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";

export function Info(infoInputProps) {
    const primary = infoInputProps.primary;
    const children = singleton(MuiHelpers_createElement(ListItemText, [["secondary", infoInputProps.secondary], ["primary", primary]]));
    return MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function InfoLink(secondary, primary, url) {
    let elems;
    const children = singleton(createElement(Row, {
        classes: empty(),
        children: singleton(createElement("div", createObj(singleton((elems = [createElement("a", {
            target: "_blank",
            href: `${RouterModule_encodeParts(ofArray(url), 1)}`,
            children: `${primary}`,
        }), MuiHelpers_createElement(ListItemText, [["secondary", secondary]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))),
    }));
    return MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function CancellationModal(cancellationModalInputProps) {
    let elems_1;
    const onCancelAppointment = cancellationModalInputProps.onCancelAppointment;
    const setShowCancellationModal = cancellationModalInputProps.setShowCancellationModal;
    const showCancellationModal = cancellationModalInputProps.showCancellationModal;
    const appointmentId = cancellationModalInputProps.appointmentId;
    return MuiHelpers_createElement(Modal, [["open", showCancellationModal], ["onClose", (_arg_1, v) => {
        setShowCancellationModal(false);
    }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.L, PaddingBottom.L, PaddingLeft.Xxl, PaddingRight.Xxl])], (elems_1 = [createElement("div", {
        className: join(" ", [MarginBottom.M]),
        children: Interop_reactApi.Children.toArray(["Sure to cancel this appointment?"]),
    }), MuiHelpers_createElement(Button, [["onClick", (_arg_2) => {
        onCancelAppointment(appointmentId);
    }], ["className", join(" ", [])], ["variant", "contained"], ["color", "secondary"], ["children", "Yes"]]), MuiHelpers_createElement(Button, [["onClick", (_arg_3) => {
        setShowCancellationModal(false);
    }], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "primary"], ["children", "No"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]]);
}

export function DisplayAppointmentInfo(displayAppointmentInfoInputProps) {
    let elems_10, children_2, children_1, children, elems, children_4, children_3, children_6, children_5, matchValue_3, matchValue_4, children_8, children_7, props_24, props_26;
    const id = displayAppointmentInfoInputProps.id;
    const auth0User = displayAppointmentInfoInputProps.auth0User;
    const details = displayAppointmentInfoInputProps.details;
    const currentPage = displayAppointmentInfoInputProps.currentPage;
    const api = displayAppointmentInfoInputProps.api;
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setShowCancellationModal = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(25);
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(empty());
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const partner = patternInput_4[0];
    const commentType = useFeliz_React__React_useState_Static_1505(new CommentType(0))[0];
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_1.Delay(() => singleton_1.Bind(api.GetDossierComments(details.Dossier.Code, new PagingQuery(patternInput_1[0], patternInput_2[0]), commentType), (_arg) => {
            const comments_1 = _arg;
            if (comments_1.tag === 0) {
                patternInput_3[1](comments_1.fields[0].Data);
                return singleton_1.Zero();
            }
            else {
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                return singleton_1.Zero();
            }
        })));
    }, [commentType]);
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(singleton_1.Delay(() => {
            const matchValue = details.Dossier.PartnerId;
            if (matchValue == null) {
                return singleton_1.Zero();
            }
            else {
                const partnerId = matchValue;
                return singleton_1.Bind(api.GetPartner(partnerId), (_arg_1) => {
                    const partner_1 = _arg_1;
                    if (partner_1.tag === 1) {
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.${partner_1.fields[0]}`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        return singleton_1.Zero();
                    }
                    else {
                        patternInput_4[1](partner_1.fields[0]);
                        return singleton_1.Zero();
                    }
                });
            }
        }));
    }, []);
    let adiName;
    const firstName = NonEmptyString___UnsafeUnwrap_Z73AD07C(details.AdiFirstName);
    const lastName = NonEmptyString___UnsafeUnwrap_Z73AD07C(details.AdiLastName);
    adiName = (`${firstName} ${lastName}`);
    const appointmentInfo = createElement("div", createObj(singleton((elems_10 = [(children_2 = singleton((children_1 = singleton((children = singleton(createElement(Row, {
        classes: empty(),
        children: singleton(createElement("div", createObj(singleton((elems = [createElement(AppointmentStatusChip, {
            status: details.Appointment.Status,
        }), MuiHelpers_createElement(ListItemText, [["secondary", "Status"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))),
    })), MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]])), createElement(CommentList, {
        comments: patternInput_3[0],
        onDeleteComment: void 0,
    }), createElement("h2", {
        children: ["Gegevens"],
    }), (children_4 = singleton((children_3 = toList(delay(() => append(singleton_2(createElement(Info, {
        secondary: "Klant",
        primary: Customer2_name(details.Customer),
    })), delay(() => append(singleton_2(MuiHelpers_createElement(Divider, [["component", "li"]])), delay(() => append(Auth0UserClaims__HasPrivilege_6B2F319B(auth0User, new Auth0UserPrivileges(0)) ? append(singleton_2(InfoLink("Dossier nummer", `${details.Dossier.Code.Value}`, Page$2__toUrl_2B594(dossierDetail, details.Dossier.Code.Value))), delay(() => singleton_2(MuiHelpers_createElement(Divider, [["component", "li"]])))) : append(singleton_2(createElement(Info, {
        secondary: "Dossier nummer",
        primary: `${DossierId__toFormattedString(details.Dossier.Id)}`,
    })), delay(() => singleton_2(MuiHelpers_createElement(Divider, [["component", "li"]])))), delay(() => append(singleton_2(createElement(Info, {
        secondary: "Huisbezoek",
        primary: HomeVisitContactModule_string(details.Dossier.HomeVisitContact),
    })), delay(() => append(singleton_2(MuiHelpers_createElement(Divider, [["component", "li"]])), delay(() => append((details.Dossier.HomeVisitContact.tag === 3) ? append(singleton_2(createElement(Info, {
        secondary: "Huisbezoek Naam",
        primary: defaultArg(map((n) => n, details.Dossier.HomeVisitContactName), ""),
    })), delay(() => append(singleton_2(MuiHelpers_createElement(Divider, [["component", "li"]])), delay(() => append(singleton_2(createElement(Info, {
        secondary: "Huisbezoek Telefoon",
        primary: defaultArg(map((n_1) => n_1, details.Dossier.HomeVisitContactEmail), ""),
    })), delay(() => singleton_2(MuiHelpers_createElement(Divider, [["component", "li"]])))))))) : ((empty_1())), delay(() => append(singleton_2(createElement(Info, {
        secondary: "Afspraak nummer",
        primary: int32ToString(details.Appointment.Id.Value),
    })), delay(() => append(singleton_2(MuiHelpers_createElement(Divider, [["component", "li"]])), delay(() => append(singleton_2(createElement(Info, {
        secondary: "Datum",
        primary: toString(details.Appointment.StartTime, "dd/MM/yyyy"),
    })), delay(() => append(singleton_2(MuiHelpers_createElement(Divider, [["component", "li"]])), delay(() => append(singleton_2(createElement(Info, {
        secondary: "Start tijd",
        primary: toString(details.Appointment.StartTime, "HH:mm"),
    })), delay(() => append(singleton_2(MuiHelpers_createElement(Divider, [["component", "li"]])), delay(() => append(singleton_2(createElement(Info, {
        secondary: "Eind tijd",
        primary: toString(details.Appointment.EndTime, "HH:mm"),
    })), delay(() => append(singleton_2(MuiHelpers_createElement(Divider, [["component", "li"]])), delay(() => append(singleton_2(createElement(Info, {
        secondary: "ADI",
        primary: adiName,
    })), delay(() => {
        let invoiceStatus_1;
        const matchValue_2 = details.InvoiceStatus;
        if (matchValue_2 == null) {
            invoiceStatus_1 = "-";
        }
        else {
            const invoiceStatus = matchValue_2;
            switch (invoiceStatus.tag) {
                case 1: {
                    invoiceStatus_1 = "Open";
                    break;
                }
                case 2: {
                    invoiceStatus_1 = "Over due";
                    break;
                }
                case 3: {
                    invoiceStatus_1 = "Paid";
                    break;
                }
                case 4: {
                    invoiceStatus_1 = "Over paid";
                    break;
                }
                default: {
                    invoiceStatus_1 = "Pending";
                }
            }
        }
        return singleton_2(createElement(Info, {
            secondary: "Invoice Status",
            primary: invoiceStatus_1,
        }));
    })))))))))))))))))))))))))))))))), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]])), createElement("h2", {
        children: ["Inspectielocatie"],
    }), (children_6 = singleton((children_5 = ofArray([createElement(Info, {
        secondary: "Straat",
        primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(details.Dossier.Address.Street),
    }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
        secondary: "Nummer",
        primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(details.Dossier.Address.Number),
    }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
        secondary: "Bus",
        primary: defaultArg(map(NonEmptyString___UnsafeUnwrap_Z73AD07C, details.Dossier.Address.Bus), "--"),
    }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
        secondary: "Postcode",
        primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(details.Dossier.Address.PostalCode),
    }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
        secondary: "Gemeente",
        primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(details.Dossier.Address.City),
    }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
        secondary: "Contact afspraak",
        primary: Dossier_contact(details.Dossier, partner),
    }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
        secondary: "Telefoon contactpersoon",
        primary: Dossier_contactPhoneNumber(details.Dossier, partner),
    }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
        secondary: "Gebouwdeel/Vleugel/Blok",
        primary: (matchValue_3 = details.Dossier.BuildingDetail, (matchValue_3 == null) ? "" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_3)),
    }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
        secondary: "Wooneenheden",
        primary: (matchValue_4 = details.Dossier.HousingUnit, (matchValue_4 == null) ? "" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_4)),
    })]), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_6))]])), createElement("h2", {
        children: ["Labo"],
    }), (children_8 = singleton((children_7 = singleton(createElement(Info, {
        secondary: "Montsters opgeladen",
        primary: "-",
    })), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_7))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_8))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))]))));
    const cancelAppointmentButton = Auth0UserClaims__HasPrivilege_6B2F319B(auth0User, new Auth0UserPrivileges(0)) ? MuiHelpers_createElement(Button, [["onClick", (_arg_4) => {
        setShowCancellationModal(true);
    }], ["className", join(" ", [MarginLeft.Xs])], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props_24 = {}, react.createElement(Close, props_24))], ["children", "Annuleren"], ["disabled", equals(details.Appointment.Status, new AppointmentStatus(3))]]) : null;
    const editButton = Auth0UserClaims__HasPrivilege_6B2F319B(auth0User, new Auth0UserPrivileges(0)) ? MuiHelpers_createElement(Button, [["onClick", (_arg_5) => {
        RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossierAppointmentEdit, id)), 1, 1);
    }], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props_26 = {}, react.createElement(Edit, props_26))], ["children", "Aanpassen"], ["disabled", equals(details.Appointment.Status, new AppointmentStatus(3))]]) : null;
    return createElement(Page, {
        currentPage: currentPage,
        title: "",
        actions: [editButton, cancelAppointmentButton],
        children: [appointmentInfo, createElement(CancellationModal, {
            appointmentId: details.Appointment.Id,
            showCancellationModal: patternInput[0],
            setShowCancellationModal: setShowCancellationModal,
            onCancelAppointment: (appointmentId) => {
                startImmediate(singleton_1.Delay(() => singleton_1.TryWith(singleton_1.Delay(() => {
                    toConsole(printf("cancel %A"))(appointmentId);
                    return singleton_1.Bind(api.CancellAppointment(appointmentId), (_arg_2) => {
                        const result = _arg_2;
                        if (result.tag === 0) {
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Afspraak geannuleerd", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                            RouterModule_nav(ofArray(Page$2__toUrl_2B594(appointments)), 1, 1);
                            return singleton_1.Zero();
                        }
                        else {
                            const err_1 = result.fields[0];
                            console.error(err_1);
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_1}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                            return singleton_1.Zero();
                        }
                    });
                }), (_arg_3) => {
                    const e = _arg_3;
                    console.error(e);
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    return singleton_1.Zero();
                })));
            },
        })],
    });
}

export function AppointmentDetail(appointmentDetailInputProps) {
    const id = appointmentDetailInputProps.id;
    const auth0User = appointmentDetailInputProps.auth0User;
    const api = appointmentDetailInputProps.api;
    const currentPage = appointmentDetailInputProps.currentPage;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetAppointmentDetails(new AppointmentId(id)), []);
    return createElement(DisplayDeferred, {
        data: data,
        view: (aptDetails) => {
            if (aptDetails == null) {
                return createElement(Page, {
                    currentPage: currentPage,
                    title: "",
                    actions: [],
                    children: [createElement(ErrorText, {
                        text: `No appointments found with ID ${id}`,
                    })],
                });
            }
            else {
                const aptDetails_1 = aptDetails;
                return createElement(DisplayAppointmentInfo, {
                    api: api,
                    currentPage: currentPage,
                    details: aptDetails_1,
                    auth0User: auth0User,
                    id: id,
                });
            }
        },
    });
}

