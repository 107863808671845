import { createElement } from "react";
import { Col, Row } from "../../../Components/UtilComponents.fs.js";
import { singleton as singleton_1, ofArray, empty } from "../fable_modules/fable-library.3.7.17/List.js";
import { append, singleton, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { RenderBreadcrumbs } from "../../../DesignSystems/DesignSystem.fs.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { quotes, appointments, dossiers, adis, syndics, partners, customers } from "../AppRoutes.fs.js";
import { alignStart, TextColor, MarginTop, hideInPrint, spaceBetween, MarginBottom, PaddingTop, fullWidth, PaddingRight, PaddingLeft } from "../../../Styles/Utils.fs.js";
import { join } from "../fable_modules/fable-library.3.7.17/String.js";

export function Breadcrumbs(breadcrumbsInputProps) {
    const currentPage = breadcrumbsInputProps.currentPage;
    return createElement(Row, {
        classes: empty(),
        children: toList(delay(() => ((currentPage.tag === 3) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Klanten", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(customers)), 1)]],
            current: "Detail",
        })) : ((currentPage.tag === 4) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Klanten", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(customers)), 1)]],
            current: "Bewerken",
        })) : ((currentPage.tag === 2) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Klanten",
        })) : ((currentPage.tag === 9) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Partners", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(partners)), 1)]],
            current: "Aanmaken",
        })) : ((currentPage.tag === 7) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Partners", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(partners)), 1)]],
            current: "Detail",
        })) : ((currentPage.tag === 8) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Partners", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(partners)), 1)]],
            current: "Bewerken",
        })) : ((currentPage.tag === 6) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Partners",
        })) : ((currentPage.tag === 30) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Syndics",
        })) : ((currentPage.tag === 32) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Syndics", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(syndics)), 1)]],
            current: "Bewerken",
        })) : ((currentPage.tag === 31) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Syndics", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(syndics)), 1)]],
            current: "Detail",
        })) : ((currentPage.tag === 33) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Syndics", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(syndics)), 1)]],
            current: "Aanmaken",
        })) : ((currentPage.tag === 10) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Adis",
        })) : ((currentPage.tag === 13) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Adis", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(adis)), 1)]],
            current: "Aanmaken",
        })) : ((currentPage.tag === 11) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Adis", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(adis)), 1)]],
            current: "Detail",
        })) : ((currentPage.tag === 12) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Adis", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(adis)), 1)]],
            current: "Bewerken",
        })) : ((currentPage.tag === 14) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Dossiers",
        })) : ((currentPage.tag === 15) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Mijn Dossiers",
        })) : ((currentPage.tag === 18) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Dossiers", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(dossiers)), 1)]],
            current: "Aanmaken",
        })) : ((currentPage.tag === 16) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Dossiers", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(dossiers)), 1)]],
            current: "Detail",
        })) : ((currentPage.tag === 17) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Dossiers", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(dossiers)), 1)]],
            current: "Bewerken",
        })) : ((currentPage.tag === 1) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Dashboard",
        })) : ((currentPage.tag === 0) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Login",
        })) : ((currentPage.tag === 19) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Beschikbaarheid",
        })) : ((currentPage.tag === 23) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Afspraken",
        })) : ((currentPage.tag === 21) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Afspraken", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(appointments)), 1)]],
            current: "Aanmaken",
        })) : ((currentPage.tag === 20) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Afspraken", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(appointments)), 1)]],
            current: "Aanmaken",
        })) : ((currentPage.tag === 24) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Afspraken", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(appointments)), 1)]],
            current: "Detail",
        })) : ((currentPage.tag === 22) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Afspraken", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(appointments)), 1)]],
            current: "Bewerken",
        })) : ((currentPage.tag === 25) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Afspraken", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(appointments)), 1)]],
            current: "Bevestig",
        })) : ((currentPage.tag === 26) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Attesten Finaliseren",
        })) : ((currentPage.tag === 27) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Beschikbaarheden",
        })) : ((currentPage.tag === 28) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Digital Signature",
        })) : ((currentPage.tag === 29) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Administratieve Hulpmiddelen",
        })) : ((currentPage.tag === 35) ? singleton(createElement(RenderBreadcrumbs, {
            links: [],
            current: "Offertes VME",
        })) : ((currentPage.tag === 34) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Offertes VME", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(quotes)), 1)]],
            current: "Aanmaken",
        })) : ((currentPage.tag === 36) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Offertes VME", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(quotes)), 1)]],
            current: "Detail",
        })) : ((currentPage.tag === 37) ? singleton(createElement(RenderBreadcrumbs, {
            links: [["Offertes VME", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(quotes)), 1)]],
            current: "Bewerken",
        })) : singleton(createElement(RenderBreadcrumbs, {
            links: [["Klanten", RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(customers)), 1)]],
            current: "Aanmaken",
        }))))))))))))))))))))))))))))))))))))))))),
    });
}

export function Page(pageInputProps) {
    const children = pageInputProps.children;
    const actions = pageInputProps.actions;
    const title = pageInputProps.title;
    const currentPage = pageInputProps.currentPage;
    return createElement(Col, {
        classes: ofArray([PaddingLeft.L, PaddingRight.L, fullWidth, PaddingTop.L, MarginBottom.L]),
        children: toList(delay(() => append(singleton(createElement(Row, {
            classes: ofArray([spaceBetween, hideInPrint]),
            children: ofArray([createElement(Col, {
                classes: empty(),
                children: ofArray([createElement(Breadcrumbs, {
                    currentPage: currentPage,
                }), createElement("h1", {
                    children: title,
                    className: join(" ", [MarginBottom.L, MarginTop.S, TextColor.Primary]),
                })]),
            }), createElement(Row, {
                classes: singleton_1(alignStart),
                children: toList(delay(() => actions)),
            })]),
        })), delay(() => children)))),
    });
}

