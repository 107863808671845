import { Record, Union } from "../client/src/fable_modules/fable-library.3.7.17/Types.js";
import { array_type, class_type, lambda_type, unit_type, bool_type, record_type, int32_type, union_type, string_type } from "../client/src/fable_modules/fable-library.3.7.17/Reflection.js";
import { keyValueList } from "../client/src/fable_modules/fable-library.3.7.17/MapUtil.js";

export class SnackAnchorOrigin extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Horizontal", "Vertical"];
    }
}

export function SnackAnchorOrigin$reflection() {
    return union_type("Notistack.SnackAnchorOrigin", [], SnackAnchorOrigin, () => [[["Item", string_type]], [["Item", string_type]]]);
}

export class TransitionDuration extends Record {
    constructor(Appear, Enter, Exit) {
        super();
        this.Appear = (Appear | 0);
        this.Enter = (Enter | 0);
        this.Exit = (Exit | 0);
    }
}

export function TransitionDuration$reflection() {
    return record_type("Notistack.TransitionDuration", [], TransitionDuration, () => [["Appear", int32_type], ["Enter", int32_type], ["Exit", int32_type]]);
}

export class SnackbarProp extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AriaAttributes", "DisableWindowBlurListener", "OnClose", "OnEnter", "OnEntered", "OnEntering", "OnExit", "OnExited", "OnExiting", "PreventDuplicate", "ResumeHideDuration", "TransitionDuration"];
    }
}

export function SnackbarProp$reflection() {
    return union_type("Notistack.SnackbarProp", [], SnackbarProp, () => [[], [["Item", bool_type]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", bool_type]], [["Item", int32_type]], [["Item", TransitionDuration$reflection()]]]);
}

export function SnackbarProp_Variant_Z609E1E86(variant) {
    return ["variant", variant];
}

export function SnackbarProp_Action_2B31D457(element) {
    return ["action", element];
}

export function SnackbarProp_Action_3A5B6456(callback) {
    return ["action", callback];
}

export function SnackbarProp_AutoHideDuration_4019FA9(durationInMilliSeconds) {
    if (durationInMilliSeconds == null) {
        return ["autoHideDuration", null];
    }
    else {
        return ["autoHideDuration", durationInMilliSeconds];
    }
}

export function SnackbarProp_Content_2B31D457(element) {
    return ["content", element];
}

export function SnackbarProp_Content_626D353D(callback) {
    return ["content", callback];
}

export class IconVariant extends Record {
    constructor(Default, Error$, Success, Warning) {
        super();
        this.Default = Default;
        this.Error = Error$;
        this.Success = Success;
        this.Warning = Warning;
    }
}

export function IconVariant$reflection() {
    return record_type("Notistack.IconVariant", [], IconVariant, () => [["Default", class_type("Fable.React.ReactElement")], ["Error", class_type("Fable.React.ReactElement")], ["Success", class_type("Fable.React.ReactElement")], ["Warning", class_type("Fable.React.ReactElement")]]);
}

export class SnackbarProviderProp extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Classes", "Dense", "DomRoot", "HideIconVariant", "IconVariant", "MaxSnack"];
    }
}

export function SnackbarProviderProp$reflection() {
    return union_type("Notistack.SnackbarProviderProp", [], SnackbarProviderProp, () => [[], [["Item", bool_type]], [["Item", class_type("Browser.Types.HTMLElement")]], [["Item", bool_type]], [["Item", IconVariant$reflection()]], [["Item", int32_type]]]);
}

export class EnqueueSnackbarOption extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AnchorOrigin", "Persist"];
    }
}

export function EnqueueSnackbarOption$reflection() {
    return union_type("Notistack.EnqueueSnackbarOption", [], EnqueueSnackbarOption, () => [[["Item", array_type(SnackAnchorOrigin$reflection())]], [["Item", bool_type]]]);
}

export function EnqueueSnackbarOption_Key_Z721C83C5(key) {
    return ["key", key];
}

export function EnqueueSnackbarOption_Key_Z524259A4(key) {
    return ["key", key];
}

export class ProviderContext {
    constructor() {
    }
}

export function ProviderContext$reflection() {
    return class_type("Notistack.ProviderContext", void 0, ProviderContext);
}

export function ProviderContext__enqueueSnackbar_Z1776A768(this$, message, ...options) {
    return this$.enqueueSnackbar(message,keyValueList(options, 1));
}

export function ProviderContext__enqueueSnackbar_33B60434(this$, message, ...options) {
    return this$.enqueueSnackbar(message,keyValueList(options, 1));
}

