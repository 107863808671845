import { useReact_useMemo_CF4EA67, useReact_useReducer_2B9E6EA0 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { CustomersData, CustomerFilter_searchTerms, CustomerFilter_initialState, CustomerFilter_update } from "../../../DesignSystems/CustomerForms.fs.js";
import { useDebouncedValue } from "../../../CustomHooks/CustomHooks.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Button from "@material-ui/core/Button";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { singleton, ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { customerDetail, customerCreate } from "../AppRoutes.fs.js";
import { createElement } from "react";
import * as react from "react";
import Add from "@material-ui/icons/Add";
import { Page } from "../components/Page.fs.js";
import Paper from "@material-ui/core/Paper";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";

export function Customers(customersInputProps) {
    let props, children_1;
    const api = customersInputProps.api;
    const currentPage = customersInputProps.currentPage;
    const patternInput = useReact_useReducer_2B9E6EA0(CustomerFilter_update, CustomerFilter_initialState);
    const state_1 = patternInput[0];
    const search = useDebouncedValue(500, useReact_useMemo_CF4EA67(() => CustomerFilter_searchTerms(state_1), [state_1]));
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetCustomers(new PagingQuery(state_1.PageNumber, state_1.PageSize), search), [state_1.PageNumber, state_1.PageSize, search]);
    const addNewCustomerButton = MuiHelpers_createElement(Button, [["onClick", (_arg_1) => {
        RouterModule_nav(ofArray(Page$2__toUrl_2B594(customerCreate)), 1, 1);
    }], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props = {}, react.createElement(Add, props))], ["children", "Klant aanmaken"]]);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Klanten",
        actions: [addNewCustomerButton],
        children: [(children_1 = singleton(createElement(CustomersData, {
            data: data,
            state: state_1,
            dispatch: patternInput[1],
            url: (customerCode) => Page$2__toUrl_2B594(customerDetail, customerCode.Value),
        })), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))],
    });
}

