import { Union } from "../fable_modules/fable-library.3.7.17/Types.js";
import { union_type } from "../fable_modules/fable-library.3.7.17/Reflection.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { count, empty, ofSeq, remove, add, contains } from "../fable_modules/fable-library.3.7.17/Set.js";
import { Browser_Types_Event__Event_get_Checked } from "../fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { useSnackbar } from "notistack";
import { Address, NonEmptyString, TypeOfCorporation$reflection, AdiRoleModule_string, AdiRole, SelectOptionData$1_Create_Z510A3DC0, BankAccount_Create_Z721C83C5, VatNumber_get_Create, PersonIdOvam_Create_Z721C83C5, NationalInsuranceNumber_Create_Z721C83C5, PhoneNumber_get_Create, Email_get_Create, NonEmptyString_get_Create, Region } from "../../../shared/Domain.fs.js";
import { compare } from "../fable_modules/fable-library.3.7.17/Util.js";
import { useForm, useFormValidator, useFormField } from "../../../CustomHooks/ComposedForm.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { CreateAddressForm, useAddressForm } from "../../../DesignSystems/AddressHook.fs.js";
import { createElement } from "react";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import { MarginTop, Gap } from "../../../Styles/Utils.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.3.7.17/List.js";
import { iterate, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { Card, SelectOptionField, OptionField, TextField } from "../../../DesignSystems/DesignSystem.fs.js";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import Button from "@material-ui/core/Button";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { adiDetail, adis } from "../AppRoutes.fs.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { isNullOrEmpty } from "../fable_modules/fable-library.3.7.17/String.js";
import { CompanyInformationData, CompanyFormatData, AdiData, CompanyData } from "../../../shared/Api.fs.js";
import { toArray } from "../fable_modules/fable-library.3.7.17/Option.js";
import { useAuth0 } from "@auth0/auth0-react";
import { Page } from "../components/Page.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";

export class UiCompanyType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Company", "NaturalPerson"];
    }
}

export function UiCompanyType$reflection() {
    return union_type("AdiCreate.UiCompanyType", [], UiCompanyType, () => [[], []]);
}

export class TermOfPayment extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Days7", "Days14", "Days30"];
    }
}

export function TermOfPayment$reflection() {
    return union_type("AdiCreate.TermOfPayment", [], TermOfPayment, () => [[], [], []]);
}

export function RegionCheckbox(regionCheckboxInputProps) {
    const setRegions = regionCheckboxInputProps.setRegions;
    const values = regionCheckboxInputProps.values;
    const regionValue = regionCheckboxInputProps.regionValue;
    return MuiHelpers_createElement(FormControlLabel, [["label", regionCheckboxInputProps.label], ["control", MuiHelpers_createElement(Checkbox, [["checked", (regionValue == null) ? false : (contains(regionValue, values))]])], ["onChange", (e) => {
        const s = Browser_Types_Event__Event_get_Checked(e);
        if (regionValue == null) {
        }
        else {
            const r = regionValue;
            if (s) {
                setRegions(add(r, values));
            }
            else {
                setRegions(remove(r, values));
            }
        }
    }]]);
}

export function CreateAdiForm(createAdiFormInputProps) {
    const onCreateAdi = createAdiFormInputProps.onCreateAdi;
    const snackbar = useSnackbar();
    const defaultRegions = ofSeq([new Region(0), new Region(1), new Region(2), new Region(3), new Region(4)], {
        Compare: compare,
    });
    const firstName = useFormField("", NonEmptyString_get_Create());
    const lastName = useFormField("", NonEmptyString_get_Create());
    const email = useFormField("", Email_get_Create());
    const phoneNumber = useFormField("", PhoneNumber_get_Create());
    const nationalInsuranceNumber = useFormField("", NationalInsuranceNumber_Create_Z721C83C5);
    const personalIdOvam = useFormField("", PersonIdOvam_Create_Z721C83C5);
    const emailAsbestConsulting = useFormField("", Email_get_Create());
    const vatNumber = useFormField("", VatNumber_get_Create());
    const bankAccount = useFormField("", BankAccount_Create_Z721C83C5);
    const patternInput = useFeliz_React__React_useState_Static_1505(empty({
        Compare: compare,
    }));
    const setRegionValues = patternInput[1];
    const regionValues = patternInput[0];
    const address = useAddressForm("", "", "", "", "");
    const addressCase = useAddressForm("", "", "", "", "");
    const companyName = useFormField("", NonEmptyString_get_Create());
    const vatLiable = useFormField(void 0, SelectOptionData$1_Create_Z510A3DC0);
    const termOfPayment = useFormField(void 0, SelectOptionData$1_Create_Z510A3DC0);
    const companyType = useFormField(void 0, SelectOptionData$1_Create_Z510A3DC0);
    const companyForm = useFormField(void 0, SelectOptionData$1_Create_Z510A3DC0);
    const telefoon = useFormField("", PhoneNumber_get_Create());
    const emailadres = useFormField("", Email_get_Create());
    const role = useFormField(void 0, SelectOptionData$1_Create_Z510A3DC0);
    const form = useFormValidator(role.Validator, (r_13, x_16) => ({
        Address: r_13.Address,
        BankAccount: r_13.BankAccount,
        CompanyType: r_13.CompanyType,
        Email: r_13.Email,
        EmailAsbestConsulting: r_13.EmailAsbestConsulting,
        FirstName: r_13.FirstName,
        LastName: r_13.LastName,
        NationalInsuranceNumber: r_13.NationalInsuranceNumber,
        PersonalIdOvam: r_13.PersonalIdOvam,
        PhoneNumber: r_13.PhoneNumber,
        Role: x_16,
        TermOfPayment: r_13.TermOfPayment,
        VatLiable: r_13.VatLiable,
        VatNumber: r_13.VatNumber,
    }), useFormValidator(vatLiable.Validator, (r_12, x_15) => ({
        Address: r_12.Address,
        BankAccount: r_12.BankAccount,
        CompanyType: r_12.CompanyType,
        Email: r_12.Email,
        EmailAsbestConsulting: r_12.EmailAsbestConsulting,
        FirstName: r_12.FirstName,
        LastName: r_12.LastName,
        NationalInsuranceNumber: r_12.NationalInsuranceNumber,
        PersonalIdOvam: r_12.PersonalIdOvam,
        PhoneNumber: r_12.PhoneNumber,
        TermOfPayment: r_12.TermOfPayment,
        VatLiable: x_15,
        VatNumber: r_12.VatNumber,
    }), useFormValidator(termOfPayment.Validator, (r_11, x_14) => ({
        Address: r_11.Address,
        BankAccount: r_11.BankAccount,
        CompanyType: r_11.CompanyType,
        Email: r_11.Email,
        EmailAsbestConsulting: r_11.EmailAsbestConsulting,
        FirstName: r_11.FirstName,
        LastName: r_11.LastName,
        NationalInsuranceNumber: r_11.NationalInsuranceNumber,
        PersonalIdOvam: r_11.PersonalIdOvam,
        PhoneNumber: r_11.PhoneNumber,
        TermOfPayment: x_14,
        VatNumber: r_11.VatNumber,
    }), useFormValidator(companyType.Validator, (r_10, x_13) => ({
        Address: r_10.Address,
        BankAccount: r_10.BankAccount,
        CompanyType: x_13,
        Email: r_10.Email,
        EmailAsbestConsulting: r_10.EmailAsbestConsulting,
        FirstName: r_10.FirstName,
        LastName: r_10.LastName,
        NationalInsuranceNumber: r_10.NationalInsuranceNumber,
        PersonalIdOvam: r_10.PersonalIdOvam,
        PhoneNumber: r_10.PhoneNumber,
        VatNumber: r_10.VatNumber,
    }), useFormValidator(bankAccount.Validator, (r_9, x_12) => ({
        Address: r_9.Address,
        BankAccount: x_12,
        Email: r_9.Email,
        EmailAsbestConsulting: r_9.EmailAsbestConsulting,
        FirstName: r_9.FirstName,
        LastName: r_9.LastName,
        NationalInsuranceNumber: r_9.NationalInsuranceNumber,
        PersonalIdOvam: r_9.PersonalIdOvam,
        PhoneNumber: r_9.PhoneNumber,
        VatNumber: r_9.VatNumber,
    }), useFormValidator(vatNumber.Validator, (r_8, x_11) => ({
        Address: r_8.Address,
        Email: r_8.Email,
        EmailAsbestConsulting: r_8.EmailAsbestConsulting,
        FirstName: r_8.FirstName,
        LastName: r_8.LastName,
        NationalInsuranceNumber: r_8.NationalInsuranceNumber,
        PersonalIdOvam: r_8.PersonalIdOvam,
        PhoneNumber: r_8.PhoneNumber,
        VatNumber: x_11,
    }), useFormValidator(vatNumber.Validator, (r_7, x_10) => ({
        Address: r_7.Address,
        Email: r_7.Email,
        EmailAsbestConsulting: r_7.EmailAsbestConsulting,
        FirstName: r_7.FirstName,
        LastName: r_7.LastName,
        NationalInsuranceNumber: r_7.NationalInsuranceNumber,
        PersonalIdOvam: r_7.PersonalIdOvam,
        PhoneNumber: r_7.PhoneNumber,
        VatNumber: x_10,
    }), useFormValidator(emailAsbestConsulting.Validator, (r_6, x_9) => ({
        Address: r_6.Address,
        Email: r_6.Email,
        EmailAsbestConsulting: x_9,
        FirstName: r_6.FirstName,
        LastName: r_6.LastName,
        NationalInsuranceNumber: r_6.NationalInsuranceNumber,
        PersonalIdOvam: r_6.PersonalIdOvam,
        PhoneNumber: r_6.PhoneNumber,
    }), useFormValidator(personalIdOvam.Validator, (r_5, x_8) => ({
        Address: r_5.Address,
        Email: r_5.Email,
        FirstName: r_5.FirstName,
        LastName: r_5.LastName,
        NationalInsuranceNumber: r_5.NationalInsuranceNumber,
        PersonalIdOvam: x_8,
        PhoneNumber: r_5.PhoneNumber,
    }), useFormValidator(nationalInsuranceNumber.Validator, (r_4, x_7) => ({
        Address: r_4.Address,
        Email: r_4.Email,
        FirstName: r_4.FirstName,
        LastName: r_4.LastName,
        NationalInsuranceNumber: x_7,
        PhoneNumber: r_4.PhoneNumber,
    }), useFormValidator(phoneNumber.Validator, (r_3, x_6) => ({
        Address: r_3.Address,
        Email: r_3.Email,
        FirstName: r_3.FirstName,
        LastName: r_3.LastName,
        PhoneNumber: x_6,
    }), useFormValidator(email.Validator, (r_2, x_5) => ({
        Address: r_2.Address,
        Email: x_5,
        FirstName: r_2.FirstName,
        LastName: r_2.LastName,
    }), useFormValidator(address.Validator, (r_1, x_4) => ({
        Address: x_4,
        FirstName: r_1.FirstName,
        LastName: r_1.LastName,
    }), useFormValidator(lastName.Validator, (r, x_3) => ({
        FirstName: r.FirstName,
        LastName: x_3,
    }), useFormValidator(firstName.Validator, (unitVar_1, x_2) => ({
        FirstName: x_2,
    }), useForm())))))))))))))));
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setDisable = patternInput_1[1];
    const disable = patternInput_1[0];
    return createElement(Col, {
        classes: singleton(Gap.M),
        children: toList(delay(() => append(singleton_1(TextField(lastName, "Naam", "", true)), delay(() => append(singleton_1(TextField(firstName, "Voornaam", "", true)), delay(() => append(singleton_1(TextField(email, "Emailadres", "", true)), delay(() => append(singleton_1(TextField(phoneNumber, "Telefoon", "", true)), delay(() => append(singleton_1(TextField(nationalInsuranceNumber, "Rijksregisternummer", "", true)), delay(() => append(singleton_1(TextField(personalIdOvam, "Persoons-id OVAM", "", true)), delay(() => append(singleton_1(TextField(emailAsbestConsulting, "Email Asbest Consulting", "", true)), delay(() => append(singleton_1(createElement("h2", {
            children: ["Role"],
        })), delay(() => {
            let elements;
            return append(singleton_1(MuiHelpers_createElement(RadioGroup, [(elements = [OptionField(role, new AdiRole(0), AdiRoleModule_string(new AdiRole(0)), "", true), OptionField(role, new AdiRole(1), AdiRoleModule_string(new AdiRole(1)), "", true), OptionField(role, new AdiRole(2), AdiRoleModule_string(new AdiRole(2)), "", true), OptionField(role, new AdiRole(3), AdiRoleModule_string(new AdiRole(3)), "", true)], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])), delay(() => append(singleton_1(createElement(CreateAddressForm, {
                addressForm: address,
                heading: void 0,
                disabled: false,
            })), delay(() => append(singleton_1(createElement("h2", {
                children: ["Bedrijfsgegevens"],
            })), delay(() => {
                let elements_1;
                return append(singleton_1(MuiHelpers_createElement(RadioGroup, [["row", true], (elements_1 = [OptionField(companyType, new UiCompanyType(0), "Vennootschap", "", false), OptionField(companyType, new UiCompanyType(1), "Zelfstandige natuurlijk persoon", "", false)], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])])), delay(() => append(singleton_1(TextField(vatNumber, "Ondernemingsnummer", "", true)), delay(() => append(singleton_1(TextField(bankAccount, "Zichtrekening", "", true)), delay(() => append(singleton_1(createElement("h2", {
                    children: ["Betalingstermijn"],
                })), delay(() => {
                    let elements_2;
                    return append(singleton_1(MuiHelpers_createElement(RadioGroup, [["row", false], (elements_2 = [OptionField(termOfPayment, new TermOfPayment(0), "7 dagen", "", false), OptionField(termOfPayment, new TermOfPayment(1), "14 dagen", "", false), OptionField(termOfPayment, new TermOfPayment(2), "30 dagen", "", false)], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])])), delay(() => append(singleton_1(createElement("h2", {
                        children: ["Regio(s) waar de ADI werkzaam is"],
                    })), delay(() => {
                        let elements_3;
                        return append(singleton_1(MuiHelpers_createElement(RadioGroup, [["row", false], (elements_3 = [createElement(RegionCheckbox, {
                            label: "West Flanders",
                            regionValue: new Region(0),
                            values: regionValues,
                            setRegions: setRegionValues,
                        }), createElement(RegionCheckbox, {
                            label: "East Flanders",
                            regionValue: new Region(1),
                            values: regionValues,
                            setRegions: setRegionValues,
                        }), createElement(RegionCheckbox, {
                            label: "Antwerp",
                            regionValue: new Region(2),
                            values: regionValues,
                            setRegions: setRegionValues,
                        }), createElement(RegionCheckbox, {
                            label: "Flemish Brabant",
                            regionValue: new Region(3),
                            values: regionValues,
                            setRegions: setRegionValues,
                        }), createElement(RegionCheckbox, {
                            label: "Limburg",
                            regionValue: new Region(4),
                            values: regionValues,
                            setRegions: setRegionValues,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elements_3))])])), delay(() => append(singleton_1(createElement("h2", {
                            children: ["BTW-plichtig"],
                        })), delay(() => {
                            let elements_4;
                            return append(singleton_1(MuiHelpers_createElement(RadioGroup, [["row", false], (elements_4 = [OptionField(vatLiable, true, "ja", "", false), OptionField(vatLiable, false, "nee", "", false)], ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])])), delay(() => {
                                let matchValue_1;
                                return append((matchValue_1 = companyType.FieldValue, (matchValue_1 == null) ? singleton_1(null) : ((matchValue_1.tag === 1) ? singleton_1(null) : append(singleton_1(createElement("h2", {
                                    children: ["Gegevens vennootschap"],
                                })), delay(() => append(singleton_1(TextField(companyName, "Naam vennootschap", "", true)), delay(() => append(singleton_1(SelectOptionField(companyForm, "Vennootschapsvorm", "", true, TypeOfCorporation$reflection())), delay(() => append(singleton_1(TextField(telefoon, "Telefoon", "", true)), delay(() => singleton_1(TextField(emailadres, "Emailadres", "", true)))))))))))), delay(() => append(singleton_1(createElement(CreateAddressForm, {
                                    addressForm: addressCase,
                                    heading: "Adres zaak",
                                    disabled: false,
                                })), delay(() => singleton_1(createElement(Row, {
                                    classes: ofArray([Gap.M, MarginTop.L]),
                                    children: ofArray([MuiHelpers_createElement(Button, [["disabled", disable], ["color", "default"], ["variant", "contained"], ["onClick", (_arg_1) => {
                                        RouterModule_nav(ofArray(Page$2__toUrl_2B594(adis)), 1, 1);
                                    }], ["children", Interop_reactApi.Children.toArray(["Annuleren"])]]), MuiHelpers_createElement(Button, [["disabled", disable], ["color", "primary"], ["variant", "contained"], ["onClick", (_arg) => {
                                        iterate((data) => {
                                            let cformVal, c;
                                            if (count(regionValues) === 0) {
                                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Minstens een regio moet geselecteerd zijn.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                            }
                                            else {
                                                iterate((x_21) => {
                                                    let matchValue;
                                                    const adi = new AdiData(data.LastName, data.FirstName, data.Address, data.Email, data.PhoneNumber, data.NationalInsuranceNumber, data.PersonalIdOvam, data.EmailAsbestConsulting, new CompanyData(data.VatNumber, data.BankAccount, (matchValue = data.TermOfPayment.Value, (matchValue.tag === 1) ? 14 : ((matchValue.tag === 2) ? 30 : 7)), data.VatLiable.Value, new Address(new NonEmptyString(addressCase.StreetField.FieldValue), new NonEmptyString(addressCase.NumberField.FieldValue), isNullOrEmpty(addressCase.BusField.FieldValue) ? (void 0) : (new NonEmptyString(addressCase.BusField.FieldValue)), new NonEmptyString(addressCase.PostalCodeField.FieldValue), new NonEmptyString(addressCase.City.FieldValue)), x_21.CompanyFormat), regionValues, data.Role.Value);
                                                    setDisable(true);
                                                    onCreateAdi(adi)(() => {
                                                        setDisable(false);
                                                    });
                                                }, toArray((data.CompanyType.Value.tag === 1) ? {
                                                    CompanyFormat: new CompanyFormatData(0),
                                                } : ((cformVal = useFormValidator(emailadres.Validator, (r_16, x_20) => ({
                                                    CompanyName: r_16.CompanyName,
                                                    Emailadres: x_20,
                                                    Telefoon: r_16.Telefoon,
                                                    TypeOfCorp: r_16.TypeOfCorp,
                                                }), useFormValidator(telefoon.Validator, (r_15, x_19) => ({
                                                    CompanyName: r_15.CompanyName,
                                                    Telefoon: x_19,
                                                    TypeOfCorp: r_15.TypeOfCorp,
                                                }), useFormValidator(companyName.Validator, (r_14, x_18) => ({
                                                    CompanyName: x_18,
                                                    TypeOfCorp: r_14.TypeOfCorp,
                                                }), useFormValidator(companyForm.Validator, (unitVar_2, x_17) => ({
                                                    TypeOfCorp: x_17.Value,
                                                }), useForm())))).Validate(), (cformVal == null) ? (void 0) : ((c = cformVal, {
                                                    CompanyFormat: new CompanyFormatData(1, new CompanyInformationData(c.CompanyName, c.TypeOfCorp, c.Telefoon, c.Emailadres)),
                                                }))))));
                                            }
                                        }, toArray(form.Validate()));
                                    }], ["children", Interop_reactApi.Children.toArray(["Bewaren"])]])]),
                                }))))));
                            }));
                        }))));
                    }))));
                }))))))));
            }))))));
        })))))))))))))))))),
    });
}

export function AdiCreate(adiCreateInputProps) {
    const api = adiCreateInputProps.api;
    const currentPage = adiCreateInputProps.currentPage;
    const auth = useAuth0();
    const snackbar = useSnackbar();
    return createElement(Page, {
        currentPage: currentPage,
        title: "Adi aanmaken",
        actions: [],
        children: [Card([createElement(CreateAdiForm, {
            onCreateAdi: (adiData) => ((onCompleted) => {
                startImmediate(singleton_2.Delay(() => singleton_2.TryFinally(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(api.CreateAdi(adiData), (_arg) => {
                    const result = _arg;
                    if (result.tag === 0) {
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "De Adi is aangemaakt", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                        RouterModule_nav(ofArray(Page$2__toUrl_2B594(adiDetail, result.fields[0].Value)), 1, 1);
                        return singleton_2.Zero();
                    }
                    else {
                        const err = result.fields[0];
                        console.error(err);
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        return singleton_2.Zero();
                    }
                })), (_arg_1) => {
                    const err_1 = _arg_1;
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err_1.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    console.error(err_1);
                    return singleton_2.Zero();
                })), () => {
                    onCompleted();
                })));
            }),
        })])],
    });
}

