import { useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { useDebouncedValue } from "../../../CustomHooks/CustomHooks.fs.js";
import { empty, sortByDescending, map as map_2, toArray, singleton, ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { map, choose } from "../fable_modules/fable-library.3.7.17/Array.js";
import { Address_address, Customer2_name, Dossier_status, Dossier_parseStatus } from "../../../shared/Format.fs.js";
import { DossierSearchTerms } from "../../../shared/DomainHelpers.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { join, isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.17/String.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { createElement } from "react";
import * as react from "react";
import { Col } from "../../../Components/UtilComponents.fs.js";
import { alignEnd, cursorPointer, TableHeaderCell } from "../../../Styles/Utils.fs.js";
import { CheckIcon, MultiSelect, TableHeader, FilterField } from "../../../DesignSystems/DesignSystem.fs.js";
import { PhoneNumber___UnsafeUnwrap_23E45174, Customer2__get_PhoneNumber, NonEmptyString___UnsafeUnwrap_Z73AD07C, DossierStatus_get_list, DossierCode } from "../../../shared/Domain.fs.js";
import { map as map_1, defaultArg } from "../fable_modules/fable-library.3.7.17/Option.js";
import TableCell from "@material-ui/core/TableCell";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { singleton as singleton_1, collect, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { RouterModule_encodeParts } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { dossierDetail } from "../AppRoutes.fs.js";
import { DossierStatusChip } from "../../../DesignSystems/DossierUtils.fs.js";
import { comparePrimitives } from "../fable_modules/fable-library.3.7.17/Util.js";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import { Page } from "../components/Page.fs.js";
import Paper from "@material-ui/core/Paper";

export function MyDossiersData(api) {
    let elems_23, elems_22, children_10, children_9, children, children_1, children_2, children_3, children_4, children_5, children_6, children_7, children_8, children_21;
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const setPageNumber = patternInput[1];
    const pageNumber = patternInput[0] | 0;
    const pageSize = useFeliz_React__React_useState_Static_1505(25)[0] | 0;
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const setSearchTermDossierNummer = patternInput_2[1];
    const searchTermDossierNummer = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermAdiNaam = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermPartnerNaam = patternInput_4[0];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermCustomerNaam = patternInput_5[0];
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermAdres = patternInput_6[0];
    const patternInput_7 = useFeliz_React__React_useState_Static_1505(void 0);
    const searchTermTel = patternInput_7[0];
    const patternInput_8 = useFeliz_React__React_useState_Static_1505([]);
    const statuses = patternInput_8[0];
    const startDate = useFeliz_React__React_useState_Static_1505(void 0)[0];
    const endDate = useFeliz_React__React_useState_Static_1505(void 0)[0];
    const search = useDebouncedValue(500, useReact_useMemo_CF4EA67(() => (new DossierSearchTerms(searchTermDossierNummer, searchTermPartnerNaam, searchTermAdiNaam, searchTermCustomerNaam, searchTermAdres, searchTermTel, ofArray(choose((x) => x, map(Dossier_parseStatus, statuses))), startDate, endDate, false, void 0, void 0, void 0, void 0, void 0, void 0)), [searchTermDossierNummer, searchTermAdiNaam, searchTermCustomerNaam, searchTermAdres, searchTermTel, statuses, searchTermPartnerNaam, startDate, endDate]));
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetMyDossiersDetails(new PagingQuery(pageNumber, pageSize), search), [pageNumber, pageSize, search]);
    const updateTerm = (text, setTerm) => {
        setPageNumber(0);
        setTerm(isNullOrWhiteSpace(text) ? (void 0) : text);
    };
    const xs_4 = [MuiHelpers_createElement(TableContainer, [["component", "table"], (elems_23 = [MuiHelpers_createElement(Table, [["size", "small"], (elems_22 = [(children_10 = singleton((children_9 = ofArray([(children = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s_3) => {
            const s_1 = s_3;
            setPageNumber(0);
            if (isNullOrWhiteSpace(s_1)) {
                setSearchTermDossierNummer(void 0);
            }
            else {
                setSearchTermDossierNummer(new DossierCode(s_1));
            }
        }, defaultArg(map_1((d) => d.Value, searchTermDossierNummer), "")), createElement(TableHeader, {
            text: "Dossier nummer",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([createElement(MultiSelect, {
            label: "Filter",
            items: toArray(map_2(Dossier_status, DossierStatus_get_list())),
            v: statuses,
            onChange: patternInput_8[1],
        }), createElement(TableHeader, {
            text: "Status",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]])), (children_2 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s_4) => {
            updateTerm(s_4, patternInput_4[1]);
        }, defaultArg(searchTermPartnerNaam, "")), createElement(TableHeader, {
            text: "Partner Naam",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]])), (children_3 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s_5) => {
            updateTerm(s_5, patternInput_3[1]);
        }, defaultArg(searchTermAdiNaam, "")), createElement(TableHeader, {
            text: "Adi Naam",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]])), (children_4 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s_6) => {
            updateTerm(s_6, patternInput_5[1]);
        }, defaultArg(searchTermCustomerNaam, "")), createElement(TableHeader, {
            text: "Naam",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]])), (children_5 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s_7) => {
            updateTerm(s_7, patternInput_6[1]);
        }, defaultArg(searchTermAdres, "")), createElement(TableHeader, {
            text: "Inspectielocatie",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]])), (children_6 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([FilterField("Filter", (s_8) => {
            updateTerm(s_8, patternInput_7[1]);
        }, defaultArg(searchTermTel, "")), createElement(TableHeader, {
            text: "Tel",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_6))]])), (children_7 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([createElement("div", {}), createElement(TableHeader, {
            text: "Particuliere Prijzen",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_7))]])), (children_8 = singleton(createElement(Col, {
        classes: singleton(TableHeaderCell),
        children: ofArray([createElement("div", {}), createElement(TableHeader, {
            text: "Actief",
        })]),
    })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_8))]]))]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_9))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_10))]])), (children_21 = singleton(createElement(DisplayDeferred, {
        data: data,
        view: (dossierDetails) => {
            const xs_3 = toList(delay(() => collect((detail) => {
                let elems_20, children_12, children_13, children_14, children_15, children_16, children_17, children_18, value_43, children_19;
                let active;
                const matchValue = detail.Dossier.Status;
                switch (matchValue.tag) {
                    case 7:
                    case 1: {
                        active = false;
                        break;
                    }
                    default: {
                        active = true;
                    }
                }
                let adiName;
                const matchValue_1 = [detail.AdiFirstName, detail.AdiLastName];
                let pattern_matching_result, firstName, lastName;
                if (matchValue_1[0] != null) {
                    if (matchValue_1[1] != null) {
                        pattern_matching_result = 0;
                        firstName = matchValue_1[0];
                        lastName = matchValue_1[1];
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        const firstName_1 = NonEmptyString___UnsafeUnwrap_Z73AD07C(firstName);
                        const lastName_1 = NonEmptyString___UnsafeUnwrap_Z73AD07C(lastName);
                        adiName = (`${firstName_1} ${lastName_1}`);
                        break;
                    }
                    case 1: {
                        adiName = (void 0);
                        break;
                    }
                }
                const url = RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(dossierDetail, detail.Dossier.Code.Value)), 1);
                return singleton_1(MuiHelpers_createElement(TableRow, [["className", join(" ", [cursorPointer])], ["hover", true], ["onClick", (_arg) => {
                    window.open(url, "_blank");
                }], ["key", detail.Dossier.Code.Value], (elems_20 = [MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([detail.Dossier.Code.Value])]]), (children_12 = singleton(createElement(DossierStatusChip, {
                    status: detail.Dossier.Status,
                })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_12))]])), (children_13 = toList(delay(() => {
                    const matchValue_2 = detail.Partner;
                    return (matchValue_2 == null) ? singleton_1("-") : singleton_1(NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_2.CompanyName));
                })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_13))]])), (children_14 = toList(delay(() => ((adiName == null) ? singleton_1("-") : singleton_1(adiName)))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_14))]])), (children_15 = singleton(Customer2_name(detail.Customer)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_15))]])), (children_16 = singleton(Address_address(detail.Dossier.Address)), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_16))]])), (children_17 = toList(delay(() => {
                    const matchValue_3 = Customer2__get_PhoneNumber(detail.Customer);
                    return (matchValue_3 == null) ? singleton_1("-") : singleton_1(PhoneNumber___UnsafeUnwrap_23E45174(matchValue_3));
                })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_17))]])), (children_18 = singleton((value_43 = (detail.Dossier.InvoicePricing.tag === 0), createElement(CheckIcon, {
                    props: [],
                    value: value_43,
                }))), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_18))]])), (children_19 = singleton(createElement(CheckIcon, {
                    props: [],
                    value: active,
                })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_19))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_20))])]));
            }, sortByDescending((s_9) => s_9.Dossier.Id.Value, dossierDetails.Data, {
                Compare: comparePrimitives,
            }))));
            return react.createElement(react.Fragment, {}, ...xs_3);
        },
    })), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_21))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_22))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_23))])]), createElement(Col, {
        classes: empty(),
        children: singleton(createElement(DisplayDeferred, {
            data: data,
            view: (dossiers) => {
                let values;
                return MuiHelpers_createElement(TablePagination, [["className", join(" ", [alignEnd])], ["rowsPerPage", pageSize], ["page", pageNumber], ["count", dossiers.TotalPages], ["onChangePage", (_arg_1, v) => {
                    setPageNumber(v);
                }], (values = (new Int32Array([])), ["rowsPerPageOptions", Array.from(values)]), ["onChangeRowsPerPage", (value_60) => {
                }]]);
            },
        })),
    })];
    return react.createElement(react.Fragment, {}, ...xs_4);
}

export function MyDossiers(myDossiersInputProps) {
    let children;
    const api = myDossiersInputProps.api;
    const currentPage = myDossiersInputProps.currentPage;
    return createElement(Page, {
        currentPage: currentPage,
        title: "Mijn Dossiers",
        actions: [],
        children: [(children = singleton(createElement(MyDossiersData, api)), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]))],
    });
}

