import { Record, Union } from "../client/src/fable_modules/fable-library.3.7.17/Types.js";
import { record_type, union_type } from "../client/src/fable_modules/fable-library.3.7.17/Reflection.js";

export class SortOrder extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Ascending", "Descending"];
    }
}

export function SortOrder$reflection() {
    return union_type("Sorting.SortOrder", [], SortOrder, () => [[], []]);
}

export class SortField$1 extends Record {
    constructor(Field, SortOrder) {
        super();
        this.Field = Field;
        this.SortOrder = SortOrder;
    }
}

export function SortField$1$reflection(gen0) {
    return record_type("Sorting.SortField`1", [gen0], SortField$1, () => [["Field", gen0], ["SortOrder", SortOrder$reflection()]]);
}

