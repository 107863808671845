import { useSnackbar } from "notistack";
import { MuiHelpers_createElement } from "../client/src/fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import Modal from "@material-ui/core/Modal";
import { createElement } from "react";
import * as react from "react";
import { createObj } from "../client/src/fable_modules/fable-library.3.7.17/Util.js";
import { replace, join } from "../client/src/fable_modules/fable-library.3.7.17/String.js";
import { cancelDossierButton, cursorPointer, Gap, MarginLeft, MarginBottom, PaddingRight, PaddingLeft, PaddingBottom, PaddingTop, modal } from "../Styles/Utils.fs.js";
import { collect, singleton, delay, toList } from "../client/src/fable_modules/fable-library.3.7.17/Seq.js";
import { Interop_reactApi } from "../client/src/fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { singleton as singleton_2, empty, ofArray } from "../client/src/fable_modules/fable-library.3.7.17/List.js";
import Button from "@material-ui/core/Button";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../Notistack/Notistack.fs.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../client/src/fable_modules/Feliz.1.68.0/React.fs.js";
import { singleton as singleton_1 } from "../client/src/fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { startImmediate } from "../client/src/fable_modules/fable-library.3.7.17/Async.js";
import { Row, Col } from "../Components/UtilComponents.fs.js";
import { ByteArrayExtensions_SaveFileAs_72B26259, Browser_Types_File__File_ReadAsByteArray } from "../client/src/fable_modules/Fable.Remoting.Client.7.25.0/Extensions.fs.js";
import { FilePath } from "../shared/Domain.fs.js";
import { Card } from "./DesignSystem.fs.js";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Close from "@material-ui/icons/Close";
import TableBody from "@material-ui/core/TableBody";

export function DeleteFileModal(deleteFileModalInputProps) {
    let elems_1, elems;
    const onDeleteFile = deleteFileModalInputProps.onDeleteFile;
    const setShowDeletionModal = deleteFileModalInputProps.setShowDeletionModal;
    const showDeleteModal = deleteFileModalInputProps.showDeleteModal;
    const fileName = deleteFileModalInputProps.fileName;
    const filePath = deleteFileModalInputProps.filePath;
    const snackbar = useSnackbar();
    return MuiHelpers_createElement(Modal, [["open", showDeleteModal], ["onClose", (_arg_1, v) => {
        setShowDeletionModal(false);
    }], ["children", createElement("div", createObj(ofArray([["className", join(" ", [modal, PaddingTop.L, PaddingBottom.L, PaddingLeft.Xxl, PaddingRight.Xxl])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", [MarginBottom.M])], (elems = toList(delay(() => {
        if (fileName == null) {
            return singleton(null);
        }
        else {
            const fileName_1 = fileName;
            return singleton(`Verwijder bestand ${fileName_1}`);
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), MuiHelpers_createElement(Button, [["onClick", (_arg_2) => {
        if (filePath == null) {
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Geen bestand geselecteerd.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            console.error("Geen bestand geselecteerd");
            setShowDeletionModal(false);
        }
        else {
            onDeleteFile(filePath);
            setShowDeletionModal(false);
        }
    }], ["className", join(" ", [])], ["variant", "contained"], ["color", "secondary"], ["children", "Ja"]]), MuiHelpers_createElement(Button, [["onClick", (_arg_3) => {
        setShowDeletionModal(false);
    }], ["className", join(" ", [MarginLeft.S])], ["variant", "contained"], ["color", "primary"], ["children", "Nee"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))]]);
}

export function UploadMiscFiles(uploadMiscFilesInputProps) {
    let elems, elems_10, elems_9, children_3, children_2, children_7;
    const removeFile = uploadMiscFilesInputProps.removeFile;
    const uploadFile = uploadMiscFilesInputProps.uploadFile;
    const getFile = uploadMiscFilesInputProps.getFile;
    const getFiles = uploadMiscFilesInputProps.getFiles;
    const prefix = uploadMiscFilesInputProps.prefix;
    const snackbar = useSnackbar();
    const patternInput = useFeliz_React__React_useState_Static_1505(empty());
    const getFiles_1 = () => singleton_1.Delay(() => singleton_1.TryWith(singleton_1.Delay(() => singleton_1.Bind(getFiles, (_arg) => {
        const result = _arg;
        if (result.tag === 1) {
            console.error(result.fields[0]);
            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
            return singleton_1.Zero();
        }
        else {
            const files_1 = result.fields[0];
            patternInput[1](files_1);
            console.log(files_1);
            return singleton_1.Zero();
        }
    })), (_arg_1) => {
        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
        console.error(_arg_1);
        return singleton_1.Zero();
    }));
    useReact_useEffect_Z101E1A95(() => {
        startImmediate(getFiles_1());
    }, []);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setShowDeletionModal = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const xs_2 = [createElement(Col, {
        classes: singleton_2(Gap.M),
        children: singleton_2(createElement("div", createObj(singleton_2((elems = [createElement("h2", {
            children: ["Extra bestand opladen"],
        }), createElement("input", {
            type: "file",
            onChange: (ev) => {
                let file_1;
                const files_2 = ev.target.files;
                if ((!(files_2 == null)) && (files_2.length > 0)) {
                    startImmediate((file_1 = files_2.item(0), singleton_1.Delay(() => singleton_1.TryWith(singleton_1.Delay(() => singleton_1.Bind(Browser_Types_File__File_ReadAsByteArray(file_1), (_arg_7) => singleton_1.Bind(uploadFile(new FilePath(file_1.name))(_arg_7), (_arg_8) => {
                        const output = _arg_8;
                        if (output.tag === 1) {
                            console.error(output.fields[0]);
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                            return singleton_1.Zero();
                        }
                        else {
                            ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Bestand opgeladen", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                            return singleton_1.Bind(getFiles_1(), () => singleton_1.Return());
                        }
                    }))), (_arg_10) => {
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        console.error(_arg_10);
                        return singleton_1.Zero();
                    }))));
                }
            },
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))),
    }), Card([createElement(DeleteFileModal, {
        filePath: patternInput_2[0],
        fileName: patternInput_3[0],
        showDeleteModal: patternInput_1[0],
        setShowDeletionModal: setShowDeletionModal,
        onDeleteFile: (filepath) => {
            startImmediate(singleton_1.Delay(() => singleton_1.TryWith(singleton_1.Delay(() => singleton_1.Bind(removeFile(filepath), (_arg_4) => {
                if (_arg_4.tag === 1) {
                    console.error(_arg_4.fields[0]);
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    return singleton_1.Zero();
                }
                else {
                    return singleton_1.Bind(getFiles_1(), () => {
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Verwijderd bestand", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                        return singleton_1.Zero();
                    });
                }
            })), (_arg_6) => {
                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                console.error(_arg_6);
                return singleton_1.Zero();
            })));
        },
    }), MuiHelpers_createElement(TableContainer, [["component", "table"], (elems_10 = [MuiHelpers_createElement(Table, [["size", "small"], (elems_9 = [(children_3 = singleton_2((children_2 = ofArray([MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(["Bestandsnaam"])]]), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([""])]])]), MuiHelpers_createElement(TableRow, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))), MuiHelpers_createElement(TableHead, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]])), (children_7 = toList(delay(() => collect((file_3) => {
        let elems_7, children_6, props_4;
        const filename = replace(file_3.Value, prefix, "");
        return singleton(MuiHelpers_createElement(TableRow, [["className", join(" ", [cursorPointer])], ["hover", true], (elems_7 = [MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray([filename])]]), (children_6 = singleton_2(createElement(Row, {
            classes: singleton_2(Gap.M),
            children: ofArray([MuiHelpers_createElement(Button, [["children", "Downloaden"], ["variant", "contained"], ["color", "default"], ["onClick", (e_4) => {
                let filePath;
                e_4.preventDefault();
                e_4.stopPropagation();
                startImmediate((filePath = file_3, singleton_1.Delay(() => singleton_1.TryWith(singleton_1.Delay(() => singleton_1.Bind(getFile(filePath), (_arg_2) => {
                    const result_1 = _arg_2;
                    if (result_1.tag === 1) {
                        console.error(result_1.fields[0]);
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        return singleton_1.Zero();
                    }
                    else {
                        ByteArrayExtensions_SaveFileAs_72B26259(result_1.fields[0], replace(filePath.Value, prefix, ""));
                        return singleton_1.Zero();
                    }
                })), (_arg_3) => {
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een onbekende fout is opgetreden. Gelieve support te contacteren.", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    console.error(_arg_3);
                    return singleton_1.Zero();
                }))));
            }]]), MuiHelpers_createElement(Button, [["className", cancelDossierButton], ["children", "Verwijderen"], ["variant", "contained"], ["color", "secondary"], ["startIcon", (props_4 = {}, react.createElement(Close, props_4))], ["onClick", (e_5) => {
                e_5.preventDefault();
                e_5.stopPropagation();
                patternInput_2[1](file_3);
                patternInput_3[1](filename);
                setShowDeletionModal(true);
            }]])]),
        })), MuiHelpers_createElement(TableCell, [["children", Interop_reactApi.Children.toArray(Array.from(children_6))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]));
    }, patternInput[0]))), MuiHelpers_createElement(TableBody, [["children", Interop_reactApi.Children.toArray(Array.from(children_7))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_10))])])])];
    return react.createElement(react.Fragment, {}, ...xs_2);
}

