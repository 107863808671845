import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import ListItemText from "@material-ui/core/ListItemText";
import { ofArray, append as append_1, length, contains, filter, map, toArray, empty, singleton } from "../fable_modules/fable-library.3.7.17/List.js";
import ListItem from "@material-ui/core/ListItem";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { CommentSource as CommentSource_1, DossierCode as DossierCode_1, CommentType, PhoneNumber___UnsafeUnwrap_23E45174, Customer2__get_PhoneNumber, Customer2__get_Code, AdiRoleModule_string, PreferredAdi__AdisWithMinimumRole_Z7F80284D, NonEmptyString___UnsafeUnwrap_Z73AD07C } from "../../../shared/Domain.fs.js";
import { CalendarResource$1 } from "../lib/bindings/BigCalendar.fs.js";
import { createObj, safeHash, equals } from "../fable_modules/fable-library.3.7.17/Util.js";
import { useUsername, useToggle } from "../../../CustomHooks/CustomHooks.fs.js";
import { createElement } from "react";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import { iterate, empty as empty_1, map as map_1, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { Gap, MarginLeft, Boldness, fullWidth, MarginRight, MarginTop } from "../../../Styles/Utils.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Browser_Types_Event__Event_get_Checked } from "../fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { Card, Collapsible } from "../../../DesignSystems/DesignSystem.fs.js";
import { Adi_nameAndRole } from "../../../shared/DomainHelpers.fs.js";
import { AppointmentScheduler } from "../components/AppointmentScheduler.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { toArray as toArray_1, defaultArg, map as map_2 } from "../fable_modules/fable-library.3.7.17/Option.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { RouterModule_nav, RouterModule_encodeParts } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { appointmentDetail, dossiers, customerDetail, dossierDetail } from "../AppRoutes.fs.js";
import Divider from "@material-ui/core/Divider";
import { Customer2_name } from "../../../shared/Format.fs.js";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import { useSnackbar } from "notistack";
import { useForm } from "../../../CustomHooks/ComposedForm.fs.js";
import { CommentEditor, CommentTypeTabs } from "../../../DesignSystems/CommentEditor.fs.js";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";
import { CommentData, AppointmentData } from "../../../shared/Api.fs.js";
import { Page } from "../components/Page.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";

export function Info(infoInputProps) {
    const primary = infoInputProps.primary;
    const children = singleton(MuiHelpers_createElement(ListItemText, [["secondary", infoInputProps.secondary], ["primary", primary]]));
    return MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]]);
}

export function RenderAdisList(renderAdisListInputProps) {
    let role, role_1, elems;
    const partner = renderAdisListInputProps.partner;
    const setAppointment = renderAdisListInputProps.setAppointment;
    const dossierCode = renderAdisListInputProps.dossierCode;
    const dossierId = renderAdisListInputProps.dossierId;
    const adis = renderAdisListInputProps.adis;
    const api = renderAdisListInputProps.api;
    const patternInput = useFeliz_React__React_useState_Static_1505(empty());
    const setSelectedAdisList = patternInput[1];
    const selectedAdisList = patternInput[0];
    const resources = useReact_useMemo_CF4EA67(() => toArray(map((tupledArg_1) => {
        const adi = tupledArg_1[0];
        return new CalendarResource$1(adi.Id.Value, `${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.FirstName)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.LastName)}`, "");
    }, filter((tupledArg) => tupledArg[1], selectedAdisList))), [selectedAdisList]);
    const normalAdis = (partner != null) ? ((partner.MinimumRole != null) ? ((role = partner.MinimumRole, filter((adi_1) => (!contains(adi_1, PreferredAdi__AdisWithMinimumRole_Z7F80284D(adis, role), {
        Equals: equals,
        GetHashCode: safeHash,
    })), adis.NormalAdis))) : adis.NormalAdis) : adis.NormalAdis;
    const roleAdis = (partner != null) ? ((partner.MinimumRole != null) ? ((role_1 = partner.MinimumRole, PreferredAdi__AdisWithMinimumRole_Z7F80284D(adis, role_1))) : empty()) : empty();
    const collapsePartnerAdis = useToggle(false);
    const collapseRoleAdis = useToggle(length(adis.PreferredAdis) > 0);
    const collapseNormalAdis = useToggle((length(adis.PreferredAdis) > 0) ? true : (length(roleAdis) > 0));
    return createElement("div", createObj(singleton((elems = [createElement(Col, {
        classes: empty(),
        children: toList(delay(() => append((length(adis.PreferredAdis) > 0) ? singleton_1(createElement(Col, {
            classes: singleton(MarginTop.M),
            children: toList(delay(() => {
                const partnerAdis = createElement(Row, {
                    classes: empty(),
                    children: singleton(createElement(Row, {
                        classes: empty(),
                        children: toList(delay(() => map_1((adi_2) => MuiHelpers_createElement(FormControlLabel, [["label", `${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi_2.FirstName)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi_2.LastName)}`], ["control", MuiHelpers_createElement(Checkbox, [["onChange", (e) => {
                            const v = Browser_Types_Event__Event_get_Checked(e);
                            setSelectedAdisList(append_1(filter((tupledArg_2) => (!equals(tupledArg_2[0], adi_2)), selectedAdisList), singleton([adi_2, v])));
                        }]])]]), adis.PreferredAdis))),
                    })),
                });
                return singleton_1(createElement(Collapsible, {
                    title: "ADIs (Partner)",
                    children: partnerAdis,
                    collapseToggle: collapsePartnerAdis,
                    disabled: length(adis.PreferredAdis) === 0,
                }));
            })),
        })) : empty_1(), delay(() => {
            let matchValue, role_2, role_3;
            return append((matchValue = partner, (matchValue != null) ? ((matchValue.MinimumRole != null) ? (((role_2 = matchValue.MinimumRole, length(roleAdis) > 0)) ? ((role_3 = matchValue.MinimumRole, singleton_1(createElement(Col, {
                classes: singleton(MarginTop.M),
                children: toList(delay(() => {
                    const roles = createElement(Row, {
                        classes: empty(),
                        children: singleton(createElement(Row, {
                            classes: empty(),
                            children: toList(delay(() => map_1((adi_3) => MuiHelpers_createElement(FormControlLabel, [["label", Adi_nameAndRole(adi_3)], ["control", MuiHelpers_createElement(Checkbox, [["onChange", (e_1) => {
                                const v_1 = Browser_Types_Event__Event_get_Checked(e_1);
                                setSelectedAdisList(append_1(filter((tupledArg_3) => (!equals(tupledArg_3[0], adi_3)), selectedAdisList), singleton([adi_3, v_1])));
                            }]])]]), roleAdis))),
                        })),
                    });
                    return singleton_1(createElement(Collapsible, {
                        title: `ADIs (Minimum Role: ${AdiRoleModule_string(role_3)})`,
                        children: roles,
                        collapseToggle: collapseRoleAdis,
                        disabled: length(PreferredAdi__AdisWithMinimumRole_Z7F80284D(adis, role_3)) === 0,
                    }));
                })),
            })))) : ((empty_1()))) : ((empty_1()))) : ((empty_1()))), delay(() => append(singleton_1(createElement(Col, {
                classes: singleton(MarginTop.M),
                children: toList(delay(() => {
                    const normal = createElement(Row, {
                        classes: empty(),
                        children: singleton(createElement(Row, {
                            classes: empty(),
                            children: toList(delay(() => map_1((adi_5) => MuiHelpers_createElement(FormControlLabel, [["label", `${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi_5.FirstName)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi_5.LastName)}`], ["control", MuiHelpers_createElement(Checkbox, [["onChange", (e_2) => {
                                const v_2 = Browser_Types_Event__Event_get_Checked(e_2);
                                setSelectedAdisList(append_1(filter((tupledArg_4) => (!equals(tupledArg_4[0], adi_5)), selectedAdisList), singleton([adi_5, v_2])));
                            }]])]]), normalAdis))),
                        })),
                    });
                    return singleton_1(createElement(Collapsible, {
                        title: "ADIs (Alle)",
                        children: normal,
                        collapseToggle: collapseNormalAdis,
                        disabled: false,
                    }));
                })),
            })), delay(() => singleton_1(createElement(Row, {
                classes: singleton(MarginTop.L),
                children: singleton(createElement(Col, {
                    classes: empty(),
                    children: toList(delay(() => ((resources.length === 0) ? singleton_1(createElement("h4", {
                        children: ["Selecteer een ADI"],
                    })) : singleton_1(createElement(AppointmentScheduler, {
                        api: api,
                        dossierCode: dossierCode,
                        appointmentLengthInMinutes: 120,
                        resources: resources,
                        setAppointment: setAppointment,
                        readOnly: false,
                        enableOpenDossierLink: false,
                    }))))),
                })),
            }))))));
        })))),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))));
}

export function AdisList(adisListInputProps) {
    const partner = adisListInputProps.partner;
    const setAppointment = adisListInputProps.setAppointment;
    const dossierCode = adisListInputProps.dossierCode;
    const dossierId = adisListInputProps.dossierId;
    const api = adisListInputProps.api;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetPreferredAdis(map_2((p) => p.Id, partner)), []);
    return createElement(DisplayDeferred, {
        data: data,
        view: (adis) => createElement(RenderAdisList, {
            api: api,
            adis: adis,
            dossierId: dossierId,
            dossierCode: dossierCode,
            setAppointment: setAppointment,
            partner: partner,
        }),
    });
}

export function DossierCustomerInfo(dossierCustomerInfoInputProps) {
    const setAppointment = dossierCustomerInfoInputProps.setAppointment;
    const dossierCode = dossierCustomerInfoInputProps.dossierCode;
    const api = dossierCustomerInfoInputProps.api;
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetDossierDetails(dossierCode), []);
    return createElement(DisplayDeferred, {
        data: data,
        view: (dossier) => {
            let children_3, children_2, children, dossierDetails, elems_1, children_1, customerName, customerId, elems, children_5, children_4, matchValue, matchValue_1, matchValue_2;
            if (dossier == null) {
                return `No dossier found with code ${dossierCode}`;
            }
            else {
                const dossierDetails_1 = dossier;
                return createElement(Row, {
                    classes: empty(),
                    children: singleton(createElement(Col, {
                        classes: ofArray([MarginRight.Xl, fullWidth]),
                        children: ofArray([createElement("h2", {
                            children: ["Algemeen"],
                        }), (children_3 = singleton((children_2 = ofArray([(children = singleton(MuiHelpers_createElement(ListItemText, [["primary", (dossierDetails = dossierDetails_1, createElement("div", createObj(singleton((elems_1 = [createElement("a", {
                            href: `${RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(dossierDetail, dossierDetails.Dossier.Code.Value)), 1)}`,
                            target: "_blank",
                            children: `${dossierDetails.Dossier.Code.Value}`,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])))))], ["secondary", "Dossier nummer"]])), MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), MuiHelpers_createElement(Divider, [["component", "li"]]), (children_1 = singleton(MuiHelpers_createElement(ListItemText, [["primary", (customerName = Customer2_name(dossierDetails_1.Customer), (customerId = Customer2__get_Code(dossierDetails_1.Customer).Value, createElement("div", createObj(singleton((elems = [createElement("a", {
                            target: "_blank",
                            href: `${RouterModule_encodeParts(ofArray(Page$2__toUrl_2B594(customerDetail, customerId)), 1)}`,
                            children: `${customerName}`,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))))))], ["secondary", "Klant"]])), MuiHelpers_createElement(ListItem, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))]), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]])), createElement("h2", {
                            children: ["Inspectielocatie"],
                        }), (children_5 = singleton((children_4 = ofArray([createElement(Info, {
                            secondary: "Straat",
                            primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(dossierDetails_1.Dossier.Address.Street),
                        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                            secondary: "Nummer",
                            primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(dossierDetails_1.Dossier.Address.Number),
                        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                            secondary: "Bus",
                            primary: defaultArg(map_2(NonEmptyString___UnsafeUnwrap_Z73AD07C, dossierDetails_1.Dossier.Address.Bus), "--"),
                        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                            secondary: "Postcode",
                            primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(dossierDetails_1.Dossier.Address.PostalCode),
                        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                            secondary: "Gemeente",
                            primary: NonEmptyString___UnsafeUnwrap_Z73AD07C(dossierDetails_1.Dossier.Address.City),
                        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                            secondary: "Telefoon contactpersoon",
                            primary: (matchValue = Customer2__get_PhoneNumber(dossierDetails_1.Customer), (matchValue == null) ? "" : PhoneNumber___UnsafeUnwrap_23E45174(matchValue)),
                        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                            secondary: "Gebouwdeel/Vleugel/Blok",
                            primary: (matchValue_1 = dossierDetails_1.Dossier.BuildingDetail, (matchValue_1 == null) ? "" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_1)),
                        }), MuiHelpers_createElement(Divider, [["component", "li"]]), createElement(Info, {
                            secondary: "Wooneenheden",
                            primary: (matchValue_2 = dossierDetails_1.Dossier.HousingUnit, (matchValue_2 == null) ? "" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_2)),
                        })]), MuiHelpers_createElement(List, [["children", Interop_reactApi.Children.toArray(Array.from(children_4))]]))), MuiHelpers_createElement(Paper, [["children", Interop_reactApi.Children.toArray(Array.from(children_5))]])), createElement(AdisList, {
                            api: api,
                            dossierId: dossierDetails_1.Dossier.Id,
                            dossierCode: dossierDetails_1.Dossier.Code,
                            setAppointment: setAppointment,
                            partner: dossierDetails_1.Partner,
                        })]),
                    })),
                });
            }
        },
    });
}

export function AppointmentDossierCreateForm(appointmentDossierCreateFormInputProps) {
    const onCreateAppointment = appointmentDossierCreateFormInputProps.onCreateAppointment;
    const dossierCode = appointmentDossierCreateFormInputProps.dossierCode;
    const api = appointmentDossierCreateFormInputProps.api;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const appointment = patternInput[0];
    const sendEmailToClient = useToggle(true);
    const sendEmailToPartner = useToggle(true);
    const snackbar = useSnackbar();
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const comment = patternInput_1[0];
    const username = useUsername();
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(new CommentType(0));
    const commentType = patternInput_2[0];
    const form = useForm();
    const sendemailSwitchText = (text) => {
        let elems;
        return createElement("div", createObj(singleton((elems = [createElement(Row, {
            classes: empty(),
            children: ofArray([createElement(Col, {
                classes: empty(),
                children: singleton("Automatisch een bevestigingsmail naar de "),
            }), createElement(Col, {
                classes: ofArray([Boldness.b700, MarginLeft.Xs, MarginRight.Xs]),
                children: singleton(text),
            }), createElement(Col, {
                classes: empty(),
                children: singleton("versturen"),
            })]),
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))]))));
    };
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setDisable = patternInput_3[1];
    const disable = patternInput_3[0];
    return createElement(Col, {
        classes: singleton(Gap.M),
        children: ofArray([createElement(DossierCustomerInfo, {
            api: api,
            dossierCode: new DossierCode_1(dossierCode),
            setAppointment: patternInput[1],
        }), MuiHelpers_createElement(Divider, []), createElement(CommentTypeTabs, {
            commentType: commentType,
            setCommentType: patternInput_2[1],
            content: createElement(CommentEditor, {
                comment: comment,
                setComment: patternInput_1[1],
                commentType: commentType,
            }),
            showInternal: true,
            showAdi: true,
            showPartner: true,
        }), MuiHelpers_createElement(FormControlLabel, [["label", [sendemailSwitchText("klant")]], ["control", MuiHelpers_createElement(Switch, [["checked", sendEmailToClient.State]])], ["onChange", (e) => {
            const _arg_1 = Browser_Types_Event__Event_get_Checked(e);
            sendEmailToClient.Toggle();
        }]]), MuiHelpers_createElement(FormControlLabel, [["label", [sendemailSwitchText("partner")]], ["control", MuiHelpers_createElement(Switch, [["checked", sendEmailToPartner.State]])], ["onChange", (e_1) => {
            const _arg_2 = Browser_Types_Event__Event_get_Checked(e_1);
            sendEmailToPartner.Toggle();
        }]]), createElement(Row, {
            classes: ofArray([Gap.M, MarginTop.S]),
            children: ofArray([MuiHelpers_createElement(Button, [["disabled", disable], ["color", "default"], ["variant", "contained"], ["onClick", (_arg_3) => {
                RouterModule_nav(ofArray(Page$2__toUrl_2B594(dossiers)), 1, 1);
            }], ["children", Interop_reactApi.Children.toArray(["Annuleren"])]]), MuiHelpers_createElement(Button, [["disabled", disable], ["color", "primary"], ["variant", "contained"], ["onClick", (_arg) => {
                if (appointment == null) {
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Selecteer een tijdstip", SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                }
                else {
                    const appointment_1 = appointment;
                    iterate(() => {
                        setDisable(true);
                        console.log(void 0);
                        onCreateAppointment(new AppointmentData(appointment_1.Status, appointment_1.DossierCode, appointment_1.DossierId, appointment_1.AdiId, appointment_1.StartTime, appointment_1.EndTime, sendEmailToClient.State, sendEmailToPartner.State))((comment.length > 0) ? (new CommentData(comment, username, new CommentSource_1(1), commentType)) : (void 0))(() => {
                            setDisable(false);
                        });
                    }, toArray_1(form.Validate()));
                }
            }], ["children", Interop_reactApi.Children.toArray(["Afspraak maken"])]])]),
        })]),
    });
}

export function AppointmentDossierCreate(appointmentDossierCreateInputProps) {
    const dossierCode = appointmentDossierCreateInputProps.dossierCode;
    const api = appointmentDossierCreateInputProps.api;
    const currentPage = appointmentDossierCreateInputProps.currentPage;
    const snackbar = useSnackbar();
    return createElement(Page, {
        currentPage: currentPage,
        title: "Afspraken aanmaken",
        actions: [],
        children: [Card([createElement(AppointmentDossierCreateForm, {
            api: api,
            dossierCode: dossierCode,
            onCreateAppointment: (appointment) => ((comment) => ((onComplete) => {
                startImmediate(singleton_2.Delay(() => singleton_2.TryFinally(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(api.CreateAppointment(appointment, comment), (_arg) => {
                    const result = _arg;
                    if (result.tag === 0) {
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Een nieuwe afspraak is aangemaakt", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                        RouterModule_nav(ofArray(Page$2__toUrl_2B594(appointmentDetail, result.fields[0].Value)), 1, 1);
                        return singleton_2.Zero();
                    }
                    else {
                        ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${result.fields[0]}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                        return singleton_2.Zero();
                    }
                })), (_arg_1) => {
                    const e = _arg_1;
                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                    console.log(e);
                    return singleton_2.Zero();
                })), () => {
                    onComplete();
                })));
            })),
        })])],
    });
}

