import { Union, Record } from "../client/src/fable_modules/fable-library.3.7.17/Types.js";
import { Decimal___UnsafeCreate_32C73145, Compensation, NonEmptyString___UnsafeUnwrap_Z73AD07C, AdiRoleModule_string, PhoneNumber___UnsafeUnwrap_23E45174, HomeVisitContactModule_string, AsbestInventory, AsbestInventory$reflection, InspectionLocation$reflection, FilePath$reflection, PhoneNumber$reflection, DossierId$reflection, Address$reflection, InvoiceStatus$reflection, DossierComment$reflection, Email$reflection, AdiId$reflection, AppointmentStatus$reflection, QuoteStatus$reflection, QuoteCode$reflection, PartnerId$reflection, DossierStatus$reflection, DossierCode$reflection, CustomerCode$reflection, PartnerCode$reflection, QuoteDocumentUploadResult$reflection, Quote$reflection, NonEmptyString$reflection, Partner$reflection, AppointmentConfirmation$reflection, Appointment$reflection, LegalRepresentative$reflection, Customer2$reflection, Dossier$reflection } from "./Domain.fs.js";
import { int32_type, union_type, bool_type, string_type, list_type, record_type, option_type, class_type } from "../client/src/fable_modules/fable-library.3.7.17/Reflection.js";
import { ofArray } from "../client/src/fable_modules/fable-library.3.7.17/List.js";
import { map, defaultArg } from "../client/src/fable_modules/fable-library.3.7.17/Option.js";
import { fromParts, equals } from "../client/src/fable_modules/fable-library.3.7.17/Decimal.js";

export class DossierDetails extends Record {
    constructor(Dossier, DossierCreationDate, Customer, LegalRepresentative, Appointment, AppointmentConfirmation, Partner, AdiFirstName, AdiLastName, InBehandelingAdiFirstName, InBehandelingAdiLastName, InvoicePaymentReceivedDate) {
        super();
        this.Dossier = Dossier;
        this.DossierCreationDate = DossierCreationDate;
        this.Customer = Customer;
        this.LegalRepresentative = LegalRepresentative;
        this.Appointment = Appointment;
        this.AppointmentConfirmation = AppointmentConfirmation;
        this.Partner = Partner;
        this.AdiFirstName = AdiFirstName;
        this.AdiLastName = AdiLastName;
        this.InBehandelingAdiFirstName = InBehandelingAdiFirstName;
        this.InBehandelingAdiLastName = InBehandelingAdiLastName;
        this.InvoicePaymentReceivedDate = InvoicePaymentReceivedDate;
    }
}

export function DossierDetails$reflection() {
    return record_type("DomainHelpers.DossierDetails", [], DossierDetails, () => [["Dossier", Dossier$reflection()], ["DossierCreationDate", option_type(class_type("System.DateTimeOffset"))], ["Customer", Customer2$reflection()], ["LegalRepresentative", option_type(LegalRepresentative$reflection())], ["Appointment", option_type(Appointment$reflection())], ["AppointmentConfirmation", option_type(AppointmentConfirmation$reflection())], ["Partner", option_type(Partner$reflection())], ["AdiFirstName", option_type(NonEmptyString$reflection())], ["AdiLastName", option_type(NonEmptyString$reflection())], ["InBehandelingAdiFirstName", option_type(NonEmptyString$reflection())], ["InBehandelingAdiLastName", option_type(NonEmptyString$reflection())], ["InvoicePaymentReceivedDate", option_type(class_type("System.DateTimeOffset"))]]);
}

export class QuoteDetails extends Record {
    constructor(Quote, Customer, LegalRepresentative, Partner, QuoteDocuments) {
        super();
        this.Quote = Quote;
        this.Customer = Customer;
        this.LegalRepresentative = LegalRepresentative;
        this.Partner = Partner;
        this.QuoteDocuments = QuoteDocuments;
    }
}

export function QuoteDetails$reflection() {
    return record_type("DomainHelpers.QuoteDetails", [], QuoteDetails, () => [["Quote", Quote$reflection()], ["Customer", Customer2$reflection()], ["LegalRepresentative", option_type(LegalRepresentative$reflection())], ["Partner", option_type(Partner$reflection())], ["QuoteDocuments", list_type(QuoteDocumentUploadResult$reflection())]]);
}

export class PartnerSearchTerms extends Record {
    constructor(PartnerCode, CompanyName, Address, PhoneNumber, Email, IsCibPartner) {
        super();
        this.PartnerCode = PartnerCode;
        this.CompanyName = CompanyName;
        this.Address = Address;
        this.PhoneNumber = PhoneNumber;
        this.Email = Email;
        this.IsCibPartner = IsCibPartner;
    }
}

export function PartnerSearchTerms$reflection() {
    return record_type("DomainHelpers.PartnerSearchTerms", [], PartnerSearchTerms, () => [["PartnerCode", option_type(PartnerCode$reflection())], ["CompanyName", option_type(string_type)], ["Address", option_type(string_type)], ["PhoneNumber", option_type(string_type)], ["Email", option_type(string_type)], ["IsCibPartner", option_type(bool_type)]]);
}

export class CustomerSearchTerms extends Record {
    constructor(CustomerCode, Name, Address, PhoneNumber, Email) {
        super();
        this.CustomerCode = CustomerCode;
        this.Name = Name;
        this.Address = Address;
        this.PhoneNumber = PhoneNumber;
        this.Email = Email;
    }
}

export function CustomerSearchTerms$reflection() {
    return record_type("DomainHelpers.CustomerSearchTerms", [], CustomerSearchTerms, () => [["CustomerCode", option_type(CustomerCode$reflection())], ["Name", option_type(string_type)], ["Address", option_type(string_type)], ["PhoneNumber", option_type(string_type)], ["Email", option_type(string_type)]]);
}

export class DossierSearchTerms extends Record {
    constructor(DossierCode, PartnerName, AdiName, CustomerName, Address, PhoneNumber, Status, StartDate, EndDate, FilterOutDossiersWithoutPartners, CibFilter, CustomerCode, PartnerPortal, Urgent, PartnerId, PartnerIdList) {
        super();
        this.DossierCode = DossierCode;
        this.PartnerName = PartnerName;
        this.AdiName = AdiName;
        this.CustomerName = CustomerName;
        this.Address = Address;
        this.PhoneNumber = PhoneNumber;
        this.Status = Status;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.FilterOutDossiersWithoutPartners = FilterOutDossiersWithoutPartners;
        this.CibFilter = CibFilter;
        this.CustomerCode = CustomerCode;
        this.PartnerPortal = PartnerPortal;
        this.Urgent = Urgent;
        this.PartnerId = PartnerId;
        this.PartnerIdList = PartnerIdList;
    }
}

export function DossierSearchTerms$reflection() {
    return record_type("DomainHelpers.DossierSearchTerms", [], DossierSearchTerms, () => [["DossierCode", option_type(DossierCode$reflection())], ["PartnerName", option_type(string_type)], ["AdiName", option_type(string_type)], ["CustomerName", option_type(string_type)], ["Address", option_type(string_type)], ["PhoneNumber", option_type(string_type)], ["Status", list_type(DossierStatus$reflection())], ["StartDate", option_type(class_type("System.DateTimeOffset"))], ["EndDate", option_type(class_type("System.DateTimeOffset"))], ["FilterOutDossiersWithoutPartners", bool_type], ["CibFilter", option_type(bool_type)], ["CustomerCode", option_type(CustomerCode$reflection())], ["PartnerPortal", option_type(bool_type)], ["Urgent", option_type(bool_type)], ["PartnerId", option_type(PartnerId$reflection())], ["PartnerIdList", option_type(list_type(PartnerId$reflection()))]]);
}

export class QuoteSearchTerms extends Record {
    constructor(QuoteCode, PartnerName, CustomerName, Address, PhoneNumber, Status, StartDate, EndDate, FilterOutDossiersWithoutPartners, CustomerCode, PartnerPortal, Urgent, PartnerId) {
        super();
        this.QuoteCode = QuoteCode;
        this.PartnerName = PartnerName;
        this.CustomerName = CustomerName;
        this.Address = Address;
        this.PhoneNumber = PhoneNumber;
        this.Status = Status;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.FilterOutDossiersWithoutPartners = FilterOutDossiersWithoutPartners;
        this.CustomerCode = CustomerCode;
        this.PartnerPortal = PartnerPortal;
        this.Urgent = Urgent;
        this.PartnerId = PartnerId;
    }
}

export function QuoteSearchTerms$reflection() {
    return record_type("DomainHelpers.QuoteSearchTerms", [], QuoteSearchTerms, () => [["QuoteCode", option_type(QuoteCode$reflection())], ["PartnerName", option_type(string_type)], ["CustomerName", option_type(string_type)], ["Address", option_type(string_type)], ["PhoneNumber", option_type(string_type)], ["Status", list_type(QuoteStatus$reflection())], ["StartDate", option_type(class_type("System.DateTimeOffset"))], ["EndDate", option_type(class_type("System.DateTimeOffset"))], ["FilterOutDossiersWithoutPartners", bool_type], ["CustomerCode", option_type(CustomerCode$reflection())], ["PartnerPortal", option_type(bool_type)], ["Urgent", option_type(bool_type)], ["PartnerId", option_type(PartnerId$reflection())]]);
}

export class AppointmentSearchTerms extends Record {
    constructor(Status, AssignmentConfirmationUploaded, SamplesUploaded, DossierCode, AdiNaam, CustomerName, Address, PhoneNumber) {
        super();
        this.Status = Status;
        this.AssignmentConfirmationUploaded = AssignmentConfirmationUploaded;
        this.SamplesUploaded = SamplesUploaded;
        this.DossierCode = DossierCode;
        this.AdiNaam = AdiNaam;
        this.CustomerName = CustomerName;
        this.Address = Address;
        this.PhoneNumber = PhoneNumber;
    }
}

export function AppointmentSearchTerms$reflection() {
    return record_type("DomainHelpers.AppointmentSearchTerms", [], AppointmentSearchTerms, () => [["Status", list_type(AppointmentStatus$reflection())], ["AssignmentConfirmationUploaded", option_type(bool_type)], ["SamplesUploaded", option_type(bool_type)], ["DossierCode", option_type(DossierCode$reflection())], ["AdiNaam", option_type(string_type)], ["CustomerName", option_type(string_type)], ["Address", option_type(string_type)], ["PhoneNumber", option_type(string_type)]]);
}

export class AttestenFinaliserenSortFields extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PaymentReceivedDate"];
    }
}

export function AttestenFinaliserenSortFields$reflection() {
    return union_type("DomainHelpers.AttestenFinaliserenSortFields", [], AttestenFinaliserenSortFields, () => [[]]);
}

export class AttestenFinaliserenSearchTerms extends Record {
    constructor(DossierCode, Name, Address, PhoneNumber, Email) {
        super();
        this.DossierCode = DossierCode;
        this.Name = Name;
        this.Address = Address;
        this.PhoneNumber = PhoneNumber;
        this.Email = Email;
    }
}

export function AttestenFinaliserenSearchTerms$reflection() {
    return record_type("DomainHelpers.AttestenFinaliserenSearchTerms", [], AttestenFinaliserenSearchTerms, () => [["DossierCode", option_type(DossierCode$reflection())], ["Name", option_type(string_type)], ["Address", option_type(string_type)], ["PhoneNumber", option_type(string_type)], ["Email", option_type(string_type)]]);
}

export class AppointmentDetails extends Record {
    constructor(Customer, AdiId, AdiEmail, AdiFirstName, AdiLastName, AdiPersonIdOvam, Appointment, Dossier, AppointmentConfirmation, Comments, InvoiceStatus) {
        super();
        this.Customer = Customer;
        this.AdiId = AdiId;
        this.AdiEmail = AdiEmail;
        this.AdiFirstName = AdiFirstName;
        this.AdiLastName = AdiLastName;
        this.AdiPersonIdOvam = AdiPersonIdOvam;
        this.Appointment = Appointment;
        this.Dossier = Dossier;
        this.AppointmentConfirmation = AppointmentConfirmation;
        this.Comments = Comments;
        this.InvoiceStatus = InvoiceStatus;
    }
}

export function AppointmentDetails$reflection() {
    return record_type("DomainHelpers.AppointmentDetails", [], AppointmentDetails, () => [["Customer", Customer2$reflection()], ["AdiId", AdiId$reflection()], ["AdiEmail", Email$reflection()], ["AdiFirstName", NonEmptyString$reflection()], ["AdiLastName", NonEmptyString$reflection()], ["AdiPersonIdOvam", NonEmptyString$reflection()], ["Appointment", Appointment$reflection()], ["Dossier", Dossier$reflection()], ["AppointmentConfirmation", option_type(AppointmentConfirmation$reflection())], ["Comments", list_type(DossierComment$reflection())], ["InvoiceStatus", option_type(InvoiceStatus$reflection())]]);
}

export class FinalCertificate extends Record {
    constructor(CustomerName, AppointmentAddress, DossierId, DossierCode, CustomerPhoneNumber, AppointmentStartDate, AppointmentEndDate, AssignmentConfirmationPhysicallySigned, PartnerId, InspectionLocation, PaymentReceivedDate) {
        super();
        this.CustomerName = CustomerName;
        this.AppointmentAddress = AppointmentAddress;
        this.DossierId = DossierId;
        this.DossierCode = DossierCode;
        this.CustomerPhoneNumber = CustomerPhoneNumber;
        this.AppointmentStartDate = AppointmentStartDate;
        this.AppointmentEndDate = AppointmentEndDate;
        this.AssignmentConfirmationPhysicallySigned = AssignmentConfirmationPhysicallySigned;
        this.PartnerId = PartnerId;
        this.InspectionLocation = InspectionLocation;
        this.PaymentReceivedDate = PaymentReceivedDate;
    }
}

export function FinalCertificate$reflection() {
    return record_type("DomainHelpers.FinalCertificate", [], FinalCertificate, () => [["CustomerName", NonEmptyString$reflection()], ["AppointmentAddress", Address$reflection()], ["DossierId", DossierId$reflection()], ["DossierCode", DossierCode$reflection()], ["CustomerPhoneNumber", option_type(PhoneNumber$reflection())], ["AppointmentStartDate", class_type("System.DateTimeOffset")], ["AppointmentEndDate", class_type("System.DateTimeOffset")], ["AssignmentConfirmationPhysicallySigned", option_type(FilePath$reflection())], ["PartnerId", option_type(PartnerId$reflection())], ["InspectionLocation", option_type(InspectionLocation$reflection())], ["PaymentReceivedDate", option_type(class_type("System.DateTimeOffset"))]]);
}

export class DashboardStatType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DossiersStartedButNotYetDigitallySignedCount", "DossiersDigitallySignedButNoAppointmentCount", "AppointmentsBookedDossiersCount", "ConfirmedAppointmentsWithNoAssignmentUploadAndNoMonstersUpload", "ConfirmedAppointmentsWithAssignmentUploadButNoMonstersUpload", "ConfirmedAppointmentsWithNoAssignmentUploadButHasMonstersUpload", "DossiersWithStatusPaidCount", "DossiersWithStatusCompletedCount", "AdisFinalizedDossiersAmount"];
    }
}

export function DashboardStatType$reflection() {
    return union_type("DomainHelpers.DashboardStatType", [], DashboardStatType, () => [[], [], [], [], [], [], [], [], []]);
}

export class DashboardStatsCount extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DashboardStatOk", "DashboardStatError"];
    }
}

export function DashboardStatsCount$reflection() {
    return union_type("DomainHelpers.DashboardStatsCount", [], DashboardStatsCount, () => [[["Item", int32_type]], [["Item", string_type]]]);
}

export class AdiInfo extends Record {
    constructor(Id, Email, Name) {
        super();
        this.Id = Id;
        this.Email = Email;
        this.Name = Name;
    }
}

export function AdiInfo$reflection() {
    return record_type("DomainHelpers.AdiInfo", [], AdiInfo, () => [["Id", AdiId$reflection()], ["Email", Email$reflection()], ["Name", NonEmptyString$reflection()]]);
}

export class DashboardStatsAdiList extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DashboardStatOk", "DashboardStatError"];
    }
}

export function DashboardStatsAdiList$reflection() {
    return union_type("DomainHelpers.DashboardStatsAdiList", [], DashboardStatsAdiList, () => [[["Item", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [AdiInfo$reflection(), int32_type])]], [["Item", string_type]]]);
}

export class DashboardStat extends Record {
    constructor(DashboardStatType, DashboardStat) {
        super();
        this.DashboardStatType = DashboardStatType;
        this.DashboardStat = DashboardStat;
    }
}

export function DashboardStat$reflection() {
    return record_type("DomainHelpers.DashboardStat", [], DashboardStat, () => [["DashboardStatType", DashboardStatType$reflection()], ["DashboardStat", DashboardStatsCount$reflection()]]);
}

export class Dashboard extends Record {
    constructor(DossiersStartedButNotYetDigitallySignedCount, DossiersDigitallySignedButNoAppointmentCount, AppointmentsBookedDossiersCount, ConfirmedAppointmentsWithNoAssignmentUploadAndNoMonstersUpload, ConfirmedAppointmentsWithAssignmentUploadButNoMonstersUpload, ConfirmedAppointmentsWithNoAssignmentUploadButHasMonstersUpload, DossiersWithStatusPaidCount, DossiersWithStatusCompletedCount) {
        super();
        this.DossiersStartedButNotYetDigitallySignedCount = DossiersStartedButNotYetDigitallySignedCount;
        this.DossiersDigitallySignedButNoAppointmentCount = DossiersDigitallySignedButNoAppointmentCount;
        this.AppointmentsBookedDossiersCount = AppointmentsBookedDossiersCount;
        this.ConfirmedAppointmentsWithNoAssignmentUploadAndNoMonstersUpload = ConfirmedAppointmentsWithNoAssignmentUploadAndNoMonstersUpload;
        this.ConfirmedAppointmentsWithAssignmentUploadButNoMonstersUpload = ConfirmedAppointmentsWithAssignmentUploadButNoMonstersUpload;
        this.ConfirmedAppointmentsWithNoAssignmentUploadButHasMonstersUpload = ConfirmedAppointmentsWithNoAssignmentUploadButHasMonstersUpload;
        this.DossiersWithStatusPaidCount = DossiersWithStatusPaidCount;
        this.DossiersWithStatusCompletedCount = DossiersWithStatusCompletedCount;
    }
}

export function Dashboard$reflection() {
    return record_type("DomainHelpers.Dashboard", [], Dashboard, () => [["DossiersStartedButNotYetDigitallySignedCount", DashboardStatsCount$reflection()], ["DossiersDigitallySignedButNoAppointmentCount", DashboardStatsCount$reflection()], ["AppointmentsBookedDossiersCount", DashboardStatsCount$reflection()], ["ConfirmedAppointmentsWithNoAssignmentUploadAndNoMonstersUpload", DashboardStatsCount$reflection()], ["ConfirmedAppointmentsWithAssignmentUploadButNoMonstersUpload", DashboardStatsCount$reflection()], ["ConfirmedAppointmentsWithNoAssignmentUploadButHasMonstersUpload", DashboardStatsCount$reflection()], ["DossiersWithStatusPaidCount", DashboardStatsCount$reflection()], ["DossiersWithStatusCompletedCount", DashboardStatsCount$reflection()]]);
}

export class AdiListSearchTerms extends Record {
    constructor(Name, Address, Telephone, Email) {
        super();
        this.Name = Name;
        this.Address = Address;
        this.Telephone = Telephone;
        this.Email = Email;
    }
}

export function AdiListSearchTerms$reflection() {
    return record_type("DomainHelpers.AdiListSearchTerms", [], AdiListSearchTerms, () => [["Name", option_type(string_type)], ["Address", option_type(string_type)], ["Telephone", option_type(string_type)], ["Email", option_type(string_type)]]);
}

export class AsbestInventoryProfile extends Record {
    constructor(Allowed) {
        super();
        this.Allowed = Allowed;
    }
}

export function AsbestInventoryProfile$reflection() {
    return record_type("DomainHelpers.AsbestInventoryProfile", [], AsbestInventoryProfile, () => [["Allowed", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [AsbestInventory$reflection()])]]);
}

export function AsbestInventoryProfile_get_defaultInventory() {
    return ofArray([new AsbestInventory(0), new AsbestInventory(1), new AsbestInventory(2), new AsbestInventory(3), new AsbestInventory(4), new AsbestInventory(5), new AsbestInventory(6), new AsbestInventory(7), new AsbestInventory(8)]);
}

export function Dossier_contact(dossier, partner) {
    const partner_1 = defaultArg(map((p) => p.CompanyName.Value, partner), "");
    const matchValue = dossier.HomeVisitContact;
    switch (matchValue.tag) {
        case 1: {
            return partner_1;
        }
        case 0:
        case 2:
        case 3: {
            return HomeVisitContactModule_string(dossier.HomeVisitContact);
        }
        default: {
            return partner_1;
        }
    }
}

export function Dossier_contactPhoneNumber(dossier, partner) {
    const matchValue = dossier.HomeVisitContact;
    switch (matchValue.tag) {
        case 1: {
            return defaultArg(map((p) => p.PhoneNumber.Value, partner), "");
        }
        case 0: {
            return defaultArg(map(PhoneNumber___UnsafeUnwrap_23E45174, dossier.Owner_PhoneNumber), "");
        }
        case 2:
        case 3: {
            return defaultArg(dossier.HomeVisitContactEmail, "");
        }
        default: {
            return defaultArg(map((p) => p.PhoneNumber.Value, partner), "");
        }
    }
}

export function Quote_contact(quote, partner) {
    const partner_1 = defaultArg(map((p) => p.CompanyName.Value, partner), "");
    const matchValue = quote.HomeVisitContact;
    if (matchValue == null) {
        return "";
    }
    else {
        const homeVisitContact = matchValue;
        switch (homeVisitContact.tag) {
            case 1: {
                return partner_1;
            }
            case 0:
            case 2:
            case 3: {
                return HomeVisitContactModule_string(homeVisitContact);
            }
            default: {
                return partner_1;
            }
        }
    }
}

export function Quote_contactPhoneNumber(quote, partner) {
    const matchValue = quote.HomeVisitContact;
    if (matchValue == null) {
        return "";
    }
    else {
        const homeVisitContact = matchValue;
        switch (homeVisitContact.tag) {
            case 1: {
                return defaultArg(map((p) => p.PhoneNumber.Value, partner), "");
            }
            case 0: {
                return defaultArg(map(PhoneNumber___UnsafeUnwrap_23E45174, quote.Owner_PhoneNumber), "");
            }
            case 2:
            case 3: {
                return defaultArg(quote.HomeVisitContactEmail, "");
            }
            default: {
                return defaultArg(map((p) => p.PhoneNumber.Value, partner), "");
            }
        }
    }
}

export function Adi_nameAndRole(adi) {
    let role_2;
    const matchValue = adi.Role;
    if (matchValue == null) {
        role_2 = "";
    }
    else {
        const role = matchValue;
        role_2 = (` (${AdiRoleModule_string(role)})`);
    }
    return `${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.FirstName)} ${NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.LastName)}${role_2}`;
}

export function Compensation_toDomain(dbo) {
    return map((o) => {
        if (equals(o, fromParts(0, 0, 0, false, 0))) {
            return new Compensation(0);
        }
        else if (equals(o, fromParts(10, 0, 0, false, 0))) {
            return new Compensation(1);
        }
        else if (equals(o, fromParts(20, 0, 0, false, 0))) {
            return new Compensation(2);
        }
        else if (equals(o, fromParts(30, 0, 0, false, 0))) {
            return new Compensation(3);
        }
        else if (equals(o, fromParts(40, 0, 0, false, 0))) {
            return new Compensation(4);
        }
        else if (equals(o, fromParts(50, 0, 0, false, 0))) {
            return new Compensation(5);
        }
        else if (equals(o, fromParts(60, 0, 0, false, 0))) {
            return new Compensation(6);
        }
        else if (equals(o, fromParts(70, 0, 0, false, 0))) {
            return new Compensation(7);
        }
        else if (equals(o, fromParts(80, 0, 0, false, 0))) {
            return new Compensation(8);
        }
        else if (equals(o, fromParts(90, 0, 0, false, 0))) {
            return new Compensation(9);
        }
        else if (equals(o, fromParts(100, 0, 0, false, 0))) {
            return new Compensation(10);
        }
        else {
            return new Compensation(11, Decimal___UnsafeCreate_32C73145(o));
        }
    }, dbo);
}

export function Compensation_fromDomain(dbo) {
    switch (dbo.tag) {
        case 1: {
            return fromParts(10, 0, 0, false, 0);
        }
        case 2: {
            return fromParts(20, 0, 0, false, 0);
        }
        case 3: {
            return fromParts(30, 0, 0, false, 0);
        }
        case 4: {
            return fromParts(40, 0, 0, false, 0);
        }
        case 5: {
            return fromParts(50, 0, 0, false, 0);
        }
        case 6: {
            return fromParts(60, 0, 0, false, 0);
        }
        case 7: {
            return fromParts(70, 0, 0, false, 0);
        }
        case 8: {
            return fromParts(80, 0, 0, false, 0);
        }
        case 9: {
            return fromParts(90, 0, 0, false, 0);
        }
        case 10: {
            return fromParts(100, 0, 0, false, 0);
        }
        case 11: {
            return dbo.fields[0].Value;
        }
        default: {
            return fromParts(0, 0, 0, false, 0);
        }
    }
}

