import { fss } from "../client/src/fable_modules/Fss-lib.Fable.1.0.3/FssFable.fs.js";
import { DisplayClasses_DisplayClass__get_grid } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Display.fs.js";
import { Display } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Display.fs.js";
import { Custom } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Custom.fs.js";
import { singleton, ofArray } from "../client/src/fable_modules/fable-library.3.7.17/List.js";
import { unitHelpers_DirectionalLength__value_Z498FEB3B, unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Units.fs.js";
import { px } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Functions.fs.js";
import { MinWidth, Height } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/ContentSize.fs.js";
import { ColorClass_Color__hex_Z721C83C5 } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Color.fs.js";
import { BackgroundImage, BackgroundColor } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Background.fs.js";
import { ImageClasses_ImageClass__url_Z721C83C5 } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Image.fs.js";
import bkg from "./imgs/bkg.png";
import { BorderRadius, BorderStyle, BorderColor, BorderWidth } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Border.fs.js";
import { BorderClasses_BorderStyleParent__get_solid } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Border.fs.js";

export const AssignmentConfirmationInspectionLocation = fss(ofArray([DisplayClasses_DisplayClass__get_grid(Display), Custom("grid-template-columns", "300px 300px")]));

export const ReviewCard = fss(ofArray([unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Height, px(600)), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MinWidth, px(300))]));

export const InfoChip = fss(singleton(ColorClass_Color__hex_Z721C83C5(BackgroundColor, "c1e1ec")));

export const UrgentChip = fss(singleton(ColorClass_Color__hex_Z721C83C5(BackgroundColor, "ff6961")));

export const BkgImage = fss(ofArray([ImageClasses_ImageClass__url_Z721C83C5(BackgroundImage, bkg), Custom("min-height", "calc(100vh - 80px)")]));

export const partnerGrid = fss(ofArray([ColorClass_Color__hex_Z721C83C5(BackgroundColor, "f3f3f3"), unitHelpers_DirectionalLength__value_Z498FEB3B(BorderWidth, px(1)), ColorClass_Color__hex_Z721C83C5(BorderColor, "e7e7e7"), BorderClasses_BorderStyleParent__get_solid(BorderStyle), unitHelpers_DirectionalLength__value_Z498FEB3B(BorderRadius, px(4))]));

