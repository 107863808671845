import { useForm, useFormValidator, useFormField } from "../../../CustomHooks/ComposedForm.fs.js";
import { AdiId, CompanyInformation, CompanyFormat, Address, NonEmptyString, TypeOfCorporation$reflection, Region, AdiRoleModule_string, AdiRole, SelectOptionData$1_Create_Z510A3DC0, EmptyString_get_Create, BankAccount_Create_Z721C83C5, BankAccount___UnsafeUnwrap_Z2C38F894, VatNumber_get_Create, VatNumber___UnsafeUnwrap_18C04ACB, PersonIdOvam_Create_Z721C83C5, PersonIdOvam___UnsafeUnwrap_ZCAC45BA, NationalInsuranceNumber_Create_Z721C83C5, NationalInsuranceNumber___UnsafeUnwrap_Z19EB54D4, PhoneNumber_get_Create, PhoneNumber___UnsafeUnwrap_23E45174, Email_get_Create, Email___UnsafeUnwrap_Z27D11339, NonEmptyString_get_Create, NonEmptyString___UnsafeUnwrap_Z73AD07C } from "../../../shared/Domain.fs.js";
import { CreateAddressForm, useAddressForm } from "../../../DesignSystems/AddressHook.fs.js";
import { RegionCheckbox, UiCompanyType, TermOfPayment } from "./AdiCreate.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { createElement } from "react";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import { MarginTop, Gap } from "../../../Styles/Utils.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.3.7.17/List.js";
import { iterate, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { Card, SelectOptionField, OptionField, TextField } from "../../../DesignSystems/DesignSystem.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import Button from "@material-ui/core/Button";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { adiDetail } from "../AppRoutes.fs.js";
import { isNullOrEmpty } from "../fable_modules/fable-library.3.7.17/String.js";
import { AdiData, CompanyData, CompanyFormatData, CompanyInformationData } from "../../../shared/Api.fs.js";
import { toArray } from "../fable_modules/fable-library.3.7.17/Option.js";
import { useSnackbar } from "notistack";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { Page } from "../components/Page.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";

export function CreateAdiForm(createAdiFormInputProps) {
    const onEditAdi = createAdiFormInputProps.onEditAdi;
    const adi = createAdiFormInputProps.adi;
    const firstName = useFormField(NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.FirstName), NonEmptyString_get_Create());
    const lastName = useFormField(NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.LastName), NonEmptyString_get_Create());
    const email = useFormField(Email___UnsafeUnwrap_Z27D11339(adi.Email), Email_get_Create());
    const phoneNumber = useFormField(PhoneNumber___UnsafeUnwrap_23E45174(adi.PhoneNumber), PhoneNumber_get_Create());
    const nationalInsuranceNumber = useFormField(NationalInsuranceNumber___UnsafeUnwrap_Z19EB54D4(adi.NationalInsuranceNumber), NationalInsuranceNumber_Create_Z721C83C5);
    const personalIdOvam = useFormField(PersonIdOvam___UnsafeUnwrap_ZCAC45BA(adi.PersonIdOvam), PersonIdOvam_Create_Z721C83C5);
    const emailAsbestConsulting = useFormField(Email___UnsafeUnwrap_Z27D11339(adi.EmailAsbestConsulting), Email_get_Create());
    const vatNumber = useFormField(VatNumber___UnsafeUnwrap_18C04ACB(adi.AdiCompany.VatNumber), VatNumber_get_Create());
    const bankAccount = useFormField(BankAccount___UnsafeUnwrap_Z2C38F894(adi.AdiCompany.BankAccount), BankAccount_Create_Z721C83C5);
    let bus_1;
    const matchValue = adi.Address.Bus;
    bus_1 = ((matchValue == null) ? "" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue));
    let busCaseAddress;
    const matchValue_1 = adi.AdiCompany.Address.Bus;
    busCaseAddress = ((matchValue_1 == null) ? "" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_1));
    const address = useAddressForm(NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.Address.Street), NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.Address.Number), bus_1, NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.Address.PostalCode), NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.Address.City));
    const addressCase = useAddressForm(NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.AdiCompany.Address.Street), NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.AdiCompany.Address.Number), busCaseAddress, NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.AdiCompany.Address.PostalCode), NonEmptyString___UnsafeUnwrap_Z73AD07C(adi.AdiCompany.Address.City));
    let patternInput;
    const matchValue_2 = adi.AdiCompany.CompanyFormat;
    if (matchValue_2.tag === 0) {
        patternInput = ["", "", ""];
    }
    else {
        const c = matchValue_2.fields[0];
        patternInput = [c.Name.Value, c.PhoneNumber.Value, c.Email.Value];
    }
    let ctermofpay;
    const matchValue_3 = adi.AdiCompany.TermOfPayment | 0;
    switch (matchValue_3) {
        case 7: {
            ctermofpay = (new TermOfPayment(0));
            break;
        }
        case 14: {
            ctermofpay = (new TermOfPayment(1));
            break;
        }
        case 30: {
            ctermofpay = (new TermOfPayment(2));
            break;
        }
        default: {
            ctermofpay = (void 0);
        }
    }
    const companyName = useFormField(patternInput[0], EmptyString_get_Create());
    const vatLiable = useFormField(adi.AdiCompany.VatLiable, SelectOptionData$1_Create_Z510A3DC0);
    const termOfPayment = useFormField(ctermofpay, SelectOptionData$1_Create_Z510A3DC0);
    const telefoon = useFormField(patternInput[1], PhoneNumber_get_Create());
    const emailadres = useFormField(patternInput[2], Email_get_Create());
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(adi.Regions);
    const setRegionValues = patternInput_1[1];
    const regionValues = patternInput_1[0];
    let patternInput_2;
    const matchValue_4 = adi.AdiCompany.CompanyFormat;
    patternInput_2 = ((matchValue_4.tag === 0) ? [void 0, new UiCompanyType(1)] : [matchValue_4.fields[0].TypeOfCorporation, new UiCompanyType(0)]);
    const companyForm = useFormField(patternInput_2[0], SelectOptionData$1_Create_Z510A3DC0);
    const companyType = useFormField(patternInput_2[1], SelectOptionData$1_Create_Z510A3DC0);
    const role = useFormField(adi.Role, SelectOptionData$1_Create_Z510A3DC0);
    const form = useFormValidator(role.Validator, (r_12, x_13) => ({
        Address: r_12.Address,
        BankAccount: r_12.BankAccount,
        CompanyType: r_12.CompanyType,
        Email: r_12.Email,
        EmailAsbestConsulting: r_12.EmailAsbestConsulting,
        FirstName: r_12.FirstName,
        LastName: r_12.LastName,
        NationalInsuranceNumber: r_12.NationalInsuranceNumber,
        PersonalIdOvam: r_12.PersonalIdOvam,
        PhoneNumber: r_12.PhoneNumber,
        Role: x_13,
        TermOfPayment: r_12.TermOfPayment,
        VatLiable: r_12.VatLiable,
        VatNumber: r_12.VatNumber,
    }), useFormValidator(vatLiable.Validator, (r_11, x_12) => ({
        Address: r_11.Address,
        BankAccount: r_11.BankAccount,
        CompanyType: r_11.CompanyType,
        Email: r_11.Email,
        EmailAsbestConsulting: r_11.EmailAsbestConsulting,
        FirstName: r_11.FirstName,
        LastName: r_11.LastName,
        NationalInsuranceNumber: r_11.NationalInsuranceNumber,
        PersonalIdOvam: r_11.PersonalIdOvam,
        PhoneNumber: r_11.PhoneNumber,
        TermOfPayment: r_11.TermOfPayment,
        VatLiable: x_12,
        VatNumber: r_11.VatNumber,
    }), useFormValidator(termOfPayment.Validator, (r_10, x_11) => ({
        Address: r_10.Address,
        BankAccount: r_10.BankAccount,
        CompanyType: r_10.CompanyType,
        Email: r_10.Email,
        EmailAsbestConsulting: r_10.EmailAsbestConsulting,
        FirstName: r_10.FirstName,
        LastName: r_10.LastName,
        NationalInsuranceNumber: r_10.NationalInsuranceNumber,
        PersonalIdOvam: r_10.PersonalIdOvam,
        PhoneNumber: r_10.PhoneNumber,
        TermOfPayment: x_11,
        VatNumber: r_10.VatNumber,
    }), useFormValidator(companyType.Validator, (r_9, x_10) => ({
        Address: r_9.Address,
        BankAccount: r_9.BankAccount,
        CompanyType: x_10,
        Email: r_9.Email,
        EmailAsbestConsulting: r_9.EmailAsbestConsulting,
        FirstName: r_9.FirstName,
        LastName: r_9.LastName,
        NationalInsuranceNumber: r_9.NationalInsuranceNumber,
        PersonalIdOvam: r_9.PersonalIdOvam,
        PhoneNumber: r_9.PhoneNumber,
        VatNumber: r_9.VatNumber,
    }), useFormValidator(bankAccount.Validator, (r_8, x_9) => ({
        Address: r_8.Address,
        BankAccount: x_9,
        Email: r_8.Email,
        EmailAsbestConsulting: r_8.EmailAsbestConsulting,
        FirstName: r_8.FirstName,
        LastName: r_8.LastName,
        NationalInsuranceNumber: r_8.NationalInsuranceNumber,
        PersonalIdOvam: r_8.PersonalIdOvam,
        PhoneNumber: r_8.PhoneNumber,
        VatNumber: r_8.VatNumber,
    }), useFormValidator(vatNumber.Validator, (r_7, x_8) => ({
        Address: r_7.Address,
        Email: r_7.Email,
        EmailAsbestConsulting: r_7.EmailAsbestConsulting,
        FirstName: r_7.FirstName,
        LastName: r_7.LastName,
        NationalInsuranceNumber: r_7.NationalInsuranceNumber,
        PersonalIdOvam: r_7.PersonalIdOvam,
        PhoneNumber: r_7.PhoneNumber,
        VatNumber: x_8,
    }), useFormValidator(emailAsbestConsulting.Validator, (r_6, x_7) => ({
        Address: r_6.Address,
        Email: r_6.Email,
        EmailAsbestConsulting: x_7,
        FirstName: r_6.FirstName,
        LastName: r_6.LastName,
        NationalInsuranceNumber: r_6.NationalInsuranceNumber,
        PersonalIdOvam: r_6.PersonalIdOvam,
        PhoneNumber: r_6.PhoneNumber,
    }), useFormValidator(personalIdOvam.Validator, (r_5, x_6) => ({
        Address: r_5.Address,
        Email: r_5.Email,
        FirstName: r_5.FirstName,
        LastName: r_5.LastName,
        NationalInsuranceNumber: r_5.NationalInsuranceNumber,
        PersonalIdOvam: x_6,
        PhoneNumber: r_5.PhoneNumber,
    }), useFormValidator(nationalInsuranceNumber.Validator, (r_4, x_5) => ({
        Address: r_4.Address,
        Email: r_4.Email,
        FirstName: r_4.FirstName,
        LastName: r_4.LastName,
        NationalInsuranceNumber: x_5,
        PhoneNumber: r_4.PhoneNumber,
    }), useFormValidator(phoneNumber.Validator, (r_3, x_4) => ({
        Address: r_3.Address,
        Email: r_3.Email,
        FirstName: r_3.FirstName,
        LastName: r_3.LastName,
        PhoneNumber: x_4,
    }), useFormValidator(email.Validator, (r_2, x_3) => ({
        Address: r_2.Address,
        Email: x_3,
        FirstName: r_2.FirstName,
        LastName: r_2.LastName,
    }), useFormValidator(address.Validator, (r_1, x_2) => ({
        Address: x_2,
        FirstName: r_1.FirstName,
        LastName: r_1.LastName,
    }), useFormValidator(lastName.Validator, (r, x_1) => ({
        FirstName: r.FirstName,
        LastName: x_1,
    }), useFormValidator(firstName.Validator, (unitVar, x) => ({
        FirstName: x,
    }), useForm()))))))))))))));
    const setDisable = useFeliz_React__React_useState_Static_1505(false)[1];
    return createElement(Col, {
        classes: singleton(Gap.M),
        children: toList(delay(() => append(singleton_1(TextField(lastName, "Naam", "", true)), delay(() => append(singleton_1(TextField(firstName, "Voornaam", "", true)), delay(() => append(singleton_1(TextField(email, "Emailadres", "", true)), delay(() => append(singleton_1(TextField(phoneNumber, "Telefoon", "", true)), delay(() => append(singleton_1(TextField(nationalInsuranceNumber, "Rijksregisternummer", "", true)), delay(() => append(singleton_1(TextField(personalIdOvam, "Persoons-id OVAM", "", true)), delay(() => append(singleton_1(TextField(emailAsbestConsulting, "Email Asbest Consulting", "", true)), delay(() => append(singleton_1(createElement("h2", {
            children: ["Role"],
        })), delay(() => {
            let elements;
            return append(singleton_1(MuiHelpers_createElement(RadioGroup, [(elements = [OptionField(role, new AdiRole(0), AdiRoleModule_string(new AdiRole(0)), "", true), OptionField(role, new AdiRole(1), AdiRoleModule_string(new AdiRole(1)), "", true), OptionField(role, new AdiRole(2), AdiRoleModule_string(new AdiRole(2)), "", true), OptionField(role, new AdiRole(3), AdiRoleModule_string(new AdiRole(3)), "", true)], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])), delay(() => append(singleton_1(createElement(CreateAddressForm, {
                addressForm: address,
                heading: void 0,
                disabled: false,
            })), delay(() => append(singleton_1(createElement("h2", {
                children: ["Bedrijfsgegevens"],
            })), delay(() => {
                let elements_1;
                return append(singleton_1(MuiHelpers_createElement(RadioGroup, [["row", true], (elements_1 = [OptionField(companyType, new UiCompanyType(0), "Vennootschap", "", false), OptionField(companyType, new UiCompanyType(1), "Zelfstandige natuurlijk persoon", "", false)], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])])), delay(() => append(singleton_1(TextField(vatNumber, "Ondernemingsnummer", "", true)), delay(() => append(singleton_1(TextField(bankAccount, "Zichtrekening", "", true)), delay(() => append(singleton_1(createElement("h2", {
                    children: ["Betalingstermijn"],
                })), delay(() => {
                    let elements_2;
                    return append(singleton_1(MuiHelpers_createElement(RadioGroup, [["row", false], (elements_2 = [OptionField(termOfPayment, new TermOfPayment(0), "7 dagen", "", false), OptionField(termOfPayment, new TermOfPayment(1), "14 dagen", "", false), OptionField(termOfPayment, new TermOfPayment(2), "30 dagen", "", false)], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])])), delay(() => append(singleton_1(createElement("h2", {
                        children: ["Regio(s) waar de ADI werkzaam is"],
                    })), delay(() => {
                        let elements_3;
                        return append(singleton_1(MuiHelpers_createElement(RadioGroup, [["row", false], (elements_3 = [createElement(RegionCheckbox, {
                            label: "West Flanders",
                            regionValue: new Region(0),
                            values: regionValues,
                            setRegions: setRegionValues,
                        }), createElement(RegionCheckbox, {
                            label: "East Flanders",
                            regionValue: new Region(1),
                            values: regionValues,
                            setRegions: setRegionValues,
                        }), createElement(RegionCheckbox, {
                            label: "Antwerp",
                            regionValue: new Region(2),
                            values: regionValues,
                            setRegions: setRegionValues,
                        }), createElement(RegionCheckbox, {
                            label: "Flemish Brabant",
                            regionValue: new Region(3),
                            values: regionValues,
                            setRegions: setRegionValues,
                        }), createElement(RegionCheckbox, {
                            label: "Limburg",
                            regionValue: new Region(4),
                            values: regionValues,
                            setRegions: setRegionValues,
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elements_3))])])), delay(() => append(singleton_1(createElement("h2", {
                            children: ["BTW-plichtig"],
                        })), delay(() => {
                            let elements_4;
                            return append(singleton_1(MuiHelpers_createElement(RadioGroup, [["row", false], (elements_4 = [OptionField(vatLiable, true, "ja", "", false), OptionField(vatLiable, false, "nee", "", false)], ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])])), delay(() => {
                                let matchValue_7;
                                return append((matchValue_7 = companyType.FieldValue, (matchValue_7 == null) ? singleton_1(null) : ((matchValue_7.tag === 1) ? singleton_1(null) : append(singleton_1(createElement("h2", {
                                    children: ["Gegevens vennootschap"],
                                })), delay(() => append(singleton_1(TextField(companyName, "Naam vennootschap", "", true)), delay(() => append(singleton_1(SelectOptionField(companyForm, "Vennootschapsvorm", "", true, TypeOfCorporation$reflection())), delay(() => append(singleton_1(TextField(telefoon, "Telefoon", "", true)), delay(() => singleton_1(TextField(emailadres, "Emailadres", "", true)))))))))))), delay(() => append(singleton_1(createElement(CreateAddressForm, {
                                    addressForm: addressCase,
                                    heading: "Adres zaak",
                                    disabled: false,
                                })), delay(() => singleton_1(createElement(Row, {
                                    classes: ofArray([Gap.M, MarginTop.L]),
                                    children: ofArray([MuiHelpers_createElement(Button, [["color", "default"], ["variant", "contained"], ["onClick", (_arg_1) => {
                                        RouterModule_nav(ofArray(Page$2__toUrl_2B594(adiDetail, adi.Id.Value)), 1, 1);
                                    }], ["children", Interop_reactApi.Children.toArray(["Annuleren"])]]), MuiHelpers_createElement(Button, [["color", "primary"], ["variant", "contained"], ["onClick", (_arg) => {
                                        iterate((data) => {
                                            let cformVal, c_2;
                                            console.log(data);
                                            setDisable(true);
                                            iterate((x_18) => {
                                                let matchValue_5, matchValue_6, cInfo;
                                                const company = new CompanyData(data.VatNumber, data.BankAccount, (matchValue_5 = data.TermOfPayment.Value, (matchValue_5.tag === 1) ? 14 : ((matchValue_5.tag === 2) ? 30 : 7)), data.VatLiable.Value, new Address(new NonEmptyString(addressCase.StreetField.FieldValue), new NonEmptyString(addressCase.NumberField.FieldValue), isNullOrEmpty(addressCase.BusField.FieldValue) ? (void 0) : (new NonEmptyString(addressCase.BusField.FieldValue)), new NonEmptyString(addressCase.PostalCodeField.FieldValue), new NonEmptyString(addressCase.City.FieldValue)), (matchValue_6 = x_18.CompanyFormat, (matchValue_6.tag === 1) ? ((cInfo = matchValue_6.fields[0], new CompanyFormatData(1, new CompanyInformationData(cInfo.Name, cInfo.TypeOfCorporation, cInfo.PhoneNumber, cInfo.Email)))) : (new CompanyFormatData(0))));
                                                onEditAdi(() => {
                                                    setDisable(false);
                                                })(adi.Id)(adi.AdiCompany.CompanyId)(new AdiData(data.LastName, data.FirstName, data.Address, data.Email, data.PhoneNumber, data.NationalInsuranceNumber, data.PersonalIdOvam, data.EmailAsbestConsulting, company, regionValues, data.Role.Value));
                                            }, toArray((data.CompanyType.Value.tag === 1) ? {
                                                CompanyFormat: new CompanyFormat(0),
                                            } : ((cformVal = useFormValidator(emailadres.Validator, (r_15, x_17) => ({
                                                CompanyName: r_15.CompanyName,
                                                Emailadres: x_17,
                                                Telefoon: r_15.Telefoon,
                                                TypeOfCorp: r_15.TypeOfCorp,
                                            }), useFormValidator(telefoon.Validator, (r_14, x_16) => ({
                                                CompanyName: r_14.CompanyName,
                                                Telefoon: x_16,
                                                TypeOfCorp: r_14.TypeOfCorp,
                                            }), useFormValidator(companyName.Validator, (r_13, x_15) => ({
                                                CompanyName: new NonEmptyString(x_15.Value),
                                                TypeOfCorp: r_13.TypeOfCorp,
                                            }), useFormValidator(companyForm.Validator, (unitVar_1, x_14) => ({
                                                TypeOfCorp: x_14.Value,
                                            }), useForm())))).Validate(), (cformVal == null) ? (void 0) : ((c_2 = cformVal, {
                                                CompanyFormat: new CompanyFormat(1, new CompanyInformation(adi.AdiCompany.CompanyId, c_2.CompanyName, c_2.TypeOfCorp, c_2.Telefoon, c_2.Emailadres)),
                                            }))))));
                                        }, toArray(form.Validate()));
                                    }], ["children", Interop_reactApi.Children.toArray(["Bewaren"])]])]),
                                }))))));
                            }));
                        }))));
                    }))));
                }))))))));
            }))))));
        })))))))))))))))))),
    });
}

export function AdiEdit(adiEditInputProps) {
    const adiId = adiEditInputProps.adiId;
    const api = adiEditInputProps.api;
    const currentPage = adiEditInputProps.currentPage;
    const snackbar = useSnackbar();
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetAdi(new AdiId(adiId)), []);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Klantenfiche",
        actions: [],
        children: [Card([createElement(DisplayDeferred, {
            data: data,
            view: (adi) => {
                if (adi == null) {
                    return `No adi found with ID ${adiId}`;
                }
                else {
                    const adi_1 = adi;
                    return createElement(CreateAdiForm, {
                        adi: adi_1,
                        onEditAdi: (onCompleted) => ((aId) => ((companyId) => ((adiData) => {
                            startImmediate(singleton_2.Delay(() => singleton_2.TryFinally(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(api.EditAdi(aId, companyId, adiData), (_arg) => {
                                const result = _arg;
                                if (result.tag === 0) {
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "Klant aangepast", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                    RouterModule_nav(ofArray(Page$2__toUrl_2B594(adiDetail, adiId)), 1, 1);
                                    return singleton_2.Zero();
                                }
                                else {
                                    const err = result.fields[0];
                                    console.log(err);
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren. [${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                    return singleton_2.Zero();
                                }
                            })), (_arg_1) => {
                                const e = _arg_1;
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.  [${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                console.log(e);
                                return singleton_2.Zero();
                            })), () => {
                                onCompleted();
                            })));
                        }))),
                    });
                }
            },
        })])],
    });
}

