import * as react_big_calendar from "react-big-calendar/lib/css/react-big-calendar.css";
import { dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import nl_BE from "date-fns/locale/nl-BE";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { CalendarProp$2_Messages_6900B931, CalendarProp$2, Calendar, Message } from "../lib/bindings/BigCalendar.fs.js";
import { ofArray } from "../fable_modules/fable-library.3.7.17/List.js";
import { singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";


export const localizer = dateFnsLocalizer({
    format: format,
    getDay: getDay,
    locales: {
        ["nl-BE"]: nl_BE,
    },
    parse: parse,
    startOfWeek: startOfWeek,
});

export const messages = ofArray([new Message(0, "Datum"), new Message(1, "Tijd"), new Message(2, "Event"), new Message(3, "Hele dag"), new Message(4, "Week"), new Message(5, "Werk week"), new Message(6, "Dag"), new Message(7, "Maand"), new Message(8, "Vorige"), new Message(9, "Volgende"), new Message(10, "Gisteren"), new Message(11, "Morgen"), new Message(12, "Vandaag"), new Message(13, "Agenda"), new Message(14, (total) => (`+${total} meer`)), new Message(15, "Geen events in bereik")]);

export function Scheduler(props) {
    return Calendar(toList(delay(() => append(singleton(new CalendarProp$2(1, localizer)), delay(() => append(singleton(new CalendarProp$2(32, "nl-BE")), delay(() => append(singleton(CalendarProp$2_Messages_6900B931(messages)), delay(() => props)))))))));
}

