import { Record } from "../client/src/fable_modules/fable-library.3.7.17/Types.js";
import { record_type, lambda_type, option_type, unit_type } from "../client/src/fable_modules/fable-library.3.7.17/Reflection.js";
import { map2, some, value as value_1, map } from "../client/src/fable_modules/fable-library.3.7.17/Option.js";
import { useFeliz_React__React_useState_Static_1505 } from "../client/src/fable_modules/Feliz.1.68.0/React.fs.js";

export class FormValidator$1 extends Record {
    constructor(Validate) {
        super();
        this.Validate = Validate;
    }
}

export function FormValidator$1$reflection(gen0) {
    return record_type("ComposedForm.FormValidator`1", [gen0], FormValidator$1, () => [["Validate", lambda_type(unit_type, option_type(gen0))]]);
}

export function FormValidator$1_map(mapper, formValidate) {
    return new FormValidator$1(() => map(mapper, formValidate.Validate()));
}

export function useFormField(initialValue, validator) {
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(initialValue);
    const setFieldValue = patternInput_1[1];
    const fieldValue = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setError = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const setTouched = patternInput_3[1];
    return {
        Error: patternInput_2[0],
        FieldValue: fieldValue,
        Reset: () => {
            setFieldValue(initialValue);
            setError(false);
            setTouched(false);
        },
        SetFieldValue: (x) => {
            setTouched(true);
            setFieldValue(x);
        },
        ShowErrorState: () => {
            setError(true);
        },
        Touched: patternInput_3[0],
        Validator: new FormValidator$1(() => {
            const matchValue = validator(fieldValue);
            if (matchValue == null) {
                setError(true);
                return void 0;
            }
            else {
                const x_1 = value_1(matchValue);
                setError(false);
                patternInput[1](some(x_1));
                return some(x_1);
            }
        }),
        Value: patternInput[0],
    };
}

export function useForm() {
    return new FormValidator$1(() => some(void 0));
}

export function useFormValidator(fieldValidator, builder, prevValidate) {
    return new FormValidator$1(() => {
        const currentFieldResult = fieldValidator.Validate();
        return map2(builder, prevValidate.Validate(), currentFieldResult);
    });
}

