import { useForm, useFormValidator, useFormField } from "../../../CustomHooks/ComposedForm.fs.js";
import { PartnerId, AdiRoleModule_string, AdiRole, SelectOptionData$1_Create_Z510A3DC0, VatNumber_get_Create, VatNumber___UnsafeUnwrap_18C04ACB, Partner__get_LastName, Partner__get_Firstname, PhoneNumber_get_Create, PhoneNumber___UnsafeUnwrap_23E45174, Email_get_Create, Email___UnsafeUnwrap_Z27D11339, NonEmptyString_get_Create, NonEmptyString___UnsafeUnwrap_Z73AD07C } from "../../../shared/Domain.fs.js";
import { toArray, map, defaultArg, some } from "../fable_modules/fable-library.3.7.17/Option.js";
import { useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.68.0/React.fs.js";
import { useToggle } from "../../../CustomHooks/CustomHooks.fs.js";
import { ofList } from "../fable_modules/fable-library.3.7.17/Set.js";
import { compare } from "../fable_modules/fable-library.3.7.17/Util.js";
import { CreateAddressFormSmallHeader, useAddressForm } from "../../../DesignSystems/AddressHook.fs.js";
import { createElement } from "react";
import { Row, Col } from "../../../Components/UtilComponents.fs.js";
import { MarginTop, Gap } from "../../../Styles/Utils.fs.js";
import { ofArray, singleton } from "../fable_modules/fable-library.3.7.17/List.js";
import { iterate, collect, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.17/Seq.js";
import { VmeSocialToggles } from "../../../DesignSystems/Contacts.fs.js";
import { ErrorText, Card, OptionField, TextField } from "../../../DesignSystems/DesignSystem.fs.js";
import { MuiHelpers_createElement } from "../fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { Browser_Types_Event__Event_get_Checked } from "../fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { DisplayDeferred } from "../../../DesignSystems/DisplayDeferred.fs.js";
import { Adi_nameAndRole } from "../../../shared/DomainHelpers.fs.js";
import { AdiCheckbox } from "../../../DesignSystems/partner/PartnerUtils.fs.js";
import Button from "@material-ui/core/Button";
import { RouterModule_nav } from "../fable_modules/Feliz.Router.3.10.0/./Router.fs.js";
import { Page$2__toUrl_2B594 } from "../../../BidirectionalRouting/BidirectionalRouting.fs.js";
import { partnersDetail } from "../AppRoutes.fs.js";
import { PartnerData } from "../../../shared/Api.fs.js";
import { useSnackbar } from "notistack";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.5.0/UseDeferred.fs.js";
import { PagingQuery } from "../../../shared/Paging.fs.js";
import { Page } from "../components/Page.fs.js";
import { startImmediate } from "../fable_modules/fable-library.3.7.17/Async.js";
import { singleton as singleton_2 } from "../fable_modules/fable-library.3.7.17/AsyncBuilder.js";
import { EnqueueSnackbarOption, SnackbarProp_Variant_Z609E1E86, ProviderContext__enqueueSnackbar_Z1776A768 } from "../../../Notistack/Notistack.fs.js";

export function EditPartnerForm(editPartnerFormInputProps) {
    let f1, f1_1, f1_2, matchValue, cname, f1_3;
    const data = editPartnerFormInputProps.data;
    const onEditPartner = editPartnerFormInputProps.onEditPartner;
    const partnerWithAdis = editPartnerFormInputProps.partnerWithAdis;
    const partner = partnerWithAdis.Partner;
    const name = useFormField(NonEmptyString___UnsafeUnwrap_Z73AD07C(partner.CompanyName), NonEmptyString_get_Create());
    const companyType = useFormField("", (f1 = NonEmptyString_get_Create(), (arg_2) => some(f1(arg_2))));
    const email = useFormField(Email___UnsafeUnwrap_Z27D11339(partner.Email), Email_get_Create());
    const phoneNumber = useFormField(PhoneNumber___UnsafeUnwrap_23E45174(partner.PhoneNumber), PhoneNumber_get_Create());
    const contactFirstName = useFormField(Partner__get_Firstname(partner), (f1_1 = NonEmptyString_get_Create(), (arg_6) => some(f1_1(arg_6))));
    const contactLastName = useFormField(Partner__get_LastName(partner), (f1_2 = NonEmptyString_get_Create(), (arg_8) => some(f1_2(arg_8))));
    const vatNumber = useFormField(defaultArg(map(VatNumber___UnsafeUnwrap_18C04ACB, partner.VatNumber), ""), VatNumber_get_Create());
    const patternInput = useFeliz_React__React_useState_Static_1505(partner.IsCibPartner);
    const isCibPartner = patternInput[0];
    const isVme = useToggle(partner.IsVME);
    const isSocialHousingCompany = useToggle(partner.IsSocialHousingCompany);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(ofList(partnerWithAdis.PartnerAdis, {
        Compare: compare,
    }));
    const adiValues = patternInput_1[0];
    const minimumRole = useFormField(partner.MinimumRole, SelectOptionData$1_Create_Z510A3DC0);
    const commercialName = useFormField((matchValue = partner.CommercialName, (matchValue != null) ? ((cname = matchValue, `${cname.Value}`)) : ""), (f1_3 = NonEmptyString_get_Create(), (arg_12) => some(f1_3(arg_12))));
    let bus_1;
    const matchValue_1 = partner.Address.Bus;
    bus_1 = ((matchValue_1 == null) ? "" : NonEmptyString___UnsafeUnwrap_Z73AD07C(matchValue_1));
    const address = useAddressForm(NonEmptyString___UnsafeUnwrap_Z73AD07C(partner.Address.Street), NonEmptyString___UnsafeUnwrap_Z73AD07C(partner.Address.Number), bus_1, NonEmptyString___UnsafeUnwrap_Z73AD07C(partner.Address.PostalCode), NonEmptyString___UnsafeUnwrap_Z73AD07C(partner.Address.City));
    const form = useFormValidator(commercialName.Validator, (r_8, x_10) => ({
        Address: r_8.Address,
        CommercialName: x_10,
        CompanyType: r_8.CompanyType,
        ContactFirstName: r_8.ContactFirstName,
        ContactLastName: r_8.ContactLastName,
        Email: r_8.Email,
        MinimumRole: r_8.MinimumRole,
        Name: r_8.Name,
        PhoneNumber: r_8.PhoneNumber,
        VatNumber: r_8.VatNumber,
    }), useFormValidator(minimumRole.Validator, (r_7, x_9) => ({
        Address: r_7.Address,
        CompanyType: r_7.CompanyType,
        ContactFirstName: r_7.ContactFirstName,
        ContactLastName: r_7.ContactLastName,
        Email: r_7.Email,
        MinimumRole: x_9,
        Name: r_7.Name,
        PhoneNumber: r_7.PhoneNumber,
        VatNumber: r_7.VatNumber,
    }), useFormValidator(vatNumber.Validator, (r_6, x_8) => ({
        Address: r_6.Address,
        CompanyType: r_6.CompanyType,
        ContactFirstName: r_6.ContactFirstName,
        ContactLastName: r_6.ContactLastName,
        Email: r_6.Email,
        Name: r_6.Name,
        PhoneNumber: r_6.PhoneNumber,
        VatNumber: x_8,
    }), useFormValidator(phoneNumber.Validator, (r_5, x_7) => ({
        Address: r_5.Address,
        CompanyType: r_5.CompanyType,
        ContactFirstName: r_5.ContactFirstName,
        ContactLastName: r_5.ContactLastName,
        Email: r_5.Email,
        Name: r_5.Name,
        PhoneNumber: x_7,
    }), useFormValidator(contactLastName.Validator, (r_4, x_6) => ({
        Address: r_4.Address,
        CompanyType: r_4.CompanyType,
        ContactFirstName: r_4.ContactFirstName,
        ContactLastName: x_6,
        Email: r_4.Email,
        Name: r_4.Name,
    }), useFormValidator(contactFirstName.Validator, (r_3, x_5) => ({
        Address: r_3.Address,
        CompanyType: r_3.CompanyType,
        ContactFirstName: x_5,
        Email: r_3.Email,
        Name: r_3.Name,
    }), useFormValidator(email.Validator, (r_2, x_4) => ({
        Address: r_2.Address,
        CompanyType: r_2.CompanyType,
        Email: x_4,
        Name: r_2.Name,
    }), useFormValidator(address.Validator, (r_1, x_3) => ({
        Address: x_3,
        CompanyType: r_1.CompanyType,
        Name: r_1.Name,
    }), useFormValidator(companyType.Validator, (r, x_2) => ({
        CompanyType: x_2,
        Name: r.Name,
    }), useFormValidator(name.Validator, (unitVar, x_1) => ({
        Name: x_1,
    }), useForm()))))))))));
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setDisable = patternInput_2[1];
    const disable = patternInput_2[0];
    return createElement(Col, {
        classes: singleton(Gap.M),
        children: toList(delay(() => append(singleton_1(createElement(VmeSocialToggles, {
            vme: isVme,
            social: isSocialHousingCompany,
            disabled: false,
            companyTypeField: companyType,
        })), delay(() => append(singleton_1(TextField(vatNumber, "BTW", "", false)), delay(() => append(singleton_1(TextField(commercialName, "Commercial Name", "", true)), delay(() => append(singleton_1(TextField(name, "Bedrijfsnaam", "", true)), delay(() => append(singleton_1(TextField(companyType, "Bedrijfstype", "", false)), delay(() => append(singleton_1(TextField(contactFirstName, "Contact Voornaam", "", false)), delay(() => append(singleton_1(TextField(contactLastName, "Contact Naam", "", false)), delay(() => append(singleton_1(TextField(email, "Email adres", "", true)), delay(() => append(singleton_1(TextField(phoneNumber, "Telefoon", "", true)), delay(() => append(singleton_1(createElement(CreateAddressFormSmallHeader, {
            addressForm: address,
            heading: void 0,
            disabled: false,
        })), delay(() => append(singleton_1(createElement(Row, {
            classes: singleton(MarginTop.M),
            children: singleton(MuiHelpers_createElement(FormControlLabel, [["label", "CIB Partner"], ["control", MuiHelpers_createElement(Switch, [["checked", isCibPartner]])], ["onChange", (e) => {
                patternInput[1](Browser_Types_Event__Event_get_Checked(e));
            }]])),
        })), delay(() => append(singleton_1(createElement("h2", {
            children: ["Minimum Adi Role"],
        })), delay(() => {
            let elements_1;
            return append(singleton_1(MuiHelpers_createElement(RadioGroup, [(elements_1 = [OptionField(minimumRole, new AdiRole(0), AdiRoleModule_string(new AdiRole(0)), "", true), OptionField(minimumRole, new AdiRole(1), AdiRoleModule_string(new AdiRole(1)), "", true), OptionField(minimumRole, new AdiRole(2), AdiRoleModule_string(new AdiRole(2)), "", true), OptionField(minimumRole, new AdiRole(3), AdiRoleModule_string(new AdiRole(3)), "", true)], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])])), delay(() => append(singleton_1(createElement("h2", {
                children: ["ADIs (Partner)"],
            })), delay(() => {
                let matchValue_2, data_2;
                return append((matchValue_2 = data, (matchValue_2 == null) ? singleton_1(null) : ((data_2 = matchValue_2, singleton_1(createElement(DisplayDeferred, {
                    data: data_2,
                    view: (adis) => {
                        let elements_2;
                        return MuiHelpers_createElement(RadioGroup, [["row", false], (elements_2 = toList(delay(() => collect((adi) => {
                            const adiName = Adi_nameAndRole(adi);
                            return singleton_1(createElement(AdiCheckbox, {
                                label: adiName,
                                adiValue: adi,
                                values: adiValues,
                                setAdis: patternInput_1[1],
                                disabled: false,
                            }));
                        }, adis.Data))), ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]);
                    },
                }))))), delay(() => singleton_1(createElement(Row, {
                    classes: ofArray([Gap.M, MarginTop.L]),
                    children: ofArray([MuiHelpers_createElement(Button, [["disabled", disable], ["color", "default"], ["variant", "contained"], ["onClick", (_arg_1) => {
                        RouterModule_nav(ofArray(Page$2__toUrl_2B594(partnersDetail, partner.Id.Value)), 1, 1);
                    }], ["children", Interop_reactApi.Children.toArray(["Annuleren"])]]), MuiHelpers_createElement(Button, [["disabled", disable], ["color", "primary"], ["variant", "contained"], ["onClick", (_arg) => {
                        iterate((data_1) => {
                            setDisable(true);
                            console.log(data_1);
                            onEditPartner(() => {
                                setDisable(false);
                            })(partner.Id)(new PartnerData(data_1.Name, data_1.CompanyType, data_1.ContactFirstName, data_1.ContactLastName, data_1.Address, data_1.Email, data_1.PhoneNumber, data_1.VatNumber, isCibPartner, isVme.State, isSocialHousingCompany.State, adiValues, data_1.MinimumRole.Value, partner.PartnerType, data_1.CommercialName));
                        }, toArray(form.Validate()));
                    }], ["children", Interop_reactApi.Children.toArray(["Bewaren"])]])]),
                }))));
            }))));
        })))))))))))))))))))))))))),
    });
}

export function PartnerEdit(partnerEditInputProps) {
    const partnerId = partnerEditInputProps.partnerId;
    const api = partnerEditInputProps.api;
    const currentPage = partnerEditInputProps.currentPage;
    const snackbar = useSnackbar();
    const adiData = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetAdis(new PagingQuery(0, 999), void 0), []);
    const data = useFeliz_React__React_useDeferred_Static_2344FC52(api.GetPartnerWithAdis(new PartnerId(partnerId)), []);
    return createElement(Page, {
        currentPage: currentPage,
        title: "Partnergegevens",
        actions: [],
        children: [Card([createElement(DisplayDeferred, {
            data: data,
            view: (partner) => {
                if (partner == null) {
                    return createElement(ErrorText, {
                        text: `Geen partner gevonden met ID ${partnerId}`,
                    });
                }
                else {
                    const partner_1 = partner;
                    return createElement(EditPartnerForm, {
                        partnerWithAdis: partner_1,
                        onEditPartner: (onCompleted) => ((id) => ((partnerData) => {
                            startImmediate(singleton_2.Delay(() => singleton_2.TryFinally(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(api.EditPartner(id, partnerData), (_arg) => {
                                const result = _arg;
                                if (result.tag === 0) {
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, "De partner is aangepast", SnackbarProp_Variant_Z609E1E86("success"), new EnqueueSnackbarOption(1, false));
                                    RouterModule_nav(ofArray(Page$2__toUrl_2B594(partnersDetail, partnerId)), 1, 1);
                                    return singleton_2.Zero();
                                }
                                else {
                                    const err = result.fields[0];
                                    console.error(err);
                                    ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${err}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                    return singleton_2.Zero();
                                }
                            })), (_arg_1) => {
                                const e = _arg_1;
                                console.log(e);
                                ProviderContext__enqueueSnackbar_Z1776A768(snackbar, `Een onbekende fout is opgetreden. Gelieve support te contacteren.[${e.message}]`, SnackbarProp_Variant_Z609E1E86("error"), new EnqueueSnackbarOption(1, false));
                                return singleton_2.Zero();
                            })), () => {
                                onCompleted();
                            })));
                        })),
                        data: adiData,
                    });
                }
            },
        })])],
    });
}

