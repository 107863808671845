import { fss } from "../client/src/fable_modules/Fss-lib.Fable.1.0.3/FssFable.fs.js";
import { empty, singleton, append, delay, toList } from "../client/src/fable_modules/fable-library.3.7.17/Seq.js";
import { BorderClasses_BorderStyleParent__get_solid, BorderClasses_BorderWidthParent__get_thin } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Border.fs.js";
import { BorderColor, BorderStyle, BorderWidth } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Border.fs.js";
import { ColorClass_Color__hex_Z721C83C5, ColorClass_Color__get_black } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Color.fs.js";
import { BackgroundColor } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Background.fs.js";
import { Var } from "./Utils.fs.js";
import { CssRuleWithNone__get_none, CssRuleWithAuto__get_auto } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/MasterTypes.fs.js";
import { Margin, MarginTop } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Margin.fs.js";
import { Label_Label } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Content.fs.js";
import { unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Units.fs.js";
import { vh, px, pct } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Functions.fs.js";
import { MinHeight, Width, Height } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/ContentSize.fs.js";
import { PositionClasses_Position__get_fixed$0027, PositionClasses_Position__get_absolute, PositionClasses_Position__get_relative } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Position.fs.js";
import { Right, Top, Position } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Position.fs.js";
import { singleton as singleton_1, ofArray } from "../client/src/fable_modules/fable-library.3.7.17/List.js";
import { op_BangGreater } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Selector.fs.js";
import { Html } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Html.fs.js";
import { Margin__get_auto } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Margin.fs.js";
import { DisplayClasses_DisplayClass__get_flex, DisplayClasses_DisplayClass__get_grid } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Display.fs.js";
import { Display } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Display.fs.js";
import { Custom } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Custom.fs.js";
import { OverflowClasses_OverflowClass__get_scroll, OverflowClasses_OverflowClass__get_hidden } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Overflow.fs.js";
import { Overflow, OverflowX } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Overflow.fs.js";
import { Media_Feature, MediaClasses_Media__query } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Media.fs.js";
import { Media } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Media.fs.js";
import { FlexClasses_JustifyContent__get_spaceBetween, FlexClasses_JustifyContent__get_center, FlexClasses_AlignItems__get_center } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Flex.fs.js";
import { JustifyContent, AlignItems } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Flex.fs.js";
import { VisibilityClasses_ZIndex__value_Z524259A4 } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/Types/Visibility.fs.js";
import { ZIndex } from "../client/src/fable_modules/Fss-lib.Core.1.0.4/css/Visibility.fs.js";

export function SignatureBox(isSignatureValid) {
    return fss(toList(delay(() => append(singleton(BorderClasses_BorderWidthParent__get_thin(BorderWidth)), delay(() => append(singleton(BorderClasses_BorderStyleParent__get_solid(BorderStyle)), delay(() => append(singleton(ColorClass_Color__get_black(BorderColor)), delay(() => append(singleton(ColorClass_Color__hex_Z721C83C5(BackgroundColor, "fff")), delay(() => ((!isSignatureValid) ? singleton(ColorClass_Color__hex_Z721C83C5(BorderColor, Var.Colors.Danger)) : empty()))))))))))));
}

export const SignatureDocument = fss(ofArray([ColorClass_Color__hex_Z721C83C5(BackgroundColor, "eee"), CssRuleWithAuto__get_auto(MarginTop), Label_Label("SignatureDocument"), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Height, pct(100)), PositionClasses_Position__get_relative(Position), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Top, px(78))]));

export const SignatureSection = fss(singleton_1(unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Width, px(500))));

export const SignaturePage = fss(ofArray([unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MarginTop, px(10)), op_BangGreater(new Html(19), singleton_1(Margin__get_auto(Margin))), Label_Label("SignaturePage")]));

export const PdfPage = fss(singleton_1(Label_Label("PdfPage")));

export const PdfPageSplit = fss(singleton_1(Label_Label("PdfPageSplit")));

export const PdfPageContainer = fss(ofArray([unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Width, pct(100)), Label_Label("PdfPageContainer"), DisplayClasses_DisplayClass__get_grid(Display), Custom("grid-template-rows", "auto auto"), OverflowClasses_OverflowClass__get_hidden(OverflowX), ColorClass_Color__hex_Z721C83C5(BackgroundColor, "eee"), (() => {
    const arg_1 = singleton_1(Custom("grid-template-rows", "auto auto"));
    return MediaClasses_Media__query(Media, singleton_1(new Media_Feature(20, px(700))), arg_1);
})()]));

export const SignaturePageMessage = fss(ofArray([Margin__get_auto(Margin), Custom("height", "calc(100vh - 200px)"), FlexClasses_AlignItems__get_center(AlignItems), FlexClasses_JustifyContent__get_center(JustifyContent)]));

export const SignatureSlider = fss(ofArray([unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Width, px(630)), PositionClasses_Position__get_absolute(Position), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Top, px(66)), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(MinHeight, vh(100)), VisibilityClasses_ZIndex__value_Z524259A4(ZIndex, 998), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Right, px(0)), ColorClass_Color__hex_Z721C83C5(BackgroundColor, "eee"), Custom("box-shadow", "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"), (() => {
    const arg_1 = ofArray([unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Width, pct(100)), PositionClasses_Position__get_relative(Position)]);
    return MediaClasses_Media__query(Media, singleton_1(new Media_Feature(20, px(1200))), arg_1);
})()]));

export const SigningBoxOpen = fss(ofArray([Custom("width", "calc(100% - 630px)"), Label_Label("SigningBoxOpen"), Custom("transition", "width 2s"), (() => {
    const arg_1 = singleton_1(CssRuleWithNone__get_none(Display));
    return MediaClasses_Media__query(Media, singleton_1(new Media_Feature(20, px(1200))), arg_1);
})()]));

export const SigningBoxOpenAnimation = fss(ofArray([Label_Label("SigningBoxOpen"), Custom("transition", "width 2s")]));

export const PdfPageWrapper = fss(ofArray([PositionClasses_Position__get_relative(Position), OverflowClasses_OverflowClass__get_hidden(Overflow), (() => {
    const arg_1 = ofArray([OverflowClasses_OverflowClass__get_scroll(Overflow), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Height, vh(100))]);
    return MediaClasses_Media__query(Media, singleton_1(new Media_Feature(20, px(1200))), arg_1);
})()]));

export const SignBoxCloseButton = fss(ofArray([PositionClasses_Position__get_absolute(Position), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Top, px(10)), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Right, px(10))]));

export const SignaturePageToolbar = fss(ofArray([Custom("border-bottom", "1px solid rgb(0,0,0, 10%)"), DisplayClasses_DisplayClass__get_flex(Display), FlexClasses_JustifyContent__get_spaceBetween(JustifyContent), PositionClasses_Position__get_fixed$0027(Position), VisibilityClasses_ZIndex__value_Z524259A4(ZIndex, 999), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Width, pct(100)), ColorClass_Color__hex_Z721C83C5(BackgroundColor, "fff")]));

export const SignAssignmentButton = fss(ofArray([FlexClasses_JustifyContent__get_center(JustifyContent), unitHelpers_CssRuleWithAutoLength__value_Z498FEB3B(Height, pct(100))]));

