import { Union } from "../client/src/fable_modules/fable-library.3.7.17/Types.js";
import { union_type } from "../client/src/fable_modules/fable-library.3.7.17/Reflection.js";
import { useFormField } from "../CustomHooks/ComposedForm.fs.js";
import { NonEmptyString, InspectionLocation, SelectOptionData$1_Create_Z510A3DC0 } from "../shared/Domain.fs.js";
import { createElement } from "react";
import * as react from "react";
import { MuiHelpers_createElement } from "../client/src/fable_modules/Feliz.MaterialUI.1.2.6/./Mui.fs.js";
import RadioGroup from "@material-ui/core/RadioGroup";
import { TextFieldDisabled, OptionField } from "./DesignSystem.fs.js";
import { Interop_reactApi } from "../client/src/fable_modules/Feliz.1.68.0/./Interop.fs.js";
import Divider from "@material-ui/core/Divider";
import { empty as empty_1, append, singleton, delay, toList } from "../client/src/fable_modules/fable-library.3.7.17/Seq.js";
import { InspectionLocation_type$0027 } from "../shared/Format.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "../client/src/fable_modules/Feliz.1.68.0/React.fs.js";
import Checkbox from "@material-ui/core/Checkbox";
import { ofArray, empty, filter, singleton as singleton_1, append as append_1, contains } from "../client/src/fable_modules/fable-library.3.7.17/List.js";
import { safeHash, equals } from "../client/src/fable_modules/fable-library.3.7.17/Util.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Browser_Types_Event__Event_get_Checked } from "../client/src/fable_modules/Feliz.MaterialUI.1.2.6/../Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { MarginTop, PaddingLeft, PaddingTop, MarginBottom, TextSize, DossierInspectionLocation, error as error_1 } from "../Styles/Utils.fs.js";
import { Col, Row } from "../Components/UtilComponents.fs.js";
import { join } from "../client/src/fable_modules/fable-library.3.7.17/String.js";
import { useToggle } from "../CustomHooks/CustomHooks.fs.js";
import { AssignmentConfirmationInspectionLocation } from "../Styles/ClientPageStyles.fs.js";
import Button from "@material-ui/core/Button";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";

export class InspectionLocationType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Residential", "NonResidential", "Community", "Public"];
    }
}

export function InspectionLocationType$reflection() {
    return union_type("InspectionLocation.InspectionLocationType", [], InspectionLocationType, () => [[], [], [], []]);
}

export function ChooseInspectionLocationForm(chooseInspectionLocationFormInputProps) {
    let elements, elements_1;
    const publicOther = chooseInspectionLocationFormInputProps.publicOther;
    const inSocialUseOther = chooseInspectionLocationFormInputProps.inSocialUseOther;
    const nonResidentialOther = chooseInspectionLocationFormInputProps.nonResidentialOther;
    const residentialOther = chooseInspectionLocationFormInputProps.residentialOther;
    const inspectionLocationData = chooseInspectionLocationFormInputProps.inspectionLocationData;
    const inspectionLocationType = useFormField(new InspectionLocationType(0), SelectOptionData$1_Create_Z510A3DC0);
    const xs = [createElement("h2", {
        children: ["Inspectielocatie"],
    }), MuiHelpers_createElement(RadioGroup, [["row", true], (elements = [OptionField(inspectionLocationType, new InspectionLocationType(0), "Residentieel", "", false), OptionField(inspectionLocationType, new InspectionLocationType(1), "Niet-residentieel", "", false), OptionField(inspectionLocationType, new InspectionLocationType(2), "Maatschappelijk", "", false), OptionField(inspectionLocationType, new InspectionLocationType(3), "Openbaar", "", false)], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]), MuiHelpers_createElement(Divider, []), MuiHelpers_createElement(RadioGroup, [["row", false], (elements_1 = toList(delay(() => {
        const matchValue = inspectionLocationType.FieldValue;
        if (matchValue == null) {
            return singleton(null);
        }
        else {
            const matchValue_1 = matchValue;
            switch (matchValue_1.tag) {
                case 1: {
                    return append(singleton(OptionField(inspectionLocationData, new InspectionLocation(4), InspectionLocation_type$0027(new InspectionLocation(4)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(5), InspectionLocation_type$0027(new InspectionLocation(5)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(6), InspectionLocation_type$0027(new InspectionLocation(6)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(7), InspectionLocation_type$0027(new InspectionLocation(7)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(8), InspectionLocation_type$0027(new InspectionLocation(8)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(9), InspectionLocation_type$0027(new InspectionLocation(9)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(10), InspectionLocation_type$0027(new InspectionLocation(10)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(11), InspectionLocation_type$0027(new InspectionLocation(11)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(12, new NonEmptyString(residentialOther.FieldValue)), "Andere", "", false)), delay(() => {
                        let matchValue_3;
                        return singleton(TextFieldDisabled(nonResidentialOther, "", "", true, (matchValue_3 = inspectionLocationData.FieldValue, (matchValue_3 != null) ? (!(matchValue_3.tag === 12)) : true)));
                    }))))))))))))))))));
                }
                case 2: {
                    return append(singleton(OptionField(inspectionLocationData, new InspectionLocation(29), InspectionLocation_type$0027(new InspectionLocation(29)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(24), InspectionLocation_type$0027(new InspectionLocation(24)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(25), InspectionLocation_type$0027(new InspectionLocation(25)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(26), InspectionLocation_type$0027(new InspectionLocation(26)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(27), InspectionLocation_type$0027(new InspectionLocation(27)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(28), InspectionLocation_type$0027(new InspectionLocation(28)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(30, new NonEmptyString(inSocialUseOther.FieldValue)), "Andere", "", false)), delay(() => {
                        let matchValue_4;
                        return singleton(TextFieldDisabled(inSocialUseOther, "", "", true, (matchValue_4 = inspectionLocationData.FieldValue, (matchValue_4 != null) ? (!(matchValue_4.tag === 30)) : true)));
                    }))))))))))))));
                }
                case 3: {
                    return append(singleton(OptionField(inspectionLocationData, new InspectionLocation(0), InspectionLocation_type$0027(new InspectionLocation(0)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(1), InspectionLocation_type$0027(new InspectionLocation(1)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(2), InspectionLocation_type$0027(new InspectionLocation(2)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(3, new NonEmptyString(publicOther.FieldValue)), "Andere", "", false)), delay(() => {
                        let matchValue_5;
                        return singleton(TextFieldDisabled(publicOther, "", "", true, (matchValue_5 = inspectionLocationData.FieldValue, (matchValue_5 != null) ? (!(matchValue_5.tag === 3)) : true)));
                    }))))))));
                }
                default: {
                    return append(singleton(OptionField(inspectionLocationData, new InspectionLocation(13), InspectionLocation_type$0027(new InspectionLocation(13)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(14), InspectionLocation_type$0027(new InspectionLocation(14)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(15), InspectionLocation_type$0027(new InspectionLocation(15)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(16), InspectionLocation_type$0027(new InspectionLocation(16)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(19), InspectionLocation_type$0027(new InspectionLocation(19)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(20), InspectionLocation_type$0027(new InspectionLocation(20)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(21), InspectionLocation_type$0027(new InspectionLocation(21)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(23, new NonEmptyString(residentialOther.FieldValue)), "Andere", "", false)), delay(() => {
                        let matchValue_2;
                        return singleton(TextFieldDisabled(residentialOther, "", "", true, (matchValue_2 = inspectionLocationData.FieldValue, (matchValue_2 != null) ? (!(matchValue_2.tag === 23)) : true)));
                    }))))))))))))))));
                }
            }
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])])];
    return react.createElement(react.Fragment, {}, ...xs);
}

export function ChooseInspectionLocationFormCheckbox(chooseInspectionLocationFormCheckboxInputProps) {
    const hideGroupSelection = chooseInspectionLocationFormCheckboxInputProps.hideGroupSelection;
    const selectedLocationGroups = chooseInspectionLocationFormCheckboxInputProps.selectedLocationGroups;
    const publicOther = chooseInspectionLocationFormCheckboxInputProps.publicOther;
    const inSocialUseOther = chooseInspectionLocationFormCheckboxInputProps.inSocialUseOther;
    const nonResidentialOther = chooseInspectionLocationFormCheckboxInputProps.nonResidentialOther;
    const residentialOther = chooseInspectionLocationFormCheckboxInputProps.residentialOther;
    const inspectionLocationData = chooseInspectionLocationFormCheckboxInputProps.inspectionLocationData;
    const inspectionLocationType = useFormField(new InspectionLocationType(0), SelectOptionData$1_Create_Z510A3DC0);
    const patternInput = useFeliz_React__React_useState_Static_1505(selectedLocationGroups);
    const setSelectedLocationGroups = patternInput[1];
    const selectedLocationGroups_1 = patternInput[0];
    const CheckboxField = (formFieldHook, trueValue, label, error, required) => {
        const radi = MuiHelpers_createElement(Checkbox, [["checked", contains(trueValue, selectedLocationGroups_1, {
            Equals: equals,
            GetHashCode: safeHash,
        })]]);
        return MuiHelpers_createElement(FormControlLabel, toList(delay(() => append(singleton(["label", label]), delay(() => append(singleton(["control", radi]), delay(() => append(singleton(["onChange", (e) => {
            if (Browser_Types_Event__Event_get_Checked(e)) {
                if (!contains(trueValue, selectedLocationGroups_1, {
                    Equals: equals,
                    GetHashCode: safeHash,
                })) {
                    setSelectedLocationGroups(append_1(selectedLocationGroups_1, singleton_1(trueValue)));
                }
            }
            else {
                setSelectedLocationGroups(filter((s_1) => (!equals(s_1, trueValue)), selectedLocationGroups_1));
            }
        }]), delay(() => (formFieldHook.Error ? singleton(["classes.label", error_1]) : singleton(["classes.label", ""])))))))))));
    };
    const isGroupSelected = (group) => contains(group, selectedLocationGroups_1, {
        Equals: equals,
        GetHashCode: safeHash,
    });
    const xs_4 = [createElement("h2", {
        children: ["Inspectielocatie"],
    }), createElement(Row, {
        classes: singleton_1(DossierInspectionLocation),
        children: ofArray([createElement(Col, {
            classes: empty(),
            children: toList(delay(() => {
                let elements;
                return hideGroupSelection ? singleton(createElement("div", {
                    className: join(" ", [TextSize.M, MarginBottom.S]),
                    children: Interop_reactApi.Children.toArray(["Residentieel"]),
                })) : singleton(MuiHelpers_createElement(RadioGroup, [["row", true], (elements = [CheckboxField(inspectionLocationType, new InspectionLocationType(0), "Residentieel", "", false)], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]));
            })),
        }), createElement(Col, {
            classes: empty(),
            children: toList(delay(() => {
                let elements_1;
                return hideGroupSelection ? singleton(createElement("div", {
                    className: join(" ", [TextSize.M, MarginBottom.S]),
                    children: Interop_reactApi.Children.toArray(["Niet-residentieel"]),
                })) : singleton(MuiHelpers_createElement(RadioGroup, [["row", true], (elements_1 = [CheckboxField(inspectionLocationType, new InspectionLocationType(1), "Niet-residentieel", "", false)], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])]));
            })),
        }), createElement(Col, {
            classes: empty(),
            children: toList(delay(() => {
                let elements_2;
                return hideGroupSelection ? singleton(createElement("div", {
                    className: join(" ", [TextSize.M, MarginBottom.S]),
                    children: Interop_reactApi.Children.toArray(["Maatschappelijk"]),
                })) : singleton(MuiHelpers_createElement(RadioGroup, [["row", true], (elements_2 = [CheckboxField(inspectionLocationType, new InspectionLocationType(2), "Maatschappelijk", "", false)], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])]));
            })),
        }), createElement(Col, {
            classes: empty(),
            children: toList(delay(() => {
                let elements_3;
                return hideGroupSelection ? singleton(createElement("div", {
                    className: join(" ", [TextSize.M, MarginBottom.S]),
                    children: Interop_reactApi.Children.toArray(["Openbaar"]),
                })) : singleton(MuiHelpers_createElement(RadioGroup, [["row", true], (elements_3 = [CheckboxField(inspectionLocationType, new InspectionLocationType(3), "Openbaar", "", false)], ["children", Interop_reactApi.Children.toArray(Array.from(elements_3))])]));
            })),
        })]),
    }), MuiHelpers_createElement(Divider, []), createElement(Row, {
        classes: ofArray([DossierInspectionLocation, PaddingTop.S]),
        children: ofArray([createElement(Col, {
            classes: empty(),
            children: toList(delay(() => {
                let elements_4;
                return isGroupSelected(new InspectionLocationType(0)) ? singleton(MuiHelpers_createElement(RadioGroup, [["row", false], (elements_4 = toList(delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(13), InspectionLocation_type$0027(new InspectionLocation(13)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(14), InspectionLocation_type$0027(new InspectionLocation(14)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(15), InspectionLocation_type$0027(new InspectionLocation(15)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(16), InspectionLocation_type$0027(new InspectionLocation(16)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(17), InspectionLocation_type$0027(new InspectionLocation(17)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(19), InspectionLocation_type$0027(new InspectionLocation(19)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(20), InspectionLocation_type$0027(new InspectionLocation(20)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(21), InspectionLocation_type$0027(new InspectionLocation(21)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(22), InspectionLocation_type$0027(new InspectionLocation(22)), "", false)), delay(() => {
                    let matchValue, v;
                    return append(singleton(OptionField(inspectionLocationData, (matchValue = inspectionLocationData.FieldValue, (matchValue != null) ? ((matchValue.tag === 23) ? ((v = matchValue.fields[0], new InspectionLocation(23, v))) : (new InspectionLocation(23, new NonEmptyString("")))) : (new InspectionLocation(23, new NonEmptyString("")))), "Andere", "", false)), delay(() => {
                        let residentialOtherDisabled;
                        const matchValue_1 = inspectionLocationData.FieldValue;
                        let pattern_matching_result_1;
                        if (matchValue_1 != null) {
                            if (matchValue_1.tag === 23) {
                                pattern_matching_result_1 = 0;
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                        switch (pattern_matching_result_1) {
                            case 0: {
                                residentialOtherDisabled = false;
                                break;
                            }
                            case 1: {
                                residentialOtherDisabled = true;
                                break;
                            }
                        }
                        return (!residentialOtherDisabled) ? singleton(createElement(Col, {
                            classes: singleton_1(PaddingLeft.L),
                            children: singleton_1(TextFieldDisabled(residentialOther, "", "", true, residentialOtherDisabled)),
                        })) : empty_1();
                    }));
                })))))))))))))))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elements_4))])])) : empty_1();
            })),
        }), createElement(Col, {
            classes: empty(),
            children: toList(delay(() => {
                let elements_5;
                return isGroupSelected(new InspectionLocationType(1)) ? singleton(MuiHelpers_createElement(RadioGroup, [["row", false], (elements_5 = toList(delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(4), InspectionLocation_type$0027(new InspectionLocation(4)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(5), InspectionLocation_type$0027(new InspectionLocation(5)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(6), InspectionLocation_type$0027(new InspectionLocation(6)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(7), InspectionLocation_type$0027(new InspectionLocation(7)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(8), InspectionLocation_type$0027(new InspectionLocation(8)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(9), InspectionLocation_type$0027(new InspectionLocation(9)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(10), InspectionLocation_type$0027(new InspectionLocation(10)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(11), InspectionLocation_type$0027(new InspectionLocation(11)), "", false)), delay(() => {
                    let matchValue_2, v_1;
                    return append(singleton(OptionField(inspectionLocationData, (matchValue_2 = inspectionLocationData.FieldValue, (matchValue_2 != null) ? ((matchValue_2.tag === 12) ? ((v_1 = matchValue_2.fields[0], new InspectionLocation(12, v_1))) : (new InspectionLocation(12, new NonEmptyString("")))) : (new InspectionLocation(12, new NonEmptyString("")))), "Andere", "", false)), delay(() => {
                        let nonResidentialOtherDisabled;
                        const matchValue_3 = inspectionLocationData.FieldValue;
                        let pattern_matching_result_2;
                        if (matchValue_3 != null) {
                            if (matchValue_3.tag === 12) {
                                pattern_matching_result_2 = 0;
                            }
                            else {
                                pattern_matching_result_2 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                        switch (pattern_matching_result_2) {
                            case 0: {
                                nonResidentialOtherDisabled = false;
                                break;
                            }
                            case 1: {
                                nonResidentialOtherDisabled = true;
                                break;
                            }
                        }
                        return (!nonResidentialOtherDisabled) ? singleton(createElement(Col, {
                            classes: singleton_1(PaddingLeft.L),
                            children: singleton_1(TextFieldDisabled(nonResidentialOther, "", "", true, nonResidentialOtherDisabled)),
                        })) : empty_1();
                    }));
                })))))))))))))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elements_5))])])) : empty_1();
            })),
        }), createElement(Col, {
            classes: empty(),
            children: toList(delay(() => (isGroupSelected(new InspectionLocationType(2)) ? append(singleton(OptionField(inspectionLocationData, new InspectionLocation(29), InspectionLocation_type$0027(new InspectionLocation(29)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(24), InspectionLocation_type$0027(new InspectionLocation(24)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(25), InspectionLocation_type$0027(new InspectionLocation(25)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(26), InspectionLocation_type$0027(new InspectionLocation(26)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(27), InspectionLocation_type$0027(new InspectionLocation(27)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(28), InspectionLocation_type$0027(new InspectionLocation(28)), "", false)), delay(() => {
                let matchValue_4, v_2;
                return append(singleton(OptionField(inspectionLocationData, (matchValue_4 = inspectionLocationData.FieldValue, (matchValue_4 != null) ? ((matchValue_4.tag === 30) ? ((v_2 = matchValue_4.fields[0], new InspectionLocation(30, v_2))) : (new InspectionLocation(30, new NonEmptyString("")))) : (new InspectionLocation(30, new NonEmptyString("")))), "Andere", "", false)), delay(() => {
                    let inSocialUseOtherDisabled;
                    const matchValue_5 = inspectionLocationData.FieldValue;
                    let pattern_matching_result_3;
                    if (matchValue_5 != null) {
                        if (matchValue_5.tag === 30) {
                            pattern_matching_result_3 = 0;
                        }
                        else {
                            pattern_matching_result_3 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_3 = 1;
                    }
                    switch (pattern_matching_result_3) {
                        case 0: {
                            inSocialUseOtherDisabled = false;
                            break;
                        }
                        case 1: {
                            inSocialUseOtherDisabled = true;
                            break;
                        }
                    }
                    return (!inSocialUseOtherDisabled) ? singleton(createElement(Col, {
                        classes: singleton_1(PaddingLeft.L),
                        children: singleton_1(TextFieldDisabled(inSocialUseOther, "", "", true, inSocialUseOtherDisabled)),
                    })) : empty_1();
                }));
            })))))))))))) : empty_1()))),
        }), createElement(Col, {
            classes: empty(),
            children: toList(delay(() => (isGroupSelected(new InspectionLocationType(3)) ? append(singleton(OptionField(inspectionLocationData, new InspectionLocation(0), InspectionLocation_type$0027(new InspectionLocation(0)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(1), InspectionLocation_type$0027(new InspectionLocation(1)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(2), InspectionLocation_type$0027(new InspectionLocation(2)), "", false)), delay(() => {
                let matchValue_6, v_3;
                return append(singleton(OptionField(inspectionLocationData, (matchValue_6 = inspectionLocationData.FieldValue, (matchValue_6 != null) ? ((matchValue_6.tag === 3) ? ((v_3 = matchValue_6.fields[0], new InspectionLocation(3, v_3))) : (new InspectionLocation(3, new NonEmptyString("")))) : (new InspectionLocation(3, new NonEmptyString("")))), "Andere", "", false)), delay(() => {
                    let publicOtherDisabled;
                    const matchValue_7 = inspectionLocationData.FieldValue;
                    let pattern_matching_result_4;
                    if (matchValue_7 != null) {
                        if (matchValue_7.tag === 3) {
                            pattern_matching_result_4 = 0;
                        }
                        else {
                            pattern_matching_result_4 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_4 = 1;
                    }
                    switch (pattern_matching_result_4) {
                        case 0: {
                            publicOtherDisabled = false;
                            break;
                        }
                        case 1: {
                            publicOtherDisabled = true;
                            break;
                        }
                    }
                    return (!publicOtherDisabled) ? singleton(createElement(Col, {
                        classes: singleton_1(PaddingLeft.L),
                        children: singleton_1(TextFieldDisabled(publicOther, "", "", true, publicOtherDisabled)),
                    })) : empty_1();
                }));
            })))))) : empty_1()))),
        })]),
    })];
    return react.createElement(react.Fragment, {}, ...xs_4);
}

export function ChooseInspectionLocationAppointmentConfirmationForm(chooseInspectionLocationAppointmentConfirmationFormInputProps) {
    let matchValue, x, elements, elements_1, props_4, props_5, elements_2;
    const otherField = chooseInspectionLocationAppointmentConfirmationFormInputProps.otherField;
    const inspectionLocationData = chooseInspectionLocationAppointmentConfirmationFormInputProps.inspectionLocationData;
    const panelState = useToggle((matchValue = inspectionLocationData.FieldValue, (matchValue == null) ? false : ((x = matchValue, (x.tag === 17) ? true : ((x.tag === 18) ? true : ((x.tag === 29) ? true : ((x.tag === 24) ? true : ((x.tag === 25) ? true : ((x.tag === 26) ? true : ((x.tag === 27) ? true : ((x.tag === 28) ? true : ((x.tag === 0) ? true : ((x.tag === 1) ? true : ((x.tag === 2) ? true : (x.tag === 23)))))))))))))));
    const inspectionLocationType = useFormField(new InspectionLocationType(0), SelectOptionData$1_Create_Z510A3DC0);
    const patternInput = useFeliz_React__React_useState_Static_1505(ofArray([new InspectionLocationType(0), new InspectionLocationType(1)]));
    const xs = [createElement("h2", {
        children: ["Inspectielocatie"],
    }), MuiHelpers_createElement(Divider, []), createElement(Row, {
        classes: ofArray([AssignmentConfirmationInspectionLocation, PaddingTop.M]),
        children: ofArray([createElement(Col, {
            classes: empty(),
            children: singleton_1(MuiHelpers_createElement(RadioGroup, [["row", false], (elements = [OptionField(inspectionLocationData, new InspectionLocation(13), InspectionLocation_type$0027(new InspectionLocation(13)), "", false), OptionField(inspectionLocationData, new InspectionLocation(14), InspectionLocation_type$0027(new InspectionLocation(14)), "", false), OptionField(inspectionLocationData, new InspectionLocation(15), InspectionLocation_type$0027(new InspectionLocation(15)), "", false), OptionField(inspectionLocationData, new InspectionLocation(16), InspectionLocation_type$0027(new InspectionLocation(16)), "", false), OptionField(inspectionLocationData, new InspectionLocation(19), InspectionLocation_type$0027(new InspectionLocation(19)), "", false), OptionField(inspectionLocationData, new InspectionLocation(20), InspectionLocation_type$0027(new InspectionLocation(20)), "", false), OptionField(inspectionLocationData, new InspectionLocation(21), InspectionLocation_type$0027(new InspectionLocation(21)), "", false), OptionField(inspectionLocationData, new InspectionLocation(22), InspectionLocation_type$0027(new InspectionLocation(22)), "", false)], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])),
        }), createElement(Col, {
            classes: empty(),
            children: singleton_1(MuiHelpers_createElement(RadioGroup, [["row", false], (elements_1 = [OptionField(inspectionLocationData, new InspectionLocation(4), InspectionLocation_type$0027(new InspectionLocation(4)), "", false), OptionField(inspectionLocationData, new InspectionLocation(5), InspectionLocation_type$0027(new InspectionLocation(5)), "", false), OptionField(inspectionLocationData, new InspectionLocation(6), InspectionLocation_type$0027(new InspectionLocation(6)), "", false), OptionField(inspectionLocationData, new InspectionLocation(7), InspectionLocation_type$0027(new InspectionLocation(7)), "", false), OptionField(inspectionLocationData, new InspectionLocation(8), InspectionLocation_type$0027(new InspectionLocation(8)), "", false), OptionField(inspectionLocationData, new InspectionLocation(9), InspectionLocation_type$0027(new InspectionLocation(9)), "", false), OptionField(inspectionLocationData, new InspectionLocation(10), InspectionLocation_type$0027(new InspectionLocation(10)), "", false), OptionField(inspectionLocationData, new InspectionLocation(11), InspectionLocation_type$0027(new InspectionLocation(11)), "", false)], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])])),
        })]),
    }), createElement(Row, {
        classes: ofArray([AssignmentConfirmationInspectionLocation, MarginBottom.L, MarginTop.L]),
        children: singleton_1(createElement(Col, {
            classes: empty(),
            children: ofArray([MuiHelpers_createElement(Button, [["onClick", (_arg) => {
                panelState.Toggle();
            }], ["className", join(" ", [])], ["variant", "outlined"], ["color", "default"], ["startIcon", panelState.State ? ((props_4 = {}, react.createElement(ExpandLess, props_4))) : ((props_5 = {}, react.createElement(ExpandMore, props_5)))], ["children", "Meer inspectie locaties"]]), MuiHelpers_createElement(Collapse, [["in", panelState.State], ["collapsedHeight", 0], (elements_2 = [createElement(Col, {
                classes: singleton_1(MarginTop.L),
                children: toList(delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(17), InspectionLocation_type$0027(new InspectionLocation(17)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(29), InspectionLocation_type$0027(new InspectionLocation(29)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(24), InspectionLocation_type$0027(new InspectionLocation(24)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(25), InspectionLocation_type$0027(new InspectionLocation(25)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(26), InspectionLocation_type$0027(new InspectionLocation(26)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(27), InspectionLocation_type$0027(new InspectionLocation(27)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(28), InspectionLocation_type$0027(new InspectionLocation(28)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(0), InspectionLocation_type$0027(new InspectionLocation(0)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(1), InspectionLocation_type$0027(new InspectionLocation(1)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(2), InspectionLocation_type$0027(new InspectionLocation(2)), "", false)), delay(() => append(singleton(OptionField(inspectionLocationData, new InspectionLocation(23, new NonEmptyString(otherField.FieldValue)), "Andere", "", false)), delay(() => {
                    let residentialOtherDisabled;
                    const matchValue_1 = inspectionLocationData.FieldValue;
                    let pattern_matching_result;
                    if (matchValue_1 != null) {
                        if (matchValue_1.tag === 23) {
                            pattern_matching_result = 0;
                        }
                        else if (matchValue_1.tag === 30) {
                            pattern_matching_result = 0;
                        }
                        else if (matchValue_1.tag === 12) {
                            pattern_matching_result = 0;
                        }
                        else if (matchValue_1.tag === 3) {
                            pattern_matching_result = 0;
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                    switch (pattern_matching_result) {
                        case 0: {
                            residentialOtherDisabled = false;
                            break;
                        }
                        case 1: {
                            residentialOtherDisabled = true;
                            break;
                        }
                    }
                    return (!residentialOtherDisabled) ? singleton(createElement(Col, {
                        classes: singleton_1(PaddingLeft.L),
                        children: singleton_1(TextFieldDisabled(otherField, "", "", true, residentialOtherDisabled)),
                    })) : empty_1();
                })))))))))))))))))))))))),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])])]),
        })),
    })];
    return react.createElement(react.Fragment, {}, ...xs);
}

